'use strict';

import angular from 'angular';
import countryPostalCodeFieldHtml from './country-postal-code-field.html'

/* @ngInject */
function countryPostalCodeField($log, config, postalCodeService) {
    function _link(scope) {
        scope.defaultDebounceOptions = config.getDebounceOptions();
        scope.lookupPostalCode = _lookupPostalCode;
        scope.location = {
            city: null,
            region: null
        };

        function _propagatePostalInfo(response) {
            let place;

            if (response.postal_codes && response.postal_codes.length) {
                place = response.postal_codes[0];
            } else {
                place = {
                    place_name: null,
                    admin_name1: null
                };
            }

            scope.location = {
                city: place.place_name,
                region: place.admin_name1
            };

            scope.vm.onSuccess(scope.location)
        }

        function _lookupPostalCode(postalCode) {
            const ngModel = scope.vm.formCtrl.postalCode;

            ngModel.$setValidity('missingCountryCode', !!postalCode.countryCode);
            ngModel.$setValidity('missingPostalCode', !!postalCode.postalCode);

            if (postalCode.countryCode && postalCode.postalCode) {
                postalCodeService.query({
                    country_code: postalCode.countryCode,
                    postal_code: postalCode.postalCode
                }).$promise
                    .then((response) => _propagatePostalInfo(response));
            } else {
                _propagatePostalInfo({});
            }
        }
    }

    return {
        scope: {
            postalCode: '=countryPostalCodeField',
            countryCodes: '=',
            onSuccess: '='
        },
        transclude: true,
        templateUrl: countryPostalCodeFieldHtml,
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        bindToController: true,
        link: {
            pre: _link
        },
        controller: function NoopController() {}
    };
}

export default angular
    .module('profile.countryPostalCodeField', [])
    .directive('countryPostalCodeField', countryPostalCodeField);
