'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _sortedIndexOf from 'lodash/sortedIndexOf'
import _startsWith from 'lodash/startsWith'
import _transform from 'lodash/transform'


/* @ngInject */
function connectedUsersService($q, $resource, $timeout, $log, api, config) {
    const _subscriptions = {},
        _resource = $resource(api.url +
            '/api/:activityType/:activityId/:thread/:threadId/:subResource/', {
            activityId: '@activity_id',
            activityType: '@activity_type'
        }, {
            connectedUsers: {
                method: 'GET',
                params: {subResource: 'connected_users'},
                isArray: false
            },
            contributors: {
                method: 'GET',
                params: {subResource: 'contributors'},
                isArray: false
            },
            profiles: {
                method: 'GET',
                params: {subResource: 'profiles'},
                isArray: false
            }

        }, {
            stripTrailingSlashes: false
        });

    function _generateKey(activityType, activityId) {
        return activityType + '-' + activityId;
    }

    function _disconnectUser(user) {
        user.isConnected = false;
    }

    function _resetDisconnectTimeout(user) {
        if (angular.isDefined(user.disconnectTimeout)) {
            $timeout.cancel(user.disconnectTimeout);
        }

        user.isConnected = true;
        user.disconnectTimeout = $timeout(_disconnectUser,
            config.realTimeMessaging.connectedTimeout, true, user);

        return user;
    }

    function _ensureProfileImageUrlAbsolute(user) {
        if (_startsWith(user.profile_picture, '/')) {
            user.profile_picture = config.apiUrl + user.profile_picture;
        }
    }

    function _addMissingUsers(subscription, activityType, activityId,
                              missingIds) {
        return _resource.profiles({
            activityId: activityId,
            activityType: activityType,
            user_ids: missingIds
        }).$promise.then(function _addMissingProfiles(response) {
            _forEach(response.users, function _addUser(user) {
                _ensureProfileImageUrlAbsolute(user);

                // subscription.profiles[user.user_id] =
                //     _resetDisconnectTimeout(merge(user, {isTyping: false}));
                subscription.contributors[user.user_id] = _merge(user, {
                    isTyping: false
                });
            });

            return subscription;
        });
    }

    function _getUser(subscription, activityType, activityId, userId) {
        const deferred = $q.defer();

        if (_has(subscription.contributors, userId)) {
            deferred.resolve(subscription.contributors[userId]);
        } else {
            _addMissingUsers(subscription, activityType, activityId, [userId])
                .then(function _resolve() {
                    deferred.resolve(subscription.contributors[userId]);
                });
        }

        return deferred.promise;
    }

    function _noticeParticipantPulse(activityType, activityId, userId) {
        const key = _generateKey(activityType, activityId),
            subscription = _subscriptions[key];

        return _getUser(subscription, activityType, activityId, userId)
            .then(function _handleUser(user) {
                return _resetDisconnectTimeout(user);
            });
    }

    function _noticeParticipantTypingStatus(activityType, activityId, userId,
                                            isTyping) {
        _noticeParticipantPulse(activityType, activityId, userId)
            .then(function _updateTypingStatus(user) {
                user.isTyping = isTyping;
            });
    }

    function _updateConnectedUsers(activityType, activityId, response) {
        const key = _generateKey(activityType, activityId),
            subscription = _subscriptions[key];

        _forEach(subscription.contributors, function _removeIfDisconnected(user) {
            if (_sortedIndexOf(response.user_ids, user.user_id) === -1) {
                _disconnectUser(user);
                //delete subscription.contributors;
            }
        });

        function _findMissingUserIds(missingIds, user_id) {
            if (!_has(subscription.contributors, user_id)) {
                missingIds.push(user_id);
            }
        }

        const missingIds = _transform(response.user_ids, _findMissingUserIds, []);

        if (missingIds.length) {
            _addMissingUsers(subscription, activityType, activityId, missingIds)
                .then(function _resetConnectionIds(subscription) {
                    let user;

                    _forEach(missingIds, function _addToContributors(userId) {
                        user = subscription.contributors[userId];
                        _resetDisconnectTimeout(user);

                        // subscription.contributors[userId] = user;
                    });
                });
        }
    }

    function _refreshConnectedUsers(activityType, activityId /*, threadId*/) {
        _resource.connectedUsers({
            activityId: activityId,
            activityType: activityType
        }).$promise
            .then(_partial(_updateConnectedUsers, activityType, activityId));
    }

    function _updateContributors(activityType, activityId, response) {
        const key = _generateKey(activityType, activityId),
            subscription = _subscriptions[key];

        response.user_ids = _map(response.contributors, 'user_id');

        function _findMissingUserIds(missingIds, userId) {
            if (!_has(subscription.contributors, userId) && userId !== null) {
                missingIds.push(userId);
            }
        }

        const missingIds = _transform(response.user_ids, _findMissingUserIds, []);

        if (missingIds.length) {
            _addMissingUsers(subscription, activityType, activityId, missingIds);
        }
    }

    function _refreshContributors(activityType, activityId, threadId) {
        const params = {
            activityId: activityId,
            activityType: activityType
        };

        if (angular.isDefined(threadId) && threadId !== null) {
            params.thread = 'thread';
            params.threadId = threadId;
        }

        _resource.contributors(params).$promise
            .then(_partial(_updateContributors, activityType, activityId));
    }


    function _subscribe(activityType, activityId, threadId) {
        const key = _generateKey(activityType, activityId);

        if (!_has(_subscriptions, key)) {
            _subscriptions[key] = {
                contributors: {},
                unsubscribe: function _unsubscribe() {
                    //$interval.cancel(promise);
                    delete _subscriptions[key];
                }
            };

            _refreshContributors(activityType, activityId, threadId);
            _refreshConnectedUsers(activityType, activityId, threadId);
        }

        return _subscriptions[key];
    }

    return {
        subscribe: _subscribe,
        noticeParticipantPulse: _noticeParticipantPulse,
        noticeParticipantTypingStatus: _noticeParticipantTypingStatus
    };
}

export default angular
    .module('components.realTimeEngine.connectedUsersService', [])
    .factory('connectedUsersService', connectedUsersService);
