'use strict';

import angular from 'angular';
import RegistrationController from './registration.controller.js'
import eula from '../../components/eula/eula.js'
import registrationHtml from './registration.html'
import welcomeModalHeaderHtml from "../welcome.modal.header.html";
import loginHtml from "../login/login.html";

/* @ngInject */
function config($stateProvider, getCookiesState, getEulaState, getPrivacyState,
                gettext) {
    $stateProvider
        .state({
            name: 'welcome.registration',
            url: '/registration',
            views: {
                'main-contents@': {
                    controller: 'RegistrationController',
                    controllerAs: 'vm',
                    templateUrl: registrationHtml
                }
            },
            onEnter: /* @ngInject */ function _openModal(coreLayoutService) {
                coreLayoutService.update({
                    footer: {
                        hidden: coreLayoutService.defaultExcept({}, true),
                        visible: coreLayoutService.defaultExcept()
                    }
                });
            },
            secure: false,
            isPopup: false,
            isRegister: true
        })
        .state({
            name: 'welcome.registration.existing',
            url: '/existing',
            params: {
                doJoinPanel: true,
            },
            title: gettext('Join this Panel'),
            views: {
                'modal-header@': {
                    templateUrl: welcomeModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    controller: 'LoginController',
                    templateUrl: loginHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    size: 'small',
                    header: {
                        visible:
                            coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({}, true),
                        visible: coreLayoutService.defaultExcept()
                    },
                    closeTargetState: 'welcome.registration'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            hideCloseButton: config.hideCloseButton || false,
            secure: false,
            isLogin: true
        })
        .state(getEulaState('welcome.registration.eula'))
        .state(getPrivacyState('welcome.registration.privacy', '/privacy/'))
        .state(getCookiesState('welcome.registration.cookies', '/cookies/'));
}

export default angular
    .module('welcome.registration', [
        RegistrationController.name,
        eula.name
    ])
    .config(config);
