'use strict';

import angular from 'angular';
import filterRep from './filter-rep.directive.js';
import {filterSearchDirective} from './filter-search.directive.ts';
import activeFiltersService from './active-filters.factory.js';
import activeFiltersHtml from './active-filters.html'

/* @ngInject */
function activeFilters($rootScope, $state, $log, activeFiltersService,
                       selectionService, $transitions) {
    function _link(scope) {
        function _forceUpdate() {
            scope.$applyAsync();
        }

        function _updateSelectionFilter(scope, $state) {
            scope.selectionsFilter =
                activeFiltersService
                    .getInheritedSelectionsFilter($state.$current);

        }

        function _init() {
            scope.filters = activeFiltersService.filters;
            scope.selectionsFilter =
                activeFiltersService
                    .getInheritedSelectionsFilter($state.$current);

            var updatedSignal = selectionService
                .getUpdatedSignal(scope.selectionsFilter.name,
                    scope.selectionsFilter.idName);

            $transitions.onSuccess({}, () => {
                // On each successful ui-router state change.
                _updateSelectionFilter(scope, $state);
            });

            scope.$on('$destroy', $rootScope.$on(updatedSignal, _forceUpdate));
        }

        _init();
    }

    return {
        link: _link,
        scope: {
            hidePanels: '=',
            hideActivities: '=',
            hideSegments: '=',
            hideSelections: '='
        },
        templateUrl: activeFiltersHtml
    };
}

export default angular
    .module('admin.activeFilters', [
        filterRep.name,
        filterSearchDirective.name,
        activeFiltersService.name
    ])
    .directive('activeFilters', activeFilters);
