'use strict';

import angular from 'angular';

/* @ngInject */
function acceptEulaService() {
    return {
        // Shared empty object to be populated by its consumers.
    };
}

export default angular
    .module('home.acceptEula.service', [])
    .factory('acceptEulaService', acceptEulaService);
