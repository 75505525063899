'use strict';

import angular from 'angular';
import setPasswordHeaderHtml from './set-password.header.html'
import setPasswordHtml from './set-password.html'
import setPasswordFooterHtml from './set-password.footer.html'

function setPasswordState(name) {
    return {
        name: name,
        url: '/complete-profile/',
        params: {profile: undefined},
        views: {
            'modal-header@': {
                controller: 'SetPasswordNavigatorController',
                controllerAs: 'vm',
                templateUrl: setPasswordHeaderHtml
            },
            'modal-contents@': {
                controller: 'SetPasswordController',
                controllerAs: 'vm',
                templateUrl: setPasswordHtml
            },
            'modal-footer@': {
                controller: 'SetPasswordNavigatorController',
                controllerAs: 'vm',
                templateUrl: setPasswordFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                footer: {
                    visible: coreLayoutService.defaultExcept({all: true}),
                    hidden: coreLayoutService.defaultExcept()
                },
                size: 'medium',
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
    };
}

export default angular
    .module('home.setPassword.state', [])
    .constant('setPasswordState', setPasswordState);
