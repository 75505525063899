'use strict';

import angular from 'angular';

/* @ngInject */
function PromoController($scope, $location, $sce, $log, api) {
    api.get($location.path()).then(function (res) {
        $log.debug('promo: res', res);
        $scope.promo = res.data.activity;

        $scope.trustedHtml = $sce.trustAsHtml($scope.promo.rendered_content);

        $scope.$emit('article:done');
    });
}

export default angular
    .module('activities.promo.promoController', [])
    .controller('PromoController', PromoController);
