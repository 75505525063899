'use strict';

import angular from 'angular';
import eMessagesListHtml from './e-messages-list.html'

/* @ngInject */
function eMessagesList($state, activeFiltersService,
                       utility, messageAdmin) {
    const config = {};

    function _link(scope) {
        scope.messages = messageAdmin.currentMessages;
        scope.status = messageAdmin.status;

        config.selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName);
        config.selectionIdName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsIdName);
        config.rowIdPath = 'user_id';

        scope.showSql = false;

    }

    return {
        scope: true,
        replace: true,
        link: _link,
        templateUrl: eMessagesListHtml
    };
}

// I don't know why, but importing TypeScript files from JavaScript files breaks `/* @ngInject */` decorations
// if imports are not performed _below_ the decorated function.
import {EMessageDirective} from './e-message.directive'

export default angular
    .module('admin.messages.eMessagesList', [
        'agGrid',
        EMessageDirective.name,
    ])
    .directive('eMessagesList', eMessagesList);
