'use strict';

import angular from 'angular';
import _fill from 'lodash/fill'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import _reduce from 'lodash/reduce'
import _zipObject from 'lodash/zipObject'


/* @ngInject */
function EditUserController($state, $scope, $resource, $q, $log, genders,
                            Modernizr, countries, languages, config, user, api,
                            postalCodeService, groupService, panelService,
                            activeFiltersService, gettext, gettextCatalog) {
    function _generateFieldKeys(fieldKeys, fields, key) {
        fieldKeys[key] = _keys(fields);
        return fieldKeys;
    }

    function _generateEmtpyMasterData(master, fields, key) {
        master[key] = {};
        return master;
    }

    const vm = this,
        _fieldDefinitions = {
            account: {
                user_id: {mode: 'rw'},
                expiry_time_z: {mode: 'r'},
                is_verified: {mode: 'r'},
                has_verified_email_address: {mode: 'rwc'},
                created: {mode: 'r'},
                trial_is_expired: {mode: 'r'},
                profile_picture: {mode: 'r'},
                is_blocked: {mode: 'rwc'}
            },
            password: {
                user_id: {mode: 'rw'},
                password: {mode: 'wc'}
            },
            personals: {
                user_id: {mode: 'rw'},
                first_name: {mode: 'rwc'},
                last_name: {mode: 'rwc'},
                gender_id: {mode: 'rwc'},
                birthdate: {mode: 'rwc'},
                email_address: {mode: 'rwc'},
                phone_number: {mode: 'rwc'},
                address_line_1: {mode: 'rwc'},
                address_line_2: {mode: 'rwc'},
                country_id: {mode: 'rwc'},
                postal_code: {mode: 'rwc'},
                city: {mode: 'rwc'},
                region: {mode: 'rwc'},
                timezone: {mode: 'rwc'},
                preferred_language_id: {mode: 'rwc'}
            },
            groups: {
                user_id: {mode: 'rw'},
                group_ids: {mode: 'rwc'}
            },
            panels: {
                user_id: {mode: 'rw'},
                panel_ids: {mode: 'rwc'}
            }
        },
        _fieldKeys = _reduce(_fieldDefinitions, _generateFieldKeys, {}),
        _master = _reduce(_fieldDefinitions, _generateEmtpyMasterData, {}),
        _subFormNames = _keys(_fieldDefinitions);

    vm.countryCodes = null;
    vm.countryDialingCodes = null;
    vm.genders = genders;
    vm.browser = {
        supportsInputTypeDate: Modernizr.inputtypes.date &&
        config.dateInputType === 'date'
    };
    vm.dateInputType = config.dateInputType;
    vm.dateValidRegex = config.dateValidRegex;
    vm.defaultDebounceOptions = config.getDebounceOptions();

    let _panelsDeferred;

    function _refreshPanels() {
        _panelsDeferred = $q.defer();

        panelService.query({status: ['open', 'closed']}).$promise
            .then(function _setPanels(response) {
                _panelsDeferred.resolve(response.panels);
            });

        return _panelsDeferred.promise;
    }

    function _getPanels() {
        return angular.isDefined(_panelsDeferred) ?
            _panelsDeferred.promise : _refreshPanels();
    }

    function _setCountries(countryCodes) {
        vm.countryCodes = countryCodes;
    }

    function _setDialingCodes(countryDialingCodes) {
        vm.countryDialingCodes = countryDialingCodes;
    }

    function _setLanguages(languages) {
        vm.languages = languages;
    }

    function _resetForm(form, name) {
        if (angular.isUndefined(name)) {
            name = form.$name;
        }

        if (angular.isDefined(form)) {
            form.$setPristine();
            form.$setUntouched();
        }

        vm[name] = angular.copy(_master[name]);
    }

    function _generateGroupSelections(masterGroups) {
        return groupService.get()
            .then(function _assignSelections(groups) {
                masterGroups.selections =
                    _filter(groups, function _getSelectedGroups(group) {
                        return _includes(masterGroups.group_ids, group.group_id);
                    });
            });
    }

    function _generatePanelSelections(masterPanels) {
        return _getPanels()
            .then(function _assignSelections(panels) {
                masterPanels.selections =
                    _filter(panels, function _getSelectedPanels(panel) {
                        return _includes(masterPanels.panel_ids, panel.panel_id);
                    });
            });
    }

    function _initMasterData(response) {
        if ($state.is('admin.users.create') && response.user_id) {
            $state.go('^.edit', {userId: response.user_id});
        }

        _forEach(_subFormNames, function _initSubForm(name) {
            _master[name] = _pick(response, _fieldKeys[name]);
        });

        return $q.all([
            _generateGroupSelections(_master.groups),
            _generatePanelSelections(_master.panels)
        ]);
    }

    function _attributeIsSubmittable(definitions, blacklist, value, key) {
        return !_includes(blacklist, key) &&
            _has(definitions, key) &&
            _includes(definitions[key].mode, 'w');
    }

    function _makeSafeCopy(data, definitions, blacklist) {
        blacklist = angular.isDefined(blacklist) ? blacklist : [];

        return _pickBy(data,
            _partial(_attributeIsSubmittable, definitions, blacklist));
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.fields) {
                _forEach(response.data.fields, function _setErrors(field) {
                    form[field.form_field].$setValidity(field.error, false);
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        vm.saveError[form.$name] = status;

        return $q.reject();
    }

    function _clearFormError(form) {
        vm.saveError[form.$name] = undefined;
    }

    function _resetSubForms() {
        _forEach(_subFormNames, function _resetSubForm(name) {
            _resetForm(undefined, name);
        });
    }

    function _postSubmitHandling(promise, form) {
        _clearFormError(form);

        return promise
            .then(_initMasterData, _partial(_handleSaveFormError, form))
            .then(_resetSubForms);
    }

    function _saveForm(form) {
        const names = vm.account.user_id ?
            [form.$name] : [form.$name, 'groups', 'panels'],
            subData = _map(names, function _getData(name) {
                return _makeSafeCopy(vm[name], _fieldDefinitions[name]);
            }),
            data = _merge(...[{}].concat(subData));

        const promise = data.user_id ?
            user.update(data).$promise :
            user.save(data).$promise;

        return _postSubmitHandling(promise, form);
    }

    function _blockAccount(form, value) {
        const userData = _makeSafeCopy(vm.account, _fieldDefinitions.account);

        if (!userData.user_id) {
            return;
        }

        userData.is_blocked = value;

        _postSubmitHandling(user.update(userData).$promise, form,
            'saveAccountError');
    }

    function _verifyAccount(form) {
        const userData = _makeSafeCopy(vm.account, _fieldDefinitions.account);

        if (!userData.user_id) {
            return;
        }

        userData.has_verified_email_address = true;

        _postSubmitHandling(user.update(userData).$promise, form,
            'saveAccountError');
    }

    function _savePasswordForm(form) {
        const userData = _makeSafeCopy(vm.password, _fieldDefinitions.password);

        if (!userData.user_id) {
            return;
        }

        _postSubmitHandling(user.update(userData).$promise, form,
            'savePasswordError');
    }

    function _createEmptyObject(keys) {
        return _zipObject(keys, _fill(new Array(keys.length), null));
    }

    function _deleteAccount() {
        if (vm.account.user_id) {
            user.delete({userId: vm.account.user_id}).$promise
                .then(function _goToList() {
                    $state.go('^.list');
                }, function _handleError(response) {
                    vm.deleteError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                });
        }
    }

    function _propagatePostalInfo(response) {
        let place;

        if (response.postal_codes && response.postal_codes.length) {
            place = response.postal_codes[0];
        } else {
            place = {
                place_name: null,
                admin_name1: null
            };
        }

        vm.personals.city = place.place_name;
        vm.personals.region = place.admin_name1;
    }

    function _lookupPostalCode() {
        postalCodeService.query({
            country_code: vm.personals.country_id,
            postal_code: vm.personals.postal_code
        }).$promise
            .then(_propagatePostalInfo);
    }

    function _setGroups(groups) {
        vm.allGroups = groups;
    }

    function _setPanels(panels) {
        vm.allPanels = panels;
    }

    function _updateSelection(item, action, collection, key) {
        switch (action) {
            case 'select':
                if (!_includes(collection, item[key])) {
                    collection.push(item[key]);
                }
                break;
            // eslint-disable-next-line no-case-declarations
            case 'remove':
                const i = collection.indexOf(item[key]);
                if (i !== -1) {
                    collection.splice(i, 1);
                }
                break;
        }
    }

    function _updateGroupsSelection(item, action) {
        _updateSelection(item, action, vm.groups.group_ids, 'group_id');
    }

    function _updatePanelsSelection(item, action) {
        _updateSelection(item, action, vm.panels.panel_ids, 'panel_id');
    }

    const _panelGroups = {
        open: gettext('Open Panels'),
        closed: gettext('Closed Panels')
    };

    function _panelGroup(item) {
        return gettextCatalog.getString(_panelGroups[item.status]);
    }

    function _setDefaultGroups() {
        _master.groups.group_ids = [config.admin.groupIds.members];

        return _generateGroupSelections(_master.groups);
    }

    function _setDefaultPanels() {
        const panel_ids = activeFiltersService.getAllActivePanelFilters();

        /**
         * `panel_id === 40` is Labben, the default panel.
         **/
        _master.panels.panel_ids = panel_ids.length ? panel_ids : [40];

        return _generatePanelSelections(_master.panels);
    }

    const _passwordsResource = $resource(api.url + '/api/passwords/', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });

    function _generatePassword(form) {
        return _passwordsResource.query().$promise
            .then(function _setPassword(response) {
                $scope.$applyAsync(function _makeFieldDirty() {
                    vm.password.password = response.password;
                    form.password.$setDirty();
                });
            });
    }

    function _activate() {
        if (angular.isUndefined($state.params.userId)) {
            _forEach(_subFormNames, function _createEmptyMaster(name) {
                _master[name] = _createEmptyObject(_fieldKeys[name]);
            });

            _setDefaultGroups()
                .then(_setDefaultPanels)
                .then(_resetSubForms);

        } else {
            user.get({userId: $state.params.userId}).$promise
                .then(_initMasterData)
                .then(_resetSubForms);
        }

        groupService.get().then(_setGroups);
        _getPanels().then(_setPanels);

        countries.get('locations', true).then(_setCountries);
        countries.get('dialingCodes', true).then(_setDialingCodes);
        languages.get().then(_setLanguages);

        vm.saveError = {};

        vm.blockAccount = _blockAccount;
        vm.verifyAccount = _verifyAccount;
        vm.saveForm = _saveForm;
        vm.savePasswordForm = _savePasswordForm;
        vm.generatePassword = _generatePassword;

        vm.updateGroupsSelection = _updateGroupsSelection;
        vm.updatePanelsSelection = _updatePanelsSelection;

        vm.panelGroup = _panelGroup;

        vm.lookupPostalCode = _lookupPostalCode;

        vm.resetForm = _resetForm;

        vm.deleteAccount = _deleteAccount;
    }

    _activate();
}

export default angular
    .module('admin.users.EditUserController', [])
    .controller('EditUserController', EditUserController);
