'use strict';

import angular from 'angular';
import _assign from 'lodash/assign'
import _difference from 'lodash/difference'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _head from 'lodash/head'
import _indexOf from 'lodash/indexOf'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _remove from 'lodash/remove'
import _some from 'lodash/some'
import _sortedIndexOf from 'lodash/sortedIndexOf'
import _split from 'lodash/split'
import _isArray from 'lodash/isArray'


/* @ngInject */
function selectionService($rootScope, $window, $log, utility) {
    const _collections = {},
        _storage = $window.sessionStorage,
        _prefix = 'selectionService';

    angular.element($window).on('storage', _updateOnStorageChange);

    function _persistCollection(collection, data) {
        _storage.setItem(_getIdentifier(collection, data.idName),
            angular.toJson(data));
    }

    function _deleteCollection(collection, idName) {
        _storage.removeItem(_getIdentifier(collection, idName));
    }

    function _loadCollection(collection, idName) {
        const item = _storage.getItem(_getIdentifier(collection, idName));
        return item !== null ? angular.fromJson(item) : item;
    }

    function _updateOnStorageChange(event) {
        const item = event.originalEvent.newValue,
            keyParts = _split(event.originalEvent.key, ':');

        if (keyParts.length < 3 || keyParts[0] !== _prefix) {
            return;
        }
        const collection = keyParts[1],
            idName = keyParts[2];

        utility.updateSharedArray(_getCollection(collection, idName),
            angular.fromJson(item));
        _broadcastUpdate(collection);
    }

    function _broadcastUpdate(collection, idName) {
        $rootScope.$emit(_service.getUpdatedSignal(collection, idName));
    }

    function _updateCount(selected) {
        if (_isInExcludeMode(selected)) {
            const excludedIds = _map(selected.excluded, selected.idName),
                diff = _difference(excludedIds, selected.wasExcludedIds || []);
            selected.count = selected.total - diff.length;
        } else {
            selected.count = selected.included.length;
        }

        switch (selected.count) {
            case 0:
                selected.isAllIndicator = false;
                break;
            case selected.total:
                selected.isAllIndicator = true;
                break;
            default:
                selected.isAllIndicator = null;
        }
    }

    function _updateTotal(collection, total) {
        const selected = _getCollection(collection);

        selected.total = total;

        _updateCount(selected);
    }

    function _afterUpdate(collection, selected) {
        _updateCount(selected);
        _persistCollection(collection, selected);
        _broadcastUpdate(collection, selected.idName);
    }

    function _newSelection(idName) {
        return {
            idName: idName,
            isAllIndicator: false,
            wasInitiallyAll: false,
            total: 0,
            included: [],
            excluded: [],
            isEnabled: false,
            wasExcludedIds: null
        };
    }

    function _getCollection(collection, idName) {
        if (!_has(_collections, collection)) {
            let data = _loadCollection(collection, idName);
            // Holds reference to shared arrays.  Only mutate, don't replace
            // them.
            if (_isArray(data)) {
                data = _newSelection(idName);
            }

            _collections[collection] =
                data !== null ? data : _newSelection(idName);
        }

        const selections = _collections[collection];

        if (angular.isUndefined(selections.allChanged)) {
            selections.allChanged = _partial(_allChanged, collection, idName);
        }

        return selections;
    }

    function _setColumnDefs(collection, columnDefs, selections) {
        // The `columnDefs` are provided, for now, by ag-grid.
        if (angular.isUndefined(selections)) {
            selections = _getCollection(collection);
        }
        selections.columnDefs = columnDefs;
    }

    function _setExtraFilters(collection, extraFilters, selections) {
        // The `extraFilters` are provided, for now, by ag-grid.
        if (angular.isUndefined(selections)) {
            selections = _getCollection(collection);
        }
        selections.extraFilters = extraFilters;
    }

    function _isInExcludeMode(selections) {
        // (x !== false) => x = {true, null}
        return selections.wasInitiallyAll !== false;
    }

    function _getActiveArray(selections) {
        return (_isInExcludeMode(selections)) ?
            selections.excluded : selections.included;
    }

    function _addItem(item, path, collection) {
        // Add `item` to `collection` where item will be identified by `path`.
        // For example, path could be 'id', 'row_id', 'data.athlete_id',
        // whatever fits the row data being added.
        const selected = _getCollection(collection, path),
            items = _getActiveArray(selected),
            itemId = _get(item, path),
            isInItems = _some(items, [path, itemId]);
        let needsUpdate = false;

        if (_isInExcludeMode(selected)) {
            if (isInItems) {
                // eslint-disable-next-line lodash/prefer-immutable-method
                _remove(items, [path, itemId]);
                needsUpdate = true;
            }
        } else {
            if (!isInItems) {
                utility.insertSorted(items, item, path);
                needsUpdate = true;
            }
        }
        if (needsUpdate) {
            _afterUpdate(collection, selected);
        }
    }

    function _removeItem(item, path, collection) {
        // Remove `item` from `collection`, where item is identified by `path`,
        // just like in _addItem().
        const selected = _getCollection(collection, path),
            items = _getActiveArray(selected),
            itemId = _get(item, path),
            isInItems = _some(items, [path, itemId]);
        let needsUpdate = false;

        if (_isInExcludeMode(selected)) {
            if (!isInItems) {
                utility.insertSorted(items, item, path);
                needsUpdate = true;
            }
        } else {
            if (isInItems) {
                // eslint-disable-next-line lodash/prefer-immutable-method
                _remove(items, [path, itemId]);
                needsUpdate = true;
            }
        }

        if (needsUpdate) {
            _afterUpdate(collection, selected);
        }
    }

    function _clear(collection, idName, initiallyAll) {
        const selected = _getCollection(collection, idName);

        utility.emptyArray(selected.included);
        utility.emptyArray(selected.excluded);
        selected.wasInitiallyAll = angular.isDefined(initiallyAll) ?
            initiallyAll : false;

        _afterUpdate(collection, selected);
    }

    function _delete(collection, idName) {
        _deleteCollection(collection, idName);
    }

    function _safeDeleteFilter(filter, toggleFilter, initiallyAll) {
        _clear(filter.name, filter.idName, initiallyAll);
        if (filter.enabled) {
            toggleFilter(filter);
        }
        _delete(filter.name, filter.idName);
    }

    function _allChanged(collection, idName, value) {
        if (value !== null) {
            if (value) {
                _clear(collection, idName, value);
            } else {
                $rootScope.$emit(_service.collectionWasClearedSignal, {
                    collection: collection,
                    idName: idName
                });
            }
        }
    }

    function _getIdentifier(collection, idName, action) {
        return `${_prefix}${angular.isDefined(action) ? `:${action}:` : ':'}${collection}:${idName}`;
    }

    function _getUpdatedSignal(collection, idName) {
        return _getIdentifier(collection, idName, 'updated');
    }

    function _isItemWithIdNotIn(array, idName, item) {
        return !_find(array, [idName, item[idName]]);
    }

    function _addIfItemWithIdNotIn(array, idName, item) {
        if (_isItemWithIdNotIn(array, idName, item)) {
            array.push(item);
        }
    }

    function _adjustToggledFilter(filter) {
        const idName = filter.idName;

        // Promote the `filter.enabled` Boolean state to the `collection`.
        filter.collection.isEnabled = !!filter.enabled;

        if (filter.enabled) {
            // Keep score of the previous (disabled) state.
            filter.disabledCollection = _merge({}, filter.collection);
            filter.collection.wasExcludedIds =
                _map(filter.collection.excluded, idName);
        } else {
            filter.collection.wasExcludedIds = null;

            const disabledCollection = filter.disabledCollection,
                enabledCollection = filter.collection,
                update = {
                    excluded: [],
                    included: [],
                    wasInitiallyAll: disabledCollection.wasInitiallyAll
                },
                keepIfNotExcluded = _partial(_isItemWithIdNotIn,
                    enabledCollection.excluded, idName);
            let addIfMissing;

            if (_isInExcludeMode(disabledCollection)) {
                if (_isInExcludeMode(enabledCollection)) {
                    update.excluded = filter(disabledCollection.excluded,
                        keepIfNotExcluded);

                    addIfMissing = _partial(_addIfItemWithIdNotIn,
                        update.excluded, idName);

                    _forEach(enabledCollection.excluded, addIfMissing);
                } else {
                    update.included = enabledCollection.included;
                    update.wasInitiallyAll = enabledCollection.wasInitiallyAll;
                }
            } else {
                if (_isInExcludeMode(enabledCollection)) {
                    update.included = filter(disabledCollection.included,
                        keepIfNotExcluded);
                } else {
                    update.included = filter(disabledCollection.included,
                        function _keepIfIncluded(item) {
                            return !!_head(
                                filter(enabledCollection.included,
                                    [idName, item[idName]]));
                        });

                    addIfMissing = _partial(_addIfItemWithIdNotIn,
                        update.included, idName);

                    _forEach(enabledCollection.included, addIfMissing);
                }
            }

            delete filter.disabledCollection;
            delete enabledCollection.wasExcludedIds;
            delete disabledCollection.wasExcludedIds;

            _assign(enabledCollection, update);
        }
    }

    function _selectAllExcept(gridApi, path, gridPath, selectedIdsInGrid,
                              excluded) {
        // It's important that the excludedIds array is
        // a copy, not a reference, because it'll be mutated below.
        const excludedIds = _map(excluded, path);
        let excludedId, selectedId, rowId;

        gridApi.forEachNode(function _selectIfNotExcluded(node) {
            rowId = _get(node, gridPath);
            excludedId = _sortedIndexOf(excludedIds, rowId);
            selectedId = _indexOf(selectedIdsInGrid, rowId);

            if (excludedId >= 0) {
                if (selectedId >= 0) {
                    // The following suppressEvents=true flag is ignored for
                    // now, but a fixing pull request is waiting at ag-grid
                    // GitHub.
                    // gridApi.deselectNode(node, true);
                    node.setSelected(false);

                    selectedIdsInGrid.splice(selectedId, 1);  // Reduce
                                                              // haystack.
                }
                excludedIds.splice(excludedId, 1);  // Reduce haystack.
            } else {
                // Avoid selecting already selected nodes.
                if (selectedId === -1) {
                    // multi=true, suppressEvents=true:
                    // gridApi.selectNode(node, true, true);
                    node.setSelected(true);
                }
            }
        });
    }

    function _selectOnlyIncluded(gridApi, path, gridPath, selectedIdsInGrid,
                                 included) {
        const includedIds = _map(included, path);
        let includedId, selectedId, rowId;

        gridApi.forEachNode(function _selectOnlyIfIncluded(node) {
            rowId = _get(node, gridPath);
            includedId = _sortedIndexOf(includedIds, rowId);
            selectedId = _indexOf(selectedIdsInGrid, rowId);

            if (includedId >= 0) {
                if (selectedId === -1) {
                    // multi=true, suppressEvents=true:
                    // gridApi.selectNode(node, true, true);
                    node.setSelected(true);
                }
                includedIds.splice(includedId, 1);  // Reduce haystack.
            } else {
                // Avoid selecting already selected nodes!!!
                if (selectedId >= 0) {
                    // The following suppressEvents=true flag is ignored for
                    // now, but a fixing pull request is waiting at ag-grid
                    // GitHub.
                    // gridApi.deselectNode(node, true);
                    node.setSelected(false);

                    selectedIdsInGrid.splice(selectedId, 1);  // Reduce
                                                              // haystack.
                }
            }
        });
    }

    function _updateInGridSelections(gridApi, path, collection) {
        // It's important that the `selectedIdsInGrid` array is a copy, not a
        // reference, because it'll be mutated below.
        const selected = _getCollection(collection, path),
            gridPath = 'data.' + path,
            selectedIdsInGrid = _map(gridApi.getSelectedNodes(), gridPath);

        if (_isInExcludeMode(selected)) {
            _selectAllExcept(gridApi, path, gridPath, selectedIdsInGrid,
                selected.excluded);
        } else {
            _selectOnlyIncluded(gridApi, path, gridPath, selectedIdsInGrid,
                selected.included);
        }
    }

    function _isIncluded(collection, item) {
        const itemId = _get(item, collection.idName);
        return _isInExcludeMode(collection) ?
            !_some(collection.excluded, [collection.idName, itemId]) :
            _some(collection.included, [collection.idName, itemId]);
    }

    function _isExcluded(collection, item) {
        return !_isIncluded(collection, item);
    }

    function _isEmpty(collection) {
        return (_isInExcludeMode(collection) ?
            collection.excluded.length : collection.included.length) === 0;
    }

    const _service = {
        getCollection: _getCollection,
        add: _addItem,
        remove: _removeItem,
        isEmpty: _isEmpty,
        isExcluded: _isExcluded,
        isIncluded: _isIncluded,
        isInExcludeMode: _isInExcludeMode,
        getActiveArray: _getActiveArray,
        adjustToggledFilter: _adjustToggledFilter,
        //allWasToggled: _allWasToggled,
        clear: _clear,
        // delete: _delete,
        collectionWasClearedSignal: _prefix + ':collectionWasCleared',
        getUpdatedSignal: _getUpdatedSignal,
        safeDeleteFilter: _safeDeleteFilter,
        setColumnDefs: _setColumnDefs,
        setExtraFilters: _setExtraFilters,
        updateInGridSelections: _updateInGridSelections,
        updateTotal: _updateTotal
    };

    return _service;
}

export default angular
    .module('components.selectionService', [])
    .factory('selectionService', selectionService);
