'use strict';
import * as angular from 'angular';
import _reduce from 'lodash/reduce';
import _partial from 'lodash/partial';
/* @ngInject */
function panelAdminService($resource, api, gridUtils) {
    var panelsResource = $resource(api.url + '/api/panels/:panelId/', { panelId: '@panel_id' }, {
        query: {
            method: 'POST',
            url: api.url + '/api/panels/:panelId/query/',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var panelSupervisorsResource = $resource(api.url + '/api/panels/:panelId/supervisors/', { panelId: '@panel_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var panelAvailableSupervisorsResource = $resource(api.url + '/api/panels/:panelId/supervisors_available/', { panelId: '@panel_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        // update: {
        //     method: 'PUT',
        //     isArray: false
        // }
    }, {
        stripTrailingSlashes: false
    });
    var panelMembershipsResource = $resource(api.url + '/api/panel_memberships/', {}, {
    // check: {
    //     method: 'POST',
    //     url: api.url + '/api/panel_memberships/check/',
    //     isArray: false
    // }
    }, {
        stripTrailingSlashes: false
    });
    var panelsDeleteResource = $resource(api.url + '/api/panels_delete/', {}, {
        deleteMultiple: {
            method: 'DELETE',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var panelsStatsResource = $resource(api.url + '/api/stats/panels', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    function _queryParamsFromFilters(filters) {
        return {
            panel_ids: filters.panels,
            activity_ids: filters.activities,
            query_ids: filters.queries
        };
    }
    function _itemsFilterToQueryParams(items, ignoreExtraFilters) {
        if (ignoreExtraFilters === void 0) { ignoreExtraFilters = false; }
        var params = {};
        if (angular.isDefined(items)) {
            params.exclude_panel_ids = items.exclude ? items.ids : null;
            params.panel_ids = !items.exclude ? items.ids : null;
            if (!ignoreExtraFilters) {
                params.panelFilters = _reduce(items.extraFilters, _partial(gridUtils.generateFilterKey, items.columnDefs), []);
            }
        }
        return params;
    }
    return {
        get: panelsResource.get,
        query: panelsResource.query,
        save: panelsResource.save,
        update: panelsResource.update,
        delete: panelsResource.delete,
        deleteMultiple: panelsDeleteResource.deleteMultiple,
        stats: panelsStatsResource.query,
        changeMemberships: panelMembershipsResource.save,
        getSupervisors: panelSupervisorsResource.query,
        getAvailableSupervisors: panelAvailableSupervisorsResource.query,
        updateSupervisors: panelSupervisorsResource.update,
        queryParamsFromFilters: _queryParamsFromFilters,
        itemsFilterToQueryParams: _itemsFilterToQueryParams
    };
}
export var name = 'components.panelAdminService';
export var PanelAdminServiceFactory = angular
    .module(name, [])
    .factory('panelAdmin', panelAdminService);
