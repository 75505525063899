'use strict';

import angular from 'angular'
import modernizr from 'modernizr'
import modernizrCsspositionfixed from './modernizr-ios-position-fixed-bug'
import modernizrUsenativescroll from './modernizr-use-native-scroll'
import modernizrIsMobileDevice from './modernizr-is-mobile-device'
import modernizrIsIOS from './modernizr-is-ios'
import modernizrIsChrome from './modernizr-is-chrome'

modernizr
    .addTest('isios', modernizrIsIOS)
    .addTest('ischrome', modernizrIsChrome)
    .addTest('csspositionfixed', modernizrCsspositionfixed)
    .addTest('usenativescroll', modernizrUsenativescroll)
    .addTest('ismobiledevice', modernizrIsMobileDevice);

export default angular
    .module('lib.modernizr', [])
    .constant('Modernizr', modernizr);
