'use strict';

import angular from 'angular';
import realTimeMenuHtml from './real-time-menu.html'

/* @ngInject */
function realTimeMenu(utility) {
    function _link(scope, element) {
        var _element = angular.element(element),
            _collapsible = _element.find('.panel-collapse'),
            _collapsibleState = {
                collapsibleElement: _collapsible,
                isExpanded: _collapsible.hasClass('in'),
                iconElement: _element.find('.collapse-icon'),
                icons: {
                    open: 'fa-minus-square',
                    closed: 'fa-plus-square'
                }
            };

        function _togglePanel() {
            utility.togglePanelCollapse(_collapsibleState);
        }

        function _init() {
            scope.togglePanel = _togglePanel;

            utility.updateCollapseIcon(_collapsibleState);
        }

        _init();
    }

    return {
        scope: {
            contributors: '=realTimeMenu',
            activity: '='
        },
        link: _link,
        templateUrl: realTimeMenuHtml
    };
}

export default angular
    .module('activities.realTimeMenu', [])
    .directive('realTimeMenu', realTimeMenu);
