'use strict';

import angular from 'angular';
import attachments from './attachments.directive.js'
import messageActions from './message-actions.directive.js'
import messageEditor from './message-editor.directive.js'
import realTimeMessageCoreHtml from './real-time-message-core.html'

/* @ngInject */
function realTimeMessageCore() {
    function _link() {}

    return {
        restrict: 'AE',
        scope: {
            message: '=',
            showEditMessage: '=',
            doShowActions: '=',
            editable: '=',
            closeEditMessage: '&',
            style: '=messageStyle'
        },
        templateUrl: realTimeMessageCoreHtml,
        link: _link
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.realTimeMessageCore', [
        attachments.name,
        messageActions.name,
        messageEditor.name
    ])
    .directive('realTimeMessageCore', realTimeMessageCore);
