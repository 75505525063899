'use strict';

import angular from 'angular';

/* @ngInject */
function checkHtml($compile, $log, api) {
    return function (scope, element) {
        $log.debug('checkHtml: api.url', api.url);

        scope.$on('article:done', function _articleDoneHandler() {
            function youtubeParser(url) {
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
                    match = url.match(regExp);

                if (match && match[7].length === 11) {
                    return match[7];
                } else {
                    $log.error("YouTube url incorrect:", url);
                }
            }

            function parseElement(el) {
                // Skip non text nodes.

                switch (el.nodeName) {
                    case 'IMG':
                        var original_src = el.src,
                            pathArray = window.location.href.split('/'),
                            protocol = pathArray[0],
                            host = pathArray[2],
                            url = protocol + '//' + host;
                        el.src = original_src.replace(url, api.url);
                        break;
                    case 'A':
                        var href = el.href;
                        scope.href = href;
                        var newLink = '<a class="external-link" ' +
                            'ng-click="openExternalUrl(href)">' + href + '</a>';

                        angular.element(el).replaceWith($compile(newLink)(scope));
                        break;
                    default:
                        // Not image.  Look for youtube.
                        if (el.textContent) {
                            if (el.textContent.indexOf('youtube') !== -1) {
                                // Grab text
                                var matches = el.textContent.match(/(?:http:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g);

                                if (matches) {
                                    var arr = matches[0].split(' ');

                                    for (var j = 0; j < arr.length; j++) {
                                        if (arr[j].search('youtube') !== -1) {
                                            angular.element(el).replaceWith('<iframe width="420" height="345" src="https://www.youtube.com/embed/' + youtubeParser(arr[j]) + '"frameborder="0" allowfullscreen />');
                                        }
                                    }

                                }

                            }
                        }
                }
            }

            setTimeout(function () {
                $log.debug('element.contents()', element.contents());

                for (var i = 0; i < element.contents().length; i++) {
                    var e = element.contents()[i];

                    for (var p = 0; p < angular.element(e).children().length; p++) {
                        parseElement(angular.element(e).children()[p]);
                    }

                    parseElement(e);

                }

            }, 0);


        });


    };
}

export default angular
    .module('activities.checkHtml', [])
    .directive('checkHtml', checkHtml);
