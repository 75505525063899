'use strict';
import * as angular from 'angular';
import _debounce from 'lodash/debounce';
var BOTTOM_SNAP_AREA = 25;
/* @ngInject */
function scrollSpy($rootScope, $stateParams, $q, $log, realTimeEngine) {
    function _link(scope, element) {
        var scrollEl = element[0], bottomAreaPx = BOTTOM_SNAP_AREA;
        var _scrollTop = scrollEl.scrollTop;
        var _engine;
        function _detectScrollDirection() {
            var wasAtBottom = _engine.status.isAtBottom, newScrollTop = scrollEl.scrollTop, bottomPosition = Math.round(newScrollTop + element.height());
            var isAtBottom = (scrollEl.scrollHeight - bottomPosition) < bottomAreaPx;
            if (isAtBottom !== wasAtBottom) {
                scope.$applyAsync(function () {
                    _engine.status.isAtBottom = isAtBottom;
                });
            }
            _scrollTop = newScrollTop;
        }
        function _setEngine() {
            _engine = realTimeEngine.getEngine(realTimeEngine.stateParamsToKey($stateParams));
            if (angular.isDefined(_engine)) {
                element.on('scroll', _debounce(_detectScrollDirection, 100));
            }
        }
        function _init() {
            $rootScope.$on('$destroy', $rootScope.$on(realTimeEngine.isIntializedSignal, _setEngine));
            _setEngine();
        }
        _init();
    }
    return {
        restrict: 'A',
        link: {
            pre: _link,
        }
    };
}
export default angular
    .module('components.realTimeEngine.scrollSpy', [])
    .directive('scrollSpy', scrollSpy);
