'use strict';

import angular from 'angular';
import _partial from 'lodash/partial'

/* @ngInject */
function AppController($scope, $rootScope, $log, coreLayoutService,
                       iScrollService, metaService, user, userSignals,
                       upload) {
    let _isLoggedIn;

    function updateLoginStatus(isLoggedIn) {
        _isLoggedIn = isLoggedIn;
    }

    $scope.iScrollState = iScrollService.state;

    // Support modal.
    $scope.layout = coreLayoutService.state;

    // Events.
    $rootScope.$on(userSignals.userLoginSignal,
        _partial(updateLoginStatus, true));
    $rootScope.$on(userSignals.userLogoutSignal,
        _partial(updateLoginStatus, false));

    function _handleMetaIsRefreshed(event, meta) {
        upload.initFilestack(meta.auth.filepicker.filepicker_api_key);
    }

    $rootScope.$on(metaService.isRefreshedSignal, _handleMetaIsRefreshed);

    function _addAlert(e, frame) {
        user.addAlert(angular.fromJson(frame.body));
    }

    $rootScope.$on('alert', _addAlert);

    //
    // Public api
    //
    function _getIsLoggedIn() {
        return _isLoggedIn;
    }

    $scope.isLoggedIn = _getIsLoggedIn;
}

const module = angular.module('AppController', []);

module.controller('AppController', AppController);

export default module;
