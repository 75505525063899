'use strict';

import angular from 'angular';
import panelBadgeHtml from './panel-badge.html'

/* @ngInject */
function panelBadge($log, api) {
    function _onBrandingChange(scope) {
        scope.backgroundStyle = {
            'background-color': scope.branding.brand_color
        };
        scope.arrowDownStyle = {
            'border-top-color': scope.branding.brand_color
        };
        scope.logoSrc = api.url +
            (scope.branding.brand_icon_url ?
                scope.branding.brand_icon_url : '/img/trans-24.png');
    }
    function _link(scope) {
        var deregister = scope.$watch('branding',
            function _onBranding(newValue) {
                if (angular.isDefined(newValue)) {
                    deregister();
                    _onBrandingChange(scope);
                }
            });
    }

    return {
        scope: {
            branding: '=panelBadge'
        },
        link: _link,
        replace: true,
        templateUrl: panelBadgeHtml
    };
}

export default angular
    .module('components.panelBadge', [])
    .directive('panelBadge', panelBadge);
