'use strict';

import angular from 'angular';
import phoneNumberFieldHtml from './phone-number-field.html'
import _filter from 'lodash/filter'
import _head from 'lodash/head'


/* @ngInject */
function phoneNumberField2($log, config) {

    function _link(scope) {
        function _handlePhoneNumberExternalChange(newValue /*, oldValue*/) {
            if (angular.isDefined(newValue)) {
                let countryCode, body;

                if (newValue !== null) {
                    const parts = newValue.split(' ', 2),
                        prefix = parts[0].split(':', 1);

                    countryCode = prefix[0];
                    body = parts[1];
                } else {
                    countryCode = undefined;
                    body = undefined;
                }

                scope.phone_number = {
                    phone_number_country_code: countryCode,
                    phone_number: body
                };

            }
        }

        function _handlePhoneNumberInternalChange(newValue /*, oldValue*/) {
            const code = newValue[0],
                number = newValue[1];

            if (angular.isDefined(code) && angular.isDefined(number) &&
                scope.countryDialingCodes) {
                const countryDialingCode = _head(
                    _filter(scope.countryDialingCodes, ['country_code', code]));

                if (angular.isDefined(countryDialingCode)) {
                    const formatted = code + ':+' +
                        countryDialingCode.dialing_country_code + ' ' +
                        number;

                    if (scope.master !== formatted) {
                        scope.master = formatted;
                    }
                }
            }
        }

        scope.defaultDebounceOptions = config.getDebounceOptions();

        function _propagateUserId(newValue, oldValue) {
            if (newValue !== oldValue) {
                scope.userId = newValue;
            }
        }

        function _activate() {
            scope.$watch('master', _handlePhoneNumberExternalChange);
            scope.$watchGroup([
                    'phone_number.phone_number_country_code',
                    'phone_number.phone_number'],
                _handlePhoneNumberInternalChange);
            scope.$watch('forceUserId', _propagateUserId);
        }

        _activate();
    }

    return {
        scope: {
            master: '=phoneNumberField2',
            handleFormat: '=',
            countryDialingCodes: '=',
            forceUserId: '=userId'
        },
        transclude: true,
        templateUrl: phoneNumberFieldHtml,
        link: {
            pre: _link
        }
    };
}

export default angular
    .module('profile.phoneNumberField2', [])
    .directive('phoneNumberField2', phoneNumberField2);
