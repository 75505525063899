'use strict';
import * as angular from 'angular';
var truncatingLinesHtml = require('./truncating-lines.html');
import _isString from 'lodash/isString';
/* @ngInject */
function truncatingLines($q, $log) {
    var endLine = '\n';
    var truncateClass = 'wrapped';
    function _link(scope) {
        function _toggleState() {
            scope.isTruncated = !scope.isTruncated;
            scope.cssClass = scope.isTruncated ? truncateClass : '';
        }
        scope.toggleState = _toggleState;
        function _init() {
            scope.isTruncated = !!scope.model.initTruncated;
            scope.cssClass = scope.isTruncated ? truncateClass : '';
            scope.maxLines = scope.model.maxLines;
            scope.$watch('tlBind', function (newValue, oldValue) {
                scope.showToggleButton = _isString(newValue) ?
                    newValue.split(endLine).length > scope.model.maxLines :
                    false;
            });
        }
        _init();
    }
    return {
        transclude: true,
        scope: {
            model: '=truncatingLines',
            tlBind: '=',
        },
        link: {
            pre: _link,
        },
        templateUrl: truncatingLinesHtml
    };
}
export var TruncatingLinesDirective = angular
    .module('components.truncatingLines', [])
    .directive('truncatingLines', truncatingLines);
