'use strict';

import angular from 'angular';
import _omit from 'lodash/omit'


/* @ngInject */
function DiaryController($scope, $rootScope, $state, moment,
                         activityService, questionnaireService) {
    let _sessionMeta = {};

    $scope.getDay = (date) => moment(date).format('dddd');

    $scope.getDate = (date) => moment(date).date();

    $scope.getFormatedDate = (date) => moment(date).format('YYYY-MM-DD');

    $scope.goToEntry = function () {
        $state.go('home.diary.entry', {
            questionnaireSessionId: _sessionMeta.sessionId,
            what: _sessionMeta.what
        });
    };

    $scope.goToExistingEntry = function (entry) {
        $state.go('home.diary.entry', {
            questionnaireSessionId: entry.questionnaire_session_id,
            what: entry.entry_id
        });
    };

    $scope.backToList = function () {
        $rootScope.questionnaireVisible = false;
    };

    function _initCtrl(diary) {
        _sessionMeta = questionnaireService.parseNewEntryURL(
            diary.meta.new_entry_url
        );

        $scope.newEntryUrl = '#' + diary.new_entry_url
            .replace(/\/+$/, '');  // Strip trailing '/'.

        $scope.diary = diary;
    }

    function _activate() {
        activityService.getActivity(_omit($state.params,
            ['what', 'questionnaireSessionId']),
            {fromCache: false})
            .then(_initCtrl);
    }

    _activate();
}

export default angular
    .module('activities.diary.DiaryController', [])
    .controller('DiaryController', DiaryController);
