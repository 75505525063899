'use strict';

import angular from 'angular';
import adjustHeight from './adjust-height.directive.js';
import iconRackAnswerHtml from './icon-rack-answer.html'

/* @ngInject */
function iconRackAnswer(answerService, iScrollService) {
    function _toggleValue(question, row) {
        var valueRowId = row.valuerow_id,
            key = question.question_id + ':' + valueRowId;

        answerService.antiJitterToggle(
            key, question.answerIndex.rows[valueRowId]);

        question.updateAnswer();
    }

    function _link(scope) {
        scope.iScrollState = iScrollService.state;

        scope.sliderIsLocked = scope.question.icon_rack_categories.length <= 1;

        scope.toggleValue = _toggleValue;

        scope.question.updateAnswer();
    }

    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        link: _link,
        templateUrl: iconRackAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.iconRackAnswer', [
        adjustHeight.name
    ])
    .directive('iconRackAnswer', iconRackAnswer);
