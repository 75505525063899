'use strict';

import angular from 'angular';
import connectedUsersService from './connected-users-service.factory.js'
import realTime from './real-time.factory.js'
import realTimeEngineService from './real-time-engine.service.js'
import realTimeMessage from './real-time-message/real-time-message.js'
import ScrollSpyDirective from './scroll-spy.directive'

export default angular
    .module('components.realTimeEngine', [
        connectedUsersService.name,
        realTime.name,
        realTimeEngineService.name,
        realTimeMessage.name,
        ScrollSpyDirective.name
    ]);
