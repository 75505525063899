'use strict';

import angular from 'angular';
import realTimeConnectedUsersHtml from './real-time-connected-users.html'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _indexOf from 'lodash/indexOf'
import _intersection from 'lodash/intersection'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _size from 'lodash/size'


/* @ngInject */
function realTimeConnectedUsers($rootScope, $uiRouterGlobals, $state, 
                                activeFiltersService,
                                selectionService, segmentPanelService) {
    function _link(scope) {
        $state.$current.locals = {globals: $uiRouterGlobals};
        var _userFilter =
                activeFiltersService.getInheritedSelectionsFilter($state.$current),
            _selectionName = _userFilter.name,
            _selectionIdName = _userFilter.idName,
            _stateSegmentsId = activeFiltersService.getStateId($state.$current,
                'segmentsName'),
            _stats = {
                count: null,
                total: null,
                isFiltered: false
            };

        function _toggleUser(user) {
            if (!selectionService.isIncluded(_userFilter.collection, user)) {
                user.isFiltered = true;
                selectionService.add(user, _selectionIdName, _selectionName);
            } else {
                user.isFiltered = false;
                selectionService.remove(user, _selectionIdName, _selectionName);
            }

            $rootScope.$emit('realTimeMessagingFilterUpdated', {
                activityType: $state.params.activityType,
                activityId: $state.params.activityId,
                filterName: 'users'
            });
        }

        function _updateUsersByFilter() {
            _forEach(scope.contributors, function _updateUser(user) {
                user.isFiltered =
                    selectionService.isIncluded(_userFilter.collection, user);
            });
        }

        function _updateStats(isFiltered) {
            _stats.total = _size(scope.contributors);
            _stats.count = _filter(scope.contributors, 'inSample').length;
            _stats.isFiltered = isFiltered;
        }

        function _definePopulation(population) {
            if (population.isFiltered) {
                const populationIds = _intersection(population.userIds,
                    _map(scope.contributors, 'user_id'));

                _forEach(scope.contributors, function _includeOrExcludeUser(user) {
                    user.inSample =
                        _indexOf(populationIds, user.user_id) !== -1;
                });
            } else {
                _forEach(scope.contributors, function _includeOrExcludeUser(user) {
                    user.inSample = true;
                });
            }

            _updateStats(population.isFiltered);
        }

        function _refreshSegments() {
            return segmentPanelService.getPopulation(_stateSegmentsId)
                .then(_definePopulation);
        }

        function _contributorsChanged(contributors) {
            if (angular.isDefined(contributors) && !_isEmpty(contributors)) {
                _refreshSegments()
                    .then(_updateUsersByFilter);
            }
        }

        function _populationChanged(event, data) {
            if (data.stateId === _stateSegmentsId) {
                _refreshSegments();
            }
        }

        function _init() {
            var destroyWatcher = scope.$watch('mayFilterUsers',
                function _changed(mayFilterUsers) {
                    if (mayFilterUsers) {
                        scope.toggleUser = _toggleUser;
                    } else {
                        scope.toggleUser = angular.noop;
                    }

                    if (angular.isDefined(mayFilterUsers)) {
                        destroyWatcher();
                    }
                });

            scope.showHelpState = false;
            scope.userFilter = _userFilter;
            scope.stats = _stats;

            if (selectionService.isInExcludeMode(_userFilter.collection)) {
                // Change to `include` mode:
                selectionService.clear(_selectionName, _selectionIdName, false);
            }

            scope.$watchCollection('contributors', _contributorsChanged);

            var destroyListener =
                $rootScope.$on(segmentPanelService.updatedSignal,
                    _populationChanged);

            scope.$on('$destroy', destroyListener);
        }

        _init();
    }

    return {
        scope: {
            contributors: '=realTimeConnectedUsers',
            stats: '=',
            mayFilterUsers: '='
        },
        link: _link,
        replace: true,
        templateUrl: realTimeConnectedUsersHtml
    };
}

export default angular
    .module('activities.realTimeConnectedUsers', [])
    .directive('realTimeConnectedUsers', realTimeConnectedUsers);
