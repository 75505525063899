'use strict';

import angular from 'angular';
import _debounce from 'lodash/debounce'


/* @ngInject */
function ActivitiesController($scope, activityService, api, utility) {
    $scope.apiUrl = api.url;
    $scope.activities = activityService.filteredActivities;
    $scope.broadcastScrollEvent =
        _debounce(utility.broadcastScrollEvent, 250);
}

export default angular
    .module('activities.activitiesController', [])
    .controller('ActivitiesController', ActivitiesController);
