'use strict';

import angular from 'angular';

export default angular.module('components.userSignals', [])
    .constant('userSignals' ,{
    userLogoutSignal: 'user.logout',
    userLoginSignal: 'user.login',
    userLogoutSuccessSignal: 'user.logout.success'
});
