'use strict';

// Faking a “shallow” Lodash object based on `google-angular-maps' usage.
export const name = 'angular-google-maps-lodash-shim';

import _clone from 'lodash/clone'
import _cloneDeep from 'lodash/cloneDeep'
import _compact from 'lodash/compact'
import _defer from 'lodash/defer'
import _delay from 'lodash/delay'
import _each from 'lodash/each'
import _every from 'lodash/every'
import _extend from 'lodash/extend'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _flatten from 'lodash/flatten'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _head from 'lodash/head'
import _includes from 'lodash/includes'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _isNumber from 'lodash/isNumber'
import _isNull from 'lodash/isNull'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _isUndefined from 'lodash/isUndefined'
import _keys from 'lodash/keys'
import _last from 'lodash/last'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _omit from 'lodash/omit'
import _once from 'lodash/once'
import _partial from 'lodash/partial'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import _pullAt from 'lodash/pullAt'
import _reduce from 'lodash/reduce'
import _remove from 'lodash/remove'
import _reverse from 'lodash/reverse'
import _size from 'lodash/size'
import _some from 'lodash/some'
import _sortedIndex from 'lodash/sortedIndex'
import _without from 'lodash/without'
import _zipObject from 'lodash/zipObject'

export const clone = _clone;
export const cloneDeep = _cloneDeep;
export const compact = _compact;
export const defer = _defer;
export const delay = _delay;
export const each = _each;
export const every = _every;
export const extend = _extend;
export const filter = _filter;
export const find = _find;
export const findIndex = _findIndex;
export const flatten = _flatten;
export const forEach = _forEach;
export const get = _get;
export const head = _head;
export const includes = _includes;
export const isArray = _isArray;
export const isEmpty = _isEmpty;
export const isEqual = _isEqual;
export const isFunction = _isFunction;
export const isNumber = _isNumber;
export const isNull = _isNull;
export const isObject = _isObject;
export const isString = _isString;
export const isUndefined = _isUndefined;
export const keys = _keys;
export const last = _last;
export const map = _map;
export const merge = _merge;
export const omit = _omit;
export const once = _once;
export const partial = _partial;
export const pick = _pick;
export const pickBy = _pickBy;
export const pullAt = _pullAt;
export const reduce = _reduce;
export const remove = _remove;
export const reverse = _reverse;
export const size = _size;
export const some = _some;
export const sortedIndex = _sortedIndex;
export const without = _without;
export const zipObject = _zipObject;

// Monkey-patching Lodash based on `fixLodash()` in `google-angular-maps':
export const all = every;
export const any = some;
export const contains = includes;
export const first = _head;
export const object = zipObject;
