'use strict';

import angular from 'angular';
import LoginController from './login.controller.js'
import loginHtml from './login.html'

/* @ngInject */
function config($stateProvider, gettext) {
    $stateProvider
        .state({
            name: 'welcome.modal.login',
            url: '/login',
            title: gettext('Login'),
            views: {
                'modal-contents@': {
                    controller: 'LoginController',
                    templateUrl: loginHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout($rootScope,
                                                            coreLayoutService) {
                $rootScope.$applyAsync(function () {
                    coreLayoutService.updateModal({
                        size: 'small',
                        footer: {
                            hidden: coreLayoutService.defaultExcept({}, true),
                            visible: coreLayoutService.defaultExcept()
                        }
                    });
                });
            },
            secure: false,
            isLogin: true
        });
}

export default angular
    .module('welcome.login', [
        LoginController.name
    ])
    .config(config);
