'use strict';
import { QueryBracket } from "./query-bracket";
import { QueryConstraint } from "./query-constraint";
import { PopulationCountDirective } from "./population-count.directive";
var queryBracketHtml = require('./query-bracket.html');
import * as angular from 'angular';
import _includes from 'lodash/includes';
/* @ngInject */
function queryBracket($log, segmentService) {
    var validQuantifiers = ['all', 'any'];
    function _link(scope) {
        var node = scope.node, newQuantifierNamesMenu = [];
        for (var i = node.quantifier_names.length - 1; i >= 0; i--) {
            //$log.debug('query-bracket.directive._link: Checking', node.quantifier_names[i]);
            if (_includes(validQuantifiers, node.quantifier_names[i].data.value)) {
                //$log.debug('query-bracket.directive._link: Deleting', node.quantifier_names[i].data.value, node.quantifier_names[i]);
                //delete node.quantifier_names[i];
                newQuantifierNamesMenu.push(node.quantifier_names[i]);
            }
        }
        node.quantifier_names = newQuantifierNamesMenu;
        //$log.debug('quantifier_names', node.quantifier_names);
        //$log.debug('angular.toJson(node.quantifier_names)', angular.toJson(node.quantifier_names));
        scope.translateQuantifier = {
            any: 'or',
            all: 'and'
        };
        function _addBracket() {
            node.children.push(new QueryBracket({
                meta: node.meta,
                children: [new QueryConstraint({ meta: node.meta })]
            }));
        }
        scope.addBracket = _addBracket;
        function _deleteBracket() {
            segmentService.deleteNode(node.meta.root, node);
            segmentService.requestUpdate(node.meta.root);
        }
        scope.deleteBracket = _deleteBracket;
        function _addConstraint() {
            node.children.push(new QueryConstraint({ meta: node.meta }));
        }
        scope.showSql = false;
        scope.addConstraint = _addConstraint;
        scope.onChange = segmentService.requestUpdate;
    }
    return {
        link: _link,
        scope: {
            node: '=queryBracket',
            extra: '='
        },
        templateUrl: queryBracketHtml,
        replace: true
    };
}
export var QueryBracketDirective = angular
    .module('components.query.queryNode.queryBracket', [
    PopulationCountDirective.name
])
    .directive('queryBracket', queryBracket);
