'use strict';

import angular from 'angular';
import usersListHtml from './users-list.html'


/* @ngInject */
function usersList($state, activeFiltersService, gridUtils, gettext,
                   gettextCatalog, utility, user, moment) {
    const columnDefs = [
        {
            field: 'user_id',
            headerName: '<input type="checkbox" class="ag-selection-checkbox" indeterminate-checkbox="selections.isAllIndicator" on-change="selections.allChanged">',
            headerClass: 'check-all',
            suppressResize: true,
            width: 25,
            checkboxSelection: true,
            valueGetter: gridUtils.nullValue,
            suppressMenu: true,
            suppressSorting: true,
            headerTooltip: 'Toggle all',
            compileWithAngular: true
        },
        {
            headerName: gettext('User ID'), field: 'user_id',
            filter: 'number',
            width: 95,
            hide: true
        },
        {
            headerName: gettext('Last Name'), field: 'last_name',
            filter: 'text',
            width: 120
        },
        {
            headerName: gettext('First Name'), field: 'first_name',
            filter: 'text',
            width: 110
        },
        {
            headerName: gettext('Email'), field: 'email_address',
            filter: 'text',
            width: 225
        },
        {
            headerName: gettext('Country'), field: 'country_id',
            hide: true,
            filter: 'text',
            width: 40
        },
        {
            headerName: gettext('ZIP/Postal Code'), field: 'postal_code',
            hide: true,
            filter: 'text',
            width: 50
        },
        {
            headerName: gettext('City'), field: 'city',
            filter: 'text',
            width: 95,
            hide: true
        },
        {
            headerName: gettext('Region'), field: 'region',
            filter: 'text',
            width: 95,
            hide: true
        },
        {
            headerName: gettext('Phone'), field: 'humane_phone_number',
            filter: 'text',
            width: 118,
            hide: true
        },
        {
            headerName: gettext('Verified?'),
            field: 'is_verified',
            filter: 'set',
            filterParams: {
                cellRenderer: _isVerifiedLongForm,
                values: [true, false],
            },
            cellRenderer: _isVerifiedShortForm,
            width: 80
        },
        {
            headerName: gettext('Birth Date'), field: 'birthdate',
            filter: 'text',
            width: 100,
            hide: true
        },
        {
            headerName: gettext('Age'), field: 'age',
            filter: 'number',
            width: 90
        },
        {
            headerName: '<span class="fa fa-mars"></span>/' +
                '<span class="fa fa-venus"></span>',
            field: 'gender_id',
            filter: 'set',
            filterParams: {
                cellRenderer: _genderLongForm,
                values: ['female', 'male']
            },
            cellRenderer: _genderShortForm,
            width: 80
        },
        {
            headerName: gettext('Latest Action'),
            field: 'latest_action_timestamp',
            filter: 'date',
            valueFormatter: _dateRenderer,
            width: 100,
            hide: false
        },
        {
            headerName: gettext('Registration Date'), field: 'created',
            filter: 'date',
            valueFormatter: _dateRenderer,
            width: 100,
            hide: false
        },
        {
            headerName: gettext('Actions'), field: 'user_id',
            cellRenderer: _actionColumnRenderer,
            width: 100,
            suppressMenu: true,
            suppressSorting: true
        }
    ];

    function _dateRenderer(data) {
        return data.value !== null ? moment(data.value).format('YYYY-MM-DD HH:mm:ss') : '';
    }

    function _actionColumnRenderer() {
        return '<a ui-sref="admin.users.edit({userId: data.user_id})" ' +
            'ng-bind="::\'Edit\' |translate"></a>';
    }

    const _genderElement = {
        male: '<span class="fa fa-fw fa-mars"></span>',
        female: '<span class="fa fa-fw fa-venus"></span>'
    };

    function _genderShortForm(params) {
        return _genderElement[angular.isDefined(params.data) ?
            params.data.gender_id : params.value] || '';
    }

    function _genderLongForm(params) {
        return {
            male: `<span class="fa fa-fw fa-mars"></span> ${gettextCatalog.getString(gettext('Male'))}`,
            female: `<span class="fa fa-fw fa-venus"></span> ${gettextCatalog.getString(gettext('Female'))}`
        }[params.value];
    }

    const _isVerifiedElement = {
        true: '<span class="fa fa-fw fa-check"></span>',
        false: '<span class="fa fa-fw fa-times"></span>'
    };

    function _isVerifiedShortForm(params) {
        return _isVerifiedElement[angular.isDefined(params.data) ?
            params.data.is_verified : params.value] || '';
    }

    function _isVerifiedLongForm(params) {
        return {
            'true': `<span>${gettextCatalog.getString(gettext('Verified'))}</span>`,
            'false': `<span>${gettextCatalog.getString(gettext('Unverified'))}</span>`
        }[params.value];
    }

    function _link(scope) {
        const selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName),
            selectionIdName = utility.getParentData($state.$current,
                activeFiltersService.defaultSelectionsIdName),
            rowIdPath = 'user_id',
            ops = gridUtils.getGridOperations({
                useVirtualPaging: true,
                columnDefs: columnDefs,
                gridOptions: {},
                selectionName: selectionName,
                selectionIdName: selectionIdName,
                rowIdPath: rowIdPath,
                resource: user,
                resourceExtraColumns: [
                    'email_address',
                    'country_id',
                    'postal_code',
                    'city',
                    'region',
                    'humane_phone_number',
                    'expiry_time_z',
                    'is_verified',
                    'birthdate',
                    'age',
                    'gender_id',
                    'created',
                    'latest_action_timestamp'
                ],
                resourceResponseCollection: 'users',
                storageName: 'usersList',
                scope: scope
            });

        scope.toggleToolPanelVisibility = ops.toggleToolPanelVisibility;
        scope.showSql = false;

        scope.gridOptions = ops.gridOptions;
    }

    return {
        scope: true,
        replace: true,
        link: _link,
        /**
         * The ng-if="gridOptions" in the template element is there to avoid
         * initializing `ag-grid` until the options have been defined.
         */
        templateUrl: usersListHtml
    };
}

export default angular
    .module('admin.users.usersList', ['agGrid'])
    .directive('usersList', usersList);
