'use strict';

import angular from 'angular';
import Color from 'color-js';
import _has from 'lodash/has'


/* @ngInject */
function activityBranding($q, $rootScope, $log, api, panelService) {
    const defaults = {
            color: {
                medium: '/* @echo DEFAULT_BRAND_COLOR */' //'#E673AC'
            }
        },
        black = new Color('#000000'),
        // blackTextColor = new Color('#333333'),
        white = new Color('#ffffff'),
        // whiteTextColor = new Color('#ffffff'),
        _panelBrands = {},
        shared = {
            color: _generateColorBlends(new Color(defaults.color.medium)),
            defaultShade: 'medium',
            updateSignal: 'activityBranding:updated',
            updateActivityBranding: _updateActivityBranding,
            panelBrands: _panelBrands,
            getBrandingForPanel: _getBrandingForPanel
        },
        blendFactor = 0.2;

    function _generateColorBlends(color, factor) {
        factor = angular.isDefined(factor) ? factor : blendFactor;
        const shades = {
            light: color.blend(white, factor).toCSS(),
            halfLight: color.blend(white, factor / 2.0).toCSS(),
            medium: color.toCSS(),
            halfDark: color.blend(black, factor / 2.0).toCSS(),
            dark: color.blend(black, factor).toCSS()
        };
        // for (const col in shades) {
        //     shades['textOn' + upperFirst(col)] =
        //         color.getLuminance() > 50 ? blackTextColor : whiteTextColor;
        // }
        return shades;
    }

    function _getBrandingForPanel(panelId) {
        const deferred = $q.defer();

        function _createPanelBranding(panel) {
            _panelBrands[panelId] =
                _generateColorBlends(new Color(panel.brand_color), 0.7);
            deferred.resolve(_panelBrands[panelId]);
        }

        if (!_has(_panelBrands, panelId)) {
            panelService.getById(panelId).then(_createPanelBranding);
        } else {
            deferred.resolve(_panelBrands[panelId]);
        }

        return deferred.promise;
    }

    function _updateActivityBranding(panel) {
        const brandColor = new Color(
            panel.brand_color || defaults.color.medium);

        shared.color = _generateColorBlends(brandColor);

        $rootScope.$broadcast(shared.updateSignal);
    }

    function _currentPanelChangedHandler(event, panel) {
        if (angular.isDefined(panel)) {
            _updateActivityBranding(panel);
        }
    }

    $rootScope.$on(panelService.currentPanelChangedSignal,
        _currentPanelChangedHandler);

    _currentPanelChangedHandler(null, $rootScope.currentPanel);

    return shared;
}

export default angular
    .module('components.activityBranding', [])
    .factory('activityBranding', activityBranding);
