'use strict';

import angular from 'angular';
import menu from '../home/menu/menu.js';
import activeFilters from './active-filters.directive.js';
import activities from './activities/activities.js';
import markets from './markets/markets.js';
import messages from './messages/messages';
import panels from './panels/panels.js';
import supervisorBox from './supervisor-box.directive.js';
import users from './users/users.js';
import headerHtml from '../components/header/header.html'
import menuHtml from '../home/menu/menu.html'
import notificationsDrawerHeaderHtml
    from '../notifications/notifications.drawer.header.html'
import notificationsDrawerHtml from '../notifications/notifications.drawer.html'

/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state({
            name: 'admin',
            abstract: true,
            url: '^/admin/',
            views: {
                'main-header@': {
                    controller: 'HeaderController',
                    templateUrl: headerHtml
                },
                'left-drawer-contents@': {
                    controller: 'MenuController',
                    controllerAs: 'vm',
                    templateUrl: menuHtml
                },
                'right-drawer-header@': {
                    //controller: 'NotificationController',
                    templateUrl: notificationsDrawerHeaderHtml
                },
                'right-drawer-contents@': {
                    //controller: 'NotificationController',
                    templateUrl: notificationsDrawerHtml
                }
                //'main-contents@': {
                //    controller: 'HomeController',
                //    templateUrl: homeHtml
                //}
            },
            onEnter: /* @ngInject */ function _updateLayout(layoutService) {
                layoutService.mainLayout();
            },
            secure: true
        });
}

export default angular
    .module('admin', [
        menu.name,
        activeFilters.name,
        activities.name,
        markets.name,
        messages.name,
        panels.name,
        supervisorBox.name,
        users.name
    ])
    .config(config);
