'use strict';

import angular from 'angular';
import openerHtml from './opener.html'
import _assignIn from 'lodash/assignIn'
import _includes from 'lodash/includes'

/* @ngInject */
function opener($location, $state, $q, $log, api, metaService,
                rewardService, activityService, trackingService, gettext) {
    const isDevelopmentMode = false;

    function _link(scope) {
        const activity = scope.activity;

        function _joinActivity() {
            api.get(activity.join_url).then(function _postJoin() {
                // put points in
                rewardService.redeemable_points = activity.redeemable_points;

                trackingService.trackActivity(trackingService.actions.join, activity);

                // Update activities.
                activityService.refresh().then(function () {
                    if (activity.discriminator === 'questionnaire') {
                        const params = {
                            activityType: activity.discriminator,
                            activityId: activity.activity_id
                        };

                        if ($state.includes('**.activity-preview')) {
                            $state.go('^.questionnaire', params);
                        } else {
                            $state.go('.questionnaire', params);
                        }
                        //$state.go('.questionnaire', {
                        //    activityType: activity.discriminator,
                        //    activityId: activity.activity_id
                        //});
                    } else {
                        $location.path(activity.external_url);
                    }

                });
            });
        }

        function _viewReport() {
            trackingService.trackActivity(trackingService.actions.report, activity);

            $state.go('report', {activityId: activity.activity_id});
        }

        function _dismissActivity() {
            api.get(activity.dismiss_url).then(function _postDismiss() {
                trackingService.trackActivity(trackingService.actions.dismiss, activity);

                // Update activities.
                activityService.refresh().then(function () {
                    $state.transitionTo($state.current,
                        $state.params,
                        {
                            reload: true,
                            inherit: true,
                            notify: true
                        });
                });

            });
        }

        function _showActivityPopup() {
            const params = {activity: activity};

            trackingService.trackActivity(trackingService.actions.about, activity);

            if ($state.is('home')) {
                $state.go('home.activity-preview', params);
            } else if ($state.is('home.activities')) {
                $state.go('home.activities.activity-preview', params);
            } else if ($state.is('home.panel')) {
                $state.go('home.panel.activity-preview', params);
            }
            //$state.go($state.current.name.split('.', 2).join('.') +
            //    '.activity-preview', {activity: activity});
        }

        function _openActivity(extraParams) {
            // put points in
            rewardService.redeemable_points = activity.redeemable_points;

            trackingService.trackActivity(trackingService.actions.open, activity);

            if (activity.discriminator === 'questionnaire') {
                const params = {
                    activityType: activity.discriminator,
                    activityId: activity.activity_id
                };

                if ($state.includes('**.activity-preview')) {
                    $state.go('^.questionnaire',
                        _assignIn(params, extraParams));
                } else {
                    $state.go('.questionnaire',
                        _assignIn(params, extraParams));
                }
            } else {
                $location.path(activity.external_url);
                //$state.go('home.' + activity.discriminator, {
                //    activityType: activity.discriminator,
                //    activityId: activity.activity_id
                //});
            }
        }

        function _openLoopActivity() {
            _openActivity({doLoop: true});
        }

        function _activate(isDemo, isSupervisor) {
            if (scope.isPopup) {
                scope.infoButtonText = gettext('No, Thanks');
                scope.infoClickHandler = _dismissActivity;
            } else {
                scope.infoButtonText = gettext('Read More');
                scope.infoClickHandler = _showActivityPopup;
            }

            // Button texts and actions
            scope.doShowCallToAction = true;
            scope.doShowInfo = true;

            switch (activity.rendered_status) {
                case 'new':
                    scope.ctaClickHandler = _joinActivity;
                    scope.ctaButtonText = activity.join_activity_synonym;
                    break;
                case 'active':
                    scope.ctaClickHandler = _openActivity;
                    scope.ctaButtonText = gettext('Open');
                    break;
                case 'closed':
                /* Fallthrough */
                // eslint-disable-next-line no-case-declarations,no-fallthrough
                case 'reported':
                    scope.ctaClickHandler = _viewReport;
                    scope.ctaButtonText = gettext('View Results');
                    // FIXME: _Temporarily_ hiding show results button.
                    //scope.doShowCallToAction =
                    //    (activity.user_feedback_url !== null);
                    scope.doShowCallToAction = false;
                    scope.doShowInfo = (!scope.isPopup);

                    const supervisorWantsReport = false;
                    // TODO: When reports are working, we probably want
                    // questionnaire type activities to be displayed as usual:
                    //var supervisorWantsReport =
                    //    includes(['questionnaire', 'diary',
                    //                 'touchpoints', 'screener',
                    //                 'quick_poll'], activity.discriminator);
                    if (isSupervisor && !supervisorWantsReport) {
                        scope.doShowCallToAction = true;
                        scope.ctaClickHandler = _openActivity;
                        scope.ctaButtonText = gettext('Open');
                    }

                    break;
                default:
                    $log.warn('Unhandled rendered_status');
                    break;
            }

            if (isDemo && activity.discriminator === 'questionnaire') {
                scope.doShowDemoButton = true;
                scope.demoClickHandler = _openLoopActivity;
                scope.demoButtonText = gettext('Loop');
            }

            /* Force possibility to "join" a questionnaire multiple
             times (to respond multiple times to the same
             questionnaire in development mode. */
            if (
                isDevelopmentMode === true &&
                _includes(['questionnaire', 'forum'],
                    activity.discriminator)) {
                scope.ctaClickHandler = _joinActivity;
                scope.ctaButtonText = activity.join_activity_synonym +
                    gettext(' (Development Mode)');
            }
        }

        $q.all({
            meta: metaService.get()
        }).then(function _preActivate(values) {
            _activate(values.meta.user.is_demo_user,
                activity.isSupervisedByCurrentUser);
        });
    }

    return {
        restrict: 'AE',
        scope: {
            activity: '=opener',
            isPopup: '='
        },
        templateUrl: openerHtml,
        link: _link
    };
}

export default angular
    .module('activities.activityList.activityItem.opener', [])
    .directive('opener', opener);
