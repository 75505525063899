'use strict';

import angular from 'angular';
import developerModeService from './developer-mode.service.js';

/* @ngInject */
function AboutController($log, api, developerModeService, panelService) {
    const vm = this;

    function _activate() {
        vm.termsUrl = api.url + '/tos/';
        vm.privacyUrl = api.url + '/privacy/';
        vm.unlockDeveloperMode = developerModeService.unlockDeveloperMode;
        vm.developerState = developerModeService.state;
        vm.showFaq = false;

        panelService.get()
            .then((panels) => {
                vm.panel = panels.current;

                $log.debug('vm.panel', vm.panel);
            });

    }

    _activate();
}

export default angular
    .module('about.aboutController', [
        developerModeService.name
    ])
    .controller('AboutController', AboutController);
