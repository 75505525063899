'use strict';

import angular from 'angular';
import ActivitiesController from './activities.controller.js';
import activitiesList from './activities-list.directive.js';
import activitiesWrapperHtml from './activities-wrapper.html'
import activitiesHtml from './activities.html'
import editActivityHtml from './edit-activity.html'
import panelModalHeaderHtml from '../../components/modal/panel.modal.header.html'
import deleteActivitiesHtml from './delete-activities.html'
import activitiesFilterHtml from './activities-filter.html'
import activitiesStatsHtml from './activities-stats.html'

/* @ngInject */
function config($stateProvider, gettext) {
    /*
     Let Angular resolve and inject meta value.
     */
    const metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state({
            name: 'admin.activities',
            abstract: true,
            url: 'activities/',
            views: {
                'main-contents@': {
                    templateUrl: activitiesWrapperHtml,
                    controller: 'ActivitiesAdminController',
                    controllerAs: 'vm'
                }
            },
            secure: true,
            data: {
                selectionsName: 'admin.activities',
                selectionsIdName: 'activity_id'
            },
            resolve: {
                meta: metaResolver
            }
        })
        .state({
            name: 'admin.activities.list',
            url: 'list/',
            views: {
                'activities': {
                    templateUrl: activitiesHtml
                }
            },
            secure: true
        })
        .state({
            name: 'admin.activities.create',
            url: 'create/',
            views: {
                'activities': {
                    templateUrl: editActivityHtml,
                    controller: 'EditActivityController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state({
            name: 'admin.activities.edit',
            url: ':activityId/edit/',
            views: {
                'activities': {
                    templateUrl: editActivityHtml,
                    controller: 'EditActivityController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        // .state({
        //     name: 'admin.activities.export-activity-data',
        //     url: 'export-activity-data/',
        //     views: {
        //         'activities': {
        //             templateUrl: 'views/admin/activities/export-activity-data.html',
        //             controller: 'ExportActivityDataController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     params: {
        //         activitiesampleQueryParams: null
        //     },
        //     secure: true
        // })
        .state({
            name: 'admin.activities.list.delete',
            url: 'delete/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: deleteActivitiesHtml,
                    controller: 'DeleteActivitiesController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.activities.list'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Danger Zone'),
            panelMode: 'danger',
            hideCloseButton: false,
            secure: true
        })
        .state({
            name: 'admin.activities.segment',
            url: 'segment/:queryId',
            views: {
                'activities': {
                    templateUrl: activitiesFilterHtml
                }
            },
            secure: true
        })
        .state({
            name: 'admin.activities.stats',
            url: 'stats/',
            views: {
                'activities': {
                    templateUrl: activitiesStatsHtml,
                    controller: 'ActivitiesStatsController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        });

}

export default angular
    .module('admin.activities', [
        ActivitiesController.name,
        activitiesList.name
    ])
    .config(config);
