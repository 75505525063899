'use strict';

import angular from 'angular';

/* @ngInject */
function CompleteProfileController($state, $urlMatcherFactory,
                                   questionnaireService, meta,
                                   activityService) {
    var urlMatcher = $urlMatcherFactory.compile(
        '/{activityType:questionnaire}/{activityId:int}.json');

    function _activate() {
        activityService.refresh();
        $state.go('^', {}, {reload: true});
    }

    questionnaireService.getActivity(
        urlMatcher.exec(meta.onboarding.profile_completion_url), {
            oneQuestionPerPage: true
        }).then(_activate);
}

export default angular
    .module('completeProfile.CompleteProfileController', [])
    .controller('CompleteProfileController', CompleteProfileController);
