'use strict';

import angular from 'angular';

/* @ngInject */
function MembershipChangesController($state, $window, activeFiltersService,
                                     user) {
    const vm = this;

    function _activate() {
        vm.cancel = () => {
            $window.history.back();
        };

        vm.action = $state.params.action;
        vm.panel = $state.params.panel;

        if ($state.params.userIds && $state.params.userIds.length) {
            user.query({
                panel_ids: [],
                activity_ids: [],
                query_ids: [],
                exclude_user_ids: null,
                user_ids: $state.params.userIds,
                extra_columns: ['email_address']
            }).$promise
                .then(function _updateRows(response) {
                    vm.total = response.total;
                    vm.users = response.users;
                });
        } else {
            vm.total = 0;
            vm.users = [];
        }
    }

    _activate();
}

export default angular
    .module('admin.users.MembershipChangesController', [])
    .controller('MembershipChangesController', MembershipChangesController);
