'use strict';

import angular from 'angular';
import angularMessages from 'angular-messages'

/* @ngInject */
function RegistrationController($scope, $rootScope, $state, $log, config,
                                countries, panelService, user, Modernizr,
                                trackingService, utility,
                                passwordService, genders, acceptEulaService,
                                legalModalService) {
    const vm = this;

    function _registrationFailure(data) {
        if (data.status === 'failure') {
            vm.errors = data.form_errors.fields;
        }
    }

    function _trackFinishedRegistration() {
        // adformService.track('Etter endt registrering');
        trackingService.trackOnboarding(trackingService.actions.complete);
    }

    function _postRegistration() {
        _trackFinishedRegistration();
        $log.debug('$rootScope.$emit("growl:removeAll")');
        $rootScope.$emit('growl:removeAll');

        const modalLevel = legalModalService.getModalLevel();
        legalModalService.onExit(modalLevel);
        delete acceptEulaService.registration;

        return panelService.refresh()
            .then(() => {
                $state.go('home');
            });
    }

    function _register() {
        return user.register(vm.registration)
            .then(_postRegistration, _registrationFailure);
    }

    function _next() {
        acceptEulaService.registration = _register;
        $state.go('welcome.registration.eula');
    }

    function _propagateLocation(location) {
        vm.registration.country = vm.registration.postalCode.countryCode;
        vm.registration.postal_code = vm.registration.postalCode.postalCode;

        vm.location = (location.city !== null && location.region !== null) ? {
            city: location.city,
            region: location.region
        } : null;
    }

    function _activate() {
        vm.defaultDebounceOptions = config.getDebounceOptions();
        vm.registration = {
            first_name: null,
            last_name: null,
            phone_number: {
                phone_number_country_code: null,
                phone_number: null
            },
            postalCode: {
                countryCode: null,
                postalCode: null
            },
            country: null,
            postal_code: null,
            birthdate: null,
            gender: null,
            email: null,
            password1: null,
            acceptEula: true
        };
        vm.location = null;
        $scope.panels = panelService.panels;  // Used by `welcome-logo` directive.
        vm.next = _next;
        vm.register = _register;
        vm.genders = genders;
        vm.today = utility.dateTime.todayAsIsoDate();
        vm.dateInputType = config.dateInputType;
        vm.dateValidRegex = config.dateValidRegex;
        vm.browser = {
            supportsInputTypeDate: Modernizr.inputtypes.date &&
            config.dateInputType === 'date'
        };
        vm.passwordMinLength = passwordService.minLength;
        vm.propagateLocation = _propagateLocation;

        countries.get('locations', true).then((countryCodes) => {
            vm.countryCodes = countryCodes
        });
        countries.get('dialingCodes', true).then((countryDialingCodes) => {
            vm.countryDialingCodes = countryDialingCodes
        });
    }

    _activate();
}

export default angular
    .module('welcome.registration.registrationController', [
        angularMessages
    ])
    .controller('RegistrationController', RegistrationController);
