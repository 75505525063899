'use strict';

import angular from 'angular';
import multipleSelectAnswerHtml from './multiple-select-answer.html'

/* @ngInject */
function multipleSelectAnswer(answerService, iScrollService) {
    function _toggleValue(question, row) {
        var valueRowId = row.valuerow_id;

        answerService.antiJitterToggle(
            question.question_id + ':' + valueRowId,
            question.answerIndex.rows[valueRowId]);

        question.updateAnswer();
    }

    function _link(scope) {
        scope.iScrollState = iScrollService.state;

        scope.toggleValue = _toggleValue;

        scope.question.updateAnswer();
    }

    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        link: _link,
        templateUrl: multipleSelectAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.multipleSelectAnswer', [])
    .directive('multipleSelectAnswer', multipleSelectAnswer);
