'use strict';

import angular from 'angular';
import uploadedImageAnswerHtml from './uploaded-image-answer.html'

/* @ngInject */
function uploadedImageAnswer(upload) {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: uploadedImageAnswerHtml,
        link: function _link(scope) {
            scope.pickAndStore = upload.pickAndStore;
        }
    };
}

export default angular
    .module('activities.questionnaire.answerType.uploadedImageAnswer', [])
    .directive('uploadedImageAnswer', uploadedImageAnswer);
