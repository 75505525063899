'use strict';

import angular from 'angular';

/* @ngInject */
function RealTimeMenuDrawerController($rootScope, $scope, $stateParams,
                                      coreLayoutService, realTimeEngine) {
    function _hideDrawer() {
        coreLayoutService.toggleDrawer('rightDrawer');
    }

    $scope.hideDrawer = _hideDrawer;

    function _setScopeAttributes(event, data) {
        $scope.activity = data.activity;
        $scope.contributors = data.contributors;
        $scope.stats = data.stats;

        const engine = realTimeEngine.getEngine(realTimeEngine.stateParamsToKey($stateParams));
        $scope.status = engine.status;
    }

    function _init() {
        $rootScope.$on('$destroy', $rootScope.$on(realTimeEngine.isIntializedSignal,
            _setScopeAttributes));
    }

    _init();
}

export default angular
    .module('activities.realTimeMenuDrawerController', [])
    .controller('RealTimeMenuDrawerController', RealTimeMenuDrawerController);
