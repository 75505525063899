'use strict';

import angular from 'angular';

/* @ngInject */
function subQuestionAnimation() {
    function _link(scope, element) {
        scope.question.$$$_transition = {
            element: element,
            scope: scope,
            parent: element.parents('.scrollable-area')
        };
    }

    return {
        restrict: 'A',
        scope: {
            question: '=subQuestionAnimation'
        },
        link: _link
    };
}

export default angular
    .module('activities.questionnaire.subQuestionAnimation', [])
    .directive('subQuestionAnimation', subQuestionAnimation);
