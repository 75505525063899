'use strict';

import angular from 'angular';
import attachmentSwitch
    from '../../components/real-time-engine/real-time-message/attachment-switch.directive.js';
import EditAttachmentController from './edit-attachment.controller';

import editAttachmentHeaderHtml from './edit-attachment.header.html'
import editAttachmentHtml from './edit-attachment.html'
// import editAttachmentFooterHtml from './edit-attachment.footer.html'

/* @ngInject */
function editAttachmentState(name) {
    return {
        name: name,
        params: {
            message: undefined,
            what: undefined,
        },
        views: {
            'modal-header@': {
                controller: 'EditAttachmentController',
                controllerAs: 'vm',
                templateUrl: editAttachmentHeaderHtml
            },
            'modal-contents@': {
                controller: 'EditAttachmentController',
                controllerAs: 'vm',
                templateUrl: editAttachmentHtml
            },
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                size: 'small',
                footer: {
                    hidden: coreLayoutService.defaultExcept({}, true),
                    visible: coreLayoutService.defaultExcept()
                }
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.editAttachmentState', [
        attachmentSwitch.name,
        EditAttachmentController.name,
    ])
    .constant('editAttachmentState', editAttachmentState);
