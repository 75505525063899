'use strict';

import angular from 'angular';

/* @ngInject */
function rewardService() {
    return {};
}

export default angular
    .module('components.rewardService', [])
    .factory('rewardService', rewardService);
