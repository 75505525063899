'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'


/* @ngInject */
function WelcomeRewardController($state, $rootScope, $scope, facebook,
                                 rewardService, config, panelService,
                                 userSignals, trackingService) {
    $scope.redeemable_points = rewardService.redeemable_points;

    $scope.registerFb = facebook.connectFb;
    $scope.config = config;
    $scope.panels = panelService.panels;

    function _trackRewardPage() {
        // adformService.track('Etter oppstartsspørsmål');
        // GA page tracking is performed automatically.
    }

    function _trackRegisterPlainClick() {
        // adformService.track('Klikk på Registrer (plain)');
        trackingService.trackOnboarding(trackingService.actions.register, 'Plain');
    }

    $scope.trackRegisterPlainClick = _trackRegisterPlainClick;

    function _trackRegisterFBClick() {
        // adformService.track('Klikk på Registrer (FB)');
        trackingService.trackOnboarding(trackingService.actions.register, 'Facebook');
    }

    $scope.trackRegisterFBClick = _trackRegisterFBClick;

    if (angular.isDefined($scope.panels.current)) {
        _trackRewardPage();
    }

    const cleanups = [
        $rootScope.$on(panelService.currentPanelChangedSignal,
            _trackRewardPage),
        $rootScope.$on(userSignals.userLoginSignal, function _goHome() {
            $state.go('home', null, {reload: true});
        })
    ];

    function _cleanupListeners() {
        _forEach(cleanups, (cleanup) => cleanup());
    }

    $scope.$on('$destroy', _cleanupListeners);
}

export default angular
    .module('welcome.reward.rewardController', [])
    .controller('WelcomeRewardController', WelcomeRewardController);
