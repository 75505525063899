'use strict';

import angular from 'angular';
import joypixels from 'emoji-toolkit';
import _replace from 'lodash/replace';

/* @ngInject */
function MdHelpController($state) {
    const vm = this;

    function _closeModal() {
        $state.go('^');
    }

    function _cancel() {
        _closeModal();
    }

    vm.cancel = _cancel;
}

/* @ngInject */
function renderMessageFilter($sce, markdownIt) {
    function _renderPipeline(string) {
        return markdownIt.render(joypixels.toImage(string));
    }

    return (text) =>
        $sce.trustAsHtml(
            _renderPipeline(_replace(text,
                /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''))
        );
}

import mdExample from '../../components/real-time-engine/real-time-message/md-example.directive'

export default angular
    .module('activities.forum.MdHelpController', [
        mdExample.name,
    ])
    .controller('MdHelpController', MdHelpController)
    .filter('renderMessage', renderMessageFilter);
