'use strict';

import angular from 'angular';
import forwardClick from './forward-click.directive.js'
import attachmentEditorHtml from './attachment-editor.html'
import _defer from 'lodash/defer'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _map from 'lodash/map'


/* @ngInject */
function attachmentEditor($q, $log, upload, gettext, gettextCatalog) {
    function _resetInputs(scope) {
        scope.model = {
            linkURL: null,
            youtubeURL: null,
            attachedFiles: null
        };
    }

    function _closeSubEditors(scope) {
        scope.showLinkEditor = false;
        scope.showVideoLinkEditor = false;
    }

    function _addAttachment(scope, attachment) {
        scope.message.attachments.push(attachment);
        _closeSubEditors(scope);
        _resetInputs(scope);

        scope.$emit('attachmentsChanged');
    }

    function _link(scope) {
        _closeSubEditors(scope);
        _resetInputs(scope);

        function _cancel() {
            _closeSubEditors(scope);
            _resetInputs(scope);
        }

        scope.cancel = _cancel;

        function _getUploadInfo(file, cache) {
            const deferred = $q.defer(),
                media_type = file.type.split('/', 1)[0] || 'image';

            _defer(function () {
                if (_has(cache, media_type)) {
                    deferred.resolve(cache[media_type]);
                } else {
                    scope.message.get_media_upload_info({
                        media_type: media_type
                    }).then(function (result) {
                        cache[media_type] = result.data;
                        deferred.resolve(cache[media_type]);
                    });
                }
            });

            return deferred.promise;
        }

        function _uploadFile(file, uploadInfo) {
            const deferred = $q.defer();
            $log.debug('attachment-editor.directive._uploadFile: uploadInfo', uploadInfo);
            upload.toFilestack(file, uploadInfo)
                .then(function (result) {
                    const data = result.data;

                    $log.debug('upload-result', result);

                    _addAttachment(scope, {
                        url: data.file_url,
                        type: data.media_type,
                        file_id: data.s3_file_id
                    });

                    deferred.resolve();
                });

            return deferred.promise;
        }

        function _uploadFiles(files) {
            const uploadInfoCache = {},
                promises = _map(files, function (file) {
                    const deferred = $q.defer();

                    _getUploadInfo(file, uploadInfoCache)
                        .then(function (uploadInfo) {
                            _uploadFile(file, uploadInfo)
                                .then(deferred.resolve);
                        });

                    return deferred.promise;
                });

            return $q.all(promises);
        }

        function _connectAttachment(result) {
            const data = result.data;

            _addAttachment(scope, {
                url: data.file_url,
                type: data.media_type,
                file_id: data.s3_file_id
            });
        }

        function _connectAttachments(results) {
            _forEach(results, _connectAttachment);

            scope.$emit('attachmentUploaded');
        }

        function _pickAndUpload(result) {
            const s3UploadInfo = result.data;

            const uploadText = {
                'image': gettextCatalog.getString(gettext('Attach Image')),
                'video': gettextCatalog.getString(gettext('Attach Video')),
            }[s3UploadInfo.media_type];

            return upload.pickAndStore(s3UploadInfo, {'Upload': uploadText});
        }

        function _pickAndStoreImage(mediaType) {
            scope.message.get_media_upload_info({
                media_type: mediaType
            })
                .then(_pickAndUpload)
                .then(_connectAttachments);
        }

        scope.pickAndStoreImage = _pickAndStoreImage;

        scope.$watch('attachedFiles', function _attachedFilesChanged(files) {
            if (files) {
                scope.$emit('attachmentsUploading');
                _uploadFiles(files).then(function _attachmentsWasUploaded() {
                    scope.$emit('attachmentUploaded');
                });
            }
        });

        function _init() {
            _resetInputs(scope);
        }

        _init();
    }

    return {
        restrict: 'AE',
        scope: {
            message: '=',
            style: '=messageStyle'
        },
        templateUrl: attachmentEditorHtml,
        link: _link
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.attachmentEditor', [
        forwardClick.name
    ])
    .directive('attachmentEditor', attachmentEditor);
