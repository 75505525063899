'use strict';

import angular from 'angular';
import raf from 'raf'
import activityBranding from '../../activity-branding.factory.js'
import messageEditor from './message-editor.directive.js'
import multiFileread from './multi-fileread.directive.js'
import realTimeMessageCore from './real-time-message-core.directive.js'
import realTimeMessageHtml from './real-time-message.html'
import _startsWith from 'lodash/startsWith'

/* @ngInject */
function realTimeMessage($rootScope, $document, $sce, $log, RecursionHelper, api,
                         realTimeEngine, gettext, activityBranding, Modernizr) {
    // var _scrollToDuration = 500;

    function _linkMessage(scope, elem) {
        var showActionBar;

        function _updateDoShowActions() {
            scope.doShowActions = showActionBar &&
                !(scope.showEditMessage || scope.showReplyMessage);
        }

        function _openEditMessage(event) {
            event.stopPropagation();

            realTimeEngine.initializeEditable(scope.editable,
                scope.message);

            scope.showEditMessage = true;
            _updateDoShowActions();
            scope.$broadcast('attachmentsEditMode');
        }

        function _closeEditMessage() {
            scope.showEditMessage = false;
            _updateDoShowActions();
            scope.$broadcast('attachmentsViewMode');
        }

        function _openReplyMessage(event, isPrivate) {
            event.stopPropagation();

            realTimeEngine.resetEditable(scope.editable, isPrivate);

            // scope.editable.notifyByEmail =
            //     scope.message.currentUser.isSupervisor || false;

            scope.showReplyMessage = true;
            _updateDoShowActions();
            scope.$broadcast('attachmentsEditMode');
        }

        function _closeReplyMessage() {
            scope.showReplyMessage = false;
            _updateDoShowActions();
            scope.$broadcast('attachmentsViewMode');
        }

        function _scrollToHandler() {
            raf(() => {
                elem[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            });
        }

        function _assignByModeratorColors(message, bySupervisor) {
            if (bySupervisor) {
                activityBranding.getBrandingForPanel(message.engine.config.activity.panel_id)
                    .then(function _assignColors(colors) {
                        // $log.debug('real-time-message.directive._assignColors: colors', colors);
                        var areaColors = {
                            headBgColor: colors.light,
                            // headTextColor: '#fff',
                            headTextColor: colors.dark,
                            bodyBgColor: colors.light,
                            // bodyTextColor: '#fff',
                            bodyTextColor: colors.dark,
                            // 'bodyLinkColor': $link-color,
                            footerBgColor: colors.light,
                            footerBgColorDark: colors.halfLight,
                            // footerTextColor: '#fff'
                            footerTextColor: colors.dark
                        };

                        scope.style = {
                            forumHead: message.forum ? {
                                'background-color': areaColors.headBgColor,
                                'color': areaColors.footerBgColor
                            } : {},
                            speechBubble: {
                                'background-color': areaColors.headBgColor,
                                'color': areaColors.footerBgColor
                            },
                            headText: {
                                'color': areaColors.headTextColor
                            },
                            content: message.forum ? {} : {
                                'background-color': areaColors.bodyBgColor,
                                'color': areaColors.bodyTextColor
                            },
                            actions: {
                                'background-color': areaColors.footerBgColor
                            },
                            votes: {
                                'background-color': areaColors.footerBgColorDark,
                                'color': areaColors.footerTextColor
                            },
                            action: {
                                'color': areaColors.footerTextColor
                            },
                            actionLock: {
                                'color': areaColors.footerBgColor
                            }
                        };
                        // $log.debug('scope.style', scope.style);
                        // $log.debug('Color', Color);
                    });
            } else {
                scope.style = {};
            }
        }

        function _init() {
            scope.profileAltText = gettext('Profile Picture');
            scope.showEditEditor = false;
            scope.showReplyEditor = false;


            if (scope.message) {
                scope.message.isEditable = false;
                scope.bySupervisor = scope.message.author.isSupervisor;

                scope.isSelfAuthored =
                    (scope.message.user.user_id ===
                        scope.message.currentUser.user_id);
                scope.isWrittenByOther = !scope.isSelfAuthored;

                showActionBar = (!scope.message.chat) ||
                    (scope.message.currentUser.isSupervisor ||
                        scope.isSelfAuthored || scope.message.is_private);

                // var notifyByEmail = (scope.message.engine.config.type !== 'chat' &&
                //     scope.message.currentUser.isSupervisor);

                // scope.editable =
                //     scope.message.engine.createEditableWithActions({
                //         notifyByEmail: false /*notifyByEmail*/,
                //         showNotifyByEmail: notifyByEmail
                //     });

                _assignByModeratorColors(scope.message, scope.bySupervisor);

                scope.profilePictureURL =
                    _startsWith(scope.message.user.profile_picture, 'http') ?
                        scope.message.user.profile_picture :
                        api.url + scope.message.user.profile_picture;

                scope.$on(`scrollTo-${scope.message.id}`, _scrollToHandler);
            } else {
                // scope.editable = realTimeEngine.createEditable();

                scope.bySupervisor = false;
            }

            scope.isMenuVisible = !(Modernizr.ismobiledevice && !!scope.message.chat);

            scope.openEditMessage = _openEditMessage;
            scope.closeEditMessage = _closeEditMessage;
            scope.openReplyMessage = _openReplyMessage;
            scope.closeReplyMessage = _closeReplyMessage;
            //scope.scrollToHandler = _scrollToHandler;

            // $rootScope.$on('openEditMessage', scope.openEditMessage);
            // $rootScope.$on('openReplyMessage', scope.openReplyMessage);

            _updateDoShowActions();
        }

        _init();
    }

    function _linkPlaceholder(scope) {
        var handlers;
        scope.blockSize = 10;

        if (scope.message.threadID) {
            handlers = {
                getAllMessagesHandler: scope.message.actions.get_all_thread_messages,
                getNextMessageBlockHandler: scope.message.actions.get_next_thread_message_block,
                getNextTopicBlockHandler: scope.message.actions.get_next_thread_topic_block
            };
        } else {
            handlers = {
                getAllMessagesHandler: scope.message.actions.get_all_messages,
                getNextMessageBlockHandler: scope.message.actions.get_next_message_block,
                getNextTopicBlockHandler: scope.message.actions.get_next_thread_topic_block
            };
        }

        scope.getAllRemaining = function () {
            handlers.getAllMessagesHandler()
                .then(scope.message.engine.prepareMessages);
        };

        scope.getNextMessageBlock = function () {
            handlers.getNextMessageBlockHandler()
                .then(scope.message.engine.prepareMessages);
        };

        scope.getNextTopicBlock = function () {
            handlers.getNextTopicBlockHandler()
                .then(scope.message.engine.prepareMessages);
        };
    }

    return {
        restrict: 'AE',
        scope: {
            message: '=',
            trackTyping: '='
        },
        templateUrl: realTimeMessageHtml,
        link: function (scope, elem, attrs) {
            if (scope.message.placeholder) {
                _linkPlaceholder(scope, elem, attrs);
            } else {
                _linkMessage(scope, elem, attrs);
            }
        },
        compile: function (element) {
            // Use the compile function from the RecursionHelper,
            // And return the linking function(s) which it returns
            return RecursionHelper.compile(element, this.link);
        }

    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.realTimeMessage', [
        activityBranding.name,
        messageEditor.name,
        multiFileread.name,
        realTimeMessageCore.name
    ])
    .directive('realTimeMessage', realTimeMessage);
