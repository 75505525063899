'use strict';
import * as angular from 'angular';
import { filterSearchItemPreviewDirective } from './filter-search-item-preview.directive';
var filterSearchHtml = require('./filter-search.html');
var tmpl = require('../components/query/select.tpl.html');
/* @ngInject */
function filterSearch($log, $templateCache, config, activeFiltersService, segmentService, panelService, activityService) {
    function _patchUISelect() {
        $templateCache.put('bootstrap/select.tpl.html', $templateCache.get(tmpl));
    }
    _patchUISelect();
    function _link(scope) {
        function _handleActivitiesResponse(response) {
            // $log.debug('response', response);
            scope.searchItems = response.activities;
        }
        function _handlePanelsResponse(response) {
            // $log.debug('response', response);
            scope.searchItems = response.panels;
        }
        function _handleQueriesResponse(response) {
            // $log.debug('response', response);
            scope.searchItems = response.segments;
        }
        function _refreshEntities(term) {
            var filter = activeFiltersService.getQueryConstraints();
            var existing = activeFiltersService.getAllFilters();
            var promise, handler;
            //$log.debug('term', term);
            //$log.debug('scope.type', scope.type);
            switch (scope.type) {
                case 'activity':
                    var params = {
                        search_term: term,
                        status: ['active'],
                        extra_columns: ['panel_id', 'status'],
                        order_by: ['name:asc', 'panel_id:asc'],
                        panel_ids: filter.panels,
                        exclude_activity_ids: existing.activities
                    };
                    promise = activityService.query(params).$promise;
                    handler = _handleActivitiesResponse;
                    break;
                case 'panel':
                    promise = panelService.query({
                        search_term: term,
                        status: ['open'],
                        exclude_panel_ids: existing.panels,
                    }).$promise;
                    handler = _handlePanelsResponse;
                    break;
                case 'query':
                    promise = segmentService.query({
                        search_term: term,
                        mode: 'mixed',
                        //status: ['active'],
                        //exclude_activity_ids: filter.activity
                        exclude_query_ids: existing.queries,
                        panel_ids: filter.panels,
                        activity_ids: filter.activities
                    }).$promise;
                    handler = _handleQueriesResponse;
                    break;
            }
            promise.then(handler)
                .finally(function _decreaseSearchCount() {
                scope.model.isSearching--;
            });
            scope.model.isSearching++;
            return promise;
        }
        scope.refreshEntities = _refreshEntities;
        function _onSelect($item, $model) {
            // $item === $model
            switch (scope.type) {
                case 'activity':
                    activeFiltersService.addActivity($item);
                    break;
                case 'panel':
                    activeFiltersService.addPanel($item);
                    break;
                case 'query':
                    activeFiltersService.addQuery($item);
                    break;
            }
            scope.model.selectedSearchItem = null;
        }
        if (angular.isDefined(scope.externalOnSelect)) {
            scope.onSelect = scope.externalOnSelect;
        }
        else {
            scope.onSelect = _onSelect;
        }
        scope.maxItemsCount = config.admin.filterSearchMaxValueItemsCount;
        function _init() {
            scope.model = {
                isSearching: 0,
                showSearch: false
            };
            scope.$on('uis:close', function _hideSearch(event) {
                $log.debug('filter-search.directive._hideSearch: ');
                scope.model.showSearch = false;
            });
        }
        _init();
    }
    return {
        scope: {
            type: '@filterSearch',
            placeholder: '=',
            externalOnSelect: '='
        },
        link: _link,
        templateUrl: filterSearchHtml
    };
}
export var name = 'components.query.filterSearch';
export var filterSearchDirective = angular
    .module(name, [
    filterSearchItemPreviewDirective.name
])
    .directive('filterSearch', filterSearch);
