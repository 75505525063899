'use strict';

import angular from 'angular';

/* @ngInject */
function ForgottenPasswordController($window, user, config) {
    var vm = this;

    vm.defaultDebounceOptions = config.getDebounceOptions();
    vm.state = {
        stage: 'pre-request'
    };

    function _changeState(response) {
        vm.state.stage = 'post-request';
        vm.info = response.data;
    }

    function _requestPasswordReset() {
        user.requestPasswordReset(vm.email).then(_changeState);
    }

    function _cancel() {
        $window.history.back();
    }

    function _retry() {
        vm.state.stage = 'pre-request';
        vm.info = null;
    }

    vm.requestPasswordReset = _requestPasswordReset;
    vm.cancel = _cancel;
    vm.retry = _retry;

    function _activate() { }

    _activate();
}

export default angular
    .module('welcome.forgotten-password.forgottenPasswordController', [])
    .controller('ForgottenPasswordController', ForgottenPasswordController);
