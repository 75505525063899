'use strict';

import angular from 'angular';
import slideDownHtml from './slide-down.html'

/* @ngInject */
function slideDown() {
    return {
        scope: {
            state: '=slideDown'
        },
        transclude: true,
        templateUrl: slideDownHtml
    };
}

export default angular
    .module('components.slideDown', [])
    .directive('slideDown', slideDown);
