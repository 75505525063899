'use strict';

import angular from 'angular';
import DeleteAccountController from './delete-account.controller'
import imageCropper from './image-cropper.directive.js'
import profileImageCropperService from './profile-image-cropper.service.js'
import ProfileImageCropperController
    from './profile-image-cropper.controller.js'
import angularMessages from 'angular-messages'
import _forEach from 'lodash/forEach'
import _isString from 'lodash/isString'


/* @ngInject */
function ProfileEditController($q, $state, $window, $log, activityService,
                               profileService, countries, api, gettextCatalog,
                               Modernizr, config, metaService,
                               postalCodeService, profileImageCropperService,
                               trackingService, upload, genders) {
    const vm = this;

    function updateI18n() {
        api.postForm('/profile/set_language', {
            lang: vm.gettextCatalog.currentLanguage
        })
            .then(function _signalUpdatedLanguage() {
                /**
                 * This is called after POST to set_language because
                 * metaService.setCurrentLanguage('…', doEmitSignal=true) will
                 * emit a changed-signal which will cause, among other things,
                 * list of country names to reload (based on current user's
                 * stored preferred language).
                 *
                 * FIXME: Perhaps the API endpoints should be changed to
                 *        take an explicit language parameter.
                 **/
                metaService.setCurrentLanguage(
                    vm.gettextCatalog.currentLanguage, true, true);
            });
    }

    function _trackCompletedEditProfile() {
        trackingService.trackProfile(trackingService.actions.complete, 'Profile');
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.form_errors) {
                _forEach(response.data.form_errors.fields, function _setErrors(value, name) {
                    if (angular.isDefined(form[name])) {
                        form[name].$setValidity('serverValidation', false);
                        form[name].$setDirty();
                        form[name].serverValidationMessage = value;
                    }
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        return $q.reject(status);
    }

    function updateProfile(form) {
        profileService.updateProfile(vm.profileForm)
            .then(_trackCompletedEditProfile, function _wrapHandler(response) {
                return _handleSaveFormError(form, response);
            })
            .then(function _goToProfile() {
                $state.go('home.profile');
            });
    }

    function _setCountries(countryCodes) {
        vm.countryCodes = countryCodes;
    }

    function _setDialingCodes(countryDialingCodes) {
        vm.countryDialingCodes = countryDialingCodes;
    }

    function _propagatePostalInfo(location) {
        vm.profileForm.country = vm.profileForm.postalCode.countryCode;
        vm.profileForm.postal_code = vm.profileForm.postalCode.postalCode;

        vm.profileForm.city = location.city;
        vm.profileForm.region = location.region;
    }

    function _pickAndUpload(response) {
        return upload.pickAndStore(response.data);
    }

    function _setMediaUploadInfo(mediaUploadInfo) {
        vm.mediaUploadInfo = mediaUploadInfo;
    }

    function _trackEditProfileImage() {
        trackingService.trackProfile(trackingService.actions.edit, 'Profile Image');
    }

    function _pickAndStoreImage() {
        _trackEditProfileImage();
        _pickAndUpload(vm.mediaUploadInfo)
            .then(profileImageCropperService.uploadedProfilePicture)
            .then(profileImageCropperService.cropImage);
    }

    function _cancel() {
        $window.history.back();
    }

    function _openDeleteAccountDialog() {
        $state.go('.deleteAccount')
    }

    function _activate() {
        vm.profile = profileService.profile;
        vm.profileForm = profileService.profileForm;

        vm.imageFileInput = null;

        vm.gettextCatalog = gettextCatalog;
        vm.countryDialingCodes = null;
        vm.genders = genders;
        vm.activityCounts = activityService.activityCounts;

        vm.apiUrl = api.url;
        vm.user = {};
        vm.browser = {
            supportsInputTypeDate: Modernizr.inputtypes.date &&
            config.dateInputType === 'date'
        };
        vm.dateInputType = config.dateInputType;
        vm.dateValidRegex = config.dateValidRegex;

        vm.openDeleteAccountDialog = _openDeleteAccountDialog;
        vm.cancel = _cancel;
        vm.updateI18n = updateI18n;
        vm.updateProfile = updateProfile;
        vm.pickAndStoreImage = _pickAndStoreImage;
        vm.propagatePostalInfo = _propagatePostalInfo;

        profileService.getMediaUploadInfo({media_type: 'image'})
            .then(_setMediaUploadInfo);
        countries.get('locations', true).then(_setCountries);
        countries.get('dialingCodes', true).then(_setDialingCodes);

    }

    _activate();
}

export default angular
    .module('profile.profileEditController', [
        imageCropper.name,
        DeleteAccountController.name,
        profileImageCropperService.name,
        ProfileImageCropperController.name,
        angularMessages
    ])
    .controller('ProfileEditController', ProfileEditController);
