'use strict';
import * as angular from 'angular';
var questionPreviewItemHtml = require('./question-preview-item.html');
/* @ngInject */
function questionPreviewItem() {
    return {
        templateUrl: questionPreviewItemHtml
    };
}
export var QuestionPreviewItemDirective = angular
    .module('components.query.questionSearch.questionPreviewItem', [])
    .directive('questionPreviewItem', questionPreviewItem);
