'use strict';

import angular from 'angular';

/* @ngInject */
function ModalAttachmentController($rootScope, $scope, $state, realTimeEngine) {
    $scope.$on('showAttachment', function (event, attachment) {
        event.stopPropagation();

        $scope.$broadcast('showModalAttachment', attachment);
    });

    function _setEngineData(event, data) {
        if (data.activityType === $state.params.activityType &&
            data.activityId === $state.params.activityId &&
            (angular.isUndefined(data.threadId) ||
            data.threadId === null ||
            data.threadId === $state.params.threadId)) {

            $scope.contributors = data.contributors;
        }
    }

    const cleanup = $rootScope.$on(realTimeEngine.isIntializedSignal,
        _setEngineData);

    $scope.$on('$destroy', cleanup);
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.ModalAttachmentController', [])
    .controller('ModalAttachmentController', ModalAttachmentController);
