'use strict';
import * as angular from 'angular';
import 'angular-chart.js';
var chartCanvasHtml = require('./chart-canvas.html');
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { MessageOverviewStatsServiceFactory } from "./message-overview-stats-service.factory";
/* @ngInject */
function messageOverviewStats($timeout, ChartJs, gettext, gettextCatalog) {
    function _createDataSet(values, colors) {
        return {
            label: gettextCatalog.getString(gettext('Recipients')),
            data: values,
            backgroundColor: colors,
            borderColor: colors
        };
    }
    function _link(scope, element) {
        var ctx, chart;
        function _updateStats(series) {
            if (angular.isDefined(series)) {
                var values = _map(series, 'value'), colors = _map(series, 'color');
                chart.data.labels = _map(series, 'name');
                chart.data.datasets = [_createDataSet(values, colors)];
                // chart.resize();
                /*
                                if (chart.data.datasets.length) {
                                    _forEach(values, function _update(value, i) {
                                        chart.data.datasets[0].data[i] = value;
                                    });
                                } else {
                                    chart.data.datasets = [_createDataSet(values, colors)];
                                    chart.resize();
                                }
                */
                chart.update();
            }
        }
        function _init() {
            ctx = element.find("#chart-" + scope.$id);
            var tooltipElement = element.find("#chart-" + scope.$id + "-tooltip");
            scope.title = gettextCatalog.getString(gettext('Delivery Status'));
            scope.bodyLines = _map(scope.stats, function (item) {
                return item.label + ": " + item.data[0];
            });
            scope.labelColors = _filter(scope.stats, 'backgroundColor');
            scope.icons = [
                'fa-times',
                'fa-envelope',
                'fa-envelope-open',
            ];
            function customTooltips(tooltipModel) {
                if (tooltipModel.opacity === 0) {
                    tooltipElement.hide();
                }
                else {
                    tooltipElement.show();
                }
                if (!angular.isDefined(scope.isTooltipInitialized)) {
                    tooltipElement.css({
                        opacity: '1',
                        marginTop: "-" + (tooltipModel.height + 10) + "px"
                    });
                    scope.isTooltipInitialized = true;
                }
            }
            chart = new ChartJs.Chart(ctx, {
                type: 'horizontalBar',
                data: {
                    datasets: scope.stats
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                display: false,
                                gridLines: {
                                    show: false
                                }
                            }
                        ],
                        yAxes: [
                            {
                                stacked: true,
                                barThickness: 10,
                                display: false,
                                gridLines: {
                                    show: false
                                }
                            }
                        ],
                    },
                    tooltips: {
                        enabled: false,
                        yAlign: false,
                        custom: customTooltips
                    }
                }
            });
            //scope.$watch('stats', _updateStats);
        }
        $timeout(_init, 0);
    }
    return {
        scope: {
            stats: '=messageOverviewStats'
        },
        link: _link,
        templateUrl: chartCanvasHtml
    };
}
export var MessageOverviewStats = angular
    .module('admin.messages.messageOverviewStats', [
    'chart.js',
    MessageOverviewStatsServiceFactory.name
])
    .directive('messageOverviewStats', messageOverviewStats);
