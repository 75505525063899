'use strict';

import angular from 'angular';
import adjustHeight from './adjust-height.directive.js';
import scaleGridAnswerHtml from './scale-grid-answer.html'

/* @ngInject */
function scaleGridAnswer(iScrollService) {
    function _setValue(question, col) {
        question.answerIndex.rows[
            question.valuerows[question.rowIndex].valuerow_id
            ].valuecolumn_id = col.valuecolumn_id;

        question.updateAnswer();
    }

    function _link(scope) {
        scope.iScrollState = iScrollService.state;

        scope.setValue = _setValue;

        scope.question.updateAnswer();
    }

    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        link: _link,
        templateUrl: scaleGridAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.scaleGridAnswer', [
        adjustHeight.name
    ])
    .directive('scaleGridAnswer', scaleGridAnswer);
