'use strict';

import angular from 'angular';

/* @ngInject */
function WelcomeFooterController($rootScope, $scope, panelService) {
    $scope.panels = panelService.panels;
}

export default angular
    .module('welcome.welcomeFooterController', [])
    .controller('WelcomeFooterController', WelcomeFooterController);
