'use strict';

import angular from 'angular';

/* @ngInject */
function ProfileImageCropperController($state, $stateParams, config, api,
                                       profileService,
                                       profileImageCropperService, upload) {
    var vm = this,
        _imageUploadParams = null;

    vm.imageFileInput = null;
    vm.apiUrl = api.url;
    vm.user = {};

    function _updateInputImage(imageMeta) {
        vm.user = imageMeta.user;
        _imageUploadParams = $stateParams.imageMeta.imageUploadParams;
    }

    function _reinitalize(imageMeta) {
        $state.go($state.current.name, {imageMeta: imageMeta}, {reload: true});
    }

    function _selected(coords) {
        var imageCropData = {
            x0: Math.ceil(coords.x),
            y0: Math.ceil(coords.y),
            x1: Math.ceil(coords.x2),
            y1: Math.ceil(coords.y2),
            crop_x_size: config.profileImage.cropSize.x,
            crop_y_size: config.profileImage.cropSize.y,
            img_width: coords.trueSize[0],
            img_height: coords.trueSize[1],
            user_id: profileService.profileForm.user_id
        };

        angular.extend(_imageUploadParams, imageCropData);
    }

    function _closeImageCrop() {
        delete vm.user;
        $state.go('^', {}, {reload: true});
    }

    function _saveCroppedImage() {
        profileImageCropperService.saveCroppedImage(_imageUploadParams)
            .then(_closeImageCrop());
    }

    function _getBoxDimensions(element) {
        var parent = element.parent();
        return {
            width: parent.width(),
            height: parent.height()
        };
    }

    function _pickAndUpload(response) {
        return upload.pickAndStore(response.data);
    }

    function _setMediaUploadInfo(mediaUploadInfo) {
        vm.mediaUploadInfo = mediaUploadInfo;
    }

    function _pickAndStoreImage() {
        _pickAndUpload(vm.mediaUploadInfo)
            .then(profileImageCropperService.uploadedProfilePicture)
            .then(_reinitalize);
    }

    function _activate() {
        _updateInputImage($stateParams.imageMeta);
        profileService.getMediaUploadInfo({media_type: 'image'})
            .then(_setMediaUploadInfo);
    }

    vm.aspectRatio =
        (config.profileImage.cropSize.x / config.profileImage.cropSize.x);
    vm.selected = _selected;
    vm.closeImgCrop = _closeImageCrop;
    vm.saveCroppedImage = _saveCroppedImage;
    vm.getBoxDimensions = _getBoxDimensions;
    vm.pickAndStoreImage = _pickAndStoreImage;


    _activate();
}

export default angular
    .module('profile.profileEditController.profileImageCropperController', [])
    .controller('ProfileImageCropperController',
    ProfileImageCropperController);
