'use strict';

import angular from 'angular';

/* @ngInject */
function ReportController($scope, $stateParams, $log, api) {
    $log.debug('report controller');
    api.get('/questionnaire/' + $stateParams.activityId +
        '/user_feedback.json').then(function (res) {
        $log.debug(res);
        $scope.report = res.data;
    });
}

export default angular
    .module('activities.reportController', [])
    .controller('ReportController', ReportController);
