'use strict';
import * as angular from 'angular';
import _map from 'lodash/map';
/* @ngInject */
function messageOverviewStatsService(gettextCatalog, gettext, utility, config) {
    var _barColors = [
        config.colors['message-error'],
        config.colors['message-sent'],
        config.colors['message-opened'],
    ];
    var _barLabels = [
        gettextCatalog.getString(gettext('Not Sent')),
        gettextCatalog.getString(gettext('Just Sent')),
        gettextCatalog.getString(gettext('Opened')),
    ];
    function _createDataSet(values) {
        return _map(values, function (value, i) {
            return {
                data: [value],
                label: _barLabels[i],
                backgroundColor: _barColors[i],
            };
        });
    }
    return {
        barColors: _barColors,
        createDataSet: _createDataSet,
    };
}
export var name = 'components.messageOverviewStatsService';
export var MessageOverviewStatsServiceFactory = angular
    .module(name, [])
    .factory('messageOverviewStatsService', messageOverviewStatsService);
