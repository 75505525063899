'use strict';

import angular from 'angular';

/* @ngInject */
function languagesService($resource, $q, api, utility) {
    const _languages = [],
        _languageResource = $resource(api.url + '/api/languages/', {},
            {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                stripTrailingSlashes: false
            });
    let _deferred = null;


    function _updateLanguages(resonse) {
        utility.updateSharedArray(_languages, resonse.languages);

        return _languages;
    }

    function _refresh() {
        _deferred = $q.defer();

        _languageResource.query().$promise
            .then(_updateLanguages)
            .then(function _resolve(languages) {
                _deferred.resolve(languages);
                //_deferred = null;
            });

        return _deferred.promise;
    }

    function _get() {
        return _deferred !== null ?
            _deferred.promise : _refresh();
    }

    const _service = {
        refresh: _refresh,
        get: _get
    };

    return _service;
}

export default angular
    .module('components.languagesService', [])
    .factory('languages', languagesService);
