'use strict';

import angular from 'angular';

/* @ngInject */
function ModalHeaderController($scope, $state) {
    $scope.$state = $state;
}

export default angular
    .module('components.modal.ModalHeaderController', [])
    .controller('ModalHeaderController', ModalHeaderController);
