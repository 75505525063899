'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'


/* @ngInject */
function WelcomeController($rootScope, $scope, meta, metaService,
                           panelService, activityService,
                           trackingService) {
    $scope.panels = panelService.panels;

    function _trackJoinClick() {
        // adformService.track('Klikket på Join');
        trackingService.trackOnboarding(trackingService.actions.beginOnboarding);
    }

    $scope.trackJoinClick = _trackJoinClick;

    function _trackLoginClick() {
        // adformService.track('Klikket på Login');
        trackingService.trackPlatform(trackingService.actions.login);
    }

    $scope.trackLoginClick = _trackLoginClick;

    function _handleTutorial(activity) {
        activityService.updateGettextDomains([activity]);

        $scope.tutorial = activity;
    }

    function _updateTutorial(event, panel) {
        const params = {
            activityType: 'questionnaire',
            activityId: (panel.tutorial_id !== null) ?
                panel.tutorial_id : meta.onboarding.tutorial_id,
            includeI18n: true
        };

        activityService.getActivity(params, {fromCache: true})
            .then(_handleTutorial);
    }

    function _updateLanguage(event, panel) {
        metaService.setCurrentLanguage(panel.language_code, true);
    }

    function _trackWelcomeLanding() {
        // adformService.track('På velkomstsiden');
        // GA page tracking is already handled automatically.
    }

    const cleanups = [
        $rootScope.$on(panelService.currentPanelChangedSignal,
            _updateLanguage),
        $rootScope.$on(panelService.currentPanelChangedSignal,
            _updateTutorial),
        $rootScope.$on(panelService.currentPanelChangedSignal,
            _trackWelcomeLanding)
    ];

    function _cleanupListeners() {
        _forEach(cleanups, (cleanup) => cleanup());
    }

    $scope.$on('$destroy', _cleanupListeners);

    if (angular.isDefined($scope.panels.current)) {
        _updateTutorial(null, $scope.panels.current);
        _trackWelcomeLanding();
    }
}

export default angular
    .module('welcome.welcomeController', [])
    .controller('WelcomeController', WelcomeController);
