'use strict';

import angular from 'angular';
import _assign from 'lodash/assign'
import _forEach from 'lodash/forEach'
import _split from 'lodash/split'
import _toLower from 'lodash/toLower'
import _hasIn from 'lodash/hasIn'

/* @ngInject */
function metaService($rootScope, $q, $raven, $log, api, gettextCatalog,
                     amMoment, userSignals) {
    const apiFieldTranslations = {
            // remote-name: local-name
            authentication: 'auth',
            identity: 'user'
        },
        _isRefreshedSignal = 'meta:isRefreshed',
        _currentLanguageIsUpdatedSignal = 'meta:currentLanguageIsUpdated';
    let deferred;

    function _setCurrentLanguage(language, doEmitSignal, doForce) {
        if (!angular.isDefined(doEmitSignal)) {
            doEmitSignal = true;
        }
        if (!angular.isDefined(doForce)) {
            doForce = false;
        }

        if (angular.isDefined(language) &&
            (doForce || (language !== gettextCatalog.currentLanguage))) {
            $log.debug('metaService: Setting language to', language);
            gettextCatalog.setCurrentLanguage(language);
            amMoment.changeLocale(_split(language, '_')[0] || 'en');

            if (doEmitSignal) {
                $log.debug('$emit(' + _currentLanguageIsUpdatedSignal + '):',
                    language);
                $rootScope.$emit(_currentLanguageIsUpdatedSignal, language);
            }
        }
    }

    function _getCurrentLanguage() {
        return gettextCatalog.currentLanguage;
    }

    const _languageCodeNormalizers = {
        'nb': 'no',
        'nn': 'no'
    };

    function _getCurrentLanguageCode() {
        // 'nb_NO' -> 'nb'
        const code = _split(_toLower(_getCurrentLanguage()), '_')[0];
        // 'nb' -> 'no'
        return _hasIn(_languageCodeNormalizers, code) ? _languageCodeNormalizers[code] : code;
    }

    function _refresh() {
        deferred = $q.defer();

        api.get('/_meta.json').then(function _handleMeta(res) {
            const _meta = _assign({}, res.data);

            _forEach(apiFieldTranslations,
                function _translateFieldNames(value, key) {
                    _meta[value] = _meta[key];
                    delete _meta[key];
                });

            if (_meta.user.is_authenticated) {
                $raven.setUserContext({
                    user_id: _meta.user.user_id,
                    public_name: _meta.user.public_name
                });

                const language = (_meta.user.i18n_language !== null) ?
                    _meta.user.i18n_language : _meta.onboarding.i18n_language;

                _setCurrentLanguage(language, false);

                /**
                 * Currently, meta emits the event userSignals.userLoginSignal
                 * because both logging in and loading the app while being
                 * logged in should be announced to the rest of the
                 * app (as a login event).
                 **/
                $log.debug("$emit('" + userSignals.userLoginSignal + "')");
                $rootScope.$emit(userSignals.userLoginSignal);
            } else {
                _setCurrentLanguage(_meta.onboarding.i18n_language, false);
            }

            $log.debug('$emit(' + _isRefreshedSignal + ')');
            $rootScope.$emit(_isRefreshedSignal, _meta);

            deferred.resolve(_meta);
        });

        return deferred.promise;
    }

    function _get() {
        return angular.isDefined(deferred) ?
            deferred.promise : _metaService.refresh();
    }

    const _metaService = {
        isRefreshedSignal: _isRefreshedSignal,
        currentLanguageIsUpdatedSignal: _currentLanguageIsUpdatedSignal,
        refresh: _refresh,
        get: _get,
        getCurrentLanguageCode: _getCurrentLanguageCode,
        getCurrentLanguage: _getCurrentLanguage,
        setCurrentLanguage: _setCurrentLanguage,
    };

    _metaService.get();

    return _metaService;
}

export default angular
    .module('metaService', [])
    .factory('metaService', metaService);
