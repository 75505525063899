'use strict';

import angular from 'angular';
import segmentPanelHtml from './segment-panel.html'
import _filter from 'lodash/filter'


/* @ngInject */
function segmentPanel($rootScope, $state, activeFiltersService,
                      segmentPanelService, utility) {
    function _link(scope, element) {
        const _element = angular.element(element),
            _collapsible = _element.find('.panel-collapse'),
            _collapsibleState = {
                collapsibleElement: _collapsible,
                isExpanded: _collapsible.hasClass('in'),
                iconElement: _element.find('.collapse-icon'),
                icons: {
                    open: 'fa-minus-square',
                    closed: 'fa-plus-square'
                }
            },
            _queryId = scope.query.query_id,
            _stateId = activeFiltersService.getStateId($state.$current,
                'segmentsName');

        function _togglePanel() {
            utility.togglePanelCollapse(_collapsibleState);
        }

        function _updateSegment(event, data) {
            if (data.stateId === _stateId) {
                scope.segmentSize =
                    _filter(scope.contributors,
                        'inSegment.' + _queryId).length;
            }
        }

        function _init() {
            scope.togglePanel = _togglePanel;

            utility.updateCollapseIcon(_collapsibleState);

            const destroyer = $rootScope.$on(segmentPanelService.updatedSignal,
                _updateSegment);

            scope.$on('$destroy', destroyer);
        }

        _init();
    }

    return {
        scope: {
            contributors: '=',
            query: '=segmentPanel'
        },
        link: _link,
        templateUrl: segmentPanelHtml
    };
}

export default angular
    .module('activities.segmentPanel', [])
    .directive('segmentPanel', segmentPanel);
