'use strict';

import angular from 'angular';
import eulaHeaderHtml from './eula.header.html'
import eulaHtml from './eula.html'
import EulaController from './eula.controller'
import legalFooterHtml from '../legal.footer.html'

/* @ngInject */
function getEulaState(name, url) {
    return {
        name: name,
        url: angular.isDefined(url) ? url : '/eula',
        views: {
            'modal-header@': {
                controller: 'LegalFooterController',
                controllerAs: 'vm',
                templateUrl: eulaHeaderHtml
            },
            'modal-contents@': {
                controller: 'EulaController',
                controllerAs: 'vm',
                templateUrl: eulaHtml
            },
            'modal-footer@': {
                controller: 'LegalFooterController',
                controllerAs: 'vm',
                templateUrl: legalFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService, $state$, legalModalService) {
            coreLayoutService.openModal({
                footer: {
                    visible: coreLayoutService.defaultExcept({all: true}),
                    hidden: coreLayoutService.defaultExcept()
                },
                size: 'large'
            });
            legalModalService.onEnter($state$);
        },
        onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: false,
        isRegister: true
    };
}

export default angular
    .module('components.eula', [
        EulaController.name
    ])
    .constant('getEulaState', getEulaState);
