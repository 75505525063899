'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _partial from 'lodash/partial'


/* @ngInject */
function HeaderController($scope, $rootScope, $state, $log, hotkeys,
                          activityService, iScrollService, coreLayoutService) {
    const hotkeyDefinitions = [
        {
            combo: 'm',
            description: 'Toggle menu visibility.',
            callback: _showMenu
        },
        {
            combo: 'i',
            description: 'Toggle notifications visibility.',
            callback: _showNotifications
        },
        {
            combo: 's',
            description: 'Toggle iscroll.',
            callback: _partial(iScrollService.toggle, false)
        },
        {
            combo: 'r',
            description: 'Refresh iscroll.',
            callback: _refreshIscroll
        }
    ];

    function _showMenu() {
        coreLayoutService.toggleDrawer('leftDrawer');
    }

    function _showNotifications() {
        coreLayoutService.toggleDrawer('rightDrawer');
    }

    function _refreshIscroll() {
        $log.debug('Refresh iScroll.');
        iScrollService.refresh();
    }

    function _refreshActivities() {
        activityService.refresh().then(function () {
            $state.transitionTo($state.current, $state.params,
                {reload: true, inherit: true, notify: true});
        });
    }

    _forEach(hotkeyDefinitions, function _addHotKey(definition) {
        hotkeys.add(definition);
    });

    $rootScope.showMainMenu = false;

    $scope.refreshActivities = _refreshActivities;
    $scope.showMenu = _showMenu;
    $scope.showNotifications = _showNotifications;
}

export default angular
    .module('components.header.headerController', [])
    .controller('HeaderController', HeaderController);
