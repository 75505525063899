'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'


/**
 * FIXME: Now that we use angular-loading-bar, this module should be removed.
 *
 * However, the (erroneously placed) login-error handling below should
 * then be moved to a (much more) appropriate place.
 **/


/* @ngInject */
function _config($httpProvider) {
    $httpProvider.interceptors.push('loadingStatusInterceptor');
}

/* @ngInject */
function loadingStatusInterceptor($q, $injector, $rootScope, $log, gettext) {
    let activeRequests = 0;

    function _growlErrorReason(reason) {
        $rootScope.$emit('error', reason);
    }

    function _emitLoadingIfNonLocalUrl(url) {
        if (/^(f|ht)tps?:\/\//i.test(url)) {
            $rootScope.$emit('loading:true');
        }
    }

    function _started(config) {
        _emitLoadingIfNonLocalUrl(config.url);

        activeRequests++;
    }

    function _ended() {
        activeRequests--;

        if (activeRequests <= 0) {
            $rootScope.$emit('loading:false');
        }

        if (activeRequests < 0) {
            $log.error('loadingStatusInterceptor: activeRequests < 0',
                activeRequests);
        }
    }

    function _request(config) {
        _started(config);
        return config;
    }

    function _requestError(response) {
        _ended();
        return response || $q.when(response);
    }

    function _response(response) {
        _ended();

        if (angular.isDefined(response.data) &&
            angular.isDefined(response.data.status)) {
            switch (response.data.status) {
                // eslint-disable-next-line no-case-declarations
                case 'failure':
                    const $state = $injector.get('$state');

                    if ($state.current.name === 'welcome.modal.login') {
                        $rootScope.$emit('error',
                            gettext("Wrong username/password"));

                        const trackingService = $injector.get('trackingService');
                        trackingService.trackPlatform(
                            trackingService.actions.loginFail);
                    }
                    break;
            }
        }

        return response || $q.when(response);
    }

    function _responseError(rejection) {
        _ended();
        $log.warn('HTTP request failed:', rejection);

        switch (rejection.status) {
            case 412: // Precondition failed.
                if (rejection.data.form_errors) {
                    _forEach(rejection.data.form_errors.fields, _growlErrorReason);
                }
                break;
        }
        return $q.reject(rejection);
    }

    return {
        request: _request,
        requestError: _requestError,
        response: _response,
        responseError: _responseError
    };
}

export default angular.module('httpInterceptor', [])
    .config(_config)
    .factory('loadingStatusInterceptor', loadingStatusInterceptor);
