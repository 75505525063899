'use strict';

import angular from 'angular'
import Raven from 'raven-js'
import joypixels from 'emoji-toolkit'

import platform from 'platform'
import reportHtml from './activities/report.html'
import _merge from 'lodash/merge'
import _assign from 'lodash/assign'


function _configureChartJs(ChartJsProvider, config) {
    const Chart = ChartJsProvider.$get().Chart;

    Chart.defaults.global.responsive = true;
    Chart.defaults.global.defaultColor = config.colors['so-green-rgba-0.5'];
    Chart.defaults.global.defaultFontFamily = config.fonts.chartJs;
    // Chart.defaults.scale.scaleLabel.fontFamily = config.fonts.chartJs;
    // Chart.defaults.scale.ticks.fontFamily = config.fonts.chartJs;
}


/* @ngInject */
function _config($stateProvider, $urlServiceProvider, $httpProvider,
                 $locationProvider, $qProvider, $ravenProvider,
                 $compileProvider, $logProvider, $windowProvider, Modernizr,
                 uiSelectConfig, iScrollServiceProvider, ChartJsProvider,
                 uiGmapGoogleMapApiProvider, config) {
    /**
     *  Workaround for uncaught $q.reject() calls.  See
     *  https://github.com/angular-ui/ui-router/issues/2889#issuecomment-266791654
     *  for more info.
     */
    $qProvider.errorOnUnhandledRejections(false);

    /**
     * If you wish to debug an application with this information, if it is
     * turned off here, then you should open up a debug console in the
     * browser then call this method directly in this console:
     *
     *   angular.reloadWithDebugInfo();
     */
    // console.log('config.angular.enableDebugDOMInfo', config.angular.enableDebugDOMInfo);
    $compileProvider.debugInfoEnabled(config.angular.enableDebugDOMInfo);
    // $compileProvider.debugInfoEnabled(true /*config.angular.enableDebugDOMInfo*/);

    /**
     * Enable or disable debug level messages.
     */
    $logProvider.debugEnabled(config.angular.enableDebugLogLevel);

    joypixels.sprites = true;
    joypixels.ascii = true;  // Convert ASCII smileys, like `=)`, to emoji.
    // joypixels.spriteSize = `${config.emoji.size}`;

    // There is a development flag to log errors rather than sending it to Sentry.
    $ravenProvider.development(!config.raven.isEnabled);

    Raven.setTagsContext(_merge({
        angular_version: angular.version.full
    }, config.raven.defaultTags));

    // $locationProvider.html5Mode(false).hashPrefix('!');
    // $locationProvider.html5Mode(true);

    $httpProvider.defaults.withCredentials = true;
    // $urlRouterProvider.otherwise('home');
    $urlServiceProvider.rules.otherwise('/home');

    if (!Modernizr.csspositionfixed) {
        Raven.captureMessage(`Device does not support CSS 'position: fixed'.  User-Agent string: "${platform.ua}".`);

        /* Cache dom reference. */
        const _body = angular.element('body');

        /* Bind events. */
        angular.element(document)
            .on('focus', 'input, textarea, select', function _addFix() {
                _body.addClass('fix-fixed');
            })
            .on('blur', 'input, textarea, select', function _removeFix() {
                _body.removeClass('fix-fixed');
            });
    }

    uiGmapGoogleMapApiProvider.configure({
        key: config.google.maps.apiKey,
        v: config.google.maps.version,
        libraries: config.google.maps.libraries
    });

    _configureChartJs(ChartJsProvider, config);

    iScrollServiceProvider.configureDefaults(config.iScrollOptions);

    _assign(uiSelectConfig, config.uiSelectConfig);

    if (!Modernizr.usenativescroll) {
        Raven.captureMessage(`Device prefers iScroll over native scrolling.  User-Agent string: "${platform.ua}".`);
    }

    /*
     Ugly (there should be a cleaner way to do this) hack to
     generate the default type "matchers" in
     ui.router.util.$urlMatcherFactory (at least in version 0.2.11).
     */
    //$urlMatcherFactoryProvider.$get[1]();

    $stateProvider
    /*
            .state({
                name: 'default',
                url: '',
                secure: false
            })
    */
        .state({
            name: 'report',
            url: '/{activityType:report}/{activityId:int}/',
            controller: 'ReportController',
            templateUrl: reportHtml,
            secure: true
        });
}

export default _config;
