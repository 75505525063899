'use strict';
import { QueryConstraintAnswer } from "./query-constraint-answer";
import * as angular from 'angular';
var queryConstraintHtml = require('./query-constraint.html');
import _remove from 'lodash/remove';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
var _ternaryOperators = [
    'a <= x <= b',
    '! (a <= x <= b)'
];
/* @ngInject */
function queryConstraint($log, segmentService) {
    function _getUnselected() {
        return {
            value: false,
            answer: null
        };
    }
    function _getSelected(answer) {
        return {
            value: true,
            answer: answer
        };
    }
    function _addGridAnswer(row_id, column_id, selected, answers) {
        var answer = new QueryConstraintAnswer({
            valuerow_id: row_id,
            valuecolumn_id: column_id
        });
        selected[row_id][column_id].answer = answer;
        selected[row_id][column_id].value = true;
        answers.push(answer);
    }
    function _removeGridAnswer(row_id, column_id, selected, answers) {
        _remove(answers, selected[row_id][column_id].answer);
        selected[row_id][column_id].answer = null;
        selected[row_id][column_id].value = false;
    }
    function _addListAnswer(row_id, selected, answers) {
        var answer = new QueryConstraintAnswer({
            valuerow_id: row_id,
            valuecolumn_id: null
        });
        selected[row_id].answer = answer;
        selected[row_id].value = true;
        answers.push(answer);
    }
    function _removeListAnswer(row_id, selected, answers) {
        _remove(answers, selected[row_id].answer);
        selected[row_id].answer = null;
        selected[row_id].value = false;
    }
    function _createGridMap(valueRows, valueColumns, answers) {
        var _selected = _reduce(valueRows, function _createElement(result, row) {
            result[row.valuerow_id] = {};
            return result;
        }, {});
        var _isAllSelected = _reduce(_selected, _handleToggle, {});
        function _handleToggle(result, columns, row_id) {
            function _allChanged(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                var doNeedUpdate = false;
                function _addAnswerIfMissing(item, column_id) {
                    if (!item.answer) {
                        _addGridAnswer(row_id, column_id, _selected, answers);
                        doNeedUpdate = true;
                    }
                }
                function _removeAnswerIfPresent(item, column_id) {
                    if (item.answer) {
                        _removeGridAnswer(row_id, column_id, _selected, answers);
                        doNeedUpdate = true;
                    }
                }
                if (newValue !== null) { // Not the third state.
                    _forEach(columns, newValue ?
                        _addAnswerIfMissing : _removeAnswerIfPresent);
                    if (doNeedUpdate) {
                        segmentService.requestUpdate();
                    }
                }
            }
            result[row_id] = {
                value: false,
                allChanged: _allChanged
            };
            return result;
        }
        _selected = _reduce(valueColumns, function _createElement(result, column) {
            _forEach(_keys(result), function _assignColumn(valuerow_id) {
                result[valuerow_id][column.valuecolumn_id] = _getUnselected();
            });
            return result;
        }, _selected);
        function _updateIsAllSelected() {
            var state;
            _forEach(_isAllSelected, function _evaluateRow(value, row_id) {
                switch (_size(_filter(_selected[row_id], { value: true }))) {
                    case 0:
                        state = false;
                        break;
                    case _size(_selected[row_id]):
                        state = true;
                        break;
                    default:
                        state = null;
                }
                _isAllSelected[row_id].value = state;
            });
        }
        function _update(answers, row_id, column_id) {
            if (angular.isDefined(row_id)) {
                if (_selected[row_id][column_id].value) {
                    // Add new answer.
                    _addGridAnswer(row_id, column_id, _selected, answers);
                }
                else {
                    // Remove existing answer.
                    _removeGridAnswer(row_id, column_id, _selected, answers);
                }
                segmentService.requestUpdate();
            }
            else {
                // Assume a zero-selected grid, and mark the cells that are
                // selected according to `answers`.
                _forEach(answers, function _updateSelections(answer) {
                    _selected[answer.valuerow_id][answer.valuecolumn_id] =
                        _getSelected(answer);
                });
            }
            _updateIsAllSelected();
        }
        return {
            update: _update,
            isAllSelected: _isAllSelected,
            selected: _selected
        };
    }
    function _createListMap(valueRows, answers) {
        var _selected = _reduce(valueRows, function _createElement(result, row) {
            result[row.valuerow_id] = _getUnselected();
            return result;
        }, {});
        function _update(answers, row_id) {
            if (angular.isDefined(row_id)) {
                if (_selected[row_id].value) {
                    // Add new answer.
                    _addListAnswer(row_id, _selected, answers);
                }
                else {
                    // Remove existing answer.
                    _removeListAnswer(row_id, _selected, answers);
                }
                segmentService.requestUpdate();
            }
            else {
                // Assume a zero-selected grid, and mark the cells that are
                // selected according to `answers`.
                _forEach(answers, function _updateSelections(answer) {
                    _selected[answer.valuerow_id] = _getSelected(answer);
                });
            }
        }
        return {
            update: _update,
            selected: _selected
        };
    }
    function _generateCheckMap(node) {
        var valueRows = node.question.valuerows, valueColumns = node.question.valuecolumns;
        var selected;
        if (valueColumns.length) {
            selected = _createGridMap(valueRows, valueColumns, node.children);
        }
        else {
            selected = _createListMap(valueRows, node.children);
        }
        selected.update(node.children);
        return selected;
    }
    function _link(scope) {
        var _node = scope.node;
        function _assignFound(value) {
            $log.debug('query-constraint.directive._assignFound: value', value);
        }
        _node.assignFound = _assignFound;
        scope.nodeStyle = {
            'operator-is-ternary': _includes(_ternaryOperators, _node.operator)
        };
        function _deleteConstraint() {
            segmentService.deleteNode(_node.meta.root, _node);
            segmentService.requestUpdate(_node.meta.root);
        }
        scope.deleteConstraint = _deleteConstraint;
        scope.onChange = segmentService.requestUpdate;
        if (_node.question_id && _node.question.valuerows.length > 0) {
            var _selected = _generateCheckMap(_node);
            scope.selected = _selected.selected;
            scope.selectionChange = _selected.update;
            scope.isAllSelected = _selected.isAllSelected;
        }
    }
    return {
        //compile: _compile,
        link: _link,
        scope: {
            node: '=queryConstraint',
            extra: '='
        },
        templateUrl: queryConstraintHtml,
        replace: true
    };
}
export var QueryConstraintDirective = angular
    .module('components.query.queryNode.queryConstraint', [])
    .directive('queryConstraint', queryConstraint);
