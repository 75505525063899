'use strict';

import angular from 'angular';
import activityCompactSummary from './activity-compact-summary.directive.js'
import activityService from './activity-service.factory.js'
// import adformService from './adform-service.factory.js'
import animationStyle from './animation-style.directive.js'
import api from './api.factory.js'
import authorizationService from './authorization-service.factory.js'
import brandMe from './brand-me.directive.js'
import branding from './branding.factory.js'
import clearServerValidationOnChange
    from './clear-server-validation-on-change.directive.js'
import countriesService from './countries-service.factory.js'
import enterPress from './enter-press.directive.js'
import facebookService from './facebook-service.factory.js'
import fileread from './fileread.directive.js'
import formService from './form-service.factory'
import foursquareService from './foursquare-service.factory.js'
import gendersConstant from './genders.constant'
import gridUtils from './grid-utils.factory.js'
import groupService from './group-service.factory'
import HeaderController from './header/header.controller.js'
import indeterminateCheckbox from './indeterminate-checkbox.directive.js'
import languagesService from './languages-service.factory'
import LegalFooterController from './legal-modal.controller'
import loadingStatusInterceptor from './loading-status-interceptor.factory.js'
import mainLayout from './main-layout.directive.js'
import media from './media.factory.js'
import metaService from './meta-service.factory.js'
import ModalHeaderController from './modal/modal-header.controller.js'
import panelBadge from './panel-badge.directive.js'
import passwordService from './password-service.factory.js'
import postalCodeService from './postal-code-service.factory'
import profileImage from './profile-image.directive.js'
import propsFilter from './props-filter.filter'
import {QueryModule} from './query/query.ts'
import {TruncatingLinesDirective} from './truncating-lines.directive.ts'
import randomConstant from './random.constant.js'
import rewardService from './reward-service.factory.js'
import realTimeEngine from './real-time-engine/real-time-engine.js'
import segmentPanelService from './segment-panel-service.factory.js'
import selectionService from './selection-service.factory.js'
import {SelfMonitoringFactory} from './self-monitoring.factory.ts'
import slideDown from './slide-down.directive'
import storageService from './storage-service.factory.js'
import themeColor from './theme-color.directive.js'
import trackingService from './tracking-service.factory.js'
import upload from './upload.factory.js'
import userService from './user-service.factory.js'
import userSignals from './user-signals.js'
import utilityService from './utility.service.js'
import validDate from './valid-date.directive.js'
import validatePassword from './validate-password.directive.js'


export default angular.module('components', [
    activityCompactSummary.name,
    activityService.name,
    // adformService.name,
    animationStyle.name,
    api.name,
    authorizationService.name,
    brandMe.name,
    branding.name,
    clearServerValidationOnChange.name,
    countriesService.name,
    enterPress.name,
    facebookService.name,
    fileread.name,
    formService.name,
    foursquareService.name,
    gendersConstant.name,
    gridUtils.name,
    groupService.name,
    HeaderController.name,
    indeterminateCheckbox.name,
    languagesService.name,
    LegalFooterController.name,
    loadingStatusInterceptor.name,
    mainLayout.name,
    media.name,
    metaService.name,
    ModalHeaderController.name,
    panelBadge.name,
    passwordService.name,
    postalCodeService.name,
    profileImage.name,
    propsFilter.name,
    QueryModule.name,
    randomConstant.name,
    rewardService.name,
    realTimeEngine.name,
    segmentPanelService.name,
    selectionService.name,
    SelfMonitoringFactory.name,
    slideDown.name,
    storageService.name,
    themeColor.name,
    trackingService.name,
    TruncatingLinesDirective.name,
    upload.name,
    userService.name,
    userSignals.name,
    utilityService.name,
    validDate.name,
    validatePassword.name
]);
