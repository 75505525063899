'use strict';

import angular from 'angular';
import imagePreviewHtml from './image-preview.html'

/* @ngInject */
function imagePreview(api) {
    function _link(scope) {
        scope.apiUrl = api.url;
    }

    return {
        restrict: 'A',
        scope: {
            imageUrl: '=imagePreview'
        },
        templateUrl: imagePreviewHtml,
        link: _link
    };
}

export default angular
    .module('activities.imagePreview', [])
    .directive('imagePreview', imagePreview);
