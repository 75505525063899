'use strict';
import * as angular from 'angular';
var populationCountHtml = require('./population-count.html');
import _merge from 'lodash/merge';
import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
/* @ngInject */
function populationCount($rootScope, $log, activeFiltersService, user, segmentService, utility) {
    function _link(scope) {
        var listenerDestroyers = [];
        function _updateCount() {
            var node = scope.node, filters = activeFiltersService.getQueryConstraints(), queryParams = _merge(user.queryParamsFromFilters(filters), {
                count: true,
                sql: true
            }), anonymizedNode = segmentService
                .anonymizeQueryNode(segmentService.trimQueryNode(node));
            if (_includes(['query_bracket', 'query_constraint'], anonymizedNode.discriminator)) {
                queryParams[anonymizedNode.discriminator] = anonymizedNode;
            }
            else {
                $log.warn('population-count.directive._updateCount: ' +
                    'unsupported node type:', anonymizedNode.discriminator);
                return;
            }
            user.query(queryParams).$promise
                .then(function _doUpdateCount(result) {
                node.userCount = result.count;
                node.sql = result.sql;
                if (!listenerDestroyers.length) {
                    listenerDestroyers = [
                        $rootScope
                            .$on(segmentService.queryWasUpdatedSignal, _updateCount),
                        $rootScope
                            .$on(activeFiltersService.updatedSignal, _updateCount)
                    ];
                    scope.$on('$destroy', function _destroyListeners() {
                        _forEach(listenerDestroyers, utility.call);
                    });
                }
            });
        }
        function _init() {
            var _node = scope.node, undefinedConstraints = _size(_filter(_node.children, {
                question_id: undefined,
                subquery_id: undefined
            }));
            _node.userCount = null;
            if (_node.discriminator === 'query_constraint' &&
                angular.isUndefined(_node.question_id) &&
                angular.isUndefined(_node.subquery_id)) {
                undefinedConstraints += 1;
            }
            if (!undefinedConstraints /*&& !unvalued*/) {
                _updateCount();
            }
        }
        _init();
    }
    return {
        scope: {
            node: '=populationCount'
        },
        link: _link,
        replace: true,
        templateUrl: populationCountHtml
    };
}
export var PopulationCountDirective = angular
    .module('components.query.queryNode.queryBracket.populationCount', [])
    .directive('populationCount', populationCount);
