'use strict';

import angular from 'angular'
import joypixels from 'emoji-toolkit';
import _replace from 'lodash/replace';
import emojiService from './emoji-service.factory'

/* @ngInject */
function mdExample($q, $compile, $sce, $log, gettext, markdownIt) {
    function _renderPipeline(string) {
        return markdownIt.render(joypixels.toImage(string));
    }

    function _render(text) {
        return $sce.trustAsHtml(
            _renderPipeline(_replace(text.replace(/(&gt;)/g, '>'),
                /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''))
        );
    }

    // function _xcompile(element) {
    //     const _origContents = element.find('.md-example-source').html(),
    //         _outputNode = element.find('.md-example-output');
    //
    //     return {
    //         post: function _link(scope, element) {
    //             function _init() {
    //                 const i18nContents = gettextCatalog.getString(_origContents);
    //                 $log.debug('md-example.directive._compile: i18nContents', i18nContents);
    //                 const rendered = _render(i18nContents);
    //                 _outputNode.replaceWith(
    //                     `<div class="list-group-item-text md-example-output">${rendered}</div>`);
    //             }
    //
    //             _init();
    //         },
    //     }
    // }

    function _link(scope, element) {
        function _init() {
            const _origContents = element.find('.md-example-source').html(),
                _outputNode = element.find('.md-example-output');
            const rendered = _render(_origContents);
            _outputNode.replaceWith(
                `<div class="list-group-item-text md-example-output">${rendered}</div>`);
        }

        _init();
    }

    return {
        restrict: 'A',
        // terminal: true,
        scope: {
            contents: '=',
            message: '=',
            style: '=messageStyle',
        },
        // compile: _compile,
        link: _link,
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.mdExample', [
        emojiService.name
    ])
    .directive('mdExample', mdExample);
