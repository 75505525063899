'use strict';

import angular from 'angular';

/* @ngInject */
function brandMe($rootScope, $state, $log,
                 branding) {
    function _updateStyling(element, isHeader, shade) {
        const style = {
            'background-color': branding.color[shade]
        };

        if (isHeader) {
            style['background-image'] = branding.logoUrl;
        }

        element.css(style).removeClass('avoid-brand-flicker');
    }

    return {
        restrict: 'A',
        link: function _link(scope, element, attrs) {
            var isHeader = angular.isDefined(attrs.header),
                shade = attrs.brandMe || branding.defaultShade,
                unbindUpdateListener =
                    scope.$on(branding.updateSignal, function () {
                        _updateStyling(element, isHeader, shade);
                    });

            if (isHeader) {
                element.addClass("branded-header");
            }

            scope.$on('$destroy', function () {
                unbindUpdateListener();
            });

            _updateStyling(element, isHeader, shade);
        }
    };
}

export default angular
    .module('components.brandMe', [])
    .directive('brandMe', brandMe);
