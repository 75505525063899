'use strict';

import angular from 'angular';
import smileyAnswerHtml from './smiley-answer.html'

/* @ngInject */
function smileyAnswer() {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: smileyAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.smileyAnswer', [])
    .directive('smileyAnswer', smileyAnswer);
