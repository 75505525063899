'use strict';

import angular from 'angular';

/* @ngInject */
function LoginController($scope, $state, $stateParams, $rootScope, $log, facebook, user, config, panelService,
                         userSignals) {
    $scope.defaultDebounceOptions = config.getDebounceOptions();

    function _goHome() {
        $state.go('home', null, {reload: true});
    }

    function _init() {
        $scope.doJoinPanel = !!$stateParams.doJoinPanel;

        const extraLoginInfo = {
            doJoinPanel: $scope.doJoinPanel,
            currentPanelId: undefined
        };

        panelService.get().then((panels) => {
            $scope.currentPanelId = panels.current.panel_id;

            extraLoginInfo.currentPanelId = $scope.currentPanelId;

            $scope.login = () => {
                user.login($scope.email, $scope.password, extraLoginInfo);
            };

            facebook.getLoginFunction(extraLoginInfo).then((fn) => {
                $scope.loginFb = fn;
            });
        });

        const cleanUp = $rootScope.$on(userSignals.userLoginSignal, _goHome);

        $scope.$on('$destroy', cleanUp);
    }

    _init();
}

export default angular
    .module('welcome.login.loginController', [])
    .controller('LoginController', LoginController);
