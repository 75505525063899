'use strict';

import angular from 'angular';
import questionnaireQuestion from './questionnaire-question.directive.js';
import questionnairePageHtml from './questionnaire-page.html'

/* @ngInject */
function questionnairePage() {
    return {
        restrict: 'A',
        scope: {
            page: '=',
            navigator: '=',
            iscrollInstance: '='
        },
        link: function _link(scope) {
            if (angular.isDefined(scope.iscrollInstance)) {
                scope.navigator.$$$_iScroll = scope.iscrollInstance;
            }
        },
        templateUrl: questionnairePageHtml
    };
}

export default angular
    .module('activities.questionnaire.questionnairePage', [
        questionnaireQuestion.name
    ])
    .directive('questionnairePage', questionnairePage);
