'use strict';

import "core-js/modules/es.date.to-string.js";
import "core-js/modules/web.timers.js";
import Raven from 'raven-js';

function _raven() {
  Raven.config('https://73cfb2648e694244879c244b47f41898@sentry.onlivepanel.com/3', {
    release: 'undefined',
    tags: {
      git_branch: 'undefined',
      env_type: 'production'
    }
  }).install();
}

function _googleAnalytics() {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();
    a = s.createElement(o), m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

  ga('create', 'UA-62616968-2', 'auto');
}

function _inspectlet() {
  if (/^.*\.onlivepanel\.com$/.test(document.location.hostname)) {
    window.__insp = window.__insp || [];

    window.__insp.push(['wid', 1359719830]);

    (function () {
      function ldinsp() {
        if (typeof window.__inspld != "undefined") return;
        window.__inspld = 1;
        var insp = document.createElement('script');
        insp.type = 'text/javascript';
        insp.async = true;
        insp.id = "inspsync";
        insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(insp, x);
      }

      setTimeout(ldinsp, 500);

      if (document.readyState != "complete") {
        if (window.attachEvent) {
          window.attachEvent('onload', ldinsp);
        } else {
          window.addEventListener('load', ldinsp, false);
        }
      } else {
        ldinsp();
      }
    })();
  }
}

function _all() {
  window.setTimeout(_raven, 0);

  _googleAnalytics(); // _inspectlet();

}

var _platformPreInit = {
  raven: _raven,
  googleAnalytics: _googleAnalytics,
  inspectlet: _inspectlet,
  all: _all
};
export default _platformPreInit;