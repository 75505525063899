'use strict';

import angular from 'angular';

import acceptEulaState from './accept-eula.constant.js';
import AcceptEulaController from './accept-eula.controller.js';
import AcceptEulaNavigatorController
    from './accept-eula-navigator.controller.js';

export default angular
    .module('home.acceptEula', [
        acceptEulaState.name,
        AcceptEulaController.name,
        AcceptEulaNavigatorController.name,
    ]);
