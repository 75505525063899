'use strict';

import angular from 'angular';
import DiaryController from './diary.controller.js';
import DiaryModalHeaderController from './diary-modal-header.controller.js';
import EntryController from './entry.controller.js';
import diaryHtml from './diary.html'
import diaryModalHeaderHtml from './diary.modal.header.html'
import diaryEntryHtml from './diary-entry.html'
import questionnaireFooterHtml from '../questionnaire/questionnaire.footer.html'

/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state({
            name: 'home.diary',
            url: '^/{activityType:diary}/{activityId:int}/',
            views: {
                'main-contents@': {
                    controller: 'DiaryController',
                    templateUrl: diaryHtml
                }
            },
            secure: true
        })
        .state({
            name: 'home.diary.entry',
            url: 'session/{questionnaireSessionId:int}/:what/',
            views: {
                'modal-header@': {
                    templateUrl: diaryModalHeaderHtml,
                    controller: 'DiaryModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: diaryEntryHtml,
                    controller: 'EntryController'
                },
                'modal-footer@': {
                    templateUrl: questionnaireFooterHtml,
                    controller: 'QuestionnaireNavigatorController'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    closeTargetState: '^'
                });
            },
            onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.closeModal();
            },
            secure: true,
            isPopup: true,
            isSurvey: true
        });
}

export default angular
    .module('activities.diary', [
        DiaryController.name,
        DiaryModalHeaderController.name,
        EntryController.name
    ])
    .config(config);
