'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _has from 'lodash/has'

import setPasswordPasswordHtml from './set-password.plain.html';
import setPasswordSocialAuthHtml from './set-password.social-auth.html';

/* @ngInject */
function setPasswordService($state, $q, panelService, profileService, gettext) {
    const _pages = [
        {
            name: 'set-password',
            title: gettext('Set Password'),
            body: setPasswordPasswordHtml,
            pageState: {},
            buttons: {
                next: {
                    text: gettext('Save Password'),
                    icon: 'fa-unlock-alt'
                },
            },
            enabled: true
        },
        {
            name: 'social-media-authentication',
            title: gettext('Use Social Authentication'),
            body: setPasswordSocialAuthHtml,
            pageState: {},
            buttons: {
                next: {
                    text: gettext('Close'),
                    icon: 'fa-close'
                },
            },
            enabled: false
        }
    ], _state = {
        next: _next,
        previous: _previous,
        currentPageIndex: null,
        setPageState: (name, state) => {
            const page = _filter(_pages, {'name': name})[0];
            page.pageState = state;
        }
    };

    function _goToPage(p) {
        _state.currentPageIndex = p;
        _state.currentPage = _pages[_state.currentPageIndex]

    }

    function _getNextEnabled(p) {
        let i;

        for (i = p + 1; i < _pages.length; i++) {
            if (_pages[i].enabled && _pages[i].isNeeded) {
                break;
            }
        }
        if (i === _pages.length) {
            i = -1;
        }

        return i;
    }

    function _getPreviousEnabled(p) {
        let i;

        for (i = p - 1; i >= 0; i--) {
            if (_pages[i].enabled && _pages[i].isNeeded) {
                break;
            }
        }
        if (i < 0) {
            i = -1;
        }

        return i;
    }

    function _exit() {
        $state.go('^');
    }

    function _performPageAction() {
        if (_has(_state.currentPage.pageState, 'action')) {
            return _state.currentPage.pageState
                .action(_state.currentPage.pageState.form);
        }
    }

    function _next() {
        $q.when(_performPageAction()).then(() => {
            const p = _getNextEnabled(_state.currentPageIndex);

            if (p < 0) {
                _exit();
            } else {
                _goToPage(p);
            }
        })
    }

    function _previous() {
        const p = _getPreviousEnabled(_state.currentPageIndex);

        if (p < 0) {
            _exit();
        } else {
            _goToPage(p);
        }
    }

    function _activate() {
        _goToPage(0);
    }

    _activate();

    return _state;
}

export default angular
    .module('home.setPassword.service', [])
    .factory('setPasswordService', setPasswordService);
