function _getUniqueTempId() {
    // FIXME: _Duplicate_ function definition, also defined in `formService`.
    // Returns timestamp in ms.
    return Math.floor(window.performance &&
        window.performance.now &&
        window.performance.timing &&
        window.performance.timing.navigationStart ?
        window.performance.now() +
            window.performance.timing.navigationStart : Date.now());
}
var QueryBracket = /** @class */ (function () {
    function QueryBracket(options) {
        var params = options || {};
        this.discriminator = 'query_bracket';
        // The `tmp-<timestamp>` id is used client-side to avoid errors in
        // `track by node.query_node_id` view expressions.
        this.query_node_id = params.query_node_id || 'tmp-' + _getUniqueTempId();
        this.meta = params.meta || null;
        this.children = params.children || [];
        this.query_bracket_id = params.query_bracket_id;
        this.quantifier = params.quantifier || 'all';
        this.quantifier_names = params.quantifier_names || [{
                data: {
                    value: "any"
                },
                description: "noen"
            }, {
                data: {
                    value: "all"
                },
                description: "alle"
            }];
    }
    return QueryBracket;
}());
export { QueryBracket };
