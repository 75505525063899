'use strict';

import angular from 'angular';
import phoneNumberAnswerHtml from './phone-number-answer.html'
import _isString from 'lodash/isString'

/* @ngInject */
function phoneNumberAnswer(metaService, api) {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: phoneNumberAnswerHtml,
        link: function (scope) {
            var question = scope.question;

            function _initCountryCodes(result) {
                var existingValue = question.answerIndex.rows[0].value;

                scope.countryDialingCodes =
                    result.data.country_dialing_codes;

                if (_isString(existingValue)) {
                    const parts = existingValue.split(' ', 2),
                        countryCode =
                            (parts.length === 2) ?
                                parts[0].split(':', 1)[0] : null,
                        phoneNumber =
                            (parts.length === 2) ? parts[1] : null;

                    question.answerIndex.rows[0].value = {
                        country_code: countryCode,
                        phone_number: phoneNumber
                    };
                }
            }

            // FIXME: Add server-based validation.
            // The user_id-phone-number combination must be unique.
            function _getCountryCodes(meta) {
                api.get(meta.registration.create_user
                    .phone_number_country_codes_url + '?constrained=true')
                    .then(_initCountryCodes);
            }

            function activate() {
                metaService.get().then(_getCountryCodes);
            }

            activate();
        }
    };
}

export default angular
    .module('activities.questionnaire.answerType.phoneNumberAnswer', [])
    .directive('phoneNumberAnswer', phoneNumberAnswer);
