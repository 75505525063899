'use strict';
import * as angular from 'angular';
/* @ngInject */
function queryNode(RecursionHelper) {
    function _link() {
    }
    function _compile(element) {
        /**
         * Use the compile function from the RecursionHelper, and return the
         * linking function(s) which it returns.
         */
        return RecursionHelper.compile(element, _link);
    }
    return {
        compile: _compile,
        scope: {
            node: '=queryNode',
            extra: '='
        },
        templateUrl: queryNodeHtml,
        replace: true
    };
}
import { ConvertToNumberDirective } from './convert-to-number.directive';
import { QueryBracketDirective } from './query-bracket.directive';
import { QueryConstraintDirective } from './query-constraint.directive';
import { QueryConstraintAnswerDirective } from './query-constraint-answer.directive';
var queryNodeHtml = require('./query-node.html');
export var QueryNodeDirective = angular
    .module('components.query.queryNode', [
    ConvertToNumberDirective.name,
    QueryBracketDirective.name,
    QueryConstraintDirective.name,
    QueryConstraintAnswerDirective.name,
])
    .directive('queryNode', queryNode);
