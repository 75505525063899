'use strict';

import angular from 'angular';
import activityCompactSummaryHtml from './activity-compact-summary.html'

/* @ngInject */
function activityCompactSummary(config) {
    function _link(scope) {
        scope.config = config;
    }

    return {
        restrict: 'AE',
        scope: {
            activity: '=activityCompactSummary'
        },
        templateUrl: activityCompactSummaryHtml,
        link: _link,
        replace: true
    };
}

export default angular
    .module('components.activityCompactSummary', [])
    .directive('activityCompactSummary', activityCompactSummary);
