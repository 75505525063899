'use strict';

import angular from 'angular';
import RewardController from './reward.controller.js'
import rewardHtml from './reward.html'
import rewardFooterHtml from './reward.footer.html'

/* @ngInject */
function config($stateProvider, gettext) {
    $stateProvider
        .state({
            name: 'welcome.modal.reward',
            url: '/reward',
            hideCloseButton: false,
            title: gettext('Register New User'),
            views: {
                'modal-contents@': {
                    controller: 'WelcomeRewardController',
                    templateUrl: rewardHtml
                },
                'modal-footer@': {
                    templateUrl: rewardFooterHtml
                }
            },
            onEnter: /* @ngInject */ function _openModal(coreLayoutService) {
                coreLayoutService.openModal({
                    footer: {
                        visible: coreLayoutService.defaultExcept({xs: true}),
                        hidden: coreLayoutService.defaultExcept()
                    },
                    closeTargetState: 'welcome',
                    size: 'medium'
                });
            },
            secure: false,
            isPopup: true,
            isOnboarding: true,
            isReward: true
        });
}

export default angular
    .module('welcome.reward', [
        RewardController.name
    ])
    .config(config);
