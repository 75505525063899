'use strict';
'use strict';
import * as angular from 'angular';
var eMessageHtml = require('./e-message.html');
import _forEach from 'lodash/forEach';
import _partial from 'lodash/partial';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _min from 'lodash/min';
import { MessageOverviewStats } from "./message-overview-stats.directive";
/* @ngInject */
function eMessage($state, $sce, activeFiltersService, utility, messageAdmin, gridUtils, gettext, gettextCatalog, selectionService) {
    var columnDefs = [
        {
            field: 'recipient_id',
            headerName: '<input type="checkbox" class="ag-selection-checkbox" indeterminate-checkbox="selections.isAllIndicator" on-change="selections.allChanged">',
            headerClass: 'check-all',
            suppressResize: true,
            width: 25,
            checkboxSelection: true,
            valueGetter: gridUtils.nullValue,
            suppressMenu: true,
            suppressSorting: true,
            headerTooltip: 'Toggle all',
            compileWithAngular: true
        },
        {
            headerName: gettext('User ID'), field: 'recipient_id',
            filter: 'number',
            width: 95,
            hide: true
        },
        {
            headerName: gettext('Last Name'), field: 'user.last_name',
            filter: 'text',
            width: 120
        },
        {
            headerName: gettext('First Name'), field: 'user.first_name',
            filter: 'text',
            width: 110
        },
        {
            headerName: gettext('Email'), field: 'user.email_address',
            filter: 'text',
            width: 225
        },
        {
            headerName: gettext('Opened?'),
            field: 'is_opened',
            hide: false,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'is_opened'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'is_opened'),
            width: 120
        },
        {
            headerName: gettext('Sent?'),
            field: 'is_sent',
            hide: false,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'is_sent'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'is_sent'),
            width: 120
        },
        {
            headerName: gettext('Email Clicked?'),
            field: 'email_is_clicked',
            hide: true,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'email_is_clicked'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'email_is_clicked'),
            width: 80
        },
        {
            headerName: gettext('Email Opened?'),
            field: 'email_is_opened',
            hide: true,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'email_is_opened'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'email_is_opened'),
            width: 80
        },
        {
            headerName: gettext('Email Sent?'),
            field: 'email_is_sent',
            hide: true,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'email_is_sent'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'email_is_sent'),
            width: 80
        },
        {
            headerName: gettext('Email Rejected?'),
            field: 'email_is_rejected',
            hide: true,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'email_is_rejected'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'email_is_rejected'),
            width: 80
        },
        {
            headerName: gettext('Archived?'),
            field: 'is_archived',
            hide: true,
            filter: 'set',
            filterParams: {
                cellRenderer: _partial(_booleanLongForm, 'is_archived'),
                values: [true, false],
            },
            cellRenderer: _partial(_booleanShortForm, 'is_archived'),
            width: 80
        },
    ];
    var _isVerifiedElement = {
        true: '<span class="fa fa-fw fa-check"></span>',
        false: '<span class="fa fa-fw fa-times"></span>'
    };
    function _booleanShortForm(field, params) {
        var key = angular.isDefined(params.data) ?
            params.data[field] : params.value;
        return angular.isDefined(key) ?
            _isVerifiedElement[key.toString()] : '';
    }
    var _humaneLongFormLabels = {
        'is_handled': {
            true: gettext('Is handled'),
            false: gettext('Is unhandled')
        },
        'is_opened': {
            true: gettext('Is opened'),
            false: gettext('Is unopened')
        },
        'is_sent': {
            true: gettext('Is sent'),
            false: gettext('Is unsent')
        },
        'email_is_clicked': {
            true: gettext('Email is clicked'),
            false: gettext('Email is not clicked')
        },
        'email_is_opened': {
            true: gettext('Email is opened'),
            false: gettext('Email is unopened')
        },
        'email_is_sent': {
            true: gettext('Email is sent'),
            false: gettext('Email is unsent')
        },
        'email_is_rejected': {
            true: gettext('Email was rejected'),
            false: gettext('Email was accepted')
        },
        'is_archived': {
            true: gettext('Email is archived'),
            false: gettext('Email is unarchived')
        },
    };
    function _booleanLongForm(field, params) {
        var humaneLabel = _humaneLongFormLabels[field][params.value.toString()];
        return "<span>" + gettextCatalog.getString(humaneLabel) + "</span>";
    }
    function _link(scope) {
        scope._min = _min;
        scope._maxRecipientRows = 12;
        if (angular.isUndefined(scope.isCollapsed)) {
            scope.isCollapsed = true;
        }
        function _prepareMessage() {
            _forEach(scope.eMessage.deliveries, function (delivery) {
                delivery.user = messageAdmin.currentUserIndex[delivery.recipient_id];
                // delivery.user_id = delivery.recipient_id;
                //
                if (scope.eMessage.use_defaults &&
                    delivery.user.prefers_delivery_via_email) {
                    scope.eMessage.via_email = true;
                }
            });
        }
        scope.toggleCollapse = function () {
            scope.isCollapsed = !scope.isCollapsed;
            if (!scope.isCollapsed) {
                _prepareMessage();
            }
        };
        function _init() {
            function _onModelUpdated(event) {
                var processedRows = event.api.getModel().getRowCount();
                selectionService.updateTotal(selectionName, processedRows);
                selectionService.updateInGridSelections(event.api, rowIdPath, selectionName);
                activeFiltersService
                    .updateSelectionsFilter(selectionName, selectionIdName);
            }
            var selectionNameBase = utility.getParentData($state.$current, activeFiltersService.defaultSelectionsName), selectionName = selectionNameBase + "-" + scope.eMessage.message_id, selectionIdName = utility.getParentData($state.$current, activeFiltersService.defaultSelectionsIdName), rowIdPath = 'recipient_id', selections = selectionService.getCollection(selectionName, selectionIdName), ops = gridUtils.getGridOperations({
                afterGridInit: function (service) {
                    service.gridOptions.api.setSortModel([
                        { colId: 'is_opened', sort: 'desc' },
                        { colId: 'is_sent', sort: 'asc' },
                        { colId: 'user.last_name', sort: 'asc' },
                        { colId: 'user.first_name', sort: 'asc' },
                    ]);
                },
                beforeRowSelected: function (event) {
                    var model = event.api.getModel(), origCount = selections.count;
                    scope.recipientSample = _map(_filter(model.rowsToDisplay, 'selected'), 'data.user');
                    selections.count = scope.recipientSample.length;
                    if (origCount !== selections.count) {
                        scope.$apply();
                    }
                    if (event.node.selected && !_includes(model.rowsToDisplay, event.node)) {
                        return false;
                    }
                },
                useVirtualPaging: false,
                columnDefs: columnDefs,
                gridOptions: {
                    onModelUpdated: _onModelUpdated,
                    enableFilter: true,
                    accentedSort: true,
                },
                selectionName: selectionName,
                selectionIdName: selectionIdName,
                rowIdPath: rowIdPath,
                rowData: scope.eMessage.deliveries,
                resource: undefined,
                storageName: 'messagesList',
                scope: scope
            });
            scope.messageView = 'contents';
            scope.selections = selections;
            scope.gridOptions = ops.gridOptions;
            scope.eMessage.trustedHtmlBody = $sce.trustAsHtml(scope.eMessage.html_body);
        }
        _init();
    }
    return {
        scope: {
            eMessage: '=',
            isCollapsed: '=?'
        },
        link: _link,
        replace: true,
        templateUrl: eMessageHtml
    };
}
export var EMessageDirective = angular
    .module('admin.messages.eMessageList.eMessage', [
    'agGrid',
    MessageOverviewStats.name
])
    .directive('eMessage', eMessage);
