'use strict';

import angular from 'angular';
import questionnairePage from './questionnaire-page.directive.js';
import questionnaireDirectiveHtml from './questionnaire.directive.html'
import _forEach from 'lodash/forEach'


/* @ngInject */
function questionnaire(questionnaireNavigator) {
    return {
        restrict: 'A',
        scope: {
            activity: '=',
            doSave: '@',
            redirect: '@',
            isOnboarding: '@',
            isDiary: '@'
        },
        templateUrl: questionnaireDirectiveHtml,
        link: function _link(scope, element) {
            _forEach(['doSave', 'isOnboarding', 'isDiary'],
                function _handleBooleanString(name) {
                    scope[name] = scope[name] === 'true';
                });

            element.addClass(scope.isDiary ? 'diary' : 'questionnaire');

            const deregister = scope.$watch('activity',
                function _activityAvailable(questionnaire) {
                    if (angular.isDefined(questionnaire)) {
                        questionnaireNavigator.init(questionnaire, {
                            isDiary: scope.isDiary,
                            isOnboarding: scope.isOnboarding,
                            questionnaireScope: scope
                        });

                        scope.navigator = questionnaireNavigator;

                        deregister();
                    }
                });
        }
    };
}

export default angular
    .module('activities.questionnaire.questionnaire', [
        questionnairePage.name
    ])
    .directive('questionnaire', questionnaire);
