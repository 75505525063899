'use strict';

import angular from 'angular';
import messageActionsHtml from './message-actions.html'
import _partial from 'lodash/partial'


/* @ngInject */
function messageActions($log, realTimeEngine, config) {
    function _link(scope) {
        scope.isThumbVotable = !!scope.message.forum;
        scope.isDislikeEnabled = config.forum.isDislikeEnabled;

        scope.message.showReplyMessage = false;
        scope.message.showEditMessage = false;

        scope.showReplyButton = !scope.message.is_private;
        scope.showPrivateReplyButton =
            (scope.message.currentUser.isSupervisor || scope.message.is_private);

        scope.showDeleteButton =
            (scope.message.author.isCurrentUser ||
                scope.message.currentUser.isSupervisor);

        function _addVote(vote_type) {
            if (!scope.isThumbVotable) {
                throw 'This message is not votable.';
            }

            scope.message.actions.vote({vote_type: vote_type})
                .then(function (res) {
                    $log.debug('added vote', res);
                });
        }

        function _deleteMessage() {
            const probe =
                    realTimeEngine.getMonitoringProbe('delete_message:broadcast'),
                action = 'delete_message:submit';

            scope.message.actions.delete_message()
                .then(_partial(probe.registerEventFromResponse, action),
                    _partial(probe.cancelFromResponse, action));
        }

        function _toggleShowReply(isPrivate, message) {
            isPrivate = !!isPrivate;
            // scope.$emit('openReplyMessage', isPrivate, message);
            scope.$emit('startEditReplyMessage', isPrivate, message);
        }

        function _beginEdit(message) {
            // scope.$emit('openEditMessage', message);
            scope.$emit('startEditMessage', message);
        }

        scope.addVote = _addVote;
        scope.deleteMessage = _deleteMessage;
        scope.toggleShowReply = _toggleShowReply;
        scope.beginEdit = _beginEdit;
    }

    return {
        scope: {
            message: '=',
            style: '=messageStyle'
        },
        link: _link,
        templateUrl: messageActionsHtml
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.messageActions', [])
    .directive('messageActions', messageActions);
