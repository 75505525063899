'use strict';

import angular from 'angular';
import adjustHeight from './adjust-height.directive.js';
import singleSelectIconRackAnswerHtml from './single-select-icon-rack-answer.html'

/* @ngInject */
function singleSelectIconRackAnswer() {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: singleSelectIconRackAnswerHtml,
        link: function _link(scope) {
            var question = scope.question;

            scope.sliderIsLocked = question.icon_rack_categories.length <= 1;
        }
    };
}

export default angular
    .module('activities.questionnaire.answerType.singleSelectIconRackAnswer', [
        adjustHeight.name
    ])
    .directive('singleSelectIconRackAnswer', singleSelectIconRackAnswer);
