'use strict';

import angular from 'angular';
import opener from './opener.directive.js';
import activityItemHtml from './activity-item.html'

/* @ngInject */
function activityItem(config) {
    function _link(scope) {
        scope.conf = config;

        function _handleLazyLoading(inview) {
            if (config.stats.isEnabled && inview &&
                scope.activity.isSupervisedByCurrentUser) {
                scope.activity.enableStats();
            }
        }

        scope.handleLazyLoading = _handleLazyLoading;
    }

    return {
        restrict: 'AE',
        scope: {
            activity: '=activityItem'
        },
        link: _link,
        templateUrl: activityItemHtml
    };
}

export default angular
    .module('activities.activityList.activityItem.directive', [
        opener.name
    ])
    .directive('activityItem', activityItem);
