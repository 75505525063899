'use strict';

import angular from 'angular';

/* @ngInject */
function foursquareService($q, $http, $resource, $log, metaService, utility) {
    var apiUrl = 'https://api.foursquare.com/v2/:resource/:action',
        _commonParams = {
            perspective: 'foursquare',  // Could also be 'swarm'.
            version: '20141014',
            oAuthToken: undefined,
            ll: undefined,
            radius: 1500,  // meters
            /* The following functions are used because (from
             * AngularJS API reference):
             *
             *     If any of the [$request] parameter value is a
             *     function, it will be executed every time when a
             *     param value needs to be obtained for a request
             *     (unless the param was overridden).
             *
             * And these values are not (necessarily) available
             * at service initialization time.
             */
            getOAuthToken: function _oAuthToken() {
                return _commonParams.oAuthToken;
            },
            getLL: function _ll() {
                return _commonParams.ll;
            }
        };

    function _getOAuthToken() {
        var deferred = $q.defer();

        metaService.get().then(function _initFoursquare(meta) {
            deferred.resolve(
                meta.auth.foursquare.foursquare_oauth_token);
        });

        return deferred.promise;
    }

    function _getLatitudeAndLongitude() {
        var deferred = $q.defer();

        navigator.geolocation
            .getCurrentPosition(function _resolveLocation(location) {
                var coords = location.coords;

                deferred.resolve(
                    '' + coords.latitude + ',' + coords.longitude);

                _foursquareService.gotLatLong = true;
            });
        return deferred.promise;
    }

    function _init() {
        var deferred = $q.defer();

        _getOAuthToken()
            .then(function _setOAuthToken(token) {
                $log.debug('foursquareService._setOAuthToken:', token);
                _commonParams.oAuthToken = token;
                deferred.resolve(_commonParams.oAuthToken);
            })
            .then(_getLatitudeAndLongitude)
            .then(function _resolveLatitudeAndLongitude(ll) {
                $log.debug('ll', ll);
                _commonParams.ll = ll;
            });

        return deferred.promise;
    }

    var _foursquareService = {
        venues: $resource(apiUrl, {
            resource: 'venues',
            oauth_token: _commonParams.getOAuthToken,
            m: _commonParams.perspective,
            v: _commonParams.version,
            ll: _commonParams.getLL,
            radius: _commonParams.radius,
            callback: 'JSON_CALLBACK'
        }, {
            suggestCompletion: {

                method: 'JSONP',
                withCredentials: false,
                cache: true,
                params: {
                    action: 'suggestcompletion'
                },
                transformResponse: utility.appendTransform(
                    $http.defaults.transformResponse,
                    function _getMiniVenues(data) {
                        return {venues: data.response.minivenues};
                    }
                )
            },
            search: {
                method: 'JSONP',
                withCredentials: false,
                cache: true,
                params: {
                    action: 'search'
                },
                transformResponse: utility.appendTransform(
                    $http.defaults.transformResponse,
                    function _getVenues(data) {
                        return {venues: data.response.venues};
                    }
                )
            },
            get: {
                method: 'JSONP',
                withCredentials: false,
                cache: true,
                transformResponse: utility.appendTransform(
                    $http.defaults.transformResponse,
                    function _getVenues(data) {
                        return data.response.venue;
                    }
                )
            }
        }),
        init: _init,
        gotLatLong: false  // Initially.
    };

    return _foursquareService;
}

export default angular
    .module('components.foursquareService', [])
    .factory('foursquareService', foursquareService);
