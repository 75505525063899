'use strict';

import angular from 'angular';
import _isString from 'lodash/isString'
import _reject from 'lodash/reject'


/* @ngInject */
function AcceptEulaController($state, $stateParams, $q, $log, $window,
                              panelService, profileService, acceptEulaService,
                              config, user, metaService) {
    const vm = this;

    function _acceptEula() {
        return user.acceptEula({
            user_id: vm.currentUserId,
            panel_id: vm.currentPanel.panel_id
        }).$promise
            .then(angular.noop,
                (response) => {
                    vm.acceptEulaError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                    return $q.reject();
                })
            .then(metaService.refresh)
            .then(panelService.refresh);
    }

    function _deleteAccount() {
        return user.delete({
            userId: vm.currentUserId,
            self_removal: true
        }).$promise
            .then(() => {
                $window.location = '/';
            }, (response) => {
                vm.deleteError = _isString(response.data) ? {
                    message: response.statusText
                } : {
                    message: response.data.message
                };
                return $q.reject();
            });
    }

    function _leavePanel() {
        const nextPanel =
            _reject(acceptEulaService.panels,
                {panel_id: vm.currentPanel.panel_id})[0];

        return user.leavePanel({
            userId: vm.currentUserId,
            panelId: vm.currentPanel.panel_id
        }).$promise
            .then(() => panelService.setCurrentPanel(nextPanel.panel_id, true),
                (response) => {
                    vm.leavePanelError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                    return $q.reject();
                })
            .then(metaService.refresh)
            .then(panelService.refresh)
    }

    function _exit() {
        $state.go('^');
    }

    function _accept() {
        $q.when(_acceptEula()).then(_exit);
    }

    function _leave() {
        $q.when(_leavePanel()).then(_exit);
    }

    function _delete() {
        $q.when(_deleteAccount()).then(_exit);
    }

    function _activate() {
        vm.organization = config.organization;
        vm.lastUpdated = config.legal.eula.lastUpdated;

        metaService.get()
            .then((meta) => {
                vm.currentUserId = meta.user.user_id
            });

        panelService.get()
            .then((panels) => {
                acceptEulaService.panels = panels.open;
                vm.currentPanel = panels.current;

                vm.eulaEmail = _isString(vm.currentPanel.support_email) ?
                    vm.currentPanel.support_email : config.organization.eulaEmail;
            });

        acceptEulaService.leave = _leave;
        acceptEulaService.delete = _delete;
        acceptEulaService.accept = _accept;

        vm.navigator = acceptEulaService;
    }

    _activate();
}

export default angular
    .module('home.acceptEula.controller', [])
    .controller('AcceptEulaController', AcceptEulaController);
