'use strict';

import angular from 'angular';
import 'brace';
import 'brace/mode/html';
import 'brace/theme/chrome';
import 'angular-ui-ace';
import base64 from 'base-64';
import utf8 from 'utf8';
import fileInputButton from './file-input-button.directive.js';
import _assign from 'lodash/assign'
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _find from 'lodash/find'
import _debounce from 'lodash/debounce'
import _partial from 'lodash/partial'
import _forEach from 'lodash/forEach'


const aceTheme = 'chrome',
    aceMode = 'html';


/* @ngInject */
function MessageUsersController($scope, $state, $sce, $window, $log,
                                activeFiltersService, user, storage, gettext,
                                utility, gettextCatalog) {

    // eslint-disable-next-line no-unused-vars
    const vm = this, selectionName = utility.getParentData($state.$current,
        activeFiltersService.defaultSelectionsName), _storage = $window.localStorage,
        // eslint-disable-next-line no-unused-vars
        i18nStringsForGettextExtraction = [
            gettext('Your user\' address'),
            gettext('Panels you supervise'),
            gettext('Clients you manage')
        ];

    function _getMessageKey(messageId) {
        return `editedMessage-${messageId}`;
    }

   function _getRecipientsKey(messageId) {
        return `recipients-${messageId}`;
    }

    function _resetMessage(messageId) {
        _assign(vm, _createEmptyEditedMessage());
        storage.storageInstance(_storage, _getMessageKey(messageId)).removeItem();
    }

    function _successfullyMessaged(messageId) {
        _resetMessage(messageId);
        _clearStoredRecipients(messageId);

        if (messageId === 'new') {
            const filter = activeFiltersService
                .getInheritedSelectionsFilter($state.$current);
            activeFiltersService.removeFilter(filter);
        }
        $state.go('admin.users.list', {}, {reload: true});
    }

    function _handleMessageError(response) {
        vm.messageError = {
            message: _isString(response.data) ?
                response.statusText :
                response.data.message
        };
    }

    function _messageSelectedAccounts() {
        if (!(vm.users && vm.users.length &&
            vm.subject && vm.body &&
            vm.fromAddress)) {
            return;
        }

        const params = {
            subject: vm.subject,
            body: vm.body,
            email_from_address: vm.fromAddress.email,
            use_html: vm.useHtml,
            use_defaults: vm.respectUsersPreferences,
            via_onlive: vm.viaPlatform,
            via_email: vm.viaEmail,
            via_sms: vm.viaSms,
            via_facebook: vm.viaFacebook,
            recipient_ids: _map(vm.users, 'user_id'),
            is_template: false,
            in_reply_to_id: vm.inReplyToId !== 'new' ? vm.inReplyToId : null,
        };

        vm.messageError = null;

        user.messageMultiple(params).$promise
            .then(_partial(_successfullyMessaged, vm.inReplyToId), _handleMessageError);
    }

    function _togglePreview() {
        vm.messageView = vm.messageView === 'editor' ? 'preview' : 'editor';

        if (vm.messageView === 'preview') {
            vm.htmlBody = $sce.trustAsHtml(vm.body);
        } else {
            vm.htmlBody = null;
        }
    }

    function _saveStoredContents(messageId, editedMessage) {
        storage.storageInstance(_storage, _getMessageKey(messageId)).setItem(editedMessage);
    }

    function _createEmptyEditedMessage(messageId) {
        return {
            subject: undefined,
            body: undefined,
            useHtml: false,
            viaPlatform: true,
            viaEmail: false,
            viaSms: false,
            viaFacebook: false,
            respectUsersPreferences: true,
            fromAddress: undefined,
            inReplyToId: messageId,
        };
    }

    function _createEditedMessage(messageId, subject, body, useHtml) {
        const editedMessage = _createEmptyEditedMessage(messageId);
        editedMessage.subject = subject;
        editedMessage.body = body;
        editedMessage.useHtml = useHtml;

        _saveStoredContents(messageId, editedMessage);

        return editedMessage;
    }

    function _loadStoredContents(messageId) {
        const editedMessage = storage.storageInstance(_storage, _getMessageKey(messageId)).getItem();

        return editedMessage === null ?
            _createEmptyEditedMessage(messageId) : editedMessage;
    }

    function _createEmptyRecipients() {
        return {
            users: [],
            total: 0,
        }
    }

    function _clearStoredRecipients(messageId) {
        storage.storageInstance(_storage, _getRecipientsKey(messageId)).removeItem();
    }

    function _saveStoredRecipients(messageId, recipients) {
        storage.storageInstance(_storage, _getRecipientsKey(messageId)).setItem(recipients);
    }

    function _loadStoredRecipients(messageId) {
        const recipients = storage.storageInstance(_storage, _getRecipientsKey(messageId)).getItem();

        return recipients === null ?
            _createEmptyRecipients() : recipients;
    }


    function _messageChanged(messageId, field, newValue, oldValue) {
        if (newValue === oldValue) {
            return;
        }

        const editedMessage = _loadStoredContents(messageId);

        if (editedMessage[field] !== newValue) {
            editedMessage[field] = newValue;

            _saveStoredContents(messageId, editedMessage);
        }
    }

    function _onFilesChanged(currentFiles) {
        if (angular.isUndefined(currentFiles) || currentFiles.length === 0) {
            return;
        }

        vm.body = utf8.decode(base64.decode(currentFiles[0].data));
    }

    function _getQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'user_id', true);

        return _merge(user.queryParamsFromFilters(filters), {
            extra_columns: [
                'email_address'
            ],
            sql: false
        });
    }

    function _activate() {
        // eslint-disable-next-line lodash/prefer-lodash-method
        const isFollowUp = $state.includes('admin.messages.follow-up'),
            messageId = isFollowUp ? $state.params.messageId : 'new';

        if (isFollowUp) {
            if ($state.params.userSample !== null) {
                vm.users = $state.params.userSample;
                vm.total = $state.params.userSample.length;
                _saveStoredRecipients(messageId, {users: vm.users, total: vm.total});
            } else {
                _assign(vm, _loadStoredRecipients(messageId));
            }
        } else {
            const queryParams = $state.params.userSampleQueryParams !== null ?
                $state.params.userSampleQueryParams : _getQueryParams();

            user.query(queryParams).$promise
                .then((response) => {
                    vm.users = response.users;
                    vm.total = response.total;
                });
        }

        user.getValidFromAddresses().$promise
            .then(function _updateMenu(response) {
                vm.fromAddresses = response.addresses;
                if (!vm.fromAddress) {
                    vm.fromAddress =
                        _find(vm.fromAddresses,
                            ['type', 'Your user\'s address']);
                }
            });

        if (isFollowUp &&
            $state.params.subject !== null &&
            $state.params.body !== null &&
            $state.params.useHtml !== null) {
            const subject = messageId !== 'new' ?
                `${(gettextCatalog.getString(gettext('Re: ')))}${$state.params.subject}` : '';
            _assign(vm, _createEditedMessage(messageId, subject,
                $state.params.body, $state.params.useHtml));

        } else {
            _assign(vm, _loadStoredContents(messageId));
        }

        const debounceOptions = {
                maxWait: 10000,
                leading: false
            },
            deregisterers = _map([
                    'subject',
                    'body',
                    'useHtml',
                    'viaPlatform',
                    'viaEmail',
                    'viaSms',
                    'viaFacebook',
                    'respectUsersPreferences',
                    'fromAddress',
                    'inReplyToId',
                ],
                function _setupWatcher(fieldName) {
                    return $scope.$watch('vm.' + fieldName,
                        _debounce(_partial(_messageChanged, messageId, fieldName), 500,
                            debounceOptions));
                });

        $scope.$on('$destroy', function _deregisterWatchers() {
            _forEach(deregisterers, (deregisterer) => deregisterer());
        });

        vm.aceOptions = {
            useWrapMode: true,
            showGutter: true,
            theme: aceTheme,
            mode: aceMode
        };

        vm.messageError = null;

        vm.topLevelView = 'message';
        vm.messageView = 'editor';

        vm.files = [];
        vm.onFilesChanged = _onFilesChanged;

        vm.togglePreview = _togglePreview;
        vm.resetMessage = _resetMessage;
        vm.messageSelectedAccounts = _messageSelectedAccounts;
    }

    _activate();
}

export default angular
    .module('admin.users.MessageUsersController', [
        'ui.ace',
        fileInputButton.name
    ])
    .controller('MessageUsersController', MessageUsersController);
