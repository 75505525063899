'use strict';

import angular from 'angular';

/* @ngInject */
function forwardClick($log) {
    return {
        link: function _link(scope, element) {
            $log.debug('forward-click.directive.js:9:._link: ');
            $log.debug('element', element);
            var input = element.children('input[type="file"]');

            $log.debug('input', input);

            element.on('tap', function _handle(event) {
                $log.debug('event', event);
                event._constructed = true;
                event.preventDefault();
                event.stopPropagation();
                //input.trigger('click');
            });
        }
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.forwardClick', [])
    .directive('forwardClick', forwardClick);
