'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _includes from 'lodash/includes'
import _toLower from 'lodash/toLower'
import _keys from 'lodash/keys'


function propsFilter() {
    return (items, props) => {
        let out = [];

        if (_isArray(items)) {
            _forEach(items, (item) => {
                let itemMatches = false;
                const keys = _keys(props);

                for (let i = 0; i < keys.length; i++) {
                    const prop = keys[i],
                        text = _toLower(props[prop]);

                    if (!_isNil(item[prop]) && _includes(_toLower(item[prop].toString()), text)) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
}

/* @ngInject */
function i18nPropsFilter(gettextCatalog) {
    return (items, props) => {
        let out = [];

        if (_isArray(items)) {
            _forEach(items, (item) => {
                let itemMatches = false;
                const keys = _keys(props);

                for (let i = 0; i < keys.length; i++) {
                    const prop = keys[i],
                        text = _toLower(props[prop]);

                    //gettextCatalog.getString(msgid, null, translateDomain);
                    if (!_isNil(item[prop]) && _includes(_toLower(gettextCatalog.getString(item[prop].toString())), text)) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            })
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
}

export default angular
    .module('components.propsFilter', [])
    .filter('propsFilter', propsFilter)
    .filter('i18nPropsFilter', i18nPropsFilter);
