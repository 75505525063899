'use strict';

import angular from 'angular';
import ArticleController from './article.controller.js';
import articleHtml from './article.html'

/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state({
            name: 'home.article',
            url: '^/{activityType:article}/{activityId:int}/',
            views: {
                'main-contents@': {
                    controller: 'ArticleController',
                    templateUrl: articleHtml
                }
            },
            secure: true
        });
}

export default angular
    .module('activities.article', [
        ArticleController.name
    ])
    .config(config);
