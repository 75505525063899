'use strict';

import angular from 'angular';

import setPasswordState from './set-password.constant.js';
import SetPasswordController from './set-password.controller.js';
import SetPasswordNavigatorController
    from './set-password-navigator.controller.js';

export default angular
    .module('home.setPassword', [
        setPasswordState.name,
        SetPasswordController.name,
        SetPasswordNavigatorController.name,
    ]);
