'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _some from 'lodash/some'
import _every from 'lodash/every'
import _reduce from 'lodash/reduce'
import _forEach from 'lodash/forEach'
import _defaults from 'lodash/defaults'
import _isString from 'lodash/isString'
import _has from 'lodash/has'
import _partial from 'lodash/partial'
import _compact from 'lodash/compact'
import _map from 'lodash/map'
import _assign from 'lodash/assign'
import _pick from 'lodash/pick'
import _head from 'lodash/head'
import _reject from 'lodash/reject'
import _findKey from 'lodash/findKey'
import _includes from 'lodash/includes'

/* @ngInject */
function answerService($log, answerClass, upload, utility) {
    function _unhandledAnswerTypeClassError(methodName, question) {
        $log.error(`${methodName}: Unhandled answerTypeClass:`,
            question.answerTypeClass, 'or answer_type:',
            question.answer_type.name, question);
    }

    function _hasValidAnswer(question) {
        let isValid = false;

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!question.optional) {
            isValid = true;
        } else {
            switch (question.answerTypeClass) {
                case answerClass.value:
                /* Fall-through. */
                // eslint-disable-next-line no-fallthrough
                case answerClass.image:
                /* Fall-through. */
                // eslint-disable-next-line no-fallthrough
                case answerClass.singleRow:
                    isValid = _filter(question.validRows).length === 1;
                    break;
                case answerClass.multiRowSingleColumn:
                    isValid = _filter(question.validRows).length ===
                        question.valuerows.length;
                    break;
                case answerClass.multiRow:
                    isValid = _some(question.validRows);
                    break;
                case answerClass.multiRowMultiColumn:
                    isValid = _every(question.validRows);
                    break;
                default:
                    _unhandledAnswerTypeClassError(
                        'questionnaireService.hasValidAnswer', question);
            }
        }

        return isValid;
    }

    function _subStepIsAnswered(question, subStep) {
        let isAnswered = false;

        //$log.debug('subStep', subStep);
        //$log.debug('valueRowId', valueRowId);
        //$log.debug('question.answerTypeClass', question.answerTypeClass);
        //$log.debug('question.validRows', question.validRows);

        if (question.optional) {
            isAnswered = true;
        } else if (angular.isUndefined(question.validRows)) {
            isAnswered = false;
        } else {
            switch (question.answerTypeClass) {
                case answerClass.value:
                /* Fall-through. */
                // eslint-disable-next-line no-fallthrough
                case answerClass.image:
                /* Fall-through. */
                // eslint-disable-next-line no-fallthrough
                case answerClass.singleRow:
                    isAnswered = _filter(question.validRows).length === 1;
                    break;
                case answerClass.multiRow:
                    isAnswered = _some(question.validRows);
                    break;
                case answerClass.multiRowSingleColumn:
                /* Fall-through. */
                // eslint-disable-next-line no-fallthrough,no-case-declarations
                case answerClass.multiRowMultiColumn:
                    const valueRowId = question.valuerows[subStep].valuerow_id;
                    isAnswered = question.validRows[valueRowId];
                    break;
                default:
                    _unhandledAnswerTypeClassError(
                        'questionnaireService.subStepIsAnswered', question);
            }
        }

        return isAnswered;
    }

    function _assumeInvalidRows(rows) {
        return _reduce(rows, function _setFalse(index, row) {
            index[row.valuerow_id] = false;
            return index;
        }, {});
    }

    function _computeValidRows(question) {
        return _reduce(question.session_answers,
            function _setRowTrue(index, answer) {
                index[answer.valuerow_id] = true;
                return index;
            }, _assumeInvalidRows(question.valuerows));
    }

    function _fakeValidRowsForValueAnswers(question) {
        return {0: question.answerIndex.rows[0].isSet};
    }


    // eslint-disable-next-line no-unused-vars
    function _updateAnswer(question, from) {
        // The `from` parameter is only used for debugging.
        const index = question.answerIndex;
        let row;

        //$log.debug('_updateAnswer, from', from);
        //$log.debug('_updateAnswer: questionId, type',
        //    question.question_id, question.answer_type.name);

        function _unsetAll(index) {
            _forEach(index.all, function _unset(answer) {
                answer.isSet = false;
            });
        }

        switch (question.answerTypeClass) {
            case answerClass.value:
                row = index.rows[0];

                row.isSet = !!row.value;

                question.session_answers =
                    _filter(index.all, {isSet: true});
                question.validRows =
                    _fakeValidRowsForValueAnswers(question);
                break;
            case answerClass.image:
                row = index.rows[0];

                row.isSet = !!row.image_id;

                question.session_answers =
                    _filter(index.all, {isSet: true});
                question.validRows =
                    _fakeValidRowsForValueAnswers(question);
                break;
            case answerClass.singleRow:
                _unsetAll(index);

                row = index.rows[0];
                row.isSet = !!row.valuerow_id;

                question.session_answers =
                    _filter(index.all, {isSet: true});
                question.validRows = _computeValidRows(question);
                break;
            case answerClass.multiRow:
                question.session_answers =
                    _filter(index.all, {isSet: true});
                question.validRows = _computeValidRows(question);
                break;
            case answerClass.multiRowSingleColumn:
                _unsetAll(index);

                _forEach(index.rows, function _setValid(row) {
                    row.isSet = !!row.valuerow_id && !!row.valuecolumn_id;
                });

                question.session_answers =
                    _filter(index.all, {isSet: true});
                question.validRows = _computeValidRows(question);
                break;
            case answerClass.multiRowMultiColumn:
                question.session_answers =
                    _filter(index.all, {isSet: true});
                question.validRows = _computeValidRows(question);
                break;
            default:
                _unhandledAnswerTypeClassError(
                    'questionnaireService.updateAnswer', question);
        }

        question.is_already_answered = _hasValidAnswer(question);

        question.$$$_page.updateStatus();

        return question.is_already_answered;
    }

    const answerTypeClassifications = {
            image: [
                'Uploaded Image'
            ],
            value: [
                'Free Text (Short)',
                'Free Text (Long)',
                'Date Selection',
                'Phone Number',
                'Foursquare Venue'
            ],
            singleRow: [
                'Single Select',
                'Country Selection',
                'Timezone',
                'Scale Single Select',
                'Single Select Icon Rack',
                'Smiley',
                'Yes/No'
            ],
            multiRow: [
                'Multiple Select',
                'Icon Rack'
            ],
            multiRowSingleColumn: [
                'Scale Grid',
                'Radio-button Grid',
                'Yes/No Grid'
            ],
            multiRowMultiColumn: [
                'Check-box Grid'
            ]
        },
        gridAnswerTypes = [
            'Check-box Grid',
            'Yes/No Grid',
            'Radio-button Grid',
            'Scale Grid',
            'Icon Rack',
            'Single Select Icon Rack'
        ],
        selectAnswerTypes = [
            'Country Selection',
            'Timezone'
        ],
        iconRackAnswerTypes = [
            'Icon Rack',
            'Single Select Icon Rack'
        ];

    function _prepareValueAnswer(question, answerSeed) {
        /* Create answerIndex with 1 entry, corresponding to
         * the answer's value, using the existing answer from
         * question.session_answers if it exist.
         */
        const rowIndex = {};

        if (question.session_answers.length > 1) {
            $log.error('Received question.session_answers with ' +
                question.session_answers.length + ' elements.', question);
        }


        if (question.session_answers.length > 0) {
            const answer = question.session_answers[0];
            rowIndex[0] = answer;
            rowIndex[0].isSet = true;

            if (question.answer_type.name === 'Date Selection' &&
                answer.value && _isString(answer.value)) {
                answer.value = utility.dateTime.fromIso(answer.value);
            }
        } else {
            rowIndex[0] = _defaults({
                value: null
            }, answerSeed);
        }

        question.answerIndex = {
            rows: rowIndex,
            all: [rowIndex[0]]
        };

        return question.answerIndex;
    }

    function _prepareSingleRowAnswer(question, answerSeed) {
        /* Create answerIndex with an row entry, using existing
         * answer from question.session_answers if it exist.
         */
        let answer;

        if (question.session_answers.length > 0) {
            answer = question.session_answers[0];
            answer.isSet = !!answer.valuerow_id;
        } else {
            answer = answerSeed;
        }
        const rowIndex = {0: answer},
            allOptions = [rowIndex[0]];

        question.answerIndex = {
            rows: rowIndex,
            all: allOptions
        };

        question.rowIndex = 0;

        return question.answerIndex;
    }

    function _prepareMultiRowAnswers(question, answerSeed) {
        /* Create answerIndex with entries for all possible
         * rows, filling in existing answers from
         * question.session_answers if they exist.
         */
        const allOptions = [],
            rowIndex = _reduce(question.session_answers,
                function _indexExistingAnswer(index, answer) {
                    answer.isSet = true;

                    index[answer.valuerow_id] = answer;

                    return index;
                }, {});

        _forEach(question.valuerows, function _indexIfMissing(row) {
            if (!_has(rowIndex, row.valuerow_id)) {
                rowIndex[row.valuerow_id] = _defaults({
                    valuerow_id: row.valuerow_id
                }, answerSeed);
            }
        });

        _forEach(rowIndex, function _collectRow(row) {
            allOptions.push(row);
        });

        question.answerIndex = {
            rows: rowIndex,
            all: allOptions
        };

        question.rowIndex = 0;

        return question.answerIndex;
    }

    function _prepareGridAnswers(question, answerSeed) {
        /* Create answerIndex with entries for all possible
         * rows x columns, filling in existing answers from
         * question.session_answers if they exist.
         */
        const allOptions = [],
            rowIndex = _reduce(question.session_answers,
                function _indexExistingAnswer(index, answer) {
                    answer.isSet = true;

                    if (!_has(index, answer.valuerow_id)) {
                        index[answer.valuerow_id] = {};
                    }

                    index[answer.valuerow_id][answer.valuecolumn_id] =
                        answer;

                    return index;
                }, {});

        _forEach(question.valuerows, function _indexIfMissing(row) {
            if (!_has(rowIndex, row.valuerow_id)) {
                rowIndex[row.valuerow_id] = {};
            }
            const columnIndex = rowIndex[row.valuerow_id];

            _forEach(question.valuecolumns,
                function _completeColumn(column) {
                    if (!_has(columnIndex, column.valuecolumn_id)) {
                        columnIndex[column.valuecolumn_id] =
                            _defaults({
                                valuerow_id: row.valuerow_id,
                                valuecolumn_id: column.valuecolumn_id
                            }, answerSeed);
                    }
                });
        });

        _forEach(rowIndex, function _collectRow(row) {
            _forEach(row, function _collectColumn(column) {
                allOptions.push(column);
            });
        });

        question.answerIndex = {
            rows: rowIndex,
            all: allOptions
        };

        question.rowIndex = 0;

        return question.answerIndex;
    }

    function _pickAndStoreImage(question) {
        upload.pickAndStore(question.s3UploadInfo)
            .then(function _onSuccess(result) {
                const data = result[0].data,
                    row = question.answerIndex.rows[0];

                row.image_id = data.s3_file_id;
                row.image = {url: data.file_url};

                question.updateAnswer();
            });
    }

    function _prepareImageAnswer(question, answerSeed) {
        const promise = question.$$$_page.$$$_questionnaire.actions
            .get_media_upload_info({
                media_type: 'image'
            })
            .then(function _setImageUploadInfo(result) {
                question.s3UploadInfo = result.data;

                question.pickAndStoreImage =
                    _partial(_pickAndStoreImage, question);
            });

        _prepareValueAnswer(question, answerSeed);

        return promise;
    }

    function _getAnswerIDs(orderedAnswers) {
        return _compact(_map(orderedAnswers, 'answer_id'));
    }

    function _getAnswerRowsAndValues(sessionAnswers) {
        const items = {};

        _forEach(sessionAnswers, function _structurePairs(answer) {
            if (!_has(items, answer.valuerow_id)) {
                items[answer.valuerow_id] = {
                    valuecolumn_id: []
                };
            }
            items[answer.valuerow_id]
                .valuecolumn_id.push(answer.valuecolumn_id);
        });
        return items;
    }

    function _handleValueAnswer(answer, answerData, field, ifMissing) {
        if (angular.isDefined(answer)) {
            if (answer.answer_id) {
                answerData.answer_id = [answer.answer_id];
            }
            _assign(answerData, _pick(answer, field));
        } else {
            answerData[field] = ifMissing;
        }
    }

    function _getAnswerData(question) {
        const sessionAnswers = question.session_answers,
            answer = _head(sessionAnswers),
            existingAnswers =
                _filter(sessionAnswers, 'answer_id'),
            newAnswers =
                _reject(sessionAnswers, 'answer_id'),
            orderedAnswers = existingAnswers.concat(newAnswers),
            answerData = _defaults(_pick(question, [
                'question_id',
                'response_time_ms',
                'countdown_s'
            ]), {
                response_time_ms: 0
            });

        switch (question.answerTypeClass) {
            case answerClass.value:
                _handleValueAnswer(answer, answerData, 'value', null);

                if (question.answer_type.name === 'Date Selection' &&
                    !_isString(answerData.value)) {
                    answerData.value =
                        utility.dateTime.toIsoDate(answerData.value);
                }
                break;
            case answerClass.image:
                _handleValueAnswer(answer, answerData, 'image_id', null);
                break;
            case answerClass.singleRow:
                _handleValueAnswer(answer, answerData, 'valuerow_id', null);
                break;
            case answerClass.multiRow:
                answerData.answer_id = _getAnswerIDs(orderedAnswers);
                answerData.valuerow_id =
                    _compact(_map(orderedAnswers, 'valuerow_id'));
                break;
            case answerClass.multiRowSingleColumn:
                answerData.answer_id = _getAnswerIDs(orderedAnswers);
                answerData.valuerow_id =
                    _getAnswerRowsAndValues(sessionAnswers);
                break;
            case answerClass.multiRowMultiColumn:
                answerData.answer_id = _getAnswerIDs(orderedAnswers);
                answerData.valuerow_id =
                    _getAnswerRowsAndValues(sessionAnswers);
                break;
            default:
                _unhandledAnswerTypeClassError(
                    'questionnaireService.getAnswerData', question);
        }

        return answerData;
    }

    function _prepareSessionAnswers(question) {
        const _answerSeed = {
            isSet: false,
            image_id: null,
            image: {
                url: null
            },
            question_id: question.question_id,
            response_time_ms: null,
            value: null,
            valuecolumn_id: null,
            valuerow_id: null
        };

        switch (question.answerTypeClass) {
            case answerClass.value:
                _prepareValueAnswer(question, _answerSeed);
                break;
            case answerClass.image:
                _prepareImageAnswer(question, _answerSeed);
                break;
            case answerClass.singleRow:
                _prepareSingleRowAnswer(question, _answerSeed);
                break;
            case answerClass.multiRow:
            /* Fall-through. */
            // eslint-disable-next-line no-fallthrough
            case answerClass.multiRowSingleColumn:
                _prepareMultiRowAnswers(question, _answerSeed, true);
                break;
            case answerClass.multiRowMultiColumn:
                _prepareGridAnswers(question, _answerSeed);
                break;
            default:
                _unhandledAnswerTypeClassError(
                    'questionnaireService.prepareSessionAnswers', question);
        }

        question.updateAnswer = _partial(_updateAnswer, question);

        const questionIndex =
            question.$$$_page.$$$_questionnaire.questionIndex;

        questionIndex[question.question_id] = question;

        return question;
    }

    const _jitterLimit = 200,  // ms, that is.
        _lastToggledIndex = {};

    function _antiJitterToggle(key, reference) {
        const lastToggled = _lastToggledIndex[key] || 0,
            now = new Date();

        if (now - lastToggled > _jitterLimit) {
            reference.isSet = !reference.isSet;
            _lastToggledIndex[key] = now;
        }
    }

    function _getAnswerTypeName(question) {
        return angular.isDefined(question.answer_type) ?
            question.answer_type.name : question.answer_type_name;
    }

    function _classifyAnswerType(question) {
        const name = _getAnswerTypeName(question),
            key = _findKey(answerTypeClassifications,
                function _containsType(vals) {
                    return _includes(vals, name);
                });
        question.answerTypeClass = answerClass[key];

        if (!question.answerTypeClass) {
            _unhandledAnswerTypeClassError(
                'questionnaireService.classifyAnswerType', question);
        }
    }

    function classifyQuestionType(question) {
        const name = _getAnswerTypeName(question);

        question.isGridQuestion = _includes(gridAnswerTypes, name);

        if (question.isGridQuestion) {
            question.gridQuestionType =
                _includes(iconRackAnswerTypes,
                    question.answer_type.name) ? 'per category' : 'per row';
        }

        question.isSelectQuestion = _includes(selectAnswerTypes, name);
    }

    return {
        classifyQuestionType: classifyQuestionType,
        classifyAnswerType: _classifyAnswerType,
        prepareSessionAnswers: _prepareSessionAnswers,
        getAnswerData: _getAnswerData,
        antiJitterToggle: _antiJitterToggle,
        subStepIsAnswered: _subStepIsAnswered,
        unhandledAnswerTypeClassError: _unhandledAnswerTypeClassError
    };
}

export default angular
    .module('activities.questionnaire.answerService', [])
    .factory('answerService', answerService);
