'use strict';

import angular from 'angular';
import angularRecursion from './angular-recursion.js'
import configConstant from './config.constant.js'
import modernizrConstant from './modernizr.constant.js'
import markdownIt from './markdown-it.constant.js'

export default angular
    .module('lib', [
        angularRecursion.name,
        configConstant.name,
        markdownIt.name,
        modernizrConstant.name
    ]);
