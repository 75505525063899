'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _includes from 'lodash/includes'
import _head from 'lodash/head'
import _remove from 'lodash/remove'
import _isFunction from 'lodash/isFunction'


/* @ngInject */
function activeFiltersService($rootScope, $log, storage, utility,
                              userSignals, selectionService, gettext) {
    const _updatedSignal = 'active-filters:updated',
        _defaultSelectionsName = 'selectionsName',
        _defaultSelectionsNameModifier = 'selectionsNameModifier',
        _defaultSelectionsIdName = 'selectionsIdName',
        _defaultSetOperation = {
            panels: 'union',
            activities: 'union',
            queries: 'intersect',
            selections: null
        },
        _filters = {
            panels: [],
            activities: [],
            queries: [],
            selections: {},
            setOperation: {
                panels: _newSetOperation(_defaultSetOperation.panels),
                activities: _newSetOperation(_defaultSetOperation.activities),
                queries: _newSetOperation(_defaultSetOperation.queries)
            }
        };

    function _storeActiveFilters() {
        let admin = storage.admin.getItem();

        if (admin === null) {
            admin = {};
        }

        admin.activeFilters = _filters;

        storage.admin.setItem(admin);
    }

    function _initSetOperations(name, activeFilters) {
        if (angular.isUndefined(_filters.setOperation)) {
            _filters.setOperation = {};
        }
        _filters.setOperation[name] =
            angular.isDefined(activeFilters.setOperation) &&
            angular.isDefined(activeFilters.setOperation[name]) ?
                activeFilters.setOperation[name] :
                _newSetOperation(_defaultSetOperation[name]);
    }

    function _loadStoredFilters() {
        const admin = storage.admin.getItem();

        if (admin !== null && angular.isDefined(admin.activeFilters)) {
            const activeFilters = admin.activeFilters;
            _forEach(['panels', 'activities', 'queries'],
                function _initFilter(name) {
                    utility.updateSharedArray(_filters[name],
                        activeFilters[name]);
                    _initSetOperations(name, activeFilters);
                });
        }
    }

    function _signalUpdate(payload) {
        // $log.debug('$emit(' + _updatedSignal + ')', payload);
        $rootScope.$emit(_updatedSignal, payload);
    }

    function _triggerUpdate(payload) {
        _storeActiveFilters();
        _signalUpdate(payload);
    }

    function _getFilteredFilters(collectionName, idName, activeOnly) {
        const items = activeOnly ?
            _filter(_filters[collectionName], ['enabled', true]) :
            _filters[collectionName];

        return _map(items, idName);
    }

    function _getAllFilters() {
        return {
            activities: _getFilteredFilters('activities', 'activity_id', false),
            panels: _getFilteredFilters('panels', 'panel_id', false),
            queries: _getFilteredFilters('queries', 'query_id', false)
        };
    }

    function _getAllActivePanelFilters() {
        return _getFilteredFilters('panels', 'panel_id', true);
    }

    function _getSelectedItems(selectionName, rowIdPath, ignoreFilterEnabled) {
        const filter = _getSelectionsFilter(selectionName, rowIdPath);

        return (filter.enabled || !!ignoreFilterEnabled) ? {
            exclude: selectionService.isInExcludeMode(filter.collection),
            ids: _map(selectionService.getActiveArray(filter.collection),
                rowIdPath),
            columnDefs: filter.collection.columnDefs,
            extraFilters: filter.collection.extraFilters
        } : {
            exclude: null,
            ids: null,
            columnDefs: filter.collection.columnDefs,
            extraFilters: filter.collection.extraFilters
        };
    }

    function _getQueryConstraints(selectionName, rowIdPath, ignoreFilterEnabled) {
        const filter = {
            activities: _getFilteredFilters('activities', 'activity_id', true),
            panels: _getFilteredFilters('panels', 'panel_id', true),
            queries: _getFilteredFilters('queries', 'query_id', true)
        };

        if (angular.isDefined(selectionName)) {
            filter.items = _getSelectedItems(selectionName, rowIdPath,
                ignoreFilterEnabled);
        }

        return filter;
    }

    function _addFilter(filter, typeName, collectionName, idName) {
        if (!_includes(_getFilteredFilters(collectionName, idName, false),
                filter[idName])) {
            filter.type = typeName;
            filter.enabled = true;
            _filters[collectionName].push(filter);

            _triggerUpdate({
                action: 'add',
                filter: filter
            });
        } else {
            $log.warn('Filter for ' + typeName + ' #' + filter[idName] +
                ' is already present.  Ignoring.');
        }
    }

    function _addActivity(activity) {
        _addFilter(activity, 'activity', 'activities', 'activity_id');
    }

    function _addPanel(panel) {
        _addFilter(panel, 'panel', 'panels', 'panel_id');
    }

    function _addQuery(query) {
        _addFilter(query, 'query', 'queries', 'query_id');
    }

    function _getFilter(id, collectionName, idName) {
        // FIXME: When using _.filter and accessing the first or last result, you should probably use _.find or _.findLast, respectively.
        return _head(_filter(_filters[collectionName], [idName, id]));
    }

    function _getActivity(activity_id) {
        return _getFilter(activity_id, 'activities', 'activity_id');
    }

    function _getPanel(panel_id) {
        return _getFilter(panel_id, 'panels', 'panel_id');
    }

    function _getQuery(query_id) {
        return _getFilter(query_id, 'queries', 'query_id');
    }

    function _newSelectionsFilter(name, idName) {
        return {
            type: 'selection',
            name: name,
            idName: idName,
            enabled: false,
            collection: selectionService.getCollection(name, idName)
        };
    }

    function _newSetOperation(operation) {
        return {
            type: 'set-operation',
            name: operation,
            text: {
                intersect: gettext('AND'),
                union: gettext('OR')
            }[operation],
            description: {
                intersect: gettext('Use the intersection of the segments.'),
                union: gettext('Use the union of the segments.')
            }[operation]
        };
    }

    function _toggleSetOperation(name) {
        _filters.setOperation[name] = _newSetOperation({
            intersect: 'union',
            union: 'intersect'
        }[_filters.setOperation[name].name]);

        _triggerUpdate({
            action: 'toggleSetOperation',
            name: name
        });

    }

    function _getSelectionsFilter(name, idName) {
        if (angular.isUndefined(_filters.selections[name])) {
            _filters.selections[name] = _newSelectionsFilter(name, idName);
        }

        return _filters.selections[name];
    }

    function _getStateId(state, name, nameModifier) {
        name = angular.isDefined(name) ? name : _defaultSelectionsName;

        if (angular.isUndefined(nameModifier)) {
            try {
                nameModifier =
                    utility.getParentData(state, _defaultSelectionsNameModifier);
            } catch (ex) {
                if (ex instanceof TypeError) {
                    nameModifier = null;
                } else {
                    throw ex;
                }
            }
        }

        let selectionsName = utility.getParentData(state, name);

        if (_isFunction(nameModifier)) {
            selectionsName = nameModifier(state, selectionsName);
        }

        return selectionsName;
    }

    function _getInheritedSelectionsFilter(state, name, idName, nameModifier) {
        idName = angular.isDefined(idName) ? idName : _defaultSelectionsIdName;

        return _getSelectionsFilter(_getStateId(state, name, nameModifier),
            utility.getParentData(state, idName));
    }

    function _updateSelectionsFilter(name, idName) {
        return _getSelectionsFilter(name, idName);
    }

    function _updateActivityFiltersByPanelId(panelId, isEnabled) {
        const filters = _filter(_filters.activities, ['panel_id', panelId]);

        _forEach(filters, (filter) => {
            filter.enabled = isEnabled;
        });
    }

    function _updateQueryFiltersByPanelId(panelId, isEnabled) {
        _forEach(_filters.queries, (filter) => {
            if (_includes(filter.panel_ids, panelId)) {
                filter.enabled = isEnabled;
            }
        });
    }

    function _toggleFilter(filter) {
        filter.enabled = !filter.enabled;

        switch (filter.type) {
            case 'panel':
                _updateActivityFiltersByPanelId(filter.panel_id, filter.enabled);
                _updateQueryFiltersByPanelId(filter.panel_id, filter.enabled);
                break;
            case 'selection':
                selectionService.adjustToggledFilter(filter);
                break;
            default:
        }

        _triggerUpdate({
            action: 'toggle',
            filter: filter
        });
    }

    function _removeFilter(filter) {
        var updatePayload = {
            action: 'remove',
            filter: filter
        };

        switch (filter.type) {
            case 'activity':
                _remove(_filters.activities, filter);
                _triggerUpdate(updatePayload);
                break;
            case 'panel':
                _remove(_filters.panels, filter);
                _triggerUpdate(updatePayload);
                break;
            case 'query':
                _remove(_filters.queries, filter);
                _triggerUpdate(updatePayload);
                break;
            case 'selection':
                selectionService.safeDeleteFilter(filter, _toggleFilter);

                //delete _filters.selections[filter.name];
                break;
        }
    }

    function _removeSelectionFilter(event, data) {
        const filter = _getSelectionsFilter(data.collection, data.idName);
        _removeFilter(filter);
    }

    function _emptyFilter(name) {
        utility.emptyArray(_filters[name]);
        _filters.setOperation[name] =
            _newSetOperation(_defaultSetOperation[name]);
    }

    function _resetFilters() {
        _forEach(['panels', 'activities', 'queries'], _emptyFilter);
    }

    function _init() {
        _loadStoredFilters();

        $rootScope.$on(selectionService.collectionWasClearedSignal,
            _removeSelectionFilter);
        $rootScope.$on(userSignals.userLogoutSignal, _resetFilters);
    }

    _init();

    return {
        updatedSignal: _updatedSignal,
        filters: _filters,
        addActivity: _addActivity,
        addPanel: _addPanel,
        addQuery: _addQuery,
        emptyFilter: _emptyFilter,
        getActivity: _getActivity,
        getPanel: _getPanel,
        getQuery: _getQuery,
        getAllFilters: _getAllFilters,
        getQueryConstraints: _getQueryConstraints,
        newSetOperation: _newSetOperation,
        removeFilter: _removeFilter,
        toggleFilter: _toggleFilter,
        toggleSetOperation: _toggleSetOperation,
        updateSelectionsFilter: _updateSelectionsFilter,
        getAllActivePanelFilters: _getAllActivePanelFilters,
        getSelectionsFilter: _getSelectionsFilter,
        getStateId: _getStateId,
        getInheritedSelectionsFilter: _getInheritedSelectionsFilter,
        defaultSelectionsName: _defaultSelectionsName,
        defaultSelectionsIdName: _defaultSelectionsIdName
    };
}

export default angular
    .module('admin.activeFilters.activeFiltersService', [])
    .factory('activeFiltersService', activeFiltersService);
