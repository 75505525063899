'use strict';

import angular from 'angular';
import _isUndefined from 'lodash/isUndefined'
import _partial from 'lodash/partial'

/* @ngInject */
function facebook($state, $q, $log, ezfb, api, metaService, trackingService,
                  user, config) {
    function _updateFbUser(data) {
        $log.debug('facebookService:_updateFbUser: data', data);
        return metaService.get()
            .then(function _postUpdateData(meta) {
                return api.postForm(
                    meta.registration.update_fb_user.update_info_url, data)
                    .then(metaService.refresh);
            });
    }

    function _connectFb() {
        ezfb.login(angular.noop, {
            scope: config.facebook.permissions
        })
            .then(_forwardLoginStatusToHandler)
            .then(_partial(_completeLoginOrRegistration, 'welcome.facebook', {}));
    }

    function _forwardLoginStatusToHandler(loginStatus) {
        $log.debug('facebookService:_forwardLoginStatusToHandler: loginStatus',
            loginStatus);
        return metaService.get()
            .then((meta) => api.postJson(
                meta.auth.facebook.login_handler_path, {
                    access_token: loginStatus.authResponse.accessToken,
                    uid: loginStatus.authResponse.userID
                }
            ));
    }

    function _getFacebookProfileData() {
        $log.debug('facebookService:_getFacebookProfileData: ');
        return ezfb.getLoginStatus()
            .then(_forwardLoginStatusToHandler)
            .then(function _returnData(res) {
                return res.data;
            });
    }

    function _completeLoginOrRegistration(registrationState, extraLoginInfo,
                                          result) {
        if (result.data.action === '/registration/accept_fb.json') {
            return $state.go(registrationState);
        } else {
            if (extraLoginInfo.doJoinPanel &&
                !_isUndefined(extraLoginInfo.currentPanelId)) {
                return user.joinPanelOnLogin(extraLoginInfo.currentPanelId)
                    .then((response) => {
                        trackingService
                            .trackPlatform(trackingService.actions.login,
                                'Facebook');
                        return metaService.refresh();
                    });
            } else {
                return metaService.refresh();
            }
        }
    }

    function _loginUnconnected(extraLoginInfo) {
        $log.debug('facebookService:_loginUnconnected: ');
        return ezfb.login(angular.noop, {
            scope: config.facebook.permissions
        })
            .then(_forwardLoginStatusToHandler)
            .then(_partial(_completeLoginOrRegistration,
                'welcome.facebook.modal.onboarding', extraLoginInfo));
    }

    function _loginConnected(loginStatus, extraLoginInfo) {
        $log.debug('facebookService:_loginConnected: ');
        return _forwardLoginStatusToHandler(loginStatus)
            .then(_partial(_completeLoginOrRegistration,
                'welcome.facebook.modal.onboarding', extraLoginInfo));
    }

    function _getLoginFunction(extraLoginInfo) {
        $log.debug('facebookService:_getLoginFunction: ');
        const deferred = $q.defer();

        ezfb.getLoginStatus().then((loginStatus) => {
            deferred.resolve(
                loginStatus.status === 'connected' ?
                    _partial(_loginConnected, loginStatus, extraLoginInfo) :
                    _partial(_loginUnconnected, extraLoginInfo)
            );
        });

        return deferred.promise;
    }

    return {
        getLoginFunction: _getLoginFunction,
        getFacebookProfileData: _getFacebookProfileData,
        connectFb: _connectFb,
        updateFbUser: _updateFbUser
    };
}

export default angular
    .module('components.facebook', [])
    .factory('facebook', facebook);
