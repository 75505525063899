'use strict';

import angular from 'angular';
import userImportHelpHtml from './user-import-help.html'

/* @ngInject */
function userImportHelp() {
    return {
        scope: true,
        replace: true,
        templateUrl: userImportHelpHtml
    };
}

export default angular
    .module('admin.panels.userImportHelp', [])
    .directive('userImportHelp', userImportHelp);
