'use strict';

import angular from 'angular';
import $ from 'jquery';
import stickyCarouselHtml from './sticky-carousel.html'
import _last from 'lodash/last'
import _min from 'lodash/min'


/* @ngInject */
function stickyCarousel() {
    return {
        restrict: 'A',
        scope: {
            offset: '@'
        },
        templateUrl: stickyCarouselHtml,
        link: function _link(scope, element) {
            const offset = parseInt(scope.offset, 10) || 0,
                $window = angular.element(window),
                wrapElement = angular.element(
                    $('#chat').find('> .wrap')),
                marginRight = 8;

            scope.messages = [];

            function _setLayout() {
                element.width(wrapElement.width() - marginRight);
            }

            function _emptyCarousel() {
                let changed = false;

                if (scope.messages) {
//                            $log.debug('sticky-carousel.directive.js:71:_emptyCarousel._emptyCarousel: emptying carousel');
                    scope.messages.pop();
                    scope.currentElement = undefined;
                    element.height(0);

                    changed = true;
                }

                element.addClass('empty');

                return changed;
            }

            function _setCarouselDisplay(above) {
                const lowestAbove = _last(above),
                    needAdjustment = {
                        needAdjustment: true
                    };
                let changed = false;

                if (!scope.messages.length) {
//                            $log.debug('sticky-carousel.directive.js:78:_updateCarousel._updateCarousel: setting message in empty carousel');
                    scope.messages.push(lowestAbove.message);
                    scope.currentElement = lowestAbove.element;

                    changed = needAdjustment;
                } else if (scope.messages[0] !== lowestAbove.message) {
//                            $log.debug('sticky-carousel.directive.js:81:_updateCarousel._updateCarousel: replacing old carousel message');
                    scope.messages[0] = lowestAbove.message;
                    scope.currentElement = lowestAbove.element;

                    changed = needAdjustment;
                }

                if (changed) {
                    element.height(scope.currentElement.height());
                    element.removeClass('empty');
                }

                return changed;
            }

            function _scrollCarousel(above, below, triggerLine) {
                const currentTop = element.css('top');
                let newTop = 0,
                    belowOffset, aboveBottom, spacing;

                if (above.length && below.length) {
                    belowOffset = below[0].element.offset().top;
                    aboveBottom = triggerLine +
                        scope.currentElement.height();
                    spacing = belowOffset - aboveBottom;

                    newTop = _min([spacing, 0]);
                }

                if (currentTop !== newTop) {
                    element.css('top', newTop);
                }
            }

            function _updateCarousel(above, below, triggerLine) {
                const changed = !above.length ?
                    _emptyCarousel() :
                    _setCarouselDisplay(above);

                _scrollCarousel(above, below, triggerLine);

                if (changed) {
                    scope.$digest();

                    if (changed.needAdjustment) {
                        element
                            .find('.attachment .image')
                            .height(scope.currentElement
                                .find('.attachment .image').height());
                    }
                }
            }

            function _check() {
                const triggerLine = $window.scrollTop() + offset,
                    above = [],
                    below = [];
                let item;

                $('#messages').find('.is-stimulus').each(function () {
                    item = {
                        element: angular.element($(this))
                    };

                    item.message = item.element.scope().message;

                    if (triggerLine >= item.element.offset().top) {
                        above.push(item);
                    } else {
                        below.push(item);
                    }
                });

                _updateCarousel(above, below, triggerLine);
            }

            _setLayout();

            $window.on('resize', _setLayout);
            $window.on('scroll', _check);

            scope.$on('$destroy', function () {
                $window.off('scroll', _check);
                $window.off('resize', _setLayout);
            });
        }
    };
}

export default angular
    .module('activities.chat.stickyCarousel', [])
    .directive('stickyCarousel', stickyCarousel);
