'use strict';

import angular from 'angular';

/* @ngInject */
function QuestionnaireNavigatorController($rootScope, $state, $scope, $log,
                                          questionnaireNavigator,
                                          activityService, trackingService) {
    var navigator = questionnaireNavigator;

    // FIXME: We should try to avoid accessing navigator._questionnaireScope.
    function _submit() {
        navigator._questionnaireScope.activity.submit()
            .then(function _postSubmit(response) {

                if (response.data.entry.is_complete) {
                    $log.debug('Completed entry');
                    trackingService.trackContribution(trackingService.actions.complete,
                                                      navigator._questionnaireScope.activity);
                }

                if (!navigator._questionnaireScope.isOnboarding) {
                    activityService.refresh();
                }

                if (navigator._questionnaireScope.doSave) {
                    activityService.removeActivity(
                        navigator._questionnaireScope.activity.activity_id);
                }

                if (!navigator._questionnaireScope.activity.doLoop) {
                    if (navigator._questionnaireScope.redirect) {
                        if (navigator._questionnaireScope.isDiary === 'true') {
                            $state.transitionTo(navigator._questionnaireScope.redirect,
                                $rootScope.currentStateParams, {
                                    reload: true,
                                    inherit: true,
                                    notify: true
                                });
                        } else {
                            $state.go(navigator._questionnaireScope.redirect);
                        }
                    }
                } else {
                    $log.warn('Demo mode.  Looping back.');
                    $state.reload();
                }
            }, function () {
                $log.error(
                    "An error occurred while communicating " +
                    "with the server.");
            });
    }

    $scope.navigator = navigator;
    $scope.submit = _submit;
}

export default angular
    .module('activities.quiestionnaire.QuestionnaireNavigatorController', [])
    .controller('QuestionnaireNavigatorController',
    QuestionnaireNavigatorController);
