'use strict';

// FIXME: Unused module, to be removed?

import angular from 'angular';
import _forEach from 'lodash/forEach'


/* @ngInject */
function animationStyle($log) {
    return {
        restrict: 'A',
        scope: {
            style: '=animationStyle'
        },
        link: function _link(scope, element) {
            function _updateStyle(style) {
                $log.debug('style', style);

                _forEach(style, function _addClass(value, cls) {
                    if (value && !element.hasClass(cls)) {
                        element.addClass(cls);
                    } else if (!value && element.hasClass(cls)) {
                        element.removeClass(cls);
                    }
                });
            }

            scope.$watch('style', _updateStyle);
        }
    };
}

export default angular
    .module('components.animationStyle', [])
    .directive('animationStyle', animationStyle);
