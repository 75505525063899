'use strict';

import angular from 'angular';

/* @ngInject */
function ProfileBoxController($scope, profileService, activityService) {
    $scope.profile = profileService.profile;
    $scope.activityCounts = activityService.activityCounts;
}

export default angular
    .module('profile.profileBoxController', [])
    .controller('ProfileBoxController', ProfileBoxController);
