'use strict';

import angular from 'angular';
import _merge from 'lodash/merge'


/* @ngInject */
function MarketsAdminController($state, gettext, activeFiltersService,
                                   user, utility) {
    const vm = this,
        selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName);

    function _getMarketSampleQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'market_id', true);
        return _merge(user.queryParamsFromFilters(filters), {
            included_entries: vm.includedEntries
        });
    }

    function _goToExport() {
        if (!(vm.selectionsFilter.collection &&
            vm.selectionsFilter.collection.count)) {
            return;
        }

        const marketSampleQueryParams = _getMarketSampleQueryParams();
        $state.go('admin.markets.export-market-data', {
            marketSampleQueryParams: marketSampleQueryParams
        });
    }

    function _activate() {
        vm.tabs = [
            {
                title: gettext('List'),
                icon: 'fa-list',
                state: 'admin.markets.list'
            },
            // {
            //     title: gettext('Stats'),
            //     icon: 'fa-bar-chart',
            //     state: 'admin.markets.stats'
            // },
            // {
            //     title: gettext('Sample'),
            //     icon: 'fa-filter',
            //     state: 'admin.markets.segment',
            //     hideWhenInactive: true
            // },
            {
                title: gettext('Create Market'),
                icon: 'fa-edit',
                state: 'admin.markets.create',
                hideWhenInactive: true
            },
            {
                title: gettext('Edit Market'),
                icon: 'fa-edit',
                state: 'admin.markets.edit',
                hideWhenInactive: true
            },
            {
                title: gettext('Export Market Data'),
                icon: 'fa-table',
                state: 'admin.markets.export-market-data',
                hideWhenInactive: true
            }
        ];

        vm.tabs.activeTab = 0;

        vm.selectionsFilter =
            activeFiltersService.getInheritedSelectionsFilter($state.$current);

        vm.goToExport = _goToExport;
    }

    _activate();
}

export default angular
    .module('admin.markets.MarketsAdminController', [])
    .controller('MarketsAdminController', MarketsAdminController);
