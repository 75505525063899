'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _intersection from 'lodash/intersection'
import _map from 'lodash/map'
import _partial from 'lodash/partial'
import _reduce from 'lodash/reduce'
import _size from 'lodash/size'
import _isEqual from 'lodash/isEqual'
import _union from 'lodash/union'
import _spread from 'lodash/spread'


/* @ngInject */
function segmentPanelService($rootScope, $q, $log, user, activeFiltersService) {
    const _panels = {},
        _updatedSignal = 'populationChanged';

    function _newState() {
        return {
            segments: {},
            queryUserIds: {},
            population: {
                userIds: [],
                isDirty: true,
                isFiltered: false
            }
        };
    }

    function _getState(stateId) {
        if (angular.isUndefined(_panels[stateId])) {
            _panels[stateId] = _newState();
        }

        return _panels[stateId];
    }

    function _refreshSegment(stateId, query, userIds) {
        const state = _getState(stateId);

        state.queryUserIds[query.query_id] = userIds;

        state.segments[query.query_id] = user.query({
            user_ids: userIds,
            query_ids: [query.query_id]
        }).$promise;

        state.segments[query.query_id].then(function _postProcess(response) {
            response.query = query;

            state.population.isDirty = true;
        });

        return state.segments[query.query_id];
    }

    function _get(stateId, query, userIds) {
        const state = _getState(stateId);
        let promise;

        if (angular.isDefined(state.segments[query.query_id]) &&
            _isEqual(userIds, state.queryUserIds[query.query_id])) {
            promise = state.segments[query.query_id];
        } else {
            promise = _refreshSegment(stateId, query, userIds);
        }

        return promise;
    }

    function _extractUserIds(result, segment) {
        if (segment.query.enabled) {
            result.push(_map(segment.users, 'user_id'));
        }
        return result;
    }

    const _operatorMap = {
        union: _union,
        intersect: _intersection
    };

    function _calculatePopulation(setOperation, segments) {
        const enabledFilters = _filter(segments, 'query.enabled');
        let userIds,
            isFiltered;

        if (enabledFilters.length) {
            const userIdSets = _reduce(segments, _extractUserIds, []),
                operator = _operatorMap[setOperation];

            userIds = _spread(operator)(userIdSets);
            isFiltered = true;
        } else {
            userIds = [];
            isFiltered = false;
        }

        return {
            userIds: userIds,
            isFiltered: isFiltered
        };
    }

    function _recalculatePopulation(state) {
        let promise;

        function _unsetDirty(result) {
            state.population.userIds = result.userIds;
            state.population.isDirty = false;
            state.population.isFiltered = result.isFiltered;

            return state.population;
        }

        if (_size(state.segments)) {
            promise = $q.all(state.segments)
                .then(_partial(_calculatePopulation,
                    activeFiltersService.filters.setOperation.queries.name))
                .then(_unsetDirty);
        } else {
            promise = $q.when(_unsetDirty({userIds: [], isFiltered: false}));
        }

        return promise;
    }

    function _refreshPopulation(stateId) {
        return $q.when(_recalculatePopulation(_getState(stateId)));
    }

    function _getPopulation(stateId) {
        const state = _getState(stateId);

        return $q.when(state.population.isDirty ?
            _recalculatePopulation(state) : state.population);
    }

    function _signalPopulationChanged(stateId) {
        $rootScope.$emit(_updatedSignal, {
            stateId: stateId
        });
    }

    function _remove(stateId, query) {
        const state = _getState(stateId);

        delete state.segments[query.query_id];
        state.queryUserIds[query.query_id] = [];
    }

    return {
        get: _get,
        getPopulation: _getPopulation,
        refreshPopulation: _refreshPopulation,
        remove: _remove,
        signalPopulationChanged: _signalPopulationChanged,
        updatedSignal: _updatedSignal
    };
}

export default angular
    .module('components.segmentPanelService', [])
    .factory('segmentPanelService', segmentPanelService);
