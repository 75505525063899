'use strict';

import angular from 'angular';
import FacebookController from './facebook.controller.js'
import welcomeModalStateConstant from '../welcome-modal-state.constant.js'
import onboarding from '../onboarding/onboarding.js'
import eula from '../../components/eula/eula.js'
import facebookHtml from './facebook.html'
import onboardingHtml from './onboarding.html'

/* @ngInject */
function config($stateProvider, getEulaState, welcomeModalState,
                getPrivacyState, getCookiesState, onboardingState) {
    $stateProvider
        .state({
            name: 'welcome.facebook',
            url: '/facebook',
            views: {
                'main-contents@': {
                    controller: 'FacebookController',
                    controllerAs: 'vm',
                    templateUrl: facebookHtml
                }
            },
            secure: false,
            isPopup: false,
            isRegister: true
        })
        .state(getEulaState('welcome.facebook.eula'))
        .state(getPrivacyState('welcome.facebook.privacy', '/privacy/'))
        .state(getCookiesState('welcome.facebook.cookies', '/cookies/'))
        .state(welcomeModalState({name: 'welcome.facebook.modal'}))
        .state(onboardingState({
            name: 'welcome.facebook.modal.onboarding',
            hideCloseButton: true,
            contentsTemplateUrl: onboardingHtml
        }));
}

export default angular
    .module('welcome.facebook', [
        FacebookController.name,
        welcomeModalStateConstant.name,
        onboarding.name,
        eula.name
    ])
    .config(config);
