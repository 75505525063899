'use strict';

import * as angular from 'angular'
import emojiToolkit from 'emoji-toolkit';
import _find from 'lodash/find'
// import _ from 'lodash';
import _flatMap from 'lodash/flatMap'
import _fromPairs from 'lodash/fromPairs'
import _join from 'lodash/join'
import _map from 'lodash/map'
import _reject from 'lodash/reject'
import _sortBy from 'lodash/sortBy'
import _take from 'lodash/take'
import orderedEmoji from '../../../lib/emoji-ordered.json'
import EmojiSearch from '../../../lib/emoji-search/emoji-search.mjs'
import searchIndexNB from '../../../lib/emoji-searchable-nb.json'

/* @ngInject */
function emojiService($log, gettext) {
    const _index = new EmojiSearch();
    const _sortedEmoji = _sortBy(_flatMap(orderedEmoji), ['order']);
    const helpEmojiShortnames = [
        ':point_up:',
        ':point_up_tone1:',
        ':point_up_tone2:',
        ':point_up_tone3:',
        ':point_up_tone4:',
        ':point_up_tone5:'
    ];
    const _diversityRe = /-1f3f([bcdef])/;
    const _diversityToModifier = {b: 1, c: 2, d: 3, e: 4, f: 5};

    function _getModifierOrderOffset(emo) {
        return emo.order -
            _diversityToModifier[_diversityRe.exec(emo.uc_base)[1]];
    }

    function _emojiToHtml(emo, isReactive = true) {
        if (!emo.display) {
            return '';
        }
        const isModifier = emo.category === 'modifier',
            functor = isModifier ? 'setModifier' : 'insertEmoji',
            isDiversity = emo.diversity && !isModifier,
            toneClass = isDiversity ?
                `tone${_diversityToModifier[_diversityRe.exec(emo.uc_base)[1]]}` :
                (emo.diversity_base ? 'no-tone' : '');

        const reactiveAttrs = isReactive ?
            `ng-click="::${functor}('${emo.shortname}', $event)" ng-mouseenter="::onMouseEnter(${emo.order})"` :
            '';
        return `<div class="joypixels joypixels-32-${isDiversity || isModifier ?
            'diversity' :
            emo.category} _${emo.uc_base} ${toneClass}" title="${emo.shortname}" ${reactiveAttrs}>${emojiToolkit.convert(emo.uc_full)}</div>`;
    }

    const _helpEmoji = _join(_map(helpEmojiShortnames, (shortname) =>
        _emojiToHtml(_find(_sortedEmoji,
            ['shortname', shortname]), false)), ' ');

    const _preparedHtml = _prepareEmojiCategories();

    function _getEmojiCategories() {
        return [
            {
                name: 'people',
                description: gettext('Smileys & People'),
                icon: 'fa-user-friends'
            },
            {
                name: 'nature',
                description: gettext('Animals & Nature'),
                icon: 'fa-leaf'
            },
            {
                name: 'food',
                description: gettext('Food & Drink'),
                icon: 'fa-hamburger'
            },
            {
                name: 'activity',
                description: gettext('Activity'),
                icon: 'fa-football-ball'
            },
            {
                name: 'travel',
                description: gettext('Travel & Places'),
                icon: 'fa-plane-departure'
            },
            {
                name: 'objects',
                description: gettext('Objects'),
                icon: 'fa-lightbulb'
            },
            {
                name: 'symbols',
                description: gettext('Symbols'),
                icon: 'fa-heart'
            },
            {
                name: 'flags',
                description: gettext('Flags'),
                icon: 'fa-flag'
            },
        ];
    }

    function _prepareEmojiCategories() {
        const emojiCategories = _getEmojiCategories();
        const emojiIndex = orderedEmoji;

        return _fromPairs(
            _map(emojiCategories, (category) => {
                const categoryName = category.name,
                    snippets = _map(emojiIndex[categoryName],
                        (emo) => _emojiToHtml(emo)),
                    header = `<h4 ng-bind="::'${category.description}' |translate"></h4>`;
                snippets.unshift(header);
                return [categoryName, _join(snippets, ' ')];
            })
        );
    }

    function _get() {
        return orderedEmoji;
    }

    function _activate() {
        _index.import(searchIndexNB.index);
    }

    function _search(query, limit) {
        return _take(_reject(
            _map(_index.search(query), (id) => _sortedEmoji[id]),
            ['category', 'modifier']), limit);
    }

    _activate();

    return {
        get: _get,
        search: _search,
        sortedEmoji: _sortedEmoji,
        i18n: searchIndexNB.i18n,
        helpEmoji: _helpEmoji,
        emojiToHtml: _emojiToHtml,
        getEmojiCategories: _getEmojiCategories,
        getModifierOrderOffset: _getModifierOrderOffset,
        preparedHtml: _preparedHtml,
    }
}

export default angular
    .module('components.emojiService', [])
    .factory('emoji', emojiService);

