'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _isString from 'lodash/isString'


/* @ngInject */
function SetPasswordController($state, $stateParams, $q, profileService,
                               passwordService, setPasswordService, config,
                               user, metaService) {
    const vm = this;

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.errors) {
                _forEach(response.data.errors, (value, name) => {
                    if (angular.isDefined(form[name])) {
                        form[name].$setValidity('serverValidation', false);
                        form[name].$setDirty();
                        form[name].serverValidationMessage = value;
                    }
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        if (status !== null) {
            vm.saveError.password = status;
        }
        return $q.reject(status);
    }

    function _changePassword(form) {
        return profileService.changePassword(vm.changePasswordForm)
            .then(metaService.refresh,
                (response) => _handleSaveFormError(form, response));
    }

    function _activate() {
        vm.organization = config.organization;

        vm.hasOldPassword = undefined;
        vm.showCancelButton = false;

        vm.changePasswordForm = {
            username: undefined,
            old_password: '',
            password1: '',
            password2: ''
        };

        vm.passwordMinLength = passwordService.minLength;

        vm.saveError = {
            password: null
        };

        vm.changePassword = _changePassword;

        metaService.get()
            .then((meta) => {
                vm.currentUserId = meta.user.user_id
            });

        profileService.refreshEditForm()
            .then((profileForm) => {
                vm.hasOldPassword = profileForm.old_password !== null;
                vm.changePasswordForm.username = profileForm.email;
            });

        vm.navigator = setPasswordService;

        const _passwordPageState = {
            form: null,
            fields: vm.changePasswordForm,
            action: _changePassword
        };

        vm.setPasswordForm = (form) => {
            _passwordPageState.form = form;
            _passwordPageState.nextEnabled = !form.$pristine && form.$valid;
        };

        setPasswordService.setPageState('set-password', _passwordPageState);

        setPasswordService.setPageState('social-media-authentication', {
            nextEnabled: true
        });
    }

    _activate();
}

export default angular
    .module('home.setPassword.controller', [])
    .controller('SetPasswordController', SetPasswordController);
