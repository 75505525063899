'use strict';

import angular from 'angular';
import OnboardingController from './onboarding.controller.js'
import questionnaireFooterHtml from '../../activities/questionnaire/questionnaire.footer.html'
import onboardingHtml from './onboarding.html'

function onboardingState(config) {
    config = config || {};

    return {
        name: config.name,
        url: '/onboarding',
        views: {
            'modal-contents@': {
                controller: 'OnboardingController',
                templateUrl: config.contentsTemplateUrl || onboardingHtml
            },
            'modal-footer@': {
                templateUrl: questionnaireFooterHtml,
                controller: 'QuestionnaireNavigatorController'
            }
        },
        onEnter: /* @ngInject */ function _updateModal(coreLayoutService) {
            coreLayoutService.updateModal({
                footer: {
                    visible: coreLayoutService.defaultExcept({all: true}),
                    hidden: coreLayoutService.defaultExcept()
                },
                closeTargetState: 'welcome',
                size: 'large'
            });
        },
        hideCloseButton: config.hideCloseButton || false,
        secure: false,
        isPopup: true,
        isSurvey: true
    };
}

/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state(onboardingState({name: 'welcome.modal.onboarding'}));
}

export default angular
    .module('welcome.onboarding', [
        OnboardingController.name
    ])
    .constant('onboardingState', onboardingState)
    .config(config);
