'use strict';

import angular from 'angular';
import AboutController from './about.controller.js'
import LicensesController from './licenses.controller.js'
import aboutHtml from './about.html'
import cookies from '../components/cookies/cookies.js'
import eula from '../components/eula/eula.js'
import privacy from '../components/privacy/privacy.js'
import licensesHtml from './licenses.html'


/* @ngInject */
function config($stateProvider, getCookiesState, getEulaState, getPrivacyState) {
    $stateProvider
        .state({
            name: 'home.about',
            url: '^/about/',
            views: {
                'main-contents@': {
                    controller: 'AboutController',
                    controllerAs: 'vm',
                    templateUrl: aboutHtml
                }
            },
            secure: true
        })
        .state({
            name: 'home.about.licenses',
            url: 'licenses/',
            views: {
                'main-contents@': {
                    controller: 'LicensesController',
                    controllerAs: 'vm',
                    templateUrl: licensesHtml
                }
            },
            secure: true
        })
        .state(getCookiesState('home.about.cookies'))
        .state(getEulaState('home.about.eula', 'eula'))
        .state(getPrivacyState('home.about.privacy'));
}

export default angular
    .module('about', [
        AboutController.name,
        LicensesController.name,
        cookies.name,
        eula.name,
        privacy.name,
    ])
    .config(config);
