'use strict';

import angular from 'angular';

/* @ngInject */
function ActivityPreviewController($state, $stateParams, api,
                                   panelService) {
    const vm = this;

    function _activate() {
        vm.apiUrl = api.url;
        vm.activity = $stateParams.activity;
        vm.panels = panelService.panels;  // For branding, eg. the EULA.

        vm.closePopup = () => {
            $state.go('^');
        };
    }
    
    _activate();
}

export default angular
    .module('activities.activityList.activityPreviewController', [])
    .controller('ActivityPreviewController', ActivityPreviewController);
