'use strict';

import angular from 'angular';
import EditMarketsController from './edit-market.controller.js';
import MarketsController from './markets.controller.js';
import marketsList from './markets-list.directive.js';
import marketsWrapperHtml from './markets-wrapper.html'
import marketsHtml from './markets.html'
import editMarketHtml from './edit-market.html'
import panelModalHeaderHtml from '../../components/modal/panel.modal.header.html'
import deleteMarketsHtml from './delete-markets.html'
import marketsFilterHtml from './markets-filter.html'
import marketsStatsHtml from './markets-stats.html'

/* @ngInject */
function config($stateProvider, gettext) {
    /*
     Let Angular resolve and inject meta value.
     */
    var metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state('admin.markets', {
            abstract: true,
            url: 'markets/',
            views: {
                'main-contents@': {
                    templateUrl: marketsWrapperHtml,
                    controller: 'MarketsAdminController',
                    controllerAs: 'vm'
                }
            },
            secure: true,
            data: {
                selectionsName: 'admin.markets',
                selectionsIdName: 'market_id'
            },
            resolve: {
                meta: metaResolver
            }
        })
        .state('admin.markets.list', {
            url: 'list/',
            views: {
                'markets': {
                    templateUrl: marketsHtml
                }
            },
            secure: true
        })
        .state('admin.markets.create', {
            url: 'create/',
            views: {
                'markets': {
                    templateUrl: editMarketHtml,
                    controller: 'EditMarketController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state('admin.markets.edit', {
            url: ':marketId/edit/',
            views: {
                'markets': {
                    templateUrl: editMarketHtml,
                    controller: 'EditMarketController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        // .state('admin.markets.export-market-data', {
        //     url: 'export-market-data/',
        //     views: {
        //         'markets': {
        //             templateUrl: 'views/admin/markets/export-market-data.html',
        //             controller: 'ExportMarketDataController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     params: {
        //         marketsampleQueryParams: null
        //     },
        //     secure: true
        // })
        .state('admin.markets.list.delete', {
            url: 'delete/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: deleteMarketsHtml,
                    controller: 'DeleteMarketsController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.markets.list'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Danger Zone'),
            panelMode: 'danger',
            hideCloseButton: false,
            secure: true
        })
        .state('admin.markets.segment', {
            url: 'segment/:queryId',
            views: {
                'markets': {
                    templateUrl: marketsFilterHtml
                }
            },
            secure: true
        })
        .state('admin.markets.stats', {
            url: 'stats/',
            views: {
                'markets': {
                    templateUrl: marketsStatsHtml,
                    controller: 'MarketsStatsController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        });

}

export default angular
    .module('admin.markets', [
        EditMarketsController.name,
        MarketsController.name,
        marketsList.name
    ])
    .config(config);
