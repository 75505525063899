'use strict';

import angular from 'angular'
import _capitalize from 'lodash/capitalize'
import _find from 'lodash/find'
import _flatMap from 'lodash/flatMap'
import _forEach from 'lodash/forEach'
import _head from 'lodash/head'
import _join from 'lodash/join'
import _map from 'lodash/map'
import _range from 'lodash/range'
import _throttle from 'lodash/throttle'

import emojiEditorHtml from './emoji-editor.html'
import emojiService from './emoji-service.factory'

const NBSP = '&nbsp;',
    _modifierToneClass = {
        ':tone0:': 'show-tone0',
        ':tone1:': 'show-tone1',
        ':tone2:': 'show-tone2',
        ':tone3:': 'show-tone3',
        ':tone4:': 'show-tone4',
        ':tone5:': 'show-tone5',
    },
    _emojiSearchLimit = 250,
    DEFAULT_MODIFIER = ':tone0:',
    onMouseMoveThrottleMs = 200;

/* @ngInject */
function emojiEditor($rootScope, $q, $compile, $log, storage, emoji, gettext) {
    // let _contents;

    function _searchEmoji(query) {
        const start = window.performance.now(),
            emos = emoji.search(query, _emojiSearchLimit),
            end = window.performance.now();

        $log.debug(`Found ${emos.length} emojis for "${query}" in ${Number(end - start)} ms.`);

        return emos;
    }

    function _link(scope, element) {
        let _contents;

        const emojiCategories = emoji.getEmojiCategories(),
            _status = {
                currentCategory: _head(emojiCategories).name,
                currentDescription: _head(emojiCategories).description,
                currentModifier: null,
                currentToneClass: null
            },
            _paletteElement = element.find('.emoji-palette'),
            _searchPaletteElement = element.find('.emoji-search-palette'),
            _previewElement = element.find('.emoji-preview');

        function _switchCategory(categoryName) {
            if (categoryName !== null) {
                scope.status.currentCategory = categoryName;
                scope.status.currentDescription = _find(emojiCategories,
                    ['name', categoryName]).description;
                _paletteElement.html($compile(emoji.preparedHtml[categoryName])(scope));
            } else {
                _paletteElement.html('');
            }
        }

        function _insertIntoElement(element, value) {
            if (document.selection) {
                element.focus();
                const sel = document.selection.createRange();
                sel.text = value;
            } else if (element.selectionStart || element.selectionStart === 0) {
                const startPos = element.selectionStart,
                    endPos = element.selectionEnd,
                    scrollTop = element.scrollTop;
                element.value = `${element.value.substring(0, startPos)}${value}${element.value.substring(endPos, element.value.length)}`;

                element.selectionStart = startPos + value.length;
                element.selectionEnd = startPos + value.length;
                element.scrollTop = scrollTop;
            } else {
                element.value += value;
            }
            element.focus();
        }

        function _insertEmoji(shortname, $event) {
            $event.preventDefault();
            $event.stopPropagation();
            const element = _contents[_contents.textAreaMode].element;
            _insertIntoElement(element, shortname);
            _contents.editable.body = element.value;
        }

        function _setCurrentModifier(modifier) {
            _status.currentModifier = modifier;
            _status.currentToneClass = _modifierToneClass[_status.currentModifier];

        }

        function _setModifier(modifier) {
            if (_status.toneMenuIsOpen) {
                _setCurrentModifier(modifier);
                _saveModifier(modifier);
                _status.toneMenuIsOpen = false;
            } else {
                _status.toneMenuIsOpen = true;
            }

        }

        function _onMouseEnter(order) {
            if (scope.currentHoverShortCode !== order) {
                scope.currentHoverShortCode = order;
                const emo = emoji.sortedEmoji[order],
                    base = emo.diversity ?
                        emoji.sortedEmoji[emoji.getModifierOrderOffset(emo)] :
                        emo,
                    current = {
                        emo,
                        i18n: emoji.i18n[base.shortname],
                    },
                    snippet = emoji.emojiToHtml(current.emo, false);

                _previewElement.html($compile(snippet)(scope));

                scope.currentEmoji = current;
                scope.currentEmojiShortname = emo.shortname;

                if (angular.isDefined(current.i18n)) {
                    scope.currentEmojiTitle =
                        _capitalize(current.i18n.i18nShortname);
                    scope.currentEmojiKeywords =
                        _join(current.i18n.i18nKeywords, ' · ');
                } else {
                    scope.currentEmojiTitle =
                        _capitalize(_join(current.emo.shortname
                            .replace(/^:?(.*):$/g, '$1')
                            .split(/[_]/g), ' '));
                    scope.currentEmojiKeywords = NBSP;
                }
            }
        }

        function _onMouseLeave() {
            _previewElement.html($compile(emoji.helpEmoji)(scope));
            scope.currentEmoji = null;

            scope.currentEmojiTitle = NBSP;
            scope.currentEmojiShortname = NBSP;
            scope.currentEmojiKeywords = NBSP;
        }

        const _searchHeader = `<h4 ng-bind="::'Search Results' |translate"></h4>`;

        function _updateSearch(query) {
            const results = _searchEmoji(query);

            const resultsWithSkinTones = _flatMap(results, (emo) => {
                const alternatives = [emo];
                if (emo.diversity_base) {
                    _forEach(_range(emo.order, emo.order + 6), (j) => {
                        const alt = emoji.sortedEmoji[j];
                        if (alt.diversity && alt.uc_base.startsWith(emo.uc_base)) {
                            alternatives.push(alt);
                        }
                    });
                }
                return alternatives;
            });

            const snippets = resultsWithSkinTones.length ?
                _map(resultsWithSkinTones, (emo) => emoji.emojiToHtml(emo)) :
                [];
            snippets.unshift(_searchHeader);
            _searchPaletteElement.html($compile(_join(snippets, ' '))(scope));
            scope.searchResults = resultsWithSkinTones.length > 0;
        }

        function _generateHelpEmoji() {
            _previewElement.html($compile(emoji.helpEmoji)(scope));
            scope.helpTitle = gettext('Pick an emoji');
            scope.helpBody = gettext('Skin color:')
        }

        function _loadEmojiMemory() {
            let emojiMemory = storage.emoji.getItem();

            if (emojiMemory === null) {
                emojiMemory = {
                    modifier: DEFAULT_MODIFIER,
                    lru: [],
                };
            }
            return emojiMemory;
        }

        function _saveEmojiMemory(emojiMemory) {
            storage.emoji.setItem(emojiMemory);
        }

        function _loadModifier() {
            return _loadEmojiMemory().modifier;
        }

        function _saveModifier(modifier) {
            const emojiMemory = _loadEmojiMemory();

            emojiMemory.modifier = modifier;

            _saveEmojiMemory(emojiMemory);
        }

        function _onOpen() {
            // console.log('Got open signal!');
            _switchCategory(_status.currentCategory);
        }

        function _onClose() {
             setTimeout(() => _switchCategory(null), 350);  // Transition = 300 ms + 50 ms.
        }

        function _init() {
            // $log.debug('emoji-editor.directive._init: scope', scope);
            _contents = scope.contents;
            // scope.editMode = scope.$parent.editMode;

            _setCurrentModifier(_loadModifier());
            _status.toneMenuIsOpen = false;

            scope.searchQuery = undefined;
            scope.$watch('searchQuery', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    _updateSearch(newValue);
                }
            });

            _generateHelpEmoji();

            scope.currentEmojiTitle = NBSP;
            scope.currentEmojiShortname = NBSP;
            scope.currentEmojiKeywords = NBSP;

            scope.searchResults = false;
            scope.onMouseEnter = _throttle(_onMouseEnter, onMouseMoveThrottleMs);
            scope.onMouseLeave = _throttle(_onMouseLeave, onMouseMoveThrottleMs);
            scope.setModifier = _setModifier;
            scope.insertEmoji = _insertEmoji;
            scope.switchCategory = _switchCategory;
            scope.emojiCategories = emojiCategories;

            scope.status = _status;

            const listenerDestroyers = [
                $rootScope.$on('emojiMenu::Open', _onOpen),
                $rootScope.$on('emojiMenu::Close', _onClose),
            ];
            scope.$on('$destroy', function _destroyListeners() {
                _forEach(listenerDestroyers, (destroyer) => destroyer());
            });
            // _switchCategory(_status.currentCategory);
        }

        _init();
    }

    return {
        restrict: 'AE',
        scope: {
            contents: '=',
            message: '=',
            style: '=messageStyle',
        },
        templateUrl: emojiEditorHtml,
        link: _link
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.emojiEditor', [
        emojiService.name
    ])
    .directive('emojiEditor', emojiEditor);
