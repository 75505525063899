'use strict';

import angular from 'angular';

/* @ngInject */
function fileread() {
    function _link(scope, element) {
        function _fileInputChanged(changeEvent) {
            scope.$apply(function _updateFileInputValue() {
                scope.fileread = changeEvent.target.files[0];
                /* ... or all selected files:
                 scope.fileread = changeEvent.target.files;
                 */
            });
        }

        element.on('change', _fileInputChanged);
        element.on('$destroy', function _removeListener() {
            element.off('change', _fileInputChanged);
        });
    }

    return {
        scope: {
            fileread: "="
        },
        link: _link
    };
}

export default angular
    .module('components.fileread', [])
    .directive('fileread', fileread);
