'use strict';

import angular from 'angular';
import dateSelectionAnswerHtml from './date-selection-answer.html'

/* @ngInject */
function dateSelectionAnswer(config, utility, Modernizr) {
    return {
        restrict: 'AE',
        scope: {
            question: '='
        },
        templateUrl: dateSelectionAnswerHtml,
        link: function _link(scope) {
            scope.today = utility.dateTime.todayAsIsoDate();
            scope.browser = {
                supportsInputTypeDate: Modernizr.inputtypes.date &&
                config.dateInputType === 'date'
            };
        }
    };
}

export default angular
    .module('activities.questionnaire.answerType.dateSelectionAnswer', [])
    .directive('dateSelectionAnswer', dateSelectionAnswer);
