'use strict';

import angular from 'angular';
import angularMessages from 'angular-messages'

/* @ngInject */
function UserHistoryController($q, $state, $window, $log, activityService,
                               profileService, countries, api, gettextCatalog,
                               Modernizr, config, profileImageCropperService,
                               trackingService, upload,
                               meta, gettext) {
    const vm = this;

    vm.imageFileInput = null;
    vm.profile = profileService.profile;
    vm.activityCounts = activityService.activityCounts;
    vm.isLookingUp = true;

    // eslint-disable-next-line no-unused-vars
    const i18nStringsForGettextExtraction = [
        // Discriminator names.
        gettext('app'),
        gettext('action'),
        gettext('base survey entry'),
        gettext('chat message'),
        gettext('diary entry'),
        gettext('Facebook Connect'),
        gettext('feed message'),
        gettext('forum message'),
        gettext('reply'),
        gettext('received a reply'),
        gettext('received a vote'),
        gettext('registration'),
        gettext('survey entry'),
        gettext('quick-poll entry'),
        gettext('screener entry'),
        gettext('touch-points entry'),
        gettext('vox pop video'),
        gettext('web'),

        // Action names.
        gettext('answered'),
        gettext('begun'),
        gettext('completed'),
        gettext('created'),
        gettext('deleted'),
        gettext('dismissed'),
        gettext('edited'),
        gettext('finished'),
        gettext('joined'),
        gettext('logged in'),
        gettext('moderated'),
        gettext('participated'),
        gettext('posted'),
        gettext('read'),
        gettext('record'),
        gettext('received a reply to'),
        gettext('received a vote for'),
        gettext('registered'),
        gettext('responded'),
        gettext('replied to'),
        gettext('sent'),
        gettext('tagged'),
        gettext('updated'),
        gettext('visited'),
        gettext('voted'),
        gettext('wrote'),

        // Artifact phrase.
        gettext('a comment'),
        gettext('an entry'),
        gettext('a message'),
        gettext('some questions'),
        gettext('a something'),
    ];


    function _pickAndUpload(response) {
        return upload.pickAndStore(response.data);
    }

    function _setMediaUploadInfo(mediaUploadInfo) {
        vm.mediaUploadInfo = mediaUploadInfo;
    }

    function _trackEditProfileImage() {
        trackingService.trackProfile(trackingService.actions.edit, 'Profile Image');
    }

    function _pickAndStoreImage() {
        _trackEditProfileImage();
        _pickAndUpload(vm.mediaUploadInfo)
            .then(profileImageCropperService.uploadedProfilePicture)
            .then(profileImageCropperService.cropImage);
    }

    function _goBack() {
        $window.history.back();
    }

    function _setUserActions(response) {
        vm.isLookingUp = false;
        vm.userActions = response.actions;
    }

    function _activate() {
        vm.isLookingUp = true;

        profileService.getMediaUploadInfo({media_type: 'image'})
            .then(_setMediaUploadInfo);

        profileService.actions.get({userId: meta.user.user_id}).$promise
            .then(_setUserActions);
    }

    vm.pickAndStoreImage = _pickAndStoreImage;
    vm.cancel = _goBack;

    _activate();
}

export default angular
    .module('profile.userHistoryController', [
        angularMessages
    ])
    .controller('UserHistoryController', UserHistoryController);
