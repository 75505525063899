'use strict';

import angular from 'angular';
import attachmentEditor from './attachment-editor.directive.js'
import attachmentSwitch from './attachment-switch.directive'
import attachmentHtml from './attachment.html'

/* @ngInject */
function attachment() {
    function _link(scope) {
        function _deleteAttachment() {
            scope.$emit('deleteAttachment', scope.attachment);
        }

        function _showAttachment() {
            scope.$emit('showAttachment', scope.attachment);
        }

        scope.delete = _deleteAttachment;
        scope.openModal = _showAttachment;
    }

    return {
        restrict: 'AE',
        scope: {
            attachment: '=',
            index: '@',
            isEditable: '='
        },
        templateUrl: attachmentHtml,
        link: _link
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.attachment', [
        attachmentEditor.name,
        attachmentSwitch.name
    ])
    .directive('attachment', attachment);
