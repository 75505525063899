'use strict';

import angular from 'angular';
import welcomePresentationHtml from './welcome-presentation.html'

/* @ngInject */
function welcomePresentation() {
    function _link(scope) {
        function _calculatePages(tutorial) {
            if (tutorial) {
                scope.columnClasses = {
                    'col-1-1': tutorial.pages.length === 1,
                    'col-1-2': tutorial.pages.length === 2,
                    'col-1-3': tutorial.pages.length === 3,
                    'col-1-4': tutorial.pages.length === 4,
                    'col-1-5': tutorial.pages.length === 5,
                    'col-1-6': tutorial.pages.length === 6
                };
            }
        }
        scope.$watch('::tutorial', _calculatePages);
    }

    return {
        scope: false,
        replace: true,
        link: _link,
        templateUrl: welcomePresentationHtml
    };
}

export default angular
    .module('welcome.welcomePresentation', [])
    .directive('welcomePresentation', welcomePresentation);
