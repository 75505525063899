'use strict';

import angular from 'angular';
import attachmentSwitchHtml from './attachment-switch.html'
// import _find from 'lodash/find'
// import _map from 'lodash/map'

export const attachmentLoadedEvent = 'real-time:attachment:loaded';

/* @ngInject */
function attachmentSwitch($rootScope, $stateParams, $log, realTimeEngine) {
    function _link(scope, element) {
        // eslint-disable-next-line lodash/prefer-lodash-method
        const image = element.find('img');
        // $log.debug('attachment-switch.directive._link: image', image);

        if (image.lenght > 0) {
            image.one('load', () => {
                const engine = realTimeEngine.getEngine(realTimeEngine.stateParamsToKey($stateParams));
                scope.$emit(attachmentLoadedEvent, scope.attachment, engine.status.isAtBottom);
            });
        }

        // eslint-disable-next-line lodash/prefer-lodash-method
        const video = element.find('video');

        if (video.length > 0) {
            video[0].crossOrigin = "anonymous";  // Ask for blanket CORS permission «without any extra info».

            // const overlay = _find(
            //     _map(element.children('div').children('div'), angular.element),
            //     (child) => child.hasClass('video-overlay')
            // );

            const _generateOverlay = ($event) => {
                const {
                    videoWidth,
                    videoHeight
                } = $event.target;

                const canvas = angular.element('<canvas></canvas>');
                const ctx = canvas[0].getContext('2d');
                canvas[0].width = videoWidth;
                canvas[0].height = videoHeight;
                // Take the content of the video frame and place in canvas.
                ctx.drawImage(video[0], 0, 0, videoWidth, videoHeight);
                const videoPoster = canvas[0].toDataURL('image/png');

                video[0].setAttribute('poster', videoPoster);
                video.css({
                    width: `${videoWidth}px`,
                    // height: `${height}px`
                });
            };

            const _seekedListener = ($event) => {
                _generateOverlay($event);
                const engine = realTimeEngine.getEngine(realTimeEngine.stateParamsToKey($stateParams));
                scope.$emit(attachmentLoadedEvent, scope.attachment, engine.status.isAtBottom);
            };

            video.one('loadeddata', ($event) => {
                video.one('seeked', _seekedListener);
                // Seems to be necessary to trigger on _all_ videos, not just some.
                $event.target.currentTime = 1;
                // Seems necessary to get the _first_ frame.
                $event.target.currentTime = 0;
            });

            video.one('loadedmetadata', ($event) => {
                // This listener seems necessary to properly show a poster image on iOS (iPhone XR). 
                $event.target.load();
            });
        }


    }

    return {
        scope: {
            attachment: '=attachmentSwitch',
            isEditable: '=',
            deleteAttachment: '&'
        },
        templateUrl: attachmentSwitchHtml,
        link: _link,
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.attachmentSwitch', [])
    .directive('attachmentSwitch', attachmentSwitch);
