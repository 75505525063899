'use strict';

import angular from 'angular';
import 'chart.js';
import 'angular-chart.js';
import chartCanvasHtml from './chart-canvas.html'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'


/* @ngInject */
function isVerifiedDistribution($timeout, ChartJs, config) {
    const noColor = config.colors['no-0.5'],
        yesColor = config.colors['yes-0.5'];

    function _createDataSet(values) {
        const colors = [noColor, yesColor];
        return {
            label: 'Verified users',
            data: values,
            backgroundColor: colors,
            borderColor: colors
        };
    }

    function _link(scope, element) {
        let ctx, chart;

        function _updateStats(stats) {
            if (angular.isDefined(stats)) {
                const series = stats.is_verified_groups,
                    values = _map(series, 'value');

                chart.data.labels = _map(series, 'name');

                chart.resize();

                if (chart.data.datasets.length) {
                    _forEach(values, function _update(value, i) {
                        chart.data.datasets[0].data[i] = value;
                    });
                } else {
                    chart.data.datasets = [_createDataSet(values)];
                    chart.resize();
                }
                chart.update();
            }
        }

        function _init() {
            ctx = element.find('#chart-' + scope.$id);
            chart = new ChartJs.Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: [
                        'Verified',
                        'Unverified'
                    ],
                    datasets: [_createDataSet([0, 0])]
                },
                options: {}
            });

            scope.$watch('stats', _updateStats);
        }

        $timeout(_init, 0);
    }

    return {
        scope: {
            stats: '=isVerifiedDistribution'
        },
        link: _link,
        templateUrl: chartCanvasHtml
    };
}

export default angular
    .module('admin.users.isVerifiedDistribution', [
        'chart.js'
    ])
    .directive('isVerifiedDistribution', isVerifiedDistribution);
