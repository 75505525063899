'use strict';
import * as angular from 'angular';
import { QueryBracket } from './query-node/query-bracket';
import { QueryConstraint } from './query-node/query-constraint';
import { QueryNodeDirective } from './query-node/query-node.directive';
import { PublishedToPanelDirective } from './published-to-panel.directive';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
var queryEditorHtml = require('./query-editor.html');
var Query = /** @class */ (function () {
    function Query(options) {
        var params = options || {};
        this.query_id = params.query_id;
        this.name = params.name;
        this.description = params.description;
        this.comment = params.comment;
        this.extra = params.extra;
        this.is_published = params.is_published;
        this.is_global = params.is_global;
        this.discarded = params.discarded;
        this.children = params.children || [];
    }
    return Query;
}());
/* @ngInject */
function queryEditor($rootScope, $state, $log, segmentService, activeFiltersService, answerService, queryQuestionService, gettext, utility) {
    function _addMeta(node, meta) {
        node.meta = angular.isDefined(meta) ? meta : {
            root: node
        };
        _forEach(node.children, function _propagateMeta(child) {
            _addMeta(child, node.meta);
        });
        return node;
    }
    /* @ngInject */
    function _controller($scope) {
        var self = this;
        function _updatePublishedInPanels(response) {
            self.publishedInPanels = response.panels;
            $scope.publishedInPanels = response.panels;
        }
        function _savePublishingDomains(query_id, panel_ids) {
            segmentService.panels.save({
                query_id: query_id,
                panel_ids: panel_ids
            }).$promise.then(_updatePublishedInPanels);
        }
        self.savePublishingDomains = _savePublishingDomains;
        function _getPublishingDomains(query_id) {
            segmentService.panels.query({
                queryId: query_id
            }).$promise.then(_updatePublishedInPanels);
        }
        self.getPublishingDomains = _getPublishingDomains;
    }
    function _link(scope, element, attrs, controller) {
        function _updateEditHistory(node) {
            segmentService.edits({ queryId: scope.node.query_id }).$promise
                .then(function _assignEdits(result) {
                scope.edits = result.edits;
            });
            return node;
        }
        function _signalUpdatedQuery(node) {
            $log.debug('$emit(' + segmentService.queryWasUpdatedSignal + ')');
            $rootScope.$emit(segmentService.queryWasUpdatedSignal);
            return node;
        }
        function _assignQueryNode(node) {
            if (!$state.includes('.', { queryId: node.query_id })) {
                $state.go('.', { queryId: node.query_id });
            }
            else {
                scope.node = _addMeta(node);
            }
            return node;
        }
        function _addToFilters(node) {
            var existing = activeFiltersService.getQuery(node.query_id);
            if (angular.isDefined(existing)) {
                existing.name = node.name;
            }
            else {
                activeFiltersService.addQuery(segmentService.trimQuery(node));
            }
            return node;
        }
        function _addOperatorsMenu(node) {
            if (node.question) {
                answerService.classifyAnswerType(node.question);
                switch (node.question.answerTypeClass) {
                    case 'image':
                        node.operator_names = [];
                        break;
                    case 'value':
                        node.operator_names = [
                            {
                                data: { value: '==' },
                                description: gettext('equals')
                            }, {
                                data: { value: '!=' },
                                description: gettext('does not equal')
                            }, {
                                data: { value: '<' },
                                description: gettext('is less than')
                            }, {
                                data: { value: '<=' },
                                description: gettext('is less than or equals')
                            }, {
                                data: { value: '>' },
                                description: gettext('is greater than')
                            }, {
                                data: { value: '>=' },
                                description: gettext('is greater than or equals')
                            }
                        ];
                        if (node.question.answer_type_name === 'Date Selection') {
                            node.operator_names = node.operator_names.concat([
                                {
                                    data: { value: 'a <= x <= b' },
                                    description: gettext('is between')
                                }, {
                                    data: { value: '! (a <= x <= b)' },
                                    description: gettext('is not between')
                                }
                            ]);
                        }
                        break;
                    case 'icon-rack':
                    /* Fall-through. */
                    case 'single-row':
                    /* Fall-through. */
                    case 'multi-row':
                    /* Fall-through. */
                    case 'multi-row, single-column':
                    /* Fall-through. */
                    case 'multi-row, multi-column':
                        node.operator_names = [{
                                data: { value: 'any of' },
                                description: gettext('match any of')
                            }, {
                                data: { value: 'all of' },
                                description: gettext('match all of')
                            }, {
                                data: { value: '! any of' },
                                description: gettext('do not match any of')
                            }, {
                                data: { value: '! all of' },
                                description: gettext('do not match all of')
                            }];
                        break;
                    default:
                        answerService.unhandledAnswerTypeClassError('queryEditor._addOperatorsMenu', node.question);
                }
            }
            if (node.operator_names.length) {
                var operators = _map(node.operator_names, 'data.value');
                if (!_includes(operators, node.operator)) {
                    node.operator = operators[0];
                }
            }
            else {
                node.operator = null;
            }
        }
        function _addMenus(node) {
            if (node.discriminator === 'query_constraint') {
                _addOperatorsMenu(node);
            }
            _forEach(node.children, function _propagateMeta(child) {
                _addMenus(child);
            });
            return node;
        }
        function _savePublishingDomainsFromCurrent(node) {
            controller.savePublishingDomains(node.query_id, activeFiltersService.getAllActivePanelFilters());
        }
        function _getPublishedInPanels(node) {
            controller.getPublishingDomains(node.query_id);
        }
        function _updatePublishedState(node, doSave) {
            if (doSave === void 0) { doSave = true; }
            var selected = _filter(node.publishedStates, ['value', node.is_published]);
            node.publishedStateText = selected.length ?
                selected[0].text : gettext('in an unknown state');
            selected = _filter(node.publishedSubStates, ['value', node.is_global]);
            node.publishedSubStateText = selected.length ?
                selected[0].text : gettext('in an unknown state');
            if (doSave) {
                _updateCurrentQuery()
                    .then(_savePublishingDomainsFromCurrent);
            }
            else {
                _getPublishedInPanels(node);
            }
        }
        function _handlePublishingState(node) {
            node.publishedStates = [
                { value: false, text: gettext('private') },
                { value: true, text: gettext('published') },
            ];
            node.publishedSubStates = [
                { value: false, text: gettext('in panels') },
                { value: true, text: gettext('globally') },
            ];
            _updatePublishedState(node, false);
            return node;
        }
        function _propagateCasting(node) {
            if (node.discriminator === 'query_constraint') {
                var constraint = node;
                if (constraint.question) {
                    constraint.question.cast = constraint.cast;
                    constraint.question.humaneCast
                        = queryQuestionService.humaneCasts[constraint.cast];
                }
            }
            _forEach(node.children, function _propagate(child) {
                _propagateCasting(child);
            });
            return node;
        }
        function _processArrivedQuery($promise) {
            return $promise
                .then(_propagateCasting)
                .then(_addToFilters)
                .then(_addMenus)
                .then(_handlePublishingState)
                .then(_assignQueryNode)
                .then(_signalUpdatedQuery)
                .then(_updateEditHistory);
        }
        function _updateCurrentQuery() {
            return _processArrivedQuery(segmentService.updateQuery(scope.node).$promise);
        }
        function _init() {
            if (scope.queryId) {
                _processArrivedQuery(segmentService.get({ queryId: scope.queryId }).$promise);
            }
            else {
                scope.node = _addMeta(new Query({
                    children: [new QueryBracket({
                            children: [new QueryConstraint()]
                        })]
                }));
            }
            scope.updateQuery = _updateCurrentQuery;
            scope.updatePublishedState = _updatePublishedState;
            scope.onPanelSelect = function _onPanelSelect(item) {
                //$log.debug('query-editor.directive._onPanelSelect: item', item);
                var panelIds = _map(scope.publishedInPanels, 'panel_id');
                panelIds.push(item.panel_id);
                controller.savePublishingDomains(scope.queryId, panelIds);
            };
            var listenerDestroyers = [
                $rootScope.$on(segmentService.updateRequestSignal, _updateCurrentQuery)
            ];
            scope.$on('$destroy', function _destroyListeners() {
                _forEach(listenerDestroyers, utility.call);
            });
        }
        _init();
    }
    return {
        controller: _controller,
        link: _link,
        scope: {
            queryId: '=queryEditor'
        },
        templateUrl: queryEditorHtml
    };
}
export var QueryEditorDirective = angular
    .module('components.query.queryEditor', [
    QueryNodeDirective.name,
    PublishedToPanelDirective.name,
])
    .directive('queryEditor', queryEditor);
