'use strict';

import angular from 'angular';
import countrySelectionAnswerHtml from './country-selection-answer.html'

/* @ngInject */
function countrySelectionAnswer() {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: countrySelectionAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.countrySelectionAnswer', [])
    .directive('countrySelectionAnswer', countrySelectionAnswer);
