'use strict';

import angular from 'angular';
import EditPanelController from './edit-panel.controller.js';
import DeleteImportUsersController from './delete-import-users.controller.js';
import ImportUsersAddMappingController
    from './import-users-add-mapping.controller';
import ImportUsersEditController from './import-users-edit.controller.js';
import ImportUsersController from './import-users.controller.js';
import PanelsController from './panels.controller.js';
import panelsList from './panels-list.directive.js';
import panelsWrapperHtml from './panels-wrapper.html'
import panelsHtml from './panels.html'
import editPanelHtml from './edit-panel.html'
import panelModalHeaderHtml
    from '../../components/modal/panel.modal.header.html'
import deletePanelsHtml from './delete-panels.html'
import importUsersHtml from './import-users.html'
import importUsersEditHtml from './import-users-edit.html'
import importUsersAddMappingHtml from './import-users-add-mapping.html'
import reUploadSpreadsheetHtml from './re-upload-spreadsheet.html'
import ReUploadSpreadsheetController from './re-upload-spreadsheet.controller';
import showMatchingRowsHtml from './show-matching-rows.html'
import ShowMatchingRowsController from './show-matching-rows.controller'

import deleteImportUsersHtml from './delete-import-users.html'
import panelsFilterHtml from './panels-filter.html'
import panelsStatsHtml from './panels-stats.html'

/* @ngInject */
function config($stateProvider, gettext) {
    /*
     Let Angular resolve and inject meta value.
     */
    var metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state('admin.panels', {
            abstract: true,
            url: 'panels/',
            views: {
                'main-contents@': {
                    templateUrl: panelsWrapperHtml,
                    controller: 'PanelsAdminController',
                    controllerAs: 'vm'
                }
            },
            secure: true,
            data: {
                selectionsName: 'admin.panels',
                selectionsIdName: 'panel_id'
            },
            resolve: {
                meta: metaResolver
            }
        })
        .state('admin.panels.list', {
            url: 'list/',
            views: {
                'panels': {
                    templateUrl: panelsHtml
                }
            },
            secure: true
        })
        .state('admin.panels.create', {
            url: 'create/',
            views: {
                'panels': {
                    templateUrl: editPanelHtml,
                    controller: 'EditPanelController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state('admin.panels.edit', {
            url: ':panelId/edit/',
            views: {
                'panels': {
                    templateUrl: editPanelHtml,
                    controller: 'EditPanelController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        // .state('admin.panels.export-panel-data', {
        //     url: 'export-panel-data/',
        //     views: {
        //         'panels': {
        //             templateUrl: 'views/admin/panels/export-panel-data.html',
        //             controller: 'ExportPanelDataController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     params: {
        //         panelsampleQueryParams: null
        //     },
        //     secure: true
        // })
        .state('admin.panels.list.delete', {
            url: 'delete/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: deletePanelsHtml,
                    controller: 'DeletePanelsController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.panels.list'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Danger Zone'),
            panelMode: 'danger',
            hideCloseButton: false,
            secure: true
        })
        .state({
            name: 'admin.panels.user_imports',
            abstract: true,
            url: ':panelId/user_imports/',
            views: {
                'main-contents@': {
                    templateUrl: panelsWrapperHtml,
                    controller: 'PanelsAdminController',
                    controllerAs: 'vm'
                }
            },
            secure: true,
            data: {
                selectionsName: 'admin.panels',
                selectionsIdName: 'panel_id'
            },
            resolve: {
                meta: metaResolver
            }
        })
        .state({
            name: 'admin.panels.user_imports.create',
            url: 'create/',
            views: {
                'panels': {
                    templateUrl: importUsersHtml,
                    controller: 'ImportUsersController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state({
            name: 'admin.panels.user_imports.edit',
            url: ':userDataImportId/edit/',
            views: {
                'panels': {
                    templateUrl: importUsersEditHtml,
                    controller: 'EditImportUsersController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state({
            name: 'admin.panels.user_imports.edit.add_mapping',
            url: 'add_mapping/:externalColumnId/',
            params: {
                externalColumnId: undefined,
                mappedColumns: undefined,
                externalColumns: undefined,
                callback: undefined,
            },
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: importUsersAddMappingHtml,
                    controller: 'ImportUsersAddMappingController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.panels.user_imports.edit'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Add Mapping'),
            panelMode: 'default',
            hideCloseButton: false,
            secure: true
        })
        .state({
            name: 'admin.panels.user_imports.edit.replace_spreadsheet',
            url: 'replace_spreadsheet/',
            params: {
                mappedColumns: undefined,
                externalColumns: undefined
            },
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: reUploadSpreadsheetHtml,
                    controller: 'ReUploadSpreadsheetController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.panels.user_imports.edit'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Re-upload Spreadsheet (CSV)'),
            panelMode: 'default',
            hideCloseButton: false,
            secure: true
        })
        .state({
            name: 'admin.panels.user_imports.edit.matching_rows',
            url: ':externalColumnId/matching_rows/',
            params: {
                attribute: undefined,
                externalColumns: undefined
            },
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: showMatchingRowsHtml,
                    controller: 'ShowMatchingRowsController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.panels.user_imports.edit'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Matching Rows'),
            panelMode: 'default',
            hideCloseButton: false,
            secure: true
        })
        .state({
            name: 'admin.panels.user_imports.delete',
            url: ':userDataImportId/delete/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: deleteImportUsersHtml,
                    controller: 'DeleteImportUsersController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.panels.user_imports.create'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Danger Zone'),
            panelMode: 'danger',
            hideCloseButton: false,
            secure: true
        })
        .state('admin.panels.segment', {
            url: 'segment/:queryId',
            views: {
                'panels': {
                    templateUrl: panelsFilterHtml
                }
            },
            secure: true
        })
        .state('admin.panels.stats', {
            url: 'stats/',
            views: {
                'panels': {
                    templateUrl: panelsStatsHtml,
                    controller: 'PanelsStatsController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        });

}

export default angular
    .module('admin.panels', [
        EditPanelController.name,
        DeleteImportUsersController.name,
        ImportUsersAddMappingController.name,
        ImportUsersEditController.name,
        ImportUsersController.name,
        PanelsController.name,
        ReUploadSpreadsheetController.name,
        panelsList.name,
        ShowMatchingRowsController.name,
    ])
    .config(config);
