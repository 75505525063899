'use strict';

import angular from 'angular';
import {PanelAdminServiceFactory} from '../panels/panel-service.factory';
import targetPanelsListHtml from './target-panels-list.html'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'


/* @ngInject */
function targetPanelsList($state, $log, activeFiltersService, gridUtils, gettext,
                          gettextCatalog, utility, panelAdmin, editMemberships,
                          user) {
    const ADD_REMOVE_COLUMN_WIDTH = 160,
        columnDefs = [
            {
                field: 'panel_id',
                headerName: '<input type="checkbox" class="ag-selection-checkbox" indeterminate-checkbox="selections.isAllIndicator" on-change="selections.allChanged">',
                headerClass: 'check-all',
                suppressResize: true,
                width: 25,
                checkboxSelection: true,
                valueGetter: gridUtils.nullValue,
                suppressMenu: true,
                suppressSorting: true,
                headerTooltip: 'Toggle all',
                compileWithAngular: true
            },
            {
                headerName: gettext('Panel ID'),
                field: 'panel_id',
                filter: 'number',
                width: 110,
                hide: false
            },
            {
                headerName: gettext('Name'),
                field: 'name',
                filter: 'text',
                width: 164,
                sort: 'asc'
            },
            {
                headerName: gettext('Internal Name'),
                field: 'internal_name',
                filter: 'text',
                width: 194
            },
            {
                headerName: gettext('Status'),
                field: 'status',
                filter: 'set',
                filterParams: {
                    cellRenderer: _panelStatusRenderer,
                    values: ['open', 'closed']
                },
                cellRenderer: _panelStatusRenderer,
                width: 98
            },
            {
                headerName: gettext('Client ID'), field: 'client_id',
                filter: 'number',
                // width: 95,
                hide: true
                // rowGroupIndex: 1
            },
            {
                headerName: gettext('Client'), field: 'client_name',
                filter: 'text',
                width: 146
                // rowGroupIndex: 1
            },
            {
                headerName: gettext('Created Date'), field: 'created',
                filter: 'text',
                width: 93,
                hide: true
            },
            {
                headerName: gettext('Users To Be Removed'),
                field: 'matching_member_ids',
                colId: 'matching_member_ids',
                cellRenderer: _partial(_actionColumnRenderer, 'remove'),
                width: ADD_REMOVE_COLUMN_WIDTH,
                hide: !editMemberships.removeMembers,
                suppressMenu: true,
                suppressSorting: true
            },
            {
                headerName: gettext('Users To Be Added'),
                field: 'not_matching_member_ids',
                colId: 'not_matching_member_ids',
                cellRenderer: _partial(_actionColumnRenderer, 'add'),
                width: ADD_REMOVE_COLUMN_WIDTH,
                hide: editMemberships.removeMembers,
                suppressMenu: true,
                suppressSorting: true
            }
        ];

    function _actionColumnRenderer(action, params) {
        if (angular.isDefined(params.value)) {
            const panel = {
                id: params.data.panel_id,
                name: params.data.name,
                internalName: params.data.internal_name,
            }, panelJson = angular.toJson(panel).replace(/"/g, "'");

            return `<a ui-sref="admin.users.edit-memberships.changes({userIds: [${params.value.join(',')}], action: '${action}', panel: ${panelJson}})">${params.value.length}</a>`;
        } else {
            return '';
        }
    }

    var _humaneStatusText = {
        open: gettext('Open'),
        closed: gettext('Closed')
    };

    function _panelStatusRenderer(params) {
        return _humaneStatusText[angular.isDefined(params.data) ?
            params.data.status : params.value] || '';
    }

    function _activateGrid(scope) {
        const selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName),
            selectionIdName = utility.getParentData($state.$current,
                activeFiltersService.defaultSelectionsIdName),
            rowIdPath = 'panel_id';

        const ops = gridUtils.getGridOperations({
            useVirtualPaging: true,
            columnDefs: columnDefs,
            gridOptions: {},
            muteQueryParams: [
                'activity_ids',
                'query_ids'
            ],
            selectionName: selectionName,
            selectionIdName: selectionIdName,
            rowIdPath: rowIdPath,
            resource: panelAdmin,
            resourceExtraColumns: [
                'name',
                'status',
                'client_id',
                'client_name',
                // 'market_id',
                // 'market_name',
                // 'description',
                'matching_member_ids',
                'not_matching_member_ids',
                'created'
            ],
            resourceExtraQueryParams: {
                'status': ['open', 'closed'],
                'user_ids': scope.userIds,
            },
            resourceResponseCollection: 'panels',
            storageName: 'targetPanelsList',
            scope: scope,
            afterGridInit: function _afterGridInit(service) {
                scope.$on('$destroy',
                    scope.$watch('editMemberships.removeMembers', (doRemoveMembers) => {
                        const columnApi = service.gridOptions.columnApi;

                        columnApi.setColumnVisible('matching_member_ids', doRemoveMembers);
                        columnApi.setColumnVisible('not_matching_member_ids', !doRemoveMembers);
                    }));
            }
        });

        scope.toggleToolPanelVisibility = ops.toggleToolPanelVisibility;
        scope.showSql = false;

        scope.gridOptions = ops.gridOptions;

    }

    function _getQueryParams(selectionName) {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'user_id', true);

        return _merge(user.queryParamsFromFilters(filters), {
            extra_columns: [
                'email_address'
            ],
            sql: false
        });
    }

    function _link(scope) {
        const selectionName = 'admin.users';

        scope.editMemberships = editMemberships;

        user.query(_getQueryParams(selectionName)).$promise
            .then(function _updateRows(response) {
                scope.total = response.total;
                scope.users = response.users;
                scope.userIds = _map(response.users, 'user_id');

                _activateGrid(scope);
            });
    }

    return {
        scope: true,
        replace: true,
        link: _link,
        /**
         * The ng-if="gridOptions" in the template element is there to avoid
         * initializing `ag-grid` until the options have been defined.
         */
        templateUrl: targetPanelsListHtml
    };
}

export default angular
    .module('admin.panels.targetPanelsList', [
        'agGrid',
        PanelAdminServiceFactory.name
    ])
    .directive('targetPanelsList', targetPanelsList);
