'use strict';

import angular from 'angular';
import tmpl from './query/match.tpl.html';
import _fill from 'lodash/fill'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _partial from 'lodash/partial'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import _zipObject from 'lodash/zipObject'


/* @ngInject */
function formService($q, $templateCache) {
    function _patchUISelect() {
        $templateCache.put('bootstrap/match.tpl.html',
            $templateCache.get(tmpl));
    }

    function _populateFormMasterData(_master, response, _subFormNames,
                                     _fieldKeys) {
        _forEach(_subFormNames, function _initSubForm(name) {
            if (_includes(_fieldKeys[name], 'isList')) {
                _master[name] = response[name];
            } else {
                _master[name] = _pick(response[name], _fieldKeys[name]);
            }
        });
    }

    function _attributeIsSubmittable(definitions, blacklist, value, key) {
        return !_includes(blacklist, key) &&
            _has(definitions, key) &&
            _includes(definitions[key].mode, 'w');
    }

    function _makeSafeCopy(data, definitions, blacklist) {
        blacklist = angular.isDefined(blacklist) ? blacklist : [];

        let safeCopy;
        if (definitions.isList && _includes(definitions.mode, 'w') &&
            angular.isDefined(definitions.itemDef)) {
            safeCopy = _map(data, function _copyItem(item) {
                return _pickBy(item,
                    _partial(_attributeIsSubmittable, definitions.itemDef,
                        blacklist));
            });
        } else {
            safeCopy = _pickBy(data,
                _partial(_attributeIsSubmittable, definitions, blacklist));
        }

        return safeCopy;
    }

    function _clearFormError(errorField, form) {
        errorField[form.$name] = undefined;
    }

    function _coreResetForm(form, name, vm, master) {
        if (angular.isUndefined(name)) {
            name = form.$name;
        }

        if (angular.isDefined(form)) {
            form.$setPristine();
            form.$setUntouched();
        }

        vm[name] = angular.copy(master[name]);
    }

    function _createEmptyObject(keys) {
        return _zipObject(keys, _fill(new Array(keys.length), null));
    }

    function _handleSaveFormError(errorField, form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.fields) {
                _forEach(response.data.fields, function _setErrors(field) {
                    form[field.form_field].$setValidity(field.error, false);
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        errorField[form.$name] = status;

        return $q.reject();
    }

    function _generateFieldKeys(fieldKeys, fields, key) {
        fieldKeys[key] = _keys(fields);
        return fieldKeys;
    }

    function _generateEmtpyMasterData(master, fields, key) {
        master[key] = {};
        return master;
    }

    function _getUniqueTempId() {
        // Returns timestamp in ms.
        return Math.floor(
            window.performance &&
            window.performance.now &&
            window.performance.timing &&
            window.performance.timing.navigationStart ?
                window.performance.now() +
                window.performance.timing.navigationStart : Date.now());
    }

    return {
        clearFormError: _clearFormError,
        coreResetForm: _coreResetForm,
        createEmptyObject: _createEmptyObject,
        generateEmtpyMasterData: _generateEmtpyMasterData,
        generateFieldKeys: _generateFieldKeys,
        getUniqueTempId: _getUniqueTempId,
        handleSaveFormError: _handleSaveFormError,
        patchUISelect: _patchUISelect,
        populateFormMasterData: _populateFormMasterData,
        makeSafeCopy: _makeSafeCopy
    };
}

export default angular
    .module('components.formService', [])
    .factory('formService', formService);
