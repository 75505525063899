'use strict';

import angular from 'angular';

import checkBoxGridAnswer from './check-box-grid-answer.directive.js';
import countrySelectionAnswer from './country-selection-answer.directive.js';
import dateSelectionAnswer from './date-selection-answer.directive.js';
import foursquareVenueAnswer from './foursquare-venue-answer.directive.js';
import iconRackAnswer from './icon-rack-answer.directive.js';
import multipleSelectAnswer from './multiple-select-answer.directive.js';
import phoneNumberAnswer from './phone-number-answer.directive.js';
import radioButtonGridAnswer from './radio-button-grid-answer.directive.js';
import scaleGridAnswer from './scale-grid-answer.directive.js';
import singleSelectAnswer from './single-select-answer.directive.js';
import singleSelectIconRackAnswer from './single-select-icon-rack-answer.directive.js';
import smileyAnswer from './smiley-answer.directive.js';
import timezoneAnswer from './timezone-answer.directive.js';
import uploadedImageAnswer from './uploaded-image-answer.directive.js';
import yesNoAnswer from './yes-no-answer.directive.js';

export default angular
    .module('activities.questionnaire.questionnaireQuestion.answerType', [
        checkBoxGridAnswer.name,
        countrySelectionAnswer.name,
        dateSelectionAnswer.name,
        foursquareVenueAnswer.name,
        iconRackAnswer.name,
        multipleSelectAnswer.name,
        phoneNumberAnswer.name,
        radioButtonGridAnswer.name,
        scaleGridAnswer.name,
        singleSelectAnswer.name,
        singleSelectIconRackAnswer.name,
        smileyAnswer.name,
        timezoneAnswer.name,
        uploadedImageAnswer.name,
        yesNoAnswer.name
    ]);
