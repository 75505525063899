'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _keyBy from 'lodash/keyBy'
import _map from 'lodash/map'
import _partial from 'lodash/partial'
import _reduce from 'lodash/reduce'


/* @ngInject */
function countriesService($rootScope, $q, api, utility, metaService) {
    const _allowedKinds = ['locations', 'dialingCodes'],
        _allowedCombos = _map(utility.cartesian(_allowedKinds, [true, false]),
            _comboToKey),
        _countries = _reduce(_allowedCombos, _initKindCollection, {}),
        _countryIndex = _reduce(_allowedCombos, _initKindIndex, {}),
        _deferreds = _reduce(_allowedCombos, _initKindDeferred, {});

    function _initKindCollection(result, key) {
        result[key] = [];
        return result;
    }

    function _initKindIndex(result, key) {
        result[key] = {};
        return result;
    }

    function _initKindDeferred(result, key) {
        result[key] = null;
        return result;
    }

    function _updateCountryCodes(kind, key, result) {
        utility.updateSharedArray(_countries[key],
            (kind === 'locations') ?
                result.data.country_codes : result.data.country_dialing_codes);

        utility.updateSharedObject(_countryIndex[key],
            _keyBy(_countries[key],
                (kind === 'locations') ? 'value' : 'country_code'));

        return _countries[key];
    }

    function _getCombo(kind, constrained) {
        kind = angular.isDefined(kind) ? kind : 'locations';
        constrained = angular.isDefined(constrained) ? constrained : false;

        return [kind, constrained];
    }

    function _comboToKey(combo) {
        return combo.join(':');
    }

    function _refresh(kind, isConstrained, key) {
        const urlField = (kind === 'locations') ?
            'country_codes_url' : 'phone_number_country_codes_url';

        key = angular.isDefined(key) ?
            key : _comboToKey(_getCombo(kind, isConstrained));

        if (!_includes(_allowedCombos, key)) {
            throw 'countriesService.refresh(): invalid key: ' + key;
        }

        const deferred = _deferreds[key] = $q.defer(),
            updateHandler = _partial(_updateCountryCodes, kind, key);

        metaService.get().then(function _getCountries(meta) {
            api.get(meta.registration.create_user[urlField] +
                    '?constrained=' + isConstrained)
                .then(function _resolveDeferred(result) {
                    deferred.resolve(updateHandler(result));

                    _deferreds[key] = null;
                });
        });

        return deferred.promise;
    }

    function _keyToCombo(key) {
        const parts = key.split(':');
        return {
            kind: parts[0],
            isConstrained: parts[1] === 'true'
        };
    }

    function _refreshExisting() {
        const promises = [];

        _forEach(_countries, function _refreshCollection(collection, key) {
            if (collection.length) {
                const combo = _keyToCombo(key);

                promises.push(
                    _service.refresh(combo.kind, combo.isConstrained, key));
            }
        });

        return $q.all(promises);
    }

    function _get(kind, isConstrained, key) {
        key = angular.isDefined(key) ?
            key : _comboToKey(_getCombo(kind, isConstrained));

        return _deferreds[key] !== null ?
            _deferreds[key].promise : _service.refresh(kind, isConstrained, key);
    }

    function _getIndex(kind, isConstrained) {
        const key = _comboToKey(_getCombo(kind, isConstrained)),
            deferred = $q.defer();

        _service.get(kind, isConstrained, key).then(function _returnIndex() {
            deferred.resolve(_countryIndex[key]);
        });

        return deferred.promise;
    }

    const _service = {
        refresh: _refresh,
        refreshExisting: _refreshExisting,
        get: _get,
        getIndex: _getIndex
    };

    $rootScope.$on(metaService.currentLanguageIsUpdatedSignal,
        _refreshExisting);

    return _service;
}

export default angular
    .module('components.countriesService', [])
    .factory('countries', countriesService);
