'use strict';

import _filter from '../../../../node_modules/lodash/filter.js'
import _forEach from '../../../../node_modules/lodash/forEach.js'
import _includes from '../../../../node_modules/lodash/includes.js'
import _intersection from '../../../../node_modules/lodash/intersection.js'
import _map from '../../../../node_modules/lodash/map.js'
import _sortedIndexBy from '../../../../node_modules/lodash/sortedIndexBy.js'

export default class EmojiSearch {
    constructor() {
        this.index = [];
        this.cleanupRe = /[^0-9A-Za-zÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿ*✓×+\-−÷?!〒♪&%コ有指得割無禁可申合空営満\s]/g;
        this.splitRe = /[\s-]+/;
    }

    _normalizeString(str) {
        return _filter(str
            .toLowerCase()
            .replace(this.cleanupRe, '')
            .split(this.splitRe), (part) => (part !== ''));
    }

    export() {
        return this.index;
    }

    import(indexData) {
        this.index = indexData;
        return this;
    }

    static insertSorted(items, value) {
        if (!_includes(items, value)) {
            items.splice(_sortedIndexBy(items, value), 0, value);
        }
    }

    add(id, keyword) {
        const keys = this._normalizeString(keyword);

        _forEach(keys, (key) => {
            const i = _sortedIndexBy(this.index, [key], (tuple) => tuple[0]);
            if ((i < this.index.length) && (this.index[i][0] === key)) {
                EmojiSearch.insertSorted(this.index[i][1], id);
            } else {
                this.index.splice(i, 0, [key, [id]]);
            }
        });
    }

    _matchNeedle(needle, bounds) {
        const results = [];

        if (bounds.i < bounds.limit) {
            _forEach(this.index, ([key, ids]) => {
                if (key.includes(needle)) {
                    _forEach(ids, (id) => {
                        EmojiSearch.insertSorted(results, id);
                        bounds.i += 1;

                        if (bounds.i === bounds.limit) {
                            return false;
                        }
                    });
                    if (bounds.i === bounds.limit) {
                        return false;
                    }
                }
            });
        }
        return results;
    }

    search(keyword, perWordLimit = 1000) {
        if (keyword === '') {
            return [];
        }
        const needles = this._normalizeString(keyword),
            sets = _map(needles, (needle) =>
                this._matchNeedle(needle, {
                    i: 0,
                    limit: perWordLimit
                }));

        return _intersection(...sets);
    }
}
