'use strict';

import angular from 'angular';
import ActivitiesController from './activities.controller.js';
import activityList from './activity-list/activity-list.js';
import activityPreview from './activity-preview/activity-preview.js';
import activityProgressBar from './activity-progress-bar.directive.js';
import activityStats from './activity-stats/activity-stats.js';
import article from './article/article.js';
import chat from './chat/chat.js';
import checkHTML from './check-html.directive.js';
import diary from './diary/diary.js';
import forum from './forum/forum.js';
import imagePreview from './image-preview.directive.js';
import promo from './promo/promo.js';
import questionnaire from './questionnaire/questionnaire.js';
import realTimeConnectedUsers from './real-time-connected-users.directive.js';
import realTimeFilter from './real-time-filter.directive.js';
import realTimeMenu from './real-time-menu.directive.js';
import realTimeMenuDrawer from './real-time-menu-drawer.controller.js';
import ReportController from './report.controller.js';
import segmentPanel from './segment-panel.directive.js';
import activitiesHtml from './activities.html'

/* @ngInject */
function config($stateProvider, activityPreviewState) {
    $stateProvider
        .state({
            name: 'home.activities',
            url: '^/activities/{filter}',
            views: {
                'main-contents@': {
                    controller: 'ActivitiesController',
                    templateUrl: activitiesHtml
                }
            },
            secure: true
        })
        .state(activityPreviewState('home.activities.activity-preview'));
}

export default angular
    .module('activities', [
        ActivitiesController.name,
        activityList.name,
        activityPreview.name,
        activityProgressBar.name,
        activityStats.name,
        article.name,
        chat.name,
        checkHTML.name,
        diary.name,
        forum.name,
        imagePreview.name,
        promo.name,
        questionnaire.name,
        realTimeConnectedUsers.name,
        realTimeFilter.name,
        realTimeMenu.name,
        realTimeMenuDrawer.name,
        ReportController.name,
        //report.name,
        segmentPanel.name
    ])
    .config(config);
