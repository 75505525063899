'use strict';

import angular from 'angular';

/* @ngInject */
function growBox($rootScope, $timeout) {
    var marginTop = 20,    // px
        padding = 2 * 10,  // px,
        adjustment = marginTop + padding;

    function _link(scope, element) {
        element.addClass('grow-box');

        var question = element.parents('.question'),
            questionBox = question.find('.white-img-container'),
            scrollableArea = question.parents('.scrollable-area');

        function _setHeight() {
            element.height(scrollableArea.outerHeight() -
            (adjustment + questionBox.outerHeight()));
        }

        function _setHeightWrapper() {
            _setHeight();
            $timeout(_setHeight, 0);
        }

        var deregister = $rootScope.$on('refreshGrowBox', _setHeightWrapper);

        scope.$on('$destroy', deregister);

        _setHeightWrapper();
    }

    return {
        link: _link
    };
}

export default angular
    .module('activities.questionnaire.growBox', [])
    .directive('growBox', growBox);
