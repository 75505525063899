'use strict';

import angular from 'angular';
import * as filestack from 'filestack-js';
import $ from 'jquery';
import _map from 'lodash/map'

/* @ngInject */
function upload($http, $q, $rootScope, api, $log, metaService) {
    let _filestackClient;

    function _registerUpload(blob, s3UploadInfo) {
        const deferred = $q.defer();

        $rootScope.$apply(() => {
            $http({
                method: 'POST',
                url: s3UploadInfo.form_values.success_action_redirect,
                data: $.param({
                    bucket: s3UploadInfo.bucket,
                    mime_type: blob.mimetype,
                    /**
                     * Filestack returns a blob.key value which
                     * is _not_ URL encoded, but encoding it here breaks
                     * uploading of profile images.
                     **/
                    key: blob.key,
                    file_size: blob.size
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(deferred.resolve);
        });
        return deferred.promise;
    }

    function _toFilestack(input, s3UploadInfo) {
        const deferred = $q.defer();
        // $log.debug('upload.factory._toFilestack: input', input);
        // $log.debug('upload.factory._toFilestack: s3UploadInfo', s3UploadInfo);

        _filestackClient.upload(input, {
            location: 's3',
            path: s3UploadInfo.directory,
            access: 'public'
        }).then((result) => {
            if (result.status === 'Stored') {
                _registerUpload(result, s3UploadInfo)
                    .then(deferred.resolve);
            } else {
                $log.debug('upload.toFilestack._onFailure: ', result);
                $rootScope.$apply(function () {
                    deferred.reject(result);
                });
            }
        });

        return deferred.promise;
    }

    function _pickAndStore(s3UploadInfo, customText=undefined) {
        const deferred = $q.defer();

        const accepted = {
            image: ['image/*'],
            video: ['video/*, image/mov', '.mov', '.mp4'],
        }[s3UploadInfo.media_type];

        _filestackClient.picker({
            accept: accepted,
            customText: customText,
            lang: metaService.getCurrentLanguageCode(),
            disableTransformer: true,
            storeTo: {
                location: 's3',
                path: s3UploadInfo.directory,
                access: 'public'
            },
            onUploadDone: (result) => {
                if (result.filesFailed.length) {
                    $log.warn('upload.pickAndStore._onFailure: result', result);

                    $rootScope.$apply(() => {
                        deferred.reject(result);
                    });
                } else {
                    const deferreds = _map(result.filesUploaded,
                        (blob) => _registerUpload(blob, s3UploadInfo));
                    $q.all(deferreds).then(deferred.resolve);
                }
            }
        }).open();

        return deferred.promise;
    }

    function _getProfileURL(userId) {
        return api.url + '/profile/' + encodeURIComponent(userId);
    }

    function _uploadOrigProfilePicture(file, user_id) {
        const deferred = $q.defer(),
            formData = new FormData();

        formData.append('user_id', user_id);
        formData.append('user_file', file);

        $http({
            method: 'POST',
            url: _getProfileURL(user_id) + '/edit_profile_picture/upload_image/',
            data: formData,
            headers: {'Content-Type': undefined},
            transformRequest: angular.identity
        }).then(deferred.resolve, deferred.reject);

        return deferred.promise;
    }

    function _saveCroppedProfilePicture(data) {
        const deferred = $q.defer();

        $http({
            method: 'POST',
            url: _getProfileURL(data.user_id) +
                '/edit_profile_picture/save_cropped_image.json',
            data: $.param(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            }
        }).then(deferred.resolve, deferred.reject);

        return deferred.promise;
    }

    function _initFilestack(apiKey) {
        // $log.debug('Setting FilePicker API key.');
        _filestackClient = filestack.init(apiKey);
    }

    return {
        initFilestack: _initFilestack,
        toFilestack: _toFilestack,
        pickAndStore: _pickAndStore,
        uploadOrigProfilePicture: _uploadOrigProfilePicture,
        saveCroppedProfilePicture: _saveCroppedProfilePicture
    };
}

export default angular
    .module('components.upload', [])
    .factory('upload', upload);
