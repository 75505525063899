'use strict';

import angular from 'angular';

/* @ngInject */
function PrivacyController($log, $state, $stateParams, api, panelService, config, legalModalService) {
    const vm = this;

    function _activate() {
        vm.organization = config.organization;
        vm.lastUpdated = config.legal.privacy.lastUpdated;

        vm.modalLevel = legalModalService.getModalLevel();

        panelService.get()
            .then((panels) => {
                vm.currentPanel = panels.current;
            });
    }

    _activate();
}

export default angular
    .module('components.privacyController', [])
    .controller('PrivacyController', PrivacyController);
