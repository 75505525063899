'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'

function gettext(string) {
    return string;
}

const _groupNames = {
    'candidates': {
        name: gettext('Candidates'),
        description: gettext('Not validated members')
    },
    'members': {
        name: gettext('Members'),
        description: gettext('Members')
    },
    'clients': {
        name: gettext('Clients'),
        description: gettext('May represent clients')
    },
    'moderators': {
        name: gettext('Moderators'),
        description: gettext('May moderate activities')
    },
    'managers': {
        name: gettext('Managers'),
        description: gettext('May manage panels')
    },
    'administrators': {
        name: gettext('Administrators'),
        description: gettext('May administrate markets')
    },
    'owners': {
        name: gettext('Owners'),
        description: gettext('Can do anything')
    },
    'demo-users': {
        name: gettext('Demo Users'),
        description: gettext('Triggers demo behavior')
    }
};

/* @ngInject */
function groupService($log, $resource, $q, api, utility, gettextCatalog) {
    const _groups = [];
    let _groupsDeferred;

    const _groupResource = $resource(api.url + '/api/groups/', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });

    function _updateTranslation() {
        let mapping;

        _forEach(_groups, function _decorateGroup(group) {
            mapping = _groupNames[group.group_name];
            group.name = gettextCatalog.getString(mapping.name);
            group.description = gettextCatalog.getString(mapping.description);
        });
    }

    function _refresh() {
        _groupsDeferred = $q.defer();

        _groupResource.query().$promise
            .then(function _refreshGroups(response) {
                utility.updateSharedArray(_groups, response.groups);
                _updateTranslation();

                _groupsDeferred.resolve(_groups);
            });

        return _groupsDeferred.promise;
    }

    function _get() {
        return angular.isDefined(_groupsDeferred) ?
            _groupsDeferred.promise : _refresh();
    }

    return {
        get: _get,
        updateTranslation: _updateTranslation
    };
}

export default angular
    .module('components.groupService', [])
    .factory('groupService', groupService);
