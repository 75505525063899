'use strict';

import angular from 'angular';
import privacyHeaderHtml from './privacy.header.html'
import privacyHtml from './privacy.html'
import legalFooterHtml from '../legal.footer.html'
import PrivacyController from '../privacy/privacy.controller.js';

/* @ngInject */
function getPrivacyState(name, url) {
    return {
        name: name,
        url: angular.isDefined(url) ? url : 'privacy',
        views: {
            'modal-header@': {
                controller: 'LegalFooterController',
                controllerAs: 'vm',
                templateUrl: privacyHeaderHtml
            },
            'modal-contents@': {
                controller: 'PrivacyController',
                controllerAs: 'vm',
                templateUrl: privacyHtml
            },
            'modal-footer@': {
                controller: 'LegalFooterController',
                controllerAs: 'vm',
                templateUrl: legalFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService, $state$, legalModalService) {
            coreLayoutService.openModal({
                footer: {
                    visible: coreLayoutService.defaultExcept({all: true}),
                    hidden: coreLayoutService.defaultExcept()
                },
                size: 'large'
            });
            legalModalService.onEnter($state$);
        },
        onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: false,
        isRegister: true
    };
}

export default angular
    .module('components.privacy', [
        PrivacyController.name,
    ])
    .constant('getPrivacyState', getPrivacyState);
