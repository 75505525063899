'use strict';

import angular from 'angular';
import profilePointsHtml from './profile-points.html'

/* @ngInject */
function profilePoints(config) {
    function _link(scope) {
        scope.config = config;
    }

    return {
        restrict: 'AE',
        scope: {
            profile: '=profilePoints'
        },
        templateUrl: profilePointsHtml,
        link: _link,
        replace: true
    };
}

export default angular
    .module('profile.profilePoints', [])
    .directive('profilePoints', profilePoints);
