'use strict';

import angular from 'angular';
import _assign from 'lodash/assign'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _includes from 'lodash/includes'
import _keys from 'lodash/keys'
import _last from 'lodash/last'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'


/* @ngInject */
function activityStatsService($resource, $http, $q, $log, api, utility,
                              config) {
    const apiUrl = api.url + '/api/:resource/:action',
        _activityStatuses = {},
        _statsConfigurations = {};
    let _cachedActivities;

    const _activityStatsResource = $resource(apiUrl, {
        resource: 'activity_status'
    }, {
        query: {
            method: 'GET',
            //cache: false,
            isArray: false,
            transformResponse: utility.appendTransform(
                $http.defaults.transformResponse,
                function _getActivityStats(data) {
                    return data;
                }
            )
        }
    });

    function _getConfig(activityId) {
        if (!_has(_statsConfigurations, activityId)) {
            _statsConfigurations[activityId] = {
                includeSupervisors: config.stats.includeSupervisorsByDefault
            };
        }
        return _statsConfigurations[activityId];
    }

    function _calculateUniqueUsers(stat) {
        stat.uniqueUsers = _keys(stat.users).length;
    }

    function _increaseStat(stat, action) {
        if (!_has(stat.users, action.user_id)) {
            stat.users[action.user_id] = 0;

            _calculateUniqueUsers(stat);

            stat.trend.users.push({
                x: action.epoch,
                y: stat.uniqueUsers
            });
        }

        stat.users[action.user_id] += 1;
        stat.total += 1;

        stat.trend.total.push({
            x: action.epoch,
            y: stat.total
        });
    }

    function _decreaseStat(stat, action, decreasePerUser) {
        decreasePerUser = angular.isDefined(decreasePerUser) ?
            decreasePerUser : false;

        if (decreasePerUser && _includes(stat.users, action.user_id)) {
            stat.users[action.user_id] -= 1;
        }
        stat.total -= 1;

        stat.trend.total.push({
            x: action.epoch,
            y: stat.total
        });
    }

    function _addNowToTrend(trend, nowEpoch) {
        trend.push({
            x: nowEpoch,
            y: _last(trend).y
        });
    }

    function _getStatStructure() {
        return {
            total: 0,
            users: {},
            trend: {
                users: [],
                total: []
            },
            sampled: {}
        };
    }

    function _resetStats(stats) {
        stats.contributions = _getStatStructure();
        stats.entries = _getStatStructure();
        stats.visits = _getStatStructure();
    }

    function _prepareActivityStatus(data, activityId) {
        const activity_actions = data.activity_actions[activityId],
            contributions = data.activity_contributions[activityId],
            supervisors = data.supervisors[activityId],
            sampleSize = config.stats.trendLineWidth * 2,
            stats = {
                contributions: _getStatStructure(),
                entries: _getStatStructure(),
                visits: _getStatStructure(),
                participation: data.activity_participation[activityId],
                conf: _getConfig(activityId),
                refresh: _refresh
            };

        function _refresh() {
            const nowEpoch = Math.floor(new Date().getTime() / 1000);

            _resetStats(stats);

            _forEach(stats.participation, function _prepareTrend(stat) {
                stat.trend = [];

                _forEach(stat.users, function _addToTrend(user, i) {
                    if (stats.conf.includeSupervisors ||
                        !_includes(supervisors.users, user.user_id)) {
                        stat.trend.push({
                            x: user.epoch || /* activity.createdEpoch + */ i,
                            y: i + 1
                        });
                    }
                });

                if (stat.trend.length) {
                    _addNowToTrend(stat.trend, nowEpoch);
                }

                stat.sampled =
                    utility.sampleUniform(stat.trend, sampleSize, true);
            });

            _forEach(contributions,
                function _prepareContributionsTrend(contribution) {
                    _increaseStat(stats.contributions, contribution);
                });

            _forEach(activity_actions, function _prepareSparkline(action) {
                switch (action.action) {
                    case 'visit': // chat, forum, …
                        if (stats.conf.includeSupervisors ||
                            !_includes(supervisors.users, action.user_id)) {
                            _increaseStat(stats.visits, action);
                        }
                        break;
                    case 'write':  // chat
                    case 'post':   // forum
                    case 'reply':  // forum
                        if (stats.conf.includeSupervisors ||
                            !_includes(supervisors.users, action.user_id)) {
                            _increaseStat(stats.entries, action);
                        }
                        break;
                    case 'delete': // chat, forum
                        if (stats.conf.includeSupervisors ||
                            !_includes(supervisors.users, action.user_id)) {
                            _decreaseStat(stats.entries, action);
                        }
                        break;
                }
            });

            if (config.stats.useContributionsForEntries) {
                stats.entries = stats.contributions;
            }

            _forEach([stats.entries, stats.visits],
                function _handle(stat) {
                    if (angular.isUndefined(stat.uniqueUsers)) {
                        stat.uniqueUsers = 0;
                    }
                    _forEach(stat.trend, function _addNow(trend, name) {
                        if (trend.length) {
                            stat.latestAction = new Date(_last(trend).x * 1000);
                            _addNowToTrend(trend, nowEpoch);
                        }

                        stat.sampled[name] =
                            utility.sampleUniform(trend, sampleSize, true);
                    });

                });

            stats.isGenerated = true;
        }

        stats.refresh();

        return stats;
    }

    function _prepareActivityStatuses(data, deferreds) {
        _forEach(deferreds, function _handleDeferred(deferred, activityId) {
            deferred.resolve(_prepareActivityStatus(data, activityId));
        });
    }

    function _refreshStatus(activityId) {
        const deferred = $q.defer();

        _activityStatsResource.query({
            activity_ids: activityId
        }, function _handleResponse(data) {
            deferred.resolve(_prepareActivityStatus(data, activityId));
        });

        return deferred.promise;
    }

    function _getStatus(activityId) {
        return angular.isDefined(_activityStatuses[activityId]) ?
            _activityStatuses[activityId].promise : _refreshStatus(activityId);
    }

    function _extractPromises(deferreds) {
        return _reduce(deferreds, function _getPromise(result, value, key) {
            result[key] = value.promise;
            return result;
        }, {});
    }

    function _refreshStatuses(activityIds, returnPromise) {
        const deferreds = {};

        returnPromise = angular.isDefined(returnPromise) ?
            returnPromise : true;

        _forEach(activityIds, function _addDeferred(activityId) {
            const deferred = $q.defer();

            _activityStatuses[activityId] = deferred;

            deferreds[activityId] = deferred;
        });

        _activityStatsResource.query({
            activity_ids: activityIds
        }, function _handleResponse(data) {
            _prepareActivityStatuses(data, deferreds);
        });

        return returnPromise ? $q.all(deferreds) : _extractPromises(deferreds);
    }

    function _getStatuses(activityIds) {
        const deferreds = {},
            missing = [];

        _forEach(activityIds, function _getDeferred(activityId) {
            if (angular.isDefined(_activityStatuses[activityId])) {
                deferreds[activityId] = _activityStatuses[activityId].promise;
            } else {
                missing.push(activityId);
            }
        });

        if (missing.length) {
            _assign(deferreds, _refreshStatuses(missing, false));
        }

        return $q.all(deferreds);
    }


    function _prepareActivities(_activities, _filteredActivities) {
        _cachedActivities = _activities;
        const supervisedActivities = _filter(_activities, {
                isSupervisedByCurrentUser: true
            }),
            /**
             * If necessary, retrieve/refresh activityStats for first
             * config.stats.initialPreloadLimit activities.
             **/
            topActivities = _filter(_filteredActivities, {
                isSupervisedByCurrentUser: true
            }).slice(0, config.stats.initialPreloadLimit);

        _forEach(supervisedActivities, function _addStatsEnabler(activity) {
            activity.enableStats = function _enableStats() {
                if (!angular.isDefined(activity.stats)) {
                    _getStatus(activity.activity_id)
                        .then(function _assign(stats) {
                            //$log.debug('assigning stats to', activity);
                            activity.stats = stats;
                        });
                }
            };
        });

        if (config.stats.isEnabled) {
            _getStatuses(_map(topActivities, 'activity_id'))
                .then(function _assignStats(stats) {
                    _forEach(topActivities, function _assignStat(activity) {
                        activity.stats = stats[activity.activity_id];
                    });
                });
        }
    }

    function _initializeVisibleActivities() {
        angular.element('#activities')
            .find('.activity-box:in-viewport')
            .each(function _enableStats(i, element) {
                const activityId = angular.element(element).data('activityId');
                _filter(_cachedActivities, ['activity_id', activityId])[0].enableStats();
            });
    }

    return {
        activityStats: _activityStatsResource,
        refreshStatus: _refreshStatus,
        getStatus: _getStatus,
        refreshStatuses: _refreshStatuses,
        getStatuses: _getStatuses,
        prepareActivities: _prepareActivities,
        initializeVisibleActivities: _initializeVisibleActivities
    };
}

export default angular
    .module('activities.activityStats.service', [])
    .factory('activityStatsService', activityStatsService);
