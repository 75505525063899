'use strict';

import angular from 'angular';
import adjustHeight from './adjust-height.directive.js';
import checkBoxGridAnswerHtml from './check-box-grid-answer.html'

/* @ngInject */
function checkBoxGridAnswer(answerService, iScrollService) {
    function _toggleValue(question, col) {
        var valueRowId = question.valuerows[question.rowIndex].valuerow_id;

        answerService.antiJitterToggle(
            question.question_id +
            ':' + valueRowId + ':' + col.valuecolumn_id,
            question.answerIndex.rows[valueRowId][col.valuecolumn_id]);

        question.updateAnswer();
    }

    function _link(scope) {
        scope.iScrollState = iScrollService.state;

        scope.toggleValue = _toggleValue;

        scope.question.updateAnswer();
    }

    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        link: _link,
        templateUrl: checkBoxGridAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.checkBoxGridAnswer', [
        adjustHeight.name
    ])
    .directive('checkBoxGridAnswer', checkBoxGridAnswer);

