'use strict';

import angular from 'angular';
import _merge from 'lodash/merge'


/* @ngInject */
function typingTracker($resource, $timeout, $log, realTime, api, config) {
    const _resource = $resource(api.url +
        '/api/:activityType/:activityId/:subResource/', {
        activityId: '@activity_id',
        activityType: '@activity_type'
    }, {
        reportTyping: {
            method: 'POST',
            params: {subResource: 'typing'},
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    let _typingTimeout = null;

    function _reportStopped(params, destination) {
        if (config.realTimeMessaging.postTypingStatus) {
            _resource.reportTyping(
                _merge({state: 'stopped'}, params));
        }

        realTime.send(destination, {}, angular.toJson({
            user_id: params.user_id,
            action: 'TYPING_STATUS',
            is_typing: false
        }));

        _typingTimeout = null;
    }

    function _reportStarted(params, destination) {
        if (config.realTimeMessaging.postTypingStatus) {
            _resource.reportTyping(
                _merge({state: 'started'}, params));
        }

        realTime.send(destination, {}, angular.toJson({
            user_id: params.user_id,
            action: 'TYPING_STATUS',
            is_typing: true
        }));
    }

    function _refresh(params, destination) {
        if (_typingTimeout === null) {
            _reportStarted(params, destination);
        } else {
            $timeout.cancel(_typingTimeout);
        }

        _typingTimeout = $timeout(_reportStopped,
            config.realTimeMessaging.typingTimeout, false, params, destination);
    }

    function _stop(params, destination) {
        if (_typingTimeout !== null) {
            $timeout.cancel(_typingTimeout);
        }

        _reportStopped(params, destination);
    }

    return {
        refresh: _refresh,
        stop: _stop
    };
}

export default angular
    .module('components.realTimeEngine.typingTracker', [])
    .factory('typingTracker', typingTracker);
