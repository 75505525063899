'use strict';

import angular from 'angular';
import panelModalHeaderHtml from '../../components/modal/panel.modal.header.html'
import usersFilterHtml from '../../admin/users/users-filter.html'

/* @ngInject */
function editSegmentState(name) {
    return {
        name: name,
        url: 'segment/:queryId',
        views: {
            'modal-header@': {
                templateUrl: panelModalHeaderHtml,
                controller: 'ModalHeaderController'
            },
            'modal-contents@': {
                templateUrl: usersFilterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                header: {
                    visible: coreLayoutService.defaultExcept({all: true})
                },
                footer: {
                    hidden: coreLayoutService.defaultExcept({all: true})
                },
                closeTargetState: '^'
            });
        },
        onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
            coreLayoutService.closeModal({
                closeTargetState: null
            });
        },
        secure: true
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.editSegmentState', [])
    .constant('editSegmentState', editSegmentState);
