'use strict';

import angular from 'angular'
import joypixels from 'emoji-toolkit'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _isMatch from 'lodash/isMatch'
import _isUndefined from 'lodash/isUndefined'
import _map from 'lodash/map'
import _max from 'lodash/max'
import _min from 'lodash/min'
import _partial from 'lodash/partial'
import _pick from 'lodash/pick'
import _replace from 'lodash/replace'
import _some from 'lodash/some'
import raf from 'raf'
// import autosize from 'autosize/dist/autosize';
import autosize from '../../autosize.directive'

// import * as _ from 'lodash'
import emojiEditor from './emoji-editor.directive'
import messageEditorHtml from './message-editor.html'

const KEYCODE_ESCAPE = 27,
    KEYCODE_ENTER = 13,
    // KEYCODE_BACKSPACE = 8,
    // KEYCODE_DELETE = 46,
    // TEXT_AREA_PADDING = 0,
    TEXT_LINE_HEIGHT = 20,
    MIN_EDITOR_TEXT_LINES_CHAT = 2,
    MIN_EDITOR_TEXT_LINES_FORUM = 3;

/* @ngInject */
function messageEditor($rootScope, $window, $document, $stateParams, $timeout,
                       $sce, $log, realTimeEngine, Modernizr, markdownIt) {
    const _validAttachmentFields = [
            'real_time_message_attachment_id',
            'title',
            'description',
            'type',
            'url',
            'thumbnail_url',
            'file_id'
        ],
        _saveStack = [],
        _clickWatchers = {
            emojiMenu: undefined,
            attachmentMenu: undefined
        };
    let _rtContent,
        _rtEditor,
        _mdView,
        _editable,
        _message = null,
        _cachedTextAreaHeight;

    function _determineCurrentAction(scope) {
        let action;

        if (scope.isEdit) {
            action = 'edit_message';
        } else if (_message !== null && _message.id) {
            action = 'add_comment';
        } else {
            action = 'add_message';
        }

        return action;
    }

    function _filterAttachments(attachments) {
        return _map(attachments, function (attachment) {
            return _pick(attachment, _validAttachmentFields);
        });
    }

    const ballDiameter = 50,
        attachmentEditorHeight = ballDiameter + 20,
        emojiEditorTotalHeight = 370,
        _givenHeights = {
            rtEditorInputPadding: (2 * 7/*px*/) + 3/*px*/,
            rtMiscButtonsHeight: 34/*px*/,
            rtTinyHelpTextHeight: 10/*px*/,
            rtEditorChromeIosPadding: 40/*px*/,
            rtMenuPlacholderHeight: _max([attachmentEditorHeight, emojiEditorTotalHeight])
        };

    function getEditorPartHeights() {
        let extraHeight = 0;
        if (!Modernizr.ismobiledevice) {
            extraHeight += _givenHeights.rtTinyHelpTextHeight;
        }

        if (Modernizr.isios && Modernizr.ischrome) {
            extraHeight += _givenHeights.rtEditorChromeIosPadding;
        }
        const rtEditorInputPassive = _givenHeights.rtEditorInputPadding + _givenHeights.rtMiscButtonsHeight + extraHeight,
            rtEditorHeightPassive = rtEditorInputPassive + _givenHeights.rtMenuPlacholderHeight;

        let passiveEditorHeight;
        switch ($stateParams.activityType) {
            case 'chat':
                passiveEditorHeight = rtEditorHeightPassive - _givenHeights.rtMiscButtonsHeight;
                break;
            case 'forum':
                passiveEditorHeight = rtEditorHeightPassive;
                break;
        }

        return {
            rtMenuPlacholderHeight: _givenHeights.rtMenuPlacholderHeight,
            passiveEditorHeight
        };
    }

    function _link(scope, element, attrs) {
        const _cache = {
                plain: {
                    element: undefined,
                    // eslint-disable-next-line lodash/prefer-lodash-method
                    getter: () => element.find('#t-a')[0]
                },
                markdown: {
                    element: undefined,
                    // eslint-disable-next-line lodash/prefer-lodash-method
                    getter: () => element.find('.md-editor')[0]
                },
                textAreaMode: undefined,
                role: undefined,
            },
            _engineKey = realTimeEngine.stateParamsToKey($stateParams),
            _observer = new MutationObserver((mutations) => {
                _forEach(_filter(mutations, ['type', 'childList']), (/*mutation*/) => {
                    // $log.debug('message-editor.directive.mutation: ', mutation);
                    $log.debug('MutationObserver!');
                    _newUpdateEditorHeight(null, 'markdown-mutated', realTimeEngine.getEngine(_engineKey));
                });
            });

        // eslint-disable-next-line lodash/prefer-lodash-method

        function _cancel() {
            if (!scope.createsNewPost) {
                if (_saveStack.length) {
                    const {message, editable, isEdit} = _saveStack.pop();
                    _setEditable(message, editable, isEdit);
                }
            } else {
                realTimeEngine.resetEditable(_editable, _editable.is_private);
            }

            if (attrs.onCancel) {
                // Call externally provided functor in
                // external scope.
                scope.onCancel();
            }

            scope.$emit('cancelEdit');

            if (angular.isDefined(scope.trackTyping)) {
                scope.trackTyping('stopped', {
                    title: _editable.title,
                    body: _editable.body
                });
            }

            _newApplyNewHeight(scope.status.minTextLines * TEXT_LINE_HEIGHT, 'cancelled');

            scope.$broadcast('updateAttachments');

            scope.engine.scrollToLastMessageNow('smooth', true);
        }

        function _disableSubmitButton($event) {
            if (angular.isDefined($event)) {
                $event.stopPropagation();
            }
            scope.submitIsEnabled = false;
        }

        function _enableSubmitButton($event) {
            if (angular.isDefined($event)) {
                $event.stopPropagation();
            }
            scope.submitIsEnabled = true;
        }

        function _broadcastUpdateAttachments($event) {
            $event.preventDefault();
            scope.$broadcast('updateAttachments');
        }

        function _submit() {
            if (_editable.submissionInProgress) {
                return;
            }
            _editable.submissionInProgress = true;

            const action = _determineCurrentAction(scope),
                handler = _message.actions[action],
                state = action + ':submit',
                probe = realTimeEngine.getMonitoringProbe(state);

            // Prevent double clicks (from users or iScroll).
            _disableSubmitButton();

            handler({
                title: angular.isDefined(_editable.title) ?
                    joypixels.toShort(_editable.title) : _editable.title,
                body: angular.isDefined(_editable.body) ?
                    joypixels.toShort(_editable.body) : _editable.body,
                attachments: _filterAttachments(_editable.attachments),
                is_private: _editable.is_private,
                notify_by_email: _editable.notifyByEmail
            })
                .then(_partial(probe.registerEventFromResponse, state),
                    _partial(probe.cancelFromResponse, state))
                .then(_cancel)
                .then(_enableSubmitButton)
                .then(() => {
                    _editable.submissionInProgress = false;
                })
                .then(_focusTextarea);
        }


        function _toggleShowAttachmentsEditor(forceClose = false) {
            scope.showAttachmentsEditor = forceClose ? false :
                !scope.showAttachmentsEditor;
        }

        function _toggleShowEmojiEditor() {
            const doShow = !scope.showEmojiEditor;
            $rootScope.$emit(doShow ? 'emojiMenu::Open' : 'emojiMenu::Close');
            scope.showEmojiEditor = doShow;
        }

        const _heightStatus = {
            isUpdatingHeight: false,
        };

        function _newApplyNewHeight(newHeight, why = null) {
            if (!angular.isDefined(_rtEditor)) {
                _rtEditor = element;
            }
            if (!angular.isDefined(_mdView)) {
                // eslint-disable-next-line lodash/prefer-lodash-method
                _mdView = element.find(`#rt-md-view-${scope.$id}`);
            }
            const textAreaHeight = newHeight,
                {
                    rtMenuPlacholderHeight,
                    passiveEditorHeight
                } = getEditorPartHeights(),
                totalHeight = passiveEditorHeight + textAreaHeight,
                editorHeight = totalHeight - rtMenuPlacholderHeight,
                height = `calc(100% - ${editorHeight}px)`;

            // $log.debug(`totalHeight (${totalHeight}) = chatEditorHeightPassive (${passiveEditorHeight}) - textAreaHeight (${textAreaHeight})`);
            // $log.debug(`editorHeight (${editorHeight}) = totalHeight (${totalHeight}) - rtMenuPlacholderHeight (${rtMenuPlacholderHeight})`);
            // $log.debug('height', height);

            _rtContent[0].style.height = '';
            _rtContent[0].style.height = height;

            _rtEditor[0].style.height = '';
            _rtEditor[0].style.height = `${totalHeight}px`;

            // $log.debug('why', why, 'newHeight =', newHeight, 'textAreaHeight =', textAreaHeight);

            switch (why) {
                case 'cancelled':
                /* Fallthrough. */
                case 'got-focus':
                /* Fallthrough. */
                case 'lost-focus': {
                    const ta = _getTextArea()
                    ta.style.height = '';
                    ta.style.height = `${textAreaHeight}px`;
                }
                    scope.$broadcast('autosize:setHeight', textAreaHeight);
                    break;
            }

            _mdView[0].style.height = '';
            _mdView[0].style.height = `${textAreaHeight}px`;

            if (why !== 'lost-focus') {
                _cachedTextAreaHeight = textAreaHeight;
            }
        }

        function _newUpdateEditorHeight(newHeight, why = null, engine) {
            if (_heightStatus.isUpdatingHeight) {
                // $log.debug('Locked out from updating the editor heigth.');
                return;
            }
            _heightStatus.isUpdatingHeight = true;

            // FIXME: I have tried alt 1 and 2 below.  Alternative 3 works
            //  way better, but there is still the annoying “scroll below
            //  the page” “bug” in Safari on iOS.
            //  iOS version 13.x supports the visualViewportAPI:
            //  https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API
            //  https://developers.google.com/web/updates/2017/09/visual-viewport-api
            //  --
            //  DEBUGGING ideas:
            //  [x] 1. Try to delay resizing to when on-screen keyboard should be done.
            //  [x] 2. Try to _not_ delay resizing by not wrapping it in requestAnimationFrame (raf).
            //  Trying this for now --> 3. Try to change height by pure CSS (if that's possible).

            engine = angular.isDefined(engine) ? engine :
                realTimeEngine.getEngine(_engineKey);

            const doScrollToLastMessage = angular.isDefined(engine) ?
                engine.status.isAtBottom : false;

            _newApplyNewHeight(newHeight, why);

            if (doScrollToLastMessage) {
                scope.$evalAsync(() => {
                    engine.scrollToLastMessageNow('auto');
                });
            }

            _heightStatus.isUpdatingHeight = false;
        }

        function _maybeGrowTextArea($event) {
            if (Modernizr.ismobiledevice ||
                !_isMatch($event, {
                    keyCode: KEYCODE_ENTER,
                    shiftKey: false
                })) {
                // FIXME: Is this _whole_function_ (_maybeGrowTextArea()) ever needed?
                //
                // if (scope.status.textLines !== textLines) {
                //     _updateEditorHeight(scope.engine);
                // }
            }
            return true;
        }

        function _onKeyUp($event) {
            scope.submitIsEnabled = _editable.body.length;

            if (_isMatch($event, {
                keyCode: KEYCODE_ESCAPE,
                ctrlKey: false,
                shiftKey: false,
            }) && !Modernizr.ismobiledevice) {
                // Quit editing.
                $event.preventDefault();
                $event.stopPropagation();
                _cancel();
            } else if ($stateParams.activityType === 'chat') {
                if (_isMatch($event, {
                    keyCode: KEYCODE_ENTER,
                    ctrlKey: false,
                    shiftKey: false
                }) && scope.submitIsEnabled && !Modernizr.ismobiledevice) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    _submit();
                } else {
                    if (angular.isDefined(scope.trackTyping)) {
                        scope.trackTyping('active', {
                            title: _editable.title,
                            body: _editable.body
                        });
                    }
                }
            } else if ($stateParams.activityType === 'forum') {
                if (_isMatch($event, {
                    keyCode: KEYCODE_ENTER,
                    ctrlKey: true,
                    shiftKey: false,
                }) && scope.submitIsEnabled && !Modernizr.ismobiledevice) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    _submit();
                } else {
                    //_maybeGrowTextArea($event);
                    // if (angular.isDefined(scope.trackTyping)) {
                    //     scope.trackTyping('active', {
                    //         title: _editable.title,
                    //         body: _editable.body
                    //     });
                    // }
                }
            }
        }


        function _onFocusChanged(isFocused) {
            const engine = realTimeEngine.getEngine(_engineKey) || scope.engine;
            if (!scope.showEmojiEditor) {
                scope.status.hasFocus = isFocused;

                const newHeight = scope.status.minTextLines * TEXT_LINE_HEIGHT;
                if (isFocused) {
                    _newUpdateEditorHeight(angular.isDefined(_cachedTextAreaHeight) ?
                        _cachedTextAreaHeight :
                        newHeight, 'got-focus', engine, true);
                } else {
                    _newUpdateEditorHeight(newHeight, 'lost-focus', engine, true);
                }
            }

            if ($stateParams.activityType !== 'forum' && Modernizr.isios) {
                engine.enableAutoScroll(!scope.status.hasFocus);
            }

            if (_editable) {
                // FIXME: _or_ if one or more attachments exist:
                scope.submitIsEnabled = _editable.body.length > 0;
            }
        }

        function _getTextArea() {
            if (_isUndefined(_cache[_cache.textAreaMode].element)) {
                _cache[_cache.textAreaMode].element =
                    _cache[_cache.textAreaMode].getter();
            }
            return _cache[_cache.textAreaMode].element;
        }

        function _toggleEditorView() {
            const doShowMarkDownEditorNext = (!scope.showMarkDownEditor);

            if (doShowMarkDownEditorNext) {
                _cache.textAreaMode = 'markdown';
            } else {
                _cache.textAreaMode = 'plain';
            }
            scope.status.mode = _cache.textAreaMode;

            const newArea = _getTextArea();

            if (_cache.textAreaMode === 'markdown') {
                _observer.observe(newArea, {
                    attributes: true,
                    childList: true,
                    characterData: true
                });
            } else {
                _observer.disconnect();
            }

            scope.showMarkDownEditor = doShowMarkDownEditorNext;
            scope.mdBody = _renderMessage(_editable.body);
        }

        function _renderPipeline(string) {
            return markdownIt.render(joypixels.toImage(string));
        }

        function _renderMessage(text) {
            return $sce.trustAsHtml(
                _renderPipeline(_replace(text,
                    /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''))
            );
        }

        function _pushCurrentToStack() {
            if (_saveStack.length < 1) {
                // const copyOfEditable = {};
                // realTimeEngine.initializeEditable(copyOfEditable, _editable);
                _saveStack.push({
                    message: _message,
                    editable: _editable,
                    isEdit: scope.isEdit,
                });
            }
        }

        function _setEditable(message, editable, isEdit) {
            _editable = editable;
            _cache.editable = _editable;

            _message = message;

            scope.isEdit = isEdit;
            scope.createsNewPost = (
                _message === null ||
                _message.id === null ||
                (_message.forum && _message.level === 0)
            );
            scope.isReply = !isEdit && !scope.createsNewPost;

            scope.submitIsEnabled = _editable ?
                _editable.body.length > 0 : false;

            if (editable !== scope.editable) {
                scope.editable = editable;
            }
            if (message !== scope.original) {
                scope.original = message;
            }

            // scope.$applyAsync(() => {
            //     _updateEditorHeight(scope.engine, false);
            // });

            // _updateEditorHeight(scope.engine);
            // $timeout(() => {
            // scope.$applyAsync(() => {
            //     // raf(() => {
            //         _updateEditorHeight(scope.engine);
            //     // });
            // });
            // }, 0);
        }

        function _focusTextarea() {
            const _textArea = _getTextArea();
            _textArea.focus();
        }

        function _copyOriginal(original) {
            const {editable} = scope.engine.createNewMessagePlaceholders();

            // editable.user = original.user;
            realTimeEngine.initializeEditable(editable, original);
            // editable.title = original.title;
            // editable.body = original.body;
            // editable.attachments = original.attachments.slice(0);
            // editable.is_private = original.is_private;

            //editable.timestamp = original.timestamp;

            original.isSelfAuthored = original.user.user_id ===
                original.currentUser.user_id;

            return editable;
        }

        function _showOriginal() {
            $timeout(() => {
                // eslint-disable-next-line lodash/prefer-lodash-method
                const message = $document.find(`#message-${_message.id}`);

                if (message.length) {
                    raf(() => {
                        message[0].scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'nearest'
                        });
                    });
                }
            }, 100);
        }

        function _onStartEditReplyMessage(event, keepPrivate, original) {
            const editable = _copyOriginal(original);
            editable.user = original.currentUser;
            editable.title = '';
            editable.body = '';
            editable.attachments = [];
            editable.is_private = keepPrivate;
            editable.timestamp = null;

            _pushCurrentToStack();
            _setEditable(original, editable, false);
            // _blurTextarea();
            _focusTextarea();
            _showOriginal();
        }

        function _onStartEditMessage(event, original) {
            const editable = _copyOriginal(original);

            _pushCurrentToStack();
            _setEditable(original, editable, true);
            _focusTextarea();
            _showOriginal();

        }

        function _initializeEmptyMessage() {
            const {editable, message, isEdit} =
                scope.engine.createNewMessagePlaceholders();

            _setEditable(message, editable, isEdit);
        }

        function _initializeScopeStatus(activityType) {
            const engine = realTimeEngine.getEngine(_engineKey);
            scope.status = engine.status;

            // scope.status.isIos = Modernizr.isios;
            // scope.status.isChrome = Modernizr.ischrome;

            //scope.status.textLines = _getTextLinesCount();

            scope.status.minTextLines = {
                'chat': MIN_EDITOR_TEXT_LINES_CHAT,
                'forum': MIN_EDITOR_TEXT_LINES_FORUM
            }[activityType];

            if ($stateParams.activityType === 'forum') {
                engine.enableAutoScroll(false);
            }

        }

        function _setupClickOutsideMenuCloser(what, watchExp, classes, callback) {
            scope.$watch(watchExp, (showEmojiEditor) => {
                if (showEmojiEditor) {
                    _clickWatchers[what] = $document.bind('click', ($event) => {
                        // eslint-disable-next-line lodash/prefer-lodash-method
                        const insideElements = _map(classes, (cls) => element.find(cls)[0])
                        insideElements.push(_getTextArea());

                        if (!_some(insideElements, (el) => el.contains($event.target))) {
                            scope.$applyAsync(callback);
                        }
                    });
                } else {
                    if (_clickWatchers[what]) {
                        _clickWatchers[what].unbind();
                        _clickWatchers[what] = undefined;
                    }
                }
            });
        }

        function _init() {
            _forEach(['plain', 'markdown'], (mode) => {
                _cache[mode].element = _cache[mode].getter();
            });
            _cache.textAreaMode = 'plain';
            _cache.role = scope.role;

            _setEditable(null, scope.editable, false);

            scope.contents = _cache;  // Used by emoji palette.

            scope.showAttachmentsEditor = false;
            scope.showEmojiEditor = false;
            scope.showFormattingEditor = false;

            scope.showMarkDownEditor = false;

            scope.createsNewPost = (!_message);
            scope.submitIsEnabled = true;

            scope.mdBody = null;

            scope.toggleShowAttachmentsEditor = _toggleShowAttachmentsEditor;
            scope.toggleShowEmojiEditor = _toggleShowEmojiEditor;
            scope.toggleEditorView = _toggleEditorView;
            // scope.maybeResizeTextArea = _maybeGrowTextArea;
            scope.onKeyUp = _onKeyUp;
            scope.onFocusChanged = _onFocusChanged;
            scope.cancel = _cancel;
            scope.submit = _submit;

            scope.showOriginal = _showOriginal;

            scope.$on('attachmentsUploading', _disableSubmitButton);
            scope.$on('attachmentUploaded', () => {
                _enableSubmitButton();
                _toggleShowAttachmentsEditor(true);
            });
            scope.$on('attachmentsChanged', ($event) => {
                _broadcastUpdateAttachments($event);
                _toggleShowAttachmentsEditor(true);
            });

            // eslint-disable-next-line lodash/prefer-lodash-method
            _rtContent = $document.find('#rt-content');

            _setupClickOutsideMenuCloser('emojiMenu',
                'showEmojiEditor', [
                    '.emoji-editor',
                    '.show-md-preview-button',
                    '.show-emoji-editor-button'
                ], () => {
                    scope.showEmojiEditor = false;
                });
            _setupClickOutsideMenuCloser('attachmentMenu',
                'showAttachmentsEditor', [
                    '.show-attachments-editor-button',
                    '.show-md-preview-button',
                ], () => {
                    scope.showAttachmentsEditor = false;
                });

            const deregisterers = [
                $rootScope.$on('startEditMessage', _onStartEditMessage),
                $rootScope.$on('startEditReplyMessage', _onStartEditReplyMessage)
            ];

            const engineInitWatcher = scope.$watch('engine.status.messagesAreLoaded', (messagesAreLoaded) => {
                if (messagesAreLoaded) {
                    _initializeEmptyMessage();
                    _initializeScopeStatus($stateParams.activityType);

                    if ($stateParams.activityType === 'chat') {
                        scope.$applyAsync(() => {
                            scope.engine.scrollToLastMessageNow('smooth', true);
                        });
                    }

                    engineInitWatcher();
                }
            });

            scope.$on('$destroy', () => {
                _forEach(deregisterers, (functor) => functor());

                _forEach(_clickWatchers, (watcher, name) => {
                    if (watcher) {
                        watcher.off();
                        _clickWatchers[name] = undefined;
                    }
                })
            });

            scope.status = {};

            scope.onResize = (updateInfo) => {
                // $log.debug('updateInfo.newHeight', updateInfo.newHeight);
                // $log.debug('updateInfo.actualHeight', updateInfo.actualHeight);

                _newUpdateEditorHeight(
                    _min([updateInfo.newHeight, updateInfo.actualHeight]),
                    'resized-textarea');
            };
        }

        _init();
    }

    return {
        restrict: 'AE',
        scope: {
            // message: '=',
            // editable: '=',
            engine: '=',
            // isEdit: '=',
            onCancel: '&',
            trackTyping: '=',
            style: '=messageStyle',
            role: '@?',
        },
        templateUrl: messageEditorHtml,
        link: _link
    };
}


/* @ngInject */
function renderMessageFilter($sce, markdownIt) {
    function _renderPipeline(string) {
        return markdownIt.render(joypixels.toImage(string));
    }

    return (text) =>
        $sce.trustAsHtml(
            _renderPipeline(_replace(text,
                /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, ''))
        )
}

// eslint-disable-next-line lodash/prefer-lodash-method
export default angular
    .module('components.realTimeEngine.realTimeMessage.messageEditor', [
        emojiEditor.name,
        autosize.name,
    ])
    .directive('messageEditor', messageEditor)
    .filter('renderMessage', renderMessageFilter);
