'use strict';

import angular from 'angular';
import phoneNumberFieldHtml from './phone-number-field.html'

/* @ngInject */
function phoneNumberField(config) {
    function _link(scope) {
        scope.defaultDebounceOptions = config.getDebounceOptions();
    }

    return {
        scope: {
            phone_number: '=phoneNumberField',
            countryDialingCodes: '='
        },
        transclude: true,
        templateUrl: phoneNumberFieldHtml,
        link: {
            pre: _link
        }
    };
}

export default angular
    .module('profile.phoneNumberField', [])
    .directive('phoneNumberField', phoneNumberField);
