'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _padStart from 'lodash/padStart'
import _forEach from 'lodash/forEach'
import _reduce from 'lodash/reduce'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _get from 'lodash/get'
import _indexOf from 'lodash/indexOf'
import _assignIn from 'lodash/assignIn'
import _pick from 'lodash/pick'
import _isFunction from 'lodash/isFunction'


/* @ngInject */
function gridUtils($rootScope, $filter, $log, storage, activeFiltersService,
                   selectionService, gettext) {
    const _filterOperators = {
            date: {
                equals: 'eq',
                notEqual: 'ne',
                lessThan: 'lt',
                greaterThan: 'gt',
                inRange: 'in'
            },
            number: {
                equals: 'eq',
                notEqual: 'ne',
                lessThan: 'lt',
                lessThanOrEqual: 'le',
                greaterThan: 'gt',
                greaterThanOrEqual: 'ge'
            },
            text: {
                contains: 'ct',
                notContains: 'nc',
                equals: 'eq',
                notEquals: 'ne',
                startsWith: 'sw',
                endsWith: 'ew'
            }
        },
        // eslint-disable-next-line no-unused-vars
        _localeStrings = [  // jshint ignore:line
            gettext('grid.applyFilter'),  // 'Apply Filter'
            gettext('grid.autosizeAllColumns'),  // 'Autosize All Columns'
            gettext('grid.autosizeThiscolumn'),  // 'Autosize This Column'
            gettext('grid.average'),  // 'Average'
            gettext('grid.blanks'),  // 'Blanks'
            gettext('grid.collapseAll'),  // 'Collapse All'
            gettext('grid.contains'),  // 'Contains'
            gettext('grid.copy'),  // 'Copy'
            gettext('grid.copyWithHeaders'),  // 'Copy with Headers'
            gettext('grid.count'),  // 'Count'
            gettext('grid.ctrlC'),  // 'Ctrl+C'
            gettext('grid.ctrlV'),  // 'Ctrl+V'
            gettext('grid.endsWith'),  // 'Ends with'
            gettext('grid.equals'),  // 'Equals'
            gettext('grid.expandAll'),  // 'Expand All'
            gettext('grid.filterOoo'),  // 'Filter...'
            gettext('grid.first'),  // 'First'
            gettext('grid.greaterThan'),  // 'Greater than'
            gettext('grid.greaterThanOrEqual'),  // 'Greater than or equal'
            gettext('grid.group'),  // 'Group'
            gettext('grid.groupBy'),  // 'Group by'
            gettext('grid.groups'),  // 'Row Groups'
            gettext('grid.last'),  // 'Last'
            gettext('grid.lessThan'),  // 'Less than'
            gettext('grid.lessThanOrEqual'),  // 'Less than or equal'
            gettext('grid.loadingOoo'),  // 'Loading...'
            gettext('grid.max'),  // 'Max'
            gettext('grid.min'),  // 'Min'
            gettext('grid.next'),  // 'Next'
            gettext('grid.noPin'),  // 'No Pin'
            gettext('grid.noRowsToShow'),  // 'No Rows To Show'
            gettext('grid.notContains'), // 'Not Contains'
            gettext('grid.notEqual'),  // 'Not equal'
            gettext('grid.notEquals'),  // 'Not equals'
            gettext('grid.of'),  // 'of'
            gettext('grid.page'),  // 'Page'
            gettext('grid.paste'),  // 'Paste'
            gettext('grid.pinColumn'),  // 'Pin Column'
            gettext('grid.pinLeft'),  // 'Pin Left'
            gettext('grid.pinRight'),  // 'Pin Right'
            gettext('grid.pivotColumnsEmptyMessage'),  // 'Drag here to aggregate'
            gettext('grid.pivotColumnsEmptyMessage'),  // 'Drag here to set column labels'
            gettext('grid.pivotMode'),  // 'Pivot Mode'
            gettext('grid.pivots'),  // 'Column Labels'
            gettext('grid.previous'),  // 'Previous'
            gettext('grid.resetColumns'),  // 'Reset Columns'
            gettext('grid.rowGroupColumnsEmptyMessage'),  // 'Drag here to set row groups'
            gettext('grid.startsWith'),  // 'Starts with'
            gettext('grid.sum'),  // 'Sum'
            gettext('grid.to'),  // 'to'
            gettext('grid.toolPanel'),  // 'Tool Panel'
            gettext('grid.ungroupBy'),  // 'Un-Group by'
            gettext('grid.valueAggregation'),  // 'Value Aggregation'
            gettext('grid.values')  // 'Values'
        ],
        formatLength = 'yyyy-mm-dd'.length;

    function _generateFilterKey(columnDefs, result, value, key) {
        const columnDef = _filter(columnDefs, ['field', key])[0];
        let filterKey;
        if (columnDef.filter === 'set') {
            filterKey = key + ':in:' + value.join(',');
        } else if (columnDef.filter === 'date') {
            filterKey = `${key}:${_filterOperators[columnDef.filter][value.type]}:${_padStart(value.dateFrom, formatLength, '0')}`;

            if (value.type === 'inRange') {
                filterKey += `,${_padStart(value.dateTo, formatLength, '0')}`;
            }
        } else {
            filterKey = `${key}:${_filterOperators[columnDef.filter][value.type]}:${value.filter}`;
        }

        result.push(filterKey);
        return result;
    }

    function _generateSortKey(result, value) {
        result.push(value.colId + ':' + value.sort);
        return result;
    }

    function _getIconOptions() {
        return {
            // column header items
            menu: '<i class="fa fa-fw fa-bars"></i>',
            filter: '<i class="fa fa-fw fa-filter"></i>',
            sortAscending: '<i class="fa fa-fw fa-sort-amount-asc"></i>',
            sortDescending: '<i class="fa fa-fw fa-sort-amount-desc"></i>',
            sortUnSort: '<i class="fa fa-fw fa-sort"></i>',

            /*// expand / contract row group
             groupExpanded: '<i class="fa fa-fw "></i>',
             groupContracted: '<i class="fa fa-fw "></i>',

             // expand / contract column group
             columnGroupOpened: '<i class="fa fa-fw "></i>',
             columnGroupClosed: '<i class="fa fa-fw "></i>',

             // tool panel column group open / close
             columnSelectOpen: '<i class="fa fa-fw "></i>',
             columnSelectClosed: '<i class="fa fa-fw "></i>',
             */

            // row checkbox selection and tool panel column selection
            checkboxChecked: '<i class="fa fa-fw fa-check-square-o"></i>',
            checkboxUnchecked: '<i class="fa fa-fw fa-square-o"></i>',
            checkboxIndeterminate: '<i class="fa fa-fw fa-minus-square"></i>',
            /*
             checkboxChecked: '<input type="checkbox" checked>',
             checkboxUnchecked: '<input type="checkbox">',
             checkboxIndeterminate: '<input type="checkbox" indeterminate="true">',
             */
            // tool panel column selection, when read only (ie disabled checkboxes)
            checkboxCheckedReadOnly: '<i class="fa fa-fw fa-dot-circle-o"></i>',
            checkboxUncheckedReadOnly: '<i class="fa fa-fw fa-dot-circle-o"></i>',
            checkboxIndeterminateReadOnly: '<i class="fa fa-fw fa-dot-circle-o"></i>',

            /* When moving columns. */
            // when column is to the left, before it gets pinned
            columnMovePin: '<i class="fa fa-fw fa-thumb-tack"></i>',
            // when adding a column
            columnMoveAdd: '<i class="fa fa-fw fa-plus"></i>',
            // when removing a column
            columnMoveHide: '<i class="fa fa-fw fa-trash-o"></i>',
            // when moving a column
            columnMoveMove: '<i class="fa fa-fw fa-arrows"></i>',
            // when moving and scrolling left
            columnMoveLeft: '<i class="fa fa-fw fa-long-arrow-left"></i>',
            // when moving and scrolling right
            columnMoveRight: '<i class="fa fa-fw fa-long-arrow-right"></i>',

            /*
             // when about to drop into group panel
             columnMoveGroup: '<i class="fa fa-fw "></i>',
             // when about to drop into value panel
             columnMoveValue: '<i class="fa fa-fw "></i>',
             // when about to drop into pivot panel
             columnMovePivot: '<i class="fa fa-fw "></i>',
             */

            // when trying to drop column into group/value/pivot panel and column doesn't support it
            dropNotAllowed: '<i class="fa fa-fw fa-ban"></i>',

            /* Column menu. */
            // beside the column pin option
            menuPin: '<i class="fa fa-fw fa-thumb-tack"></i>',
            // beside the column value option
            menuValue: '<i class="fa fa-fw fa-diamond"></i>',
            /*
             // beside the column row group option
             menuAddRowGroup: '<i class="fa fa-fw "></i>',
             // beside the column row group option
             menuRemoveRowGroup: '<i class="fa fa-fw "></i>',
             */

            /* Column drop panels. */
            /*
             // beside where to drop columns for row group
             rowGroupPanel: '<i class="fa fa-fw "></i>',
             // beside where to drop columns for pivot
             pivotPanel: '<i class="fa fa-fw "></i>',
             // beside where to drop columns for value
             valuePanel: '<i class="fa fa-fw "></i>',
             */
        };
    }

    function _localeTextFunc(key, defaultValue) {
        const gridKey = 'grid.' + key,
            value = $filter('translate')(gridKey);

        return value === gridKey ? defaultValue : value;
    }

    function _getGridOperations(config) {
        const _gridOptions = config.gridOptions,
            selections = selectionService.getCollection(config.selectionName, config.selectionIdName);

        function _removeMutedParams(queryParams) {
            if (angular.isDefined(config.muteQueryParams)) {
                _forEach(config.muteQueryParams, function _removeMutedParam(key) {
                    delete queryParams[key];
                });
            }
        }

        function _performQuery(queryParams, params) {
            _removeMutedParams(queryParams);

            _gridOptions.api.showLoadingOverlay();

            config.resource.query(queryParams).$promise
                .then(function _updateRows(results) {
                    _gridOptions.sqlExpression = results.sql;
                    _gridOptions.total = results.total;

                    params.successCallback(
                        _get(results, config.resourceResponseCollection),
                        results.total);

                    _gridOptions.api.hideOverlay();
                    selectionService.updateTotal(config.selectionName,
                        results.total);
                    _updateSelections();
                }, function _handleFailure() {
                    _gridOptions.api.hideOverlay();
                    params.failCallback();
                });
        }

        function _getRowsForVirtualPagination(params) {
            // The preparations of `queryParams.filters` has been moved into
            // `activeFiltersService.getQueryConstraints()`.
            const filters =
                    activeFiltersService.getQueryConstraints(
                        config.selectionName, config.rowIdPath),
                sortKey = _reduce(params.sortModel, _generateSortKey, []),
                queryParams =
                    _merge(config.resource.queryParamsFromFilters(filters), {
                        extra_columns: config.resourceExtraColumns,
                        search_term: null,
                        sql: true,
                        order_by: sortKey,
                        start_row: params.startRow,
                        end_row: params.endRow
                    }, config.resourceExtraQueryParams);

            _performQuery(queryParams, params);
        }

        function _getData(params) {
            // The preparations of `queryParams.filters` has been moved into
            // `activeFiltersService.getQueryConstraints()`.
            const filters =
                    activeFiltersService.getQueryConstraints(
                        config.selectionName, config.rowIdPath),
                sortKey = _reduce(params.sortModel, _generateSortKey, []),
                queryParams =
                    _merge(config.resource.queryParamsFromFilters(filters), {
                        extra_columns: config.resourceExtraColumns,
                        search_term: null,
                        sql: true,
                        order_by: sortKey
                    }, config.resourceExtraQueryParams);

            _performQuery(queryParams, params);
        }

        function _getMainMenuItems(params) {
            const customizedMenu = params.defaultItems.slice(0),
                i = _indexOf(customizedMenu, 'resetColumns'),
                resetFiltersMenuItem = {
                    name: $filter('translate')(gettext('Reset Filters')),
                    action: () => {
                        params.api.setFilterModel(null);
                    }
                };

            if (i >= 0) {
                customizedMenu.splice(i + 1, 0, resetFiltersMenuItem);
            } else {
                customizedMenu.push(resetFiltersMenuItem);
            }

            return customizedMenu;
        }

        function _setupBasicGridConfiguration() {
            _assignIn(_gridOptions, {
                angularCompileHeaders: true,
                angularCompileRows: true,
                headerCellRenderer: _headerCellRenderer,
                enableServerSideSorting: config.useVirtualPaging,
                enableServerSideFilter: config.useVirtualPaging,
                enableSorting: !config.useVirtualPaging,
                enableColResize: true,
                // enableRowGroup: true,
                getMainMenuItems: _getMainMenuItems,
                showToolPanel: false,
                unSortIcon: true,
                rowModelType: config.useVirtualPaging ? 'infinite' : 'normal',
                rowData: config.rowData,
                rowDeselection: true,
                rowSelection: 'multiple',
                columnDefs: config.columnDefs,
                icons: _getIconOptions(),
                localeTextFunc: _localeTextFunc,
                datasource: config.useVirtualPaging ? {
                    rowCount: null,  // Behave as infinite scroll.
                    getRows: _getRowsForVirtualPagination
                } : null,
                paginationPageSize: 100,
                cacheOverflowSize: 100,
                rememberGroupStateWhenNewData: !config.useVirtualPaging,
                maxConcurrentDatasourceRequests: 3,
                maxPagesInPaginationCache: 5,
                suppressRowClickSelection: true,
                onRowSelected: _rowSelectionChanged,
                onSortChanged: _beforeFilterOrSortChanged,
                onFilterChanged: _beforeFilterOrSortChanged,
                onGridReady: _gridInit,
                afterGridInit: undefined,
                toolPanelSuppressRowGroups: true,
                toolPanelSuppressValues: true,
                toolPanelSuppressPivots: true,
                toolPanelSuppressPivotMode: true
            });
        }

        function _storeModels(gridApi) {
            let admin = storage.admin.getItem();

            if (admin === null) {
                admin = {};
            }

            admin[config.storageName] = {
                filterModel: gridApi.getFilterModel(),
                sortModel: gridApi.getSortModel()
            };

            const columnDefs = _reduce(
                _map(gridApi.columnController.primaryColumns, 'colDef'),
                function _foo(result, column) {
                    result.push(_pick(column, ['field', 'filter']));
                    return result;
                }, []);

            // These are needed to calculate `queryParams.filter` in
            // `activeFiltersService.getQueryConstraints()`.
            selectionService.setColumnDefs(config.selectionName,
                columnDefs, selections);
            selectionService.setExtraFilters(config.selectionName,
                admin[config.storageName].filterModel, selections);

            storage.admin.setItem(admin);
        }

        function _loadStoredModels() {
            const admin = storage.admin.getItem();

            if (admin !== null && angular.isDefined(admin[config.storageName])) {
                _gridOptions.api
                    .setFilterModel(admin[config.storageName].filterModel);
                _gridOptions.api
                    .setSortModel(admin[config.storageName].sortModel);
            }
        }

        function _beforeFilterOrSortChanged() {
            _storeModels(_gridOptions.api);
        }

        function _headerCellRenderer(params) {
            if (params.colDef.compileWithAngular) {
                params.$scope.selections = selections;
                return params.value;
            }

            return $filter('translate')(params.value);
        }

        function _rowSelectionChanged(event) {
            if (angular.isDefined(event.node.isCurrentlyBeingHandled) &&
                event.node.isCurrentlyBeingHandled === true) {
                $log.warn('Avoided redundant handling of same rowSelection event.');
                return;
            }

            event.node.isCurrentlyBeingHandled = true;

            if (angular.isDefined(config.beforeRowSelected)) {
                if (config.beforeRowSelected(event) === false) {
                    event.node.isCurrentlyBeingHandled = false;
                    return;
                }
            }

            if (event.node.selected) {
                selectionService.add(event.node.data, config.rowIdPath,
                    config.selectionName);
            } else {
                selectionService.remove(event.node.data, config.rowIdPath,
                    config.selectionName);
            }

            if (angular.isDefined(config.afterRowSelected)) {
                config.afterRowSelected(event);
            }

            event.node.isCurrentlyBeingHandled = false;

        }

        function _updateSelections() {
            selectionService.updateInGridSelections(_gridOptions.api,
                config.rowIdPath, config.selectionName);
            activeFiltersService
                .updateSelectionsFilter(config.selectionName,
                    config.selectionIdName);
        }

        function _activeFiltersUpdated() {
            _gridOptions.api.onFilterChanged();
        }

        function _toggleToolPanelVisibility() {
            _gridOptions.showToolPanel = !_gridOptions.showToolPanel;

            _gridOptions.api.showToolPanel(_gridOptions.showToolPanel);
        }

        const _service = {
            gridOptions: _gridOptions,
            toggleToolPanelVisibility: _toggleToolPanelVisibility
        };

        function _gridInit() {
            _loadStoredModels();

            const listenerDestroyers = [
                $rootScope.$on(
                    selectionService.getUpdatedSignal(config.selectionName,
                        config.selectionIdName), _updateSelections),
                $rootScope.$on(activeFiltersService.updatedSignal,
                    _activeFiltersUpdated)
            ];

            config.scope.$on('$destroy', function _destroyListeners() {
                _forEach(listenerDestroyers, (destroyer) => destroyer());
            });

            if (!config.useVirtualPaging) {
                _gridOptions.api.setSortModel([
                    {colId: 'ag-Grid-AutoColumn', sort: 'asc'}
                ]);

                if (!config.rowData) {
                    _getData({
                        filterModel: _gridOptions.api.getFilterModel(),
                        sortModel: _gridOptions.api.getSortModel(),
                        successCallback: function _onSuccess(rowData/*, totalRows*/) {
                            _gridOptions.api.setRowData(rowData);
                        },
                        failCallback: function _onFail() {
                            throw 'Getting data from server failed.';
                        }
                    });
                } else {
                    _gridOptions.api.hideOverlay();
                }
            }

            if (_isFunction(config.afterGridInit)) {
                config.afterGridInit(_service)
            }
        }

        function _init() {
            _setupBasicGridConfiguration();
        }

        _init();

        return _service;
    }

    function _nullValue() {
        return null;
    }

    return {
        filterOperators: _filterOperators,
        generateFilterKey: _generateFilterKey,
        generateSortKey: _generateSortKey,
        getGridOperations: _getGridOperations,
        localeTextFunc: _localeTextFunc,
        nullValue: _nullValue
    };
}

export default angular
    .module('components.gridUtils', [])
    .factory('gridUtils', gridUtils);
