'use strict';

import angular from 'angular';
import activityItem from './activity-item.directive.js';
import activityListHtml from './activity-list.html'

/* @ngInject */
function activityList(activityService) {
    function _link(scope) {
        scope.activityService = activityService;
    }

    return {
        restrict: 'AE',
        scope: {
            activities: '=activityList',
            /* TODO: Consider if scenario can be removed.  It's here
             because the duplicated HTML, now in one template,
             originally included the differing classes 'home' and
             'activities-list'. */
            scenario: '@'
        },
        link: _link,
        templateUrl: activityListHtml
    };
}

export default angular
    .module('activities.activityList.directive', [
        activityItem.name
    ])
    .directive('activityList', activityList);
