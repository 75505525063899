'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _keys from 'lodash/keys'
import _difference from 'lodash/difference'
import _assign from 'lodash/assign'
import _shuffle from 'lodash/shuffle'
import _partial from 'lodash/partial'
import _transform from 'lodash/transform'
import _assignIn from 'lodash/assignIn'
import _reduce from 'lodash/reduce'
import _flatten from 'lodash/flatten'
import _map from 'lodash/map'
import _last from 'lodash/last'
import _sortedIndexBy from 'lodash/sortedIndexBy'
import _has from 'lodash/has'
import _isObject from 'lodash/isObject'


/* @ngInject */
function utility($urlMatcherFactory, $window, $log, moment, api) {
    const _httpMethodHandlers = {
        'GET': api.get,
        'POST': api.postForm,
        'POST_JSON': api.postJson,
        'DELETE': api.deleteRequest
    };

    function _alphaComparator(a, b) {
        const x = a.toLowerCase(),
            y = b.toLowerCase();
        return x === y ? 0 : (x > y ? 1 : -1);
    }

    function _numericComparator(a, b) {
        return a - b;
    }

    function _columnSort(comparator, columns) {
        return function (a, b) {
            let i, column, result = 0;

            for (i = 0; i < columns.length; i++) {
                column = columns[i];

                result = comparator(a[column], b[column]);
                if (result) {
                    break;
                }
            }
            return result;
        };
    }

    function _mergeFromOffset(params, args, offset) {
        let i;

        for (i = offset || 0; i < args.length; i++) {
            params.push(args[i]);
        }
        return params;
    }

    function _multicolumnSort(comparator, array) {
        switch (arguments.length) {
            case 0:
            case 1:
                throw 'Supply at least a comparator and ' +
                'an array to sort.';
            case 2:
                array.sort(comparator);
                break;
            default:
                array.sort(_columnSort(comparator,
                    _mergeFromOffset([], arguments, 2)));
        }

        return array;
    }

    function _updateSharedObject(target, source) {
        const targetKeys = _keys(target),
            sourceKeys = _keys(source);

        function _deleteTargetKey(key) {
            delete target[key];
        }

        _forEach(_difference(targetKeys, sourceKeys),
            _deleteTargetKey);

        _assign(target, source);
    }

    function _shuffleArray(array) {
        return _shuffle(array);
    }

    function _shuffleArrayInPlace(array) {
        /**
         * Randomize array element order in-place.
         * Using Fisher-Yates shuffle algorithm.
         */
        let i, j, temp;

        for (i = array.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    function _serialize(obj, prefix) {
        $log.warn('Deprecated utility.serialize() was called.');

        function serialize(obj, prefix) {
            const str = [];
            for (const p in obj) {
                const k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
                str.push(_isObject(v) ?
                    serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
            return str.join("&");
        }

        return serialize(obj, prefix);
    }

    function _capitalize(string) {
        return string.charAt(0).toUpperCase() + string.substr(1);
    }

    function _emptyObject(obj) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                delete obj[prop];
            }
        }
    }

    function _emptyArray(array) {
        while (array.length) {
            array.pop();
        }
    }

    function _updateSharedArray(target, source) {
        _emptyArray(target);
        _forEach(source, function _addElement(element) {
            target.push(element);
        });
    }

    function _extendArray(target, source) {
        _forEach(source, function _addElement(element) {
            target.push(element);
        });
    }

    /**
     * When (normally secure) HTTP Content-Security Policy (CSP) is
     * enforced, code that uses eval() is not allowed to run.  However,
     * lodash's template() relies on Function(), which is an implicit
     * eval() call.  Hence, we use this work-around solution.  Future
     * possibility: use precompiled templates instead.
     */
    const _cspTemplate = function _template(message, data) {
        if (angular.isUndefined(data)) {
            return _partial(_cspTemplate, message);
        } else {
            return message.replace(/\{\{([^}]+)}}/g, function (s, match) {
                let result = data;
                _forEach(match.trim().split('.'), function (propertyName) {
                    result = result[propertyName];
                });
                return escape(result);
            });
        }
    };

    function _format(template, values) {
        // var compiled = template(template, {
        //     interpolate: /{{([\s\S]+?)}}/g
        // });
        const compiled = _cspTemplate(template);

        return angular.isDefined(values) ? compiled(values) : compiled;
    }

    function _compileActionURLTemplates(action_urls) {
        return _transform(action_urls,
            function __compileActionURLTemplates(result, info, action) {
                result[action] = {
                    url: _format(info.url),
                    method: info.method
                };
            }, {});
    }

    function _leaf(obj, path) {
        let i,
            res = obj;
        const parts = path.split('.');

        if (path !== '') {  // Empty path returns obj itself.
            for (i = 0; i < parts.length; i++) {
                res = res[parts[i]];
            }
        }
        return res;
    }

    // Generate action parameters from activity info using the provided mapping.
    function _extractParams(map, types, source) {
        const params = {};

        _forEach(types, function _extractForType(type) {
            const locations = map[type],
                subParams = {};

            _forEach(locations, function _extractFromLeaf(fields, path) {
                const leaf = _leaf(source, path);

                _forEach(fields, function _assign(sourceKey, targetKey) {
                    if (_isObject(sourceKey)) {
                        subParams[targetKey] =
                            leaf[sourceKey.key] || sourceKey.default;
                    } else {
                        subParams[targetKey] = leaf[sourceKey];
                    }
                });
            });

            _assignIn(params, subParams);
        });

        return params;
    }

    function _appendTransform(defaults, transform) {
        defaults = angular.isArray(defaults) ? defaults : [defaults];

        return defaults.concat(transform);
    }

    function _matchUrl(pattern, url) {
        return $urlMatcherFactory.compile(pattern).exec(url);
    }

    function _cartesian() {
        return _reduce(arguments, function (a, b) {
            return _flatten(_map(a, function (x) {
                return _map(b, function (y) {
                    return x.concat([y]);
                });
            }));
        }, [[]]);
    }

    const _isoDateFormat = 'YYYY-MM-DD',
        _isoTimeFormat = 'HH:mm:ssZ',
        _dateTime = {
            isoDateFormat: _isoDateFormat,
            isoTimeFormat: _isoTimeFormat,
            isoDateTimeFormat: _isoDateFormat + ' ' + _isoTimeFormat,
            shortTimeFormat: 'HH:mm',

            fromIso: function _fromIso(string) {
                return moment(string).toDate();
            },

            toIsoDate: function _toIsoDate(date) {
                return moment(date).format(_dateTime.isoDateFormat);
            },

            toIsoTime: function _toIsoTime(date) {
                return moment(date).format(_dateTime.isoTimeFormat);
            },

            toShortTime: function _toShortTime(date) {
                return moment(date).format(_dateTime.shortTimeFormat);
            },

            todayAsIsoDate: function _todayAsIsoDate() {
                return moment().format(_dateTime.isoDateFormat);
            },

            stripSeconds: function _stripSeconds(date) {
                return moment(date).seconds(0).toDate();
            },

            toIso: function _toIso(datetime) {
                return moment(datetime).format(_dateTime.isoDateTimeFormat);
            },

            combine: function _combine(date, time) {
                return moment(time).set({
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    date: date.getDate()
                }).toDate();
            }
        };

    function _sampleUniform(list, n, includeLast) {
        const populationSize = list.length,
            nth = Math.floor(populationSize / n);
        let sample = [];

        includeLast = angular.isDefined(includeLast) ? includeLast : false;

        if (nth <= 1) {
            sample = list;
        } else {
            for (let i = 0; i < populationSize; i += nth) {
                sample.push(list[i]);
            }

            // console.assert(sample.length >= n);
            const lastElement = _last(list);

            if (includeLast && (_last(sample) !== lastElement)) {
                sample.push(lastElement);
            }
        }

        return sample;
    }

    function _broadcastScrollEvent() {
        angular.element($window).trigger('scroll');
    }

    function _promiseIsResolved(deferred) {
        return !!deferred.promise.$$state.status;
    }

    function _insertSorted(array, item, path) {
        array.splice(_sortedIndexBy(array, item, path), 0, item);
    }

    function _getParentData(state, key) {
        if (state && _has(state.data, key)) {
            return state.data[key];
        } else {
            if (_has(state, 'parent')) {
                return _getParentData(state.parent, key);
            } else {
                return undefined;
            }
        }
    }

    function _getFileNameFromHeader(header) {
        if (!header) {
            return null;
        }

        const result = header.split(";")[1].trim().split("=")[1];

        return result.replace(/"/g, '');
    }

    function _updateCollapseIcon(state) {
        const update = state.isExpanded ? {
            remove: state.icons.closed,
            add: state.icons.open
        } : {
            remove: state.icons.open,
            add: state.icons.closed
        };

        state.iconElement.removeClass(update.remove);
        state.iconElement.addClass(update.add);
    }

    function _togglePanelCollapse(state) {
        state.isExpanded = !state.isExpanded;

        if (state.isExpanded) {
            state.collapsibleElement.addClass('in');
        } else {
            state.collapsibleElement.removeClass('in');
        }

        _updateCollapseIcon(state);
    }

    return {
        extractParams: _extractParams,
        call: (f) => {f();},
        format: _format,
        compileActionURLTemplates: _compileActionURLTemplates,
        emptyArray: _emptyArray,
        emptyObject: _emptyObject,
        extendArray: _extendArray,
        capitalize: _capitalize,
        getParentData: _getParentData,
        insertSorted: _insertSorted,
        multicolumnAlphaSort: function _multicolumnAlphaSort() {
            return _multicolumnSort.apply(this,
                _mergeFromOffset([_alphaComparator], arguments));
        },
        multicolumnNumericSort: function _multicolumnNumericSort() {
            return _multicolumnSort.apply(this,
                _mergeFromOffset([_numericComparator], arguments));
        },
        serialize: _serialize,
        shuffleArrayInPlace: _shuffleArrayInPlace,
        shuffleArray: _shuffleArray,
        togglePanelCollapse: _togglePanelCollapse,
        updateCollapseIcon: _updateCollapseIcon,
        updateSharedArray: _updateSharedArray,
        updateSharedObject: _updateSharedObject,
        httpMethodHandlers: _httpMethodHandlers,
        appendTransform: _appendTransform,
        matchUrl: _matchUrl,
        cartesian: _cartesian,
        dateTime: _dateTime,
        sampleUniform: _sampleUniform,
        broadcastScrollEvent: _broadcastScrollEvent,
        promiseIsResolved: _promiseIsResolved,
        getFileNameFromHeader: _getFileNameFromHeader
    };
}

export default angular
    .module('components.utility', [])
    .factory('utility', utility);
