'use strict';

import angular from 'angular';
import TrialExpiredController from './trial-expired.controller.js'
import trialExpiredHtml from './trial-expired.html'

/* @ngInject */
function config($stateProvider) {
    /*
     Let Angular resolve and inject meta value.
     */
    var metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state({
            name: 'home.trial-expired',
            url: '^/trial-expired/',
            views: {
                'main-contents@': {
                    controller: 'TrialExpiredController',
                    templateUrl: trialExpiredHtml
                }
            },
            secure: true,
            resolve: {
                meta: metaResolver
            }
        });
}

export default angular
    .module('trialExpired', [
        TrialExpiredController.name
    ])
    .config(config);
