'use strict';

import angular from 'angular';

import messagesList from './e-messages-list.directive.js'

import MessagesController from './messages.controller'
import messagesWrapperHtml from './messages-wrapper.html'
import {MessageAdminServiceFactory} from './message-service.factory'
import messagesHtml from './messages.html'
import messageUsersHtml from "../users/message-users.html";


/* @ngInject */
function config($stateProvider) {
    var metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state({
            name: 'admin.messages',
            abstract: true,
            url: 'messages/',
            views: {
                'main-contents@': {
                    templateUrl: messagesWrapperHtml,
                    controller: 'MessagesController',
                    controllerAs: 'vm'
                }
            },
            secure: true,
            data: {
                selectionsName: 'admin.messages',
                selectionsIdName: 'recipient_id'
            },
            resolve: {
                meta: metaResolver
            }
        })
        .state({
            name: 'admin.messages.list',
            url: 'list/',
            views: {
                'messages': {
                    templateUrl: messagesHtml
                }
            },
            secure: true
        })
        .state({
            name: 'admin.messages.follow-up',
            url: ':messageId/follow-up/',
            views: {
                'messages': {
                    templateUrl: messageUsersHtml,
                    controller: 'MessageUsersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                userSampleQueryParams: null,
                userSample: null,
                body: null,
                subject: null,
                useHtml: null,
            },
            secure: true
        });
}

export default angular
    .module('admin.messages', [
        MessagesController.name,
        messagesList.name,
        MessageAdminServiceFactory.name,
    ])
    .config(config);
