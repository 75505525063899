'use strict';

import angular from 'angular';
import welcomeButtonsHtml from './welcome-buttons.html'

/* @ngInject */
function welcomeButtons() {
    return {
        scope: {
            currentPanel: '='
        },
        replace: true,
        templateUrl: welcomeButtonsHtml
    };
}

export default angular
    .module('welcome.welcomeButtons', [])
    .directive('welcomeButtons', welcomeButtons);
