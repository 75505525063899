'use strict';

import angular from 'angular';

import activityPreview from './activity-preview.constant.js';
import ActivityPreviewController from './activity-preview.controller.js';

export default angular
    .module('activities.activityPreview', [
        activityPreview.name,
        ActivityPreviewController.name
    ]);
