'use strict';

import angular from 'angular';

/* @ngInject */
function layoutService(coreLayoutService) {
    function _mainLayout() {
        coreLayoutService.updateDrawer('leftDrawer', {enabled: true});
        coreLayoutService.updateDrawer('rightDrawer', {enabled: true});
        coreLayoutService.update({
            footer: {
                visible: coreLayoutService.defaultExcept(),
                hidden: coreLayoutService.defaultExcept({}, true)
            }
        });
        coreLayoutService.updateModal({
            size: 'large',
            footer: {
                visible: coreLayoutService.defaultExcept({all: true}),
                hidden: coreLayoutService.defaultExcept({}, false)
            }
        });
    }

    return {
        mainLayout: _mainLayout
    };
}

export default angular
    .module('layout.layoutService', [])
    .service('layoutService', layoutService);
