'use strict';

import angular from 'angular';
import $ from 'jquery';
import 'jquery-jcrop';
import _min from 'lodash/min'


/* @ngInject */
function imageCropper($q) {
    function _link(scope, element) {
        let myImg;

        function _clear() {
            if (myImg) {
                myImg.next().remove();
                myImg.remove();
                myImg = undefined;
            }
        }

        function _getImageDimensions(url) {
            const deferred = $q.defer();
            const image = new Image();
            image.onload = function _onImageLoad() {
                deferred.resolve({
                    src: url,
                    trueSize: [image.width, image.height]
                });
            };
            image.src = url;

            return deferred.promise;
        }

        function _initJCrop(imageInfo) {
            const boxDimensions = scope.getBoxDimensions(element);

            element.after('<img />');
            myImg = element.next();
            myImg.attr('src', imageInfo.src);

            $(myImg).Jcrop({
                //boxWidth: boxDimensions.width,
                boxHeight: boxDimensions.height,
                onSelect: function _onSelect(coords) {
                    /*if (!scope.$$phase) {
                     scope.$apply(function() {
                     scope.selected({coords: coords});
                     });
                     }*/
                    coords.trueSize = imageInfo.trueSize;

                    scope.selectedCallback(coords);
                },
                trueSize: imageInfo.trueSize,
                setSelect: [],
                aspectRatio: scope.aspectRatio
            }, function _onReady() {
                const jcropApi = this,
                    bounds = jcropApi.getBounds(),
                    minDimension = _min(bounds),
                    x0 = (bounds[0] - minDimension) / 2,
                    y0 = (bounds[1] - minDimension) / 2,
                    x1 = x0 + minDimension,
                    y1 = y0 + minDimension;

                jcropApi.setSelect([x0, y0, x1, y1]);
            });
        }

        function _srcChanged(newSrc) {
            _clear();

            if (newSrc) {
                _getImageDimensions(newSrc).then(_initJCrop);
            }
        }

        scope.$watch('src', _srcChanged);

        scope.$on('$destroy', _clear);
    }

    return {
        restrict: 'AE',
        replace: true,
        scope: {
            aspectRatio: '=',
            getBoxDimensions: '=',
            response: '=',
            src: '=',
            selectedCallback: '='
        },
        link: _link
    };
}

export default angular
    .module('profile.profileEditController.imageCropper', [])
    .directive('imageCropper', imageCropper);
