'use strict';

import angular from 'angular';

/* @ngInject */
function ForumController($rootScope, $scope, $stateParams, $timeout, $q, $log,
                         api, metaService, realTimeEngine, activityService) {
    const engine = realTimeEngine.create('forum', $scope);

    $scope.engine = engine;

    $scope.activityID = $stateParams.activityId;
    $scope.threadID = $stateParams.threadId;

    $scope.apiUrl = api.url;

    $scope.createNewTopic = false;
    $scope.toggleCreateNewTopic = function () {
        $scope.createNewTopic = !$scope.createNewTopic;
    };

    $q.all([metaService.get(), activityService.getCurrentActivity()])
        .then(function _initForum(resources) {
            var _meta = resources[0],
                _activity = resources[1];

            engine.register(realTimeEngine.stateParamsToKey($stateParams));

            $scope.meta = _activity.meta;

            engine.init({
                activity: _activity,
                meta: _activity.meta,
                channelTypes: [
                    realTimeEngine.generateChannelSpec('message'),
                    realTimeEngine.generateChannelSpec('server'),
                    realTimeEngine.generateChannelSpec('status'),
                    realTimeEngine.generateChannelSpec('user', `user.${_meta.user.user_id}`)
                ],
                threadID: $scope.threadID,
                currentUser: _meta.user
            });
            $scope.trackTyping = engine.trackTyping;
            $scope.realtimeEngine = engine;

            return engine.config.meta.last_messages_url;
        })
        .then(engine.getLastMessages)
        .then(engine.prepareMessages)
        .then(() => {
            engine.status.messagesAreLoaded = true;
        })
        // .then(engine.createNewMessagePlaceholders)
        .then(engine.subscribeToRealTime)
        .then(engine.startPublishingConnectedStatus);
}

export default angular
    .module('activities.forum.forumController', [])
    .controller('ForumController', ForumController);
