'use strict';

import angular from 'angular';
import CompleteProfileController from './complete-profile.controller.js';
import completeProfileHtml from './complete-profile.html'

/* @ngInject */
function config($stateProvider) {
    // Let Angular resolve and inject meta value.
    var metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state({
            name: 'home.complete-profile',
            url: '^/complete-profile/',
            views: {
                'modal-contents@': {
                    controller: 'CompleteProfileController',
                    templateUrl: completeProfileHtml
                }
            },
            secure: true,
            isPopup: true,
            isSurvey: true,
            resolve: {
                meta: metaResolver
            }
        });
}

export default angular
    .module('completeProfile', [
        CompleteProfileController.name
    ])
    .config(config);
