'use strict';

import angular from 'angular';
import eulaState from '../components/eula/eula'
import headerHtml from '../components/header/header.html'
import privacyState from '../components/privacy/privacy'
import notificationsDrawerHeaderHtml
    from '../notifications/notifications.drawer.header.html'
import notificationsDrawerHtml from '../notifications/notifications.drawer.html'
import acceptEula from './accept-eula/accept-eula'
import HomeController from './home.controller.js'
import homeHtml from './home.html'
import testHtml from '../test/test.html'
import menuHtml from './menu/menu.html'
import menu from './menu/menu.js'
import setPassword from './set-password/set-password'
import slider from './slider/slider.directive.js'

/* @ngInject */
function config($stateProvider, activityPreviewState, acceptEulaState,
                setPasswordState, getCookiesState, getPrivacyState) {
    $stateProvider
        .state({
            name: 'home',
            url: '/home',
            views: {
                'main-header@': {
                    controller: 'HeaderController',
                    templateUrl: headerHtml
                },
                'left-drawer-contents@': {
                    controller: 'MenuController',
                    controllerAs: 'vm',
                    templateUrl: menuHtml
                },
                'right-drawer-header@': {
                    //controller: 'NotificationController',
                    templateUrl: notificationsDrawerHeaderHtml
                },
                'right-drawer-contents@': {
                    //controller: 'NotificationController',
                    templateUrl: notificationsDrawerHtml
                },
                'main-contents@': {
                    controller: 'HomeController',
                    templateUrl: homeHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(layoutService) {
                layoutService.mainLayout();
            },
            secure: true
        })
        .state(activityPreviewState('home.activity-preview'))
        .state(acceptEulaState('home.accept-eula'))
        .state(getPrivacyState('home.privacy', '/privacy/'))
        .state(getCookiesState('home.cookies', '/cookies/'))
        .state(setPasswordState('home.set-password'))
        .state({
            name: 'test',
            url: '/test',
            views: {
                'main-header@': {
                    controller: 'HeaderController',
                    templateUrl: headerHtml
                },
                // 'left-drawer-contents@': {
                //     controller: 'MenuController',
                //     controllerAs: 'vm',
                //     templateUrl: menuHtml
                // },
                // 'right-drawer-header@': {
                //     //controller: 'NotificationController',
                //     templateUrl: notificationsDrawerHeaderHtml
                // },
                // 'right-drawer-contents@': {
                //     //controller: 'NotificationController',
                //     templateUrl: notificationsDrawerHtml
                // },
                'main-contents@': {
                    controller: 'TestController',
                    templateUrl: testHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(layoutService) {
                layoutService.mainLayout();
            },
            secure: true
        });
}

export default angular
    .module('home', [
        acceptEula.name,
        eulaState.name,
        privacyState.name,
        setPassword.name,
        menu.name,
        HomeController.name,
        slider.name
    ])
    .config(config);
