'use strict';

import angular from 'angular';
import 'chart.js';
import 'angular-chart.js';
import chartCanvasHtml from './chart-canvas.html'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'


/* @ngInject */
function ageDistribution($timeout, ChartJs, config) {
    const barColor = config.colors['so-green-rgba-0.5'];

    function _createDataSet(values) {
        return {
            label: 'Users in age range',
            data: values,
            backgroundColor: barColor,
            borderColor: barColor
        };
    }

    function _link(scope, element) {
        let ctx, chart;

        function _updateStats(stats) {
            if (angular.isDefined(stats)) {
                const series = stats.age_groups,
                    values = _map(series, 'value');

                chart.data.labels = _map(series, 'name');

                chart.resize();

                if (chart.data.datasets.length) {
                    _forEach(values, function _update(value, i) {
                        chart.data.datasets[0].data[i] = value;
                    });
                } else {
                    chart.data.datasets = [_createDataSet(values)];
                    chart.resize();
                }
                chart.update();
            }
        }

        function _init() {
            ctx = element.find('#chart-' + scope.$id);
            chart = new ChartJs.Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [
                        '0–14',
                        '15–24',
                        '25–34',
                        '35–44',
                        '45–54',
                        '55–64',
                        '65+'
                    ],
                    datasets: [_createDataSet([0, 0, 0, 0, 0, 0, 0])]
                },
                options: {
                    scales: {
                        xAxes: [{
                            scaleLabel: {
                                show: true,
                                labelString: 'Age Groups'
                            },
                            gridLines: {
                                show: false
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                show: true,
                                labelString: 'Number of Users'
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                suggestedMax: 10
                            }
                        }]
                    }
                }
            });

            scope.$watch('stats', _updateStats);
        }

        $timeout(_init, 0);
    }

    return {
        scope: {
            stats: '=ageDistribution'
        },
        link: _link,
        templateUrl: chartCanvasHtml
    };
}

export default angular
    .module('admin.users.ageDistribution', [
        'chart.js'
    ])
    .directive('ageDistribution', ageDistribution);
