'use strict';
import * as angular from 'angular';
/* @ngInject */
function editMembershipsService() {
    var _state = {
        removeMembers: false
    };
    return _state;
}
export var name = 'components.editMembershipsService';
export var editMembershipsServiceFactory = angular
    .module(name, [])
    .factory('editMemberships', editMembershipsService);
