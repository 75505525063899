'use strict';

import angular from 'angular';
import realTimeFilterHtml from './real-time-filter.html';
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _sortedIndexOf from 'lodash/sortedIndexOf'
import _transform from 'lodash/transform'
import _partial from 'lodash/partial'
import _isEmpty from 'lodash/isEmpty'

/* @ngInject */
function realTimeFilter($rootScope, $state, $q, activeFiltersService,
                        segmentPanelService, utility) {
    function _link(scope, element) {
        const _element = angular.element(element),
            _collapsible = _element.find('.panel-collapse'),
            _collapsibleState = {
                collapsibleElement: _collapsible,
                isExpanded: _collapsible.hasClass('in'),
                iconElement: _element.find('.collapse-icon'),
                icons: {
                    open: 'fa-minus-square',
                    closed: 'fa-plus-square'
                }
            },
            _stateSegmentsId = activeFiltersService.getStateId($state.$current,
                'segmentsName');

        function _togglePanel() {
            utility.togglePanelCollapse(_collapsibleState);
        }

        function _toggleSegmentsSetOperation(name) {
            activeFiltersService.toggleSetOperation(name);
        }

        function _initActiveFilters(activity) {
            if (!_filter(activeFiltersService.filters.activities,
                    ['activity_id', activity.activity_id]).length) {
                const activityFilter = {
                    activity_id: activity.activity_id,
                    panel_id: activity.panel_id,
                    name: activity.name,
                    enabled: true,
                    type: 'activity'
                };

                activeFiltersService.addActivity(activityFilter);
            }

            scope.filters = activeFiltersService.filters;
        }

        function _defineUserSegments(responses) {
            _forEach(scope.contributors, function _updateUser(contributor) {
                if (angular.isUndefined(contributor.inSegment)) {
                    contributor.inSegment = {};
                }
            });

            _forEach(responses, function _applySegment(response, segmentId) {
                const segmentPopulationIds = _map(response.users, 'user_id');
                _forEach(scope.contributors, function _updateUser(contributor) {
                    contributor.inSegment[segmentId] =
                        _sortedIndexOf(segmentPopulationIds,
                            contributor.user_id) !== -1;
                });
            });
        }

        function _updateSegments() {
            const segments = activeFiltersService.filters.queries,
                userIds = _map(scope.contributors, 'user_id');

            function _getSegmentUsers(result, segment) {
                result[segment.query_id] = segmentPanelService
                    .get(_stateSegmentsId, segment, userIds);
                return result;
            }

            return $q.all(_transform(segments, _getSegmentUsers, {}))
                .then(_defineUserSegments);
        }

        function _signalPopulationChanged() {
            segmentPanelService.signalPopulationChanged(_stateSegmentsId);
        }

        function _filtersChanged(event, data) {
            let populationNeedsUpdate;

            if (angular.isDefined(data.filter) && data.filter.type === 'query') {
                const userIds = _map(scope.contributors, 'user_id');

                switch (data.action) {
                    case 'add':
                        segmentPanelService.get(_stateSegmentsId, data.filter,
                            userIds);
                        break;
                    case 'toggle':
                        segmentPanelService.get(_stateSegmentsId, data.filter,
                            userIds);
                        break;
                    case 'remove':
                        segmentPanelService.remove(_stateSegmentsId, data.filter);
                }
                populationNeedsUpdate = true;
            } else {
                populationNeedsUpdate =
                    data.action === 'toggleSetOperation' &&
                    data.name === 'queries';
            }

            if (populationNeedsUpdate) {
                _updateSegments()
                    .then(_partial(segmentPanelService.refreshPopulation,
                        _stateSegmentsId))
                    .then(_signalPopulationChanged);
            }
        }

        function _contributorsChanged(contributors) {
            if (angular.isDefined(contributors) && !_isEmpty(contributors)) {
                _updateSegments()
                    .then(_partial(segmentPanelService.refreshPopulation,
                        _stateSegmentsId))
                    .then(_signalPopulationChanged);
            }
        }

        function _init() {
            scope.togglePanel = _togglePanel;

            utility.updateCollapseIcon(_collapsibleState);

            scope.toggleSegmentsSetOperation = _toggleSegmentsSetOperation;

            const destroyOnceWatcher = scope.$watch('activity',
                function _initActiveFiltersOnce(activity) {
                    if (angular.isDefined(activity) && !_isEmpty(activity)) {
                        _initActiveFilters(activity);
                        destroyOnceWatcher();
                    }
                });

            scope.$watchCollection('contributors', _contributorsChanged);

            const destroyListener =
                $rootScope.$on(activeFiltersService.updatedSignal,
                    _filtersChanged);

            scope.$on('$destroy', destroyListener);
        }

        _init();
    }

    return {
        scope: {
            contributors: '=realTimeFilter',
            activity: '='
        },
        link: _link,
        templateUrl: realTimeFilterHtml
    };
}

export default angular
    .module('activities.realTimeFilter', [])
    .directive('realTimeFilter', realTimeFilter);
