'use strict';

import angular from 'angular'
import _isString from 'lodash/isString'
import _reject from 'lodash/reject'


/* @ngInject */
function DeleteAccountController($log, $window, $state, api, activityService,
                                 panelService, config, user, metaService,
                                 gettext) {
    const vm = this;

    function _deleteAccount() {
        if (vm.currentUserId) {
            // $window.location = '/';
            user.delete({userId: vm.currentUserId, self_removal: true}).$promise
                .then(() => {
                    $window.location = '/';
                    // $state.go('^', undefined, {reload: true});
                }, (response) => {
                    vm.deleteError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                });
        } else {
            vm.deleteError = {
                message: gettext('Something went wrong; you don\'t appear to be logged in.')
            };
        }
    }

    function _leavePanel(panel) {
        const nextPanel = _reject(vm.panels, {panel_id: panel.panel_id})[0];

        user.leavePanel({
            userId: vm.currentUserId,
            panelId: panel.panel_id
        }).$promise
            .then(() => panelService.setCurrentPanel(nextPanel.panel_id, true),
                (response) => {
                    vm.leavePanelError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    }
                })
            .then(metaService.refresh)
            .then(panelService.refresh)
            // .then(activityService.refresh)
            .then(() => {
                $state.go('^', undefined, {reload: true});
            });
    }

    function _activate() {
        vm.organization = config.organization;
        vm.currentStateName = $state.$current.name;

        vm.closePopup = () => {
            $window.history.back();
        };

        vm.deleteAccount = _deleteAccount;
        vm.leavePanel = _leavePanel;

        metaService.get()
            .then((meta) => {
                $log.debug('delete-account.controller.: meta', meta);
                vm.currentUserId = meta.user.user_id;
            });

        panelService.get()
            .then((panels) => {
                vm.currentPanel = panels.current;
                $log.debug('delete-account.controller.: panels', panels);
                vm.panels = panels.open;

                vm.deleteAccountEmail = _isString(vm.currentPanel.support_email) ? vm.currentPanel.support_email : 'tos@encount.co';
            });

    }

    _activate();
}

export default angular
    .module('components.deleteAccountController', [])
    .controller('DeleteAccountController', DeleteAccountController);
