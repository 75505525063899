'use strict';

import angular from 'angular';
import legalModalService from './legal-modal.factory'

/* @ngInject */
function LegalModalController($state, $log, $window, $stateParams,
                              legalModalService, acceptEulaService) {
    const vm = this;

    function _closePopup() {
        const modalLevel = legalModalService.getModalLevel();
        legalModalService.onExit(modalLevel);
        delete acceptEulaService.registration;
        $window.history.go(-(modalLevel));
    }

    function _back() {
        legalModalService.onExit();
        $window.history.back();
    }

    function _activate() {
        vm.modalLevel = legalModalService.getModalLevel();

        if (angular.isDefined(acceptEulaService.registration)) {
            vm.registration = acceptEulaService.registration;
        }

        vm.goBack = _back;

        vm.closePopup = _closePopup;
    }

    _activate();
}

export default angular
    .module('components.legalFooterController', [
        legalModalService.name
    ])
    .controller('LegalFooterController', LegalModalController);
