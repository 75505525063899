'use strict';

import angular from 'angular';
import 'emoji-assets/sprites/joypixels-sprite-32.css'

import joypixels from 'emoji-toolkit'
import _assignIn from 'lodash/assignIn'
import _debounce from 'lodash/debounce'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _forEachRight from 'lodash/forEachRight'
import _forOwn from 'lodash/forOwn'
import _has from 'lodash/has'
import _last from 'lodash/last'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _replace from 'lodash/replace'
import _sortedIndex from 'lodash/sortedIndex'
import _sortedIndexOf from 'lodash/sortedIndexOf'
import _split from 'lodash/split'
import _throttle from 'lodash/throttle'
import _transform from 'lodash/transform'
import _values from 'lodash/values'
import raf from 'raf'

import typingTracker from './typing-tracker.factory'


function RealTimeEngineException(message) {
    this.message = message;
    this.name = 'RealTimeEngineException';
}


/* @ngInject */
function realTimeEngine($resource, $rootScope, $stateParams, $interval, $document,
                        $sce, $log, api, realTime, utility, media,
                        selfMonitoring, connectedUsersService, typingTracker,
                        config, markdownIt, activeFiltersService, selectionService,
                        segmentPanelService) {
    const _messageActions = [
            'add_comment',
            'add_message',
            'delete_message',
            'edit_message',
            'get_media_upload_info',
            'vote'
        ],
        _threadBlockActions = [
            'get_all_thread_messages',
            'get_next_thread_message_block',
            'get_next_thread_topic_block'
        ],
        _blockActions = [
            'get_all_messages',
            'get_next_message_block',
            'get_next_topic_block'
        ],
        _resource = $resource(`${api.url}/api/:activityType/:activityId/:subResource/`, {
            activityId: '@activity_id',
            activityType: '@activity_type'
        }, {
            getPrivateContents: {
                method: 'GET',
                params: {subResource: 'private_contents'},
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        }),
        _isIntializedSignal = 'realTimeEngineIsIntialized',
        _engines = {};

    function _registerMessageForMonitoring(message) {
        const externalId = message.id !== null ?
            message.id : parseInt(message.body, 10);
        let action;

        switch (message.action) {
            case 'REMOVE_MESSAGE':
                action = 'delete_message';
                break;
            case 'NONE':
                action = message.in_reply_to === null ? 'add_message' :
                    'add_comment';
                break;
        }

        selfMonitoring.registerAsyncEvent(
            new selfMonitoring.MonitoringEvent(externalId,
                `${action}:broadcast`, _merge({
                    message_id: externalId
                }, realTime.getConnectionInfo())));
    }

    function _createEditable(params) {
        params = params || {};

        return {
            body: '',
            title: '',
            attachments: [],
            is_private: params.isPrivate || false,
            isEditable: params.isEditable || true,
            notifyByEmail: params.notifyByEmail || false,
            showNotifyByEmail: params.showNotifyByEmail || false
        };
    }

    function MessageStore() {
        const messageIndex = {
                null: {
                    id: null,
                    in_reply_to: null,
                    body: '1st induction step',
                    replyIndex: [],
                    replies: []
                }
            },
            stats = {
                shownMessageCount: 0,
                totalMessageCount: 0,
                hiddenMessageCount: () => stats.totalMessageCount - stats.shownMessageCount
            };
        let _filters,
            _stateSegmentsId;

        function _isAnyoneIncluded() {
            return !selectionService.isEmpty(_filters.users.collection);
        }

        function _isUserExcluded(user) {
            return selectionService.isExcluded(_filters.users.collection,
                user);
        }

        function _isUserInPopulation(user, userIds) {
            return _sortedIndexOf(userIds, user.user_id) !== -1;
        }

        function _applyFiltersSingle(message, population) {
            if (population.isFiltered) {
                message.isFilteredOut =
                    !_isUserInPopulation(message.user, population.userIds) ||
                    _isAnyoneIncluded() && _isUserExcluded(message.user);
            } else {
                message.isFilteredOut =
                    _isAnyoneIncluded() && _isUserExcluded(message.user);
            }
        }

        function _getLeafMessages(node, leafs) {
            if (!node.replies.length) {
                leafs.push(node);
            } else {
                _forEach(node.replies, function _gatherLeafs(reply) {
                    _getLeafMessages(reply, leafs);
                });
            }

            return leafs;
        }

        function _getPathToRoot(node, path) {
            path.push(node);

            if (node.in_reply_to !== null) {
                const parent = messageIndex[node.in_reply_to];
                _getPathToRoot(parent, path);
            }

            return path;
        }

        function _makeThreadsVisible(node) {
            const pathToRoot = _getPathToRoot(node, []);
            let hasVisibleChild = false;

            _forEach(pathToRoot, function _markThreadIfNeeded(node) {
                node.isNecessaryLink =
                    // _isAnyoneIncluded() &&
                    node.isFilteredOut &&
                    hasVisibleChild;

                if (!node.isFilteredOut) {
                    hasVisibleChild = true;
                }
            });
        }

        function _doApplyFilters(message, population) {
            let leafs;

            if (angular.isDefined(message)) {
                leafs = _getLeafMessages(message, []);

                _applyFiltersSingle(message, population);
                _forEach(leafs, _makeThreadsVisible);
            } else {
                // Filter to ignore the (symbolic) root node.
                const messages = _filter(_values(messageIndex), 'id');

                leafs = _getLeafMessages(messageIndex[null], []);

                _forEach(messages, function _callApplyFiltersSingle(message) {
                    _applyFiltersSingle(message, population);
                });
                _forEach(leafs, _makeThreadsVisible);
            }
        }

        function _applyFilters(message) {
            if (angular.isUndefined(_filters)) {
                throw new RealTimeEngineException(
                    'Missing filters to be applied.');
            }

            segmentPanelService.getPopulation(_stateSegmentsId)
                .then(_partial(_doApplyFilters, message));
        }

        function _setFilters(filters) {
            _filters = filters;
        }

        function _setStateSegmentsId(stateSegmentsId) {
            _stateSegmentsId = stateSegmentsId;
        }

        function _insert(message) {
            const parent = messageIndex[message.in_reply_to],
                i = _sortedIndexOf(parent.replyIndex, message.id);

            if (i === (-1)) {
                // Insert.
                const j = _sortedIndex(parent.replyIndex, message.id);

                message.replyIndex = [];
                message.replies = [];

                parent.replyIndex.splice(j, 0, message.id);
                parent.replies.splice(j, 0, message);

                if (message.placeholder) {
                    stats.totalMessageCount =
                        message.thread.message_count;
                } else {
                    stats.shownMessageCount += 1;
                }
            } else {
                // Replace.
                const old = parent.replies[i];
                if (old && (old.id === message.engine.config.threadID)) {
                    return;
                }

                message.replyIndex = old.replyIndex;
                message.replies = old.replies;

                parent.replies[i] = message;
            }

            messageIndex[message.id] = message;
//                    $log.debug('messageIndex', messageIndex);
            _applyFilters(message);
        }

        function _getMessage(messageID) {
            return messageIndex[messageID];
        }

        function _remove(message) {
            const parent = messageIndex[message.in_reply_to];
            let current,
                i;

            if (parent) {
//                        $log.debug('parent', parent);
                i = _sortedIndexOf(parent.replyIndex, message.id);
//                        $log.debug('i', i);

                if (i !== -1) {
                    current = parent.replies[i];
                    _forEachRight(current.replies, _remove);
                } else {
                    $log.debug('Couldn\'t find message to delete.');
                }

                parent.replyIndex.splice(i, 1);
                parent.replies.splice(i, 1);
            } else {
                $log.debug('Couldn\'t find parent of message to delete.');
            }

            if (!message.placeholder) {
                stats.shownMessageCount -= 1;
            }
            delete messageIndex[message.id];

//                    $log.debug('messageIndex', messageIndex);
        }

        function _removeByID(messageID) {
            const message = _getMessage(messageID);

            if (message) {
                _remove(message);
            } else {
                $log.debug('Unable to find message to be removed.');
            }
        }

        return {
            messages: messageIndex[null].replies,
            stats: stats,
            applyFilters: _applyFilters,
            insert: _insert,
            remove: _removeByID,
            getMessage: _getMessage,
            setFilters: _setFilters,
            setStateSegmentsId: _setStateSegmentsId
        };
    }

    function _stateParamsToKey($stateParams) {
        return angular.isDefined($stateParams.threadId) ?
            `${$stateParams.activityType}.${$stateParams.activityId}.${$stateParams.threadId}` :
            `${$stateParams.activityType}.${$stateParams.activityId}`
    }

    function _createEngine(discriminator, $scope) {
        const _config = {
                type: discriminator,
                channelTypes: []
            },
            store = new MessageStore(),
            _expandedSubTopics = {
                message: 'messages',
                status: 'status_updates'
            };
        let _filters,
            _self;

        function _register(engineKey, what) {
            _engines[engineKey] = what;
        }

        function _getTopicName(subTopic) {
            if (_has(_expandedSubTopics, subTopic)) {
                subTopic = _expandedSubTopics[subTopic];
            }

            return `/topic/${$stateParams.activityType}.${$stateParams.activityId}.${subTopic}`;
        }

        function _prepareSubscriptions(channelTypes, activityType,
                                       activityID) {
            function _defineSubscription(result, type) {
                result[type.kind] = {
                    name: `${activityType}.${activityID}.${type.kind}`,
                    channels: [_getTopicName(type.name)]
                };
            }

            // On the difference between reduce() and transform():
            // https://stackoverflow.com/a/21536978/126273
            return _transform(channelTypes, _defineSubscription, {});
        }

        function _constructActivityKey(kind, activityParams) {
            return `${activityParams.activityType}.${activityParams.activityID}.${kind}`;
        }

        function _constructThreadedActivityKey(kind, activityParams) {
            return `${activityParams.activityType}.${activityParams.activityID}.thread.${activityParams.threadID}.${kind}`;
        }

        function _getFilterName(kind, activityParams) {
            let name;

            switch (activityParams.activityType) {
                case 'chat':
                    name = _constructActivityKey(kind, activityParams);
                    break;
                case 'forum':
                    name = ((angular.isDefined(activityParams.threadID) &&
                        activityParams.threadID !== null) ?
                        _constructThreadedActivityKey :
                        _constructActivityKey)(kind, activityParams);
                    break;
            }

            return name;
        }

        function _handleFilterUpdate(activityParams, event, data) {
            if (activityParams.activityType === data.activityType &&
                activityParams.activityID === data.activityId) {
                store.applyFilters();
            }
        }

        function _handleUpdatedSegments(stateSegmentsId, event, data) {
            if (data.stateId === stateSegmentsId) {
                store.applyFilters();
            }
        }

        function _init(options) {
            const stateSegmentsId = _getFilterName('segments',
                options.activity.params);

            _assignIn(_config, {
                params: options.activity.params,
                subscriptions: _prepareSubscriptions(options.channelTypes,
                    _config.type,
                    options.activity.activity_id),
                compiledActions: options.activity.compiledActions,
                meta: options.meta
            }, options);

            const subscription = connectedUsersService
                .subscribe(_config.type, options.activity.params.activityID,
                    options.activity.params.threadID);

            _filters = {
                users: activeFiltersService
                    .getSelectionsFilter(_getFilterName('users',
                        options.activity.params))
            };

            store.setFilters(_filters);
            store.setStateSegmentsId(stateSegmentsId);

            const destroyFilterUpdateListener =
                    $rootScope.$on('realTimeMessagingFilterUpdated',
                        _partial(_handleFilterUpdate, options.activity.params)),
                destroySegmentUpdateListener =
                    $rootScope.$on(segmentPanelService.updatedSignal,
                        _partial(_handleUpdatedSegments, stateSegmentsId));

            $scope.$on('$destroy', function _destroy() {
                subscription.unsubscribe();
                destroyFilterUpdateListener();
                destroySegmentUpdateListener();
            });

            const exported = {
                activity: _config.activity,
                messages: store.messages,
                stats: store.stats,
                currentUser: _config.currentUser,
                contributors: subscription.contributors,
                filters: _filters,
            };

            _assignIn($scope, exported);

            $rootScope.$emit(_isIntializedSignal, _assignIn({
                activityType: _config.type,
                activityId: options.activity.params.activityID,
                threadId: options.activity.params.threadID
            }, exported));

            _status.engineIsIntialized = true;
            _config.currentUser.isSupervisor = !!_config.meta.may_see_full_names;
            // _config.currentUser.isObserver = _config.meta.user_observes.length > 0;
        }

        function _addStats() {
            return store.stats;
        }

        function _addMessageActions(message) {
            const params = _assignIn({
                    messageID: message.id,
                    inReplyToID: message.in_reply_to !== null ?
                        message.in_reply_to : 0,
                    skipToID: message.placeholder ?
                        message.placeholder.skip_to_id : null
                }, _config.params),
                handlers = {};
            let blockActions;

            if (params.threadID) {
                blockActions = _threadBlockActions;
                message.threadID = params.threadID;
            } else {
                blockActions = _blockActions;
            }

            function _composePartialHandler(result, action) {
                // Create a partial function for 'action' which selects
                // the correct http method and url as defined in the activity's
                // action_url (+ method) map. Yields an $http promise.
                result[action] = _partial(
                    utility.httpMethodHandlers[
                        _config.compiledActions[action].method],
                    _config.compiledActions[action].url(params));
            }

            _transform(_messageActions, _composePartialHandler, handlers);
            _transform(blockActions, _composePartialHandler, handlers);

            return handlers;
        }

        function _addMessageAuthorInfo(message) {
            return {
                name: _config.currentUser.isSupervisor ?
                    message.user.display_name : message.user.first_name,
                profileInfo: message.user.profile_info,
                registrationDate: message.user.registration_date,
                isSupervisor: message.user.is_moderator,
                isCurrentUser: message.user.user_id ===
                    _config.currentUser.user_id
            };
        }

        function _handleMediaMessage(message) {
            const i = message.body.indexOf('<'),
                media_url = _split(message.body.substring(0, i), ' ')[1],
                media_type = media.getType(media_url);

            message.attachments = [
                {
                    type: media_type,
                    url: media_url
                }
            ];
            message.body = '';
            message.isStimulus = true;
        }

        function _prepareMessage(message) {
            if (message.action === 'SHOW_MEDIA') {
                _handleMediaMessage(message);
                // $log.debug('message.action', message.action);
                // $log.debug('message', message);
            }

            message.actions = _addMessageActions(message);
            message.stats = _addStats();
            message.engine = _self;

            message.currentUser = $scope.currentUser;
            message.author = _addMessageAuthorInfo(message);

            return message;
        }

        function _scrollToLastMessage(behavior = 'smooth', force = false, doRAF = true, messageId=undefined) {
            if ((_status.isAutoScrollEnabled || messageId) &&
                store.messages.length && (_status.isAtBottom || force)) {

                const message = angular.isUndefined(messageId) ?
                    // eslint-disable-next-line lodash/prefer-lodash-method
                    $document.find(`#message-${_last(store.messages).id}`) :
                    // eslint-disable-next-line lodash/prefer-lodash-method
                    $document.find(`#message-${messageId}`);

                if (message.length) {
                    const wrapper = doRAF ? raf : (functor) => functor();
                    wrapper(() => {
                        message[0].scrollIntoView({
                            behavior: behavior,
                            block: 'start',
                            inline: 'nearest'
                        });
                    });
                }
            }
        }

        const scrollToLastMessageWaitMs = 750;
        const _debouncedScrollToLastMessage = _debounce(_scrollToLastMessage, scrollToLastMessageWaitMs);

        function _enableAutoScroll(doEnable) {
            _status.isAutoScrollEnabled = doEnable;
        }

        function _tryDecodeURI(string, what, messageId) {
            let decoded;
            try {
                decoded = decodeURI(string);
            } catch (e) {
                $log.debug(`decodeURI(${what}="${string}") for message #${messageId} failed.`);
                decoded = string;
            }

            return decoded;
        }

        function _emitUpdateAttachments(message) {
            $rootScope.$emit('updateAttachments', {
                messageId: message.id
            });
        }

        function _insertMessage(message) {
            store.insert(_prepareMessage(message));
        }

        const _renderPipeline = (string, what, messageId) =>
            markdownIt.render(joypixels.toImage(
                _tryDecodeURI(string, what, messageId)
            ));

        function _handleHtmlContents(message) {
            if (message.title) {
                message.htmlTitle = $sce.trustAsHtml(
                    _renderPipeline(message.title, 'message.title', message.id)
                );
            } else {
                message.htmlTitle = null;
            }

            if (message.body) {
                message.body = _replace(message.body,
                    /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');

                message.htmlBody = $sce.trustAsHtml(
                    _renderPipeline(message.body, 'message.body', message.id)
                );
            } else {
                message.htmlBody = undefined;
            }
        }

        function _prepareContents(message) {
            if (message.is_private) {
                message.isNotForMe = true;
                _resource.getPrivateContents({
                    activityType: _config.params.activityType,
                    activityId: _config.params.activityID,
                    message_id: message.id
                }).$promise
                    .then(function _resolve(response) {
                        message.body = response.body;
                        message.attachments = response.attachments;
                        message.isNotForMe = false;

                        _handleHtmlContents(message);
                        _emitUpdateAttachments(message);
                    });
            }

            _handleHtmlContents(message);

            return message;
        }

        function _prepareMessages(result) {
            _forEach(result.data.messages, function _insertMessageInStore(message) {
                _insertMessage(_prepareContents(message));
            });
        }

        function _getLastMessages(last_messages_url) {
            return api.get(last_messages_url);
        }

        function _messageBelongs(message) {
            /* FIXME: This function should probably be made obsolete by
             properly handling new arriving messages that aren't
             replies to any already shown/inserted messages. */
            return !(_config.params.threadID && !message.in_reply_to);
        }

        function _acknowledgeContribution(message) {
            if (message.author.isCurrentUser) {
                _config.meta.user_is_contributor = true;
            }
        }

        function _onMessageFrame(event, frame) {
            const message = angular.fromJson(frame.body);

            if (_messageBelongs(message)) {
                const wasAtBottom = _status.isAtBottom;
                _registerMessageForMonitoring(message);
                _insertMessage(_prepareContents(message));
                _acknowledgeContribution(message);

                $scope.$applyAsync(() => {
                    _scrollToLastMessage('smooth', wasAtBottom || message.author.isCurrentUser, true, message.id);
                    _debouncedScrollToLastMessage('smooth', wasAtBottom || message.author.isCurrentUser, true, message.id);
                });
            }
        }

        function _onUserFrame(event, frame) {
            return _onMessageFrame(event, frame);
        }

        function _getCurrentActivityDescription() {
            return {
                activity_id: $stateParams.activityId,
                activity_type: $stateParams.activityType
            };
        }

        function _onStatusFrame(event, frame) {
            const message = angular.fromJson(frame.body),
                state = _getCurrentActivityDescription();

            switch (message.action) {
                case 'REMOVE_MESSAGE':
                    _registerMessageForMonitoring(message);
                    $scope.$apply(function () {
                        store.remove(parseInt(message.body, 10));
                    });
                    break;
                case 'IS_CONNECTED':
                /* Fallthrough! */
                case 'CONNECT':
                    connectedUsersService.noticeParticipantPulse(
                        state.activity_type, state.activity_id, message.user_id);
                    break;
                case 'TYPING_STATUS':
                    connectedUsersService.noticeParticipantTypingStatus(
                        state.activity_type, state.activity_id, message.user_id,
                        message.is_typing);
                    break;
                default:
                    $log.debug('Unhandled action', message);
            }
        }

        function _onUnhandledFrame(event, frame) {
            const message = angular.fromJson(frame.body);

            $log.debug('Received (unhandled) message:', message);
        }

        function _subscribeToRealTime(messageHandlers) {
            const subscriptions = _config.subscriptions,
                deregisters = [];

            _forOwn(messageHandlers, function _createListener(handler, type) {
                deregisters.push(
                    $rootScope.$on(subscriptions[type].name, handler)
                );
            });

            _forEach(_config.channelTypes, function _subscribeToChannel(type) {
                realTime.subscribe(subscriptions[type.kind].channels,
                    subscriptions[type.kind].name);
            });

            function _cleanUpSubscriptions() {
                _forEachRight(_config.channelTypes, (type) => {
                    realTime.unsubscribe(subscriptions[type.kind].channels);
                });

                _forEach(deregisters, (deregeister) => deregeister());
            }

            $scope.$on('$destroy', _cleanUpSubscriptions);
        }

        function _createEditableWithActions(params) {
            const editable = _createEditable(params);

            editable.get_media_upload_info =
                _addMessageActions({id: null}).get_media_upload_info;

            return editable;
        }

        function _createNewMessagePlaceholders() {
            const notifyByEmail = (_config.type !== 'chat' &&
                _config.currentUser.isSupervisor),
                editable = _createEditableWithActions({
                    notifyByEmail: false /*notifyByEmail*/,
                    showNotifyByEmail: notifyByEmail
                });
            let message;

            if (_config.threadID) {
                message = store.getMessage(_config.threadID);
            } else {
                message = {id: null};
                message.actions = _addMessageActions(message);
            }

            // $scope.next = {
            //     editable: editable,
            //     message: message,
            //     isEdit: false
            // };

            return {
                editable: editable,
                message: message,
                isEdit: false
            };
        }

        function _broadcastReadyStatus() {
            $scope.$broadcast('realTimeEngineIsReady')
        }

        function _broadcastPulse() {
            realTime.send(_config.meta.status_channels[0], {}, angular.toJson({
                user_id: _config.meta.user.user_id,
                action: 'IS_CONNECTED'
            }));
        }

        function _trackTyping(state, params) {
            const extendedParams = _merge(params, {
                user_id: _config.meta.user.user_id
            }, _getCurrentActivityDescription());

            switch (state) {
                case 'active':
                    typingTracker.refresh(extendedParams,
                        _config.meta.status_channels[0]);
                    break;
                case 'stopped':
                    typingTracker.stop(extendedParams,
                        _config.meta.status_channels[0]);
                    break;
            }
        }

        function _startPublishingConnectedStatus() {
            _broadcastPulse();

            const promise = $interval(_broadcastPulse,
                config.realTimeMessaging.reportConnectedInterval, 0, false);

            $scope.$on('$destroy', function _cancelInterval() {
                $interval.cancel(promise);
            });
        }

        const messageHandlers = {
                message: _onMessageFrame,
                status: _onStatusFrame,
                server: _onUnhandledFrame,
                user: _onUserFrame
            },
            _status = {
                engineIsIntialized: false,
                messagesAreLoaded: false,
                isAtBottom: true,
                isAutoScrollEnabled: true
            };

        return {
            self: _self,
            config: _config,
            store: store,
            status: _status,
            register: function _registerThis(key) {
                _register(key, this);
            },
            init: function _initWrapper(options) {
                _self = this;
                _init(options);
            },
            prepareMessages: _prepareMessages,
            getLastMessages: _getLastMessages,
            subscribeToRealTime: _partial(_subscribeToRealTime,
                messageHandlers),
            startPublishingConnectedStatus: _startPublishingConnectedStatus,
            trackTyping: _throttle(_trackTyping, config.realTimeMessaging.typingReporterThrottleTimeMs),
            typingStopped: typingTracker.stop,
            createEditableWithActions: _createEditableWithActions,
            createNewMessagePlaceholders: _createNewMessagePlaceholders,
            scrollToLastMessage: _debouncedScrollToLastMessage,
            scrollToLastMessageNow: _scrollToLastMessage,
            enableAutoScroll: _enableAutoScroll,
        };
    }

    function _getEngine(engineKey) {
        return _engines[engineKey];
    }

    function _initializeEditable(editable, message) {
        editable.title = message.title;
        editable.body = message.body;
        editable.attachments = message.attachments.slice(0);
        editable.is_private = message.is_private;
        editable.submissionInProgress = false;
    }

    function _resetEditable(editable, isPrivate) {
        editable.title = '';
        editable.body = '';
        editable.attachments = [];
        editable.is_private = isPrivate;
    }

    function _getMonitoringProbe(completeState) {
        return new selfMonitoring.MonitoringProbe(
            _merge({
                domain: $stateParams.activityType,
                completeState: completeState,
                extra: {
                    activityId: $stateParams.activityId
                }
            }, config.selfMonitoring.probe.realTimeMessaging));
    }

    function _generateChannelSpec(kind, name) {
        return {
            kind: kind,
            name: angular.isDefined(name) ? name : kind
        };
    }

    return {
        create: _createEngine,
        stateParamsToKey: _stateParamsToKey,
        getEngine: _getEngine,
        generateChannelSpec: _generateChannelSpec,
        createEditable: _createEditable,
        getMonitoringProbe: _getMonitoringProbe,
        initializeEditable: _initializeEditable,
        resetEditable: _resetEditable,
        isIntializedSignal: _isIntializedSignal,
    };
}

export default angular
    .module('components.realTimeEngine.service', [
        typingTracker.name
    ])
    .factory('realTimeEngine', realTimeEngine);
