'use strict';

import angular from 'angular';

import activityStatsService from './activity-stats-service.factory.js';
import activityStat from './activity-stat.directive.js';
import activityStats from './activity-stats.directive.js';
import trendLine from './trend-line.directive.js';

export default angular
    .module('activities.activityStats', [
        activityStatsService.name,
        activityStat.name,
        activityStats.name,
        trendLine.name
    ]);


