'use strict';

import angular from 'angular';
import _merge from 'lodash/merge'


/* @ngInject */
function UsersController($state, gettext, activeFiltersService, user,
                         utility) {
    const vm = this,
        selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName);

    vm.$state = $state;

    function _getUserSampleQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'user_id', true);
        return _merge(user.queryParamsFromFilters(filters), {
            included_entries: vm.includedEntries
        });
    }

    function _goToExport() {
        if (!(vm.selectionsFilter.collection &&
            vm.selectionsFilter.collection.count)) {
            return;
        }

        const userSampleQueryParams = _getUserSampleQueryParams();
        $state.go('admin.users.export-user-data', {
            userSampleQueryParams: userSampleQueryParams
        });
    }

    function _activate() {
        vm.tabs = [
            {
                title: gettext('List'),
                icon: 'fa-list',
                state: 'admin.users.list'
            },
            {
                title: gettext('Stats'),
                icon: 'fa-bar-chart',
                state: 'admin.users.stats'
            },
            {
                title: gettext('Sample'),
                icon: 'fa-filter',
                state: 'admin.users.segment',
                hideWhenInactive: true
            },
            {
                title: gettext('Create User'),
                icon: 'fa-edit',
                state: 'admin.users.create',
                hideWhenInactive: true
            },
            {
                title: gettext('Edit User'),
                icon: 'fa-edit',
                state: 'admin.users.edit',
                hideWhenInactive: true
            },
            {
                title: gettext('Export User Data'),
                icon: 'fa-table',
                state: 'admin.users.export-user-data',
                hideWhenInactive: true
            },
            {
                title: gettext('Message Users'),
                icon: 'fa-envelope-o',
                state: 'admin.users.message-users',
                hideWhenInactive: true
            }
        ];

        vm.tabs.activeTab = 0;

        vm.selectionsFilter =
            activeFiltersService.getInheritedSelectionsFilter($state.$current);
        vm.goToExport = _goToExport;
    }

    _activate();
}

export default angular
    .module('admin.users.UsersController', [])
    .controller('UsersController', UsersController);
