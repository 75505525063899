'use strict';

import angular from 'angular';
import timezoneAnswerHtml from './timezone-answer.html'

/* @ngInject */
function timezoneAnswer() {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: timezoneAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.timezoneAnswer', [])
    .directive('timezoneAnswer', timezoneAnswer);
