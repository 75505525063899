'use strict';

import angular from 'angular';
import _difference from 'lodash/difference'
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import _merge from 'lodash/merge'

import targetPanelsList from './target-panels-list.directive'

/* @ngInject */
function EditMembershipsController($state, $window, activeFiltersService, user, panelAdmin, editMemberships) {
    const vm = this;

    function _successfullyChanged() {
        const filter = activeFiltersService
            .getSelectionsFilter('admin.users.edit-memberships.panels',
                'panel_id');

        activeFiltersService.removeFilter(filter);
        $state.go('admin.users.list', {}, {reload: true});
    }

    function _handleMessageError(response) {
        vm.messageError = {
            message: _isString(response.data) ?
                response.statusText :
                response.data.message
        };
    }

    function _getQueryParams() {
        const panelsFilter =
                activeFiltersService.getQueryConstraints('admin.users.edit-memberships.panels', 'panel_id', true),
            userIds = _map(vm.users, 'user_id');

        const panelsQueryParams = panelAdmin.queryParamsFromFilters(panelsFilter);
        const panelItemsQueryParams = panelAdmin.itemsFilterToQueryParams(panelsFilter.items);

        return {
            user_ids: userIds,
            panel_ids: panelItemsQueryParams.panel_ids !== null ?
                panelItemsQueryParams.panel_ids :
                _difference(panelsQueryParams.panel_ids, panelItemsQueryParams.exclude_panel_ids),
            action: vm.editMemberships.removeMembers ? 'remove' : 'add',
            already_checked: false
        };
    }

    function _moveMembers() {
        if (!(vm.users && vm.users.length &&
            vm.panelIds && vm.panelIds.count &&
            vm.editMemberships && angular.isDefined(vm.editMemberships.removeMembers))) {
            return;
        }

        const params = _getQueryParams();

        vm.messageError = null;

        panelAdmin.changeMemberships(params).$promise
            .then((response) => {
                if (response.status === 'warning' &&
                    response.what === 'Users will be deleted.') {
                    $state.go('admin.users.edit-memberships.deleting-accounts', {
                        params: params,
                        users: vm.users,
                        wouldBeDeletedIds: response.user_ids,
                    })
                } else {
                    _successfullyChanged(response);
                }

            }, _handleMessageError);
    }

    function _getUserQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints('admin.users',
                'user_id', true);

        return _merge(user.queryParamsFromFilters(filters), {
            extra_columns: [
                'email_address'
            ],
            sql: false
        });
    }

    function _activate() {
        user.query(_getUserQueryParams()).$promise
            .then(function _updateRows(response) {
                vm.total = response.total;
                vm.users = response.users;
            });

        vm.messageError = null;

        vm.topLevelView = 'edit-memberships';
        vm.messageView = 'editor';

        vm.moveMembers = _moveMembers;
        vm.cancel = () => {
            $window.history.back();
        };

        if (angular.isUndefined(editMemberships.removeMembers)) {
            editMemberships.removeMembers = false;
        }
        vm.editMemberships = editMemberships;

        vm.panelIds = activeFiltersService
            .getSelectionsFilter('admin.users.edit-memberships.panels',
                'panel_id').collection;
    }

    _activate();
}

// I don't know why, but importing TypeScript files from JavaScript files breaks `/* @ngInject */` decorations
// if imports are not performed _below_ the decorated function.
import {editMembershipsServiceFactory} from './edit-memberships-service.factory'

export default angular
    .module('admin.users.EditMembershipsController', [
        editMembershipsServiceFactory.name,
        targetPanelsList.name
    ])
    .controller('EditMembershipsController', EditMembershipsController);
