'use strict';

import angular from 'angular';
import growlTemplate from './growl.html';
import growlAlertTemplate from './growl-alert.html';
import _forEach from 'lodash/forEach'
import _pull from 'lodash/pull'


/* @ngInject */
function growl($rootScope, utility) {
    /* @ngInject */
    function GrowlController($scope) {
        const self = this;

        function _onAlert(e, frame) {
            //$log.debug("Growl alert received.");
            self.notifications.push(angular.fromJson(frame.body));
        }

        function _onError(e, msg) {
            //$log.debug("Growl error received.");
            self.notifications.push({
                subject: msg,
                type: 'error'
            });
        }

        function _onRemoved() {
            //$log.debug("'removed' broadcast received.");
            $scope.$digest();
        }

        function _onRemoveAll() {
            //$log.debug("'growl:removeAll' broadcast received.");

            if (angular.isDefined(self.notifications)) {
                utility.emptyArray(self.notifications);
            }
        }

        function _init() {
            $scope.notifications = self.notifications = [];
            $scope.removalDelay = 5000;

            const deregisterers = [
                $rootScope.$on('alert', _onAlert),
                $rootScope.$on('error', _onError),
                $rootScope.$on('removed', _onRemoved),
                $rootScope.$on('growl:removeAll', _onRemoveAll)
            ];

            $scope.$on('$destroy', function _deregisterWatchers() {
                _forEach(deregisterers, (deregisterer) => deregisterer());
            });
        }

        _init();
    }

    return {
        restrict: 'A',
        templateUrl: growlTemplate,
        controller: GrowlController
    };
}

/* @ngInject */
function growlAlert($timeout, $rootScope) {
    function _link(scope, element, attrs, growlController) {
        function _removeNotification(emitSignal) {
            _pull(growlController.notifications, scope.notification);

            if (emitSignal) {
                $rootScope.$emit('removed');
            }
        }

        scope.removeNotification = _removeNotification;

        $timeout(_removeNotification, scope.removalDelay, true);
    }

    return {
        restrict: 'A',
        require: '^growl',
        scope: {
            notification: '=growlAlert',
            removalDelay: '='
        },
        link: _link,
        templateUrl: growlAlertTemplate
    };
}

export default angular.module('growl', [])
    .directive('growl', growl)
    .directive('growlAlert', growlAlert);
