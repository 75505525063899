'use strict';

import "core-js/modules/es.array.join.js";
import angular from 'angular';

var gettext = function gettext(string) {
  return string;
};

var _realTimeReportConnectedInterval = 10 * 1000; // milliseconds.


export default angular.module('lib.config', []).constant('config', {
  activities: {
    preOpeningMinutes: 15
  },
  admin: {
    showMenu: true,
    isFullMenuEnabled: true,
    entitySearchMaxValueItemsCount: 10,
    groupIds: {
      members: 2
    }
  },
  // adform: {
  //     // Used by Nettavisen (client of Sher and Sher Blogg panels).
  //     isEnabled: false,
  //     pm: 393700,
  //     trackPanels: {
  //         141: 'Sher',
  //         145: 'Sher Blogg'
  //     }
  // },
  angular: {
    enableDebugDOMInfo: Boolean(),
    enableDebugLogLevel: true
  },
  apiUrl: '',
  // 'https://opinion.onlivepanel.com',
  baseUrl: 'https://opinion.onlivepanel.com',
  colors: {
    'so-green-rgba-0.5': 'rgba(172, 201, 157, .5)',
    'gender-color-female-0.5': 'rgba(233, 53, 120, .5)',
    'gender-color-female-1.0': 'rgba(233, 53, 120, 1)',
    'gender-color-male-0.5': 'rgba(117, 169, 249, .5)',
    'gender-color-male-1.0': 'rgba(117, 169, 249, 1)',
    'message-sent': 'rgba(117, 169, 249, 1)',
    'message-error': 'rgba(233, 53, 120, 1)',
    'message-opened': 'rgba(172, 201, 157, 1)',
    'yes-0.5': 'rgba(172, 201, 157, .5)',
    'yes-1.0': 'rgba(172, 201, 157, 1)',
    'no-0.5': 'rgba(246, 156, 85, .5)',
    'no-1.0': 'rgba(246, 156, 85, 1)'
  },
  customUrlScheme: 'encount://',
  // The 'date' input type is supported mainly on mobile browsers and
  // Chrome, and is haunted with iScroll bugs.
  dateInputType: 'text',
  dateValidRegex: /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
  emoji: {
    size: 24
  },
  facebook: {
    apiVersion: 'v5.0',
    cookie: true,
    permissions: ['email', // 'user_friends',
    'user_birthday', 'user_location'].join(',')
  },
  fonts: {
    'chartJs': 'Roboto-Regular, Helvetica, Arial, sans-serif'
  },
  forum: {
    isDislikeEnabled: false,
    showCreateThread: false
  },
  getDebounceOptions: function _getDebounceOptions() {
    return {
      updateOn: 'default blur',
      debounce: {
        "default": 250,
        blur: 0
      }
    };
  },
  google: {
    maps: {
      apiKey: 'AIzaSyCTq8otw7ha__auksDZ-dgdhPQNj64Jshw',
      libraries: '',
      version: '3'
    }
  },
  inView: {
    /* activity-item + stats ~= 314 px on desktop */
    offset: 314 * 2
  },
  isPointsEnabled: false,
  iScrollOptions: {
    iScroll: {
      momentum: true,
      mouseWheel: true,
      click: true,
      //tap: true,
      preventDefaultException: {
        tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|LABEL|A)$/,
        className: /^(file-input)$/
      }
    },
    directive: {
      asyncRefreshDelay: 0,
      refreshInterval: 499 // Because it's prime.  ;)

    }
  },
  menu: {
    showReports: false
  },
  organization: {
    name: 'Encount AS',
    orgNumber: '995 209 810',
    addressLine1: 'Vulkan 16',
    country: gettext('Norway'),
    zipCode: '0178',
    city: 'OSLO',
    phoneNumber: '+47 21300400',
    cookiesEmail: 'cookies@encount.co',
    eulaEmail: 'tos@encount.co',
    privacyEmail: 'privacy@encount.co'
  },
  legal: {
    cookies: {
      lastUpdated: '2018-05-08'
    },
    eula: {
      lastUpdated: '2018-05-08'
    },
    privacy: {
      lastUpdated: '2018-05-08'
    }
  },
  passwords: {
    minLength: 9
  },
  profileImage: {
    cropSize: {
      x: 210,
      y: 210
    },
    defaultUrls: ['https://opinion.onlivepanel.com/images/anonymous_profile.jpg', '/images/anonymous_profile.jpg']
  },
  raven: {
    isEnabled: Boolean(),
    defaultTags: {
      git_branch: 'undefined',
      env_type: 'production'
    }
  },
  realTimeMessaging: {
    debugSTOMP: false,
    refreshConnectedUsersIntervalMs: 10 * 1000,
    // milliseconds.
    reportConnectedInterval: _realTimeReportConnectedInterval,
    // milliseconds.
    connectedTimeout: 2 * _realTimeReportConnectedInterval,
    // milliseconds.
    typingReporterThrottleTimeMs: 2 * 1000,
    // milliseconds.
    typingTimeout: 10 * 1000,
    // milliseconds.
    postTypingStatus: false
  },
  selfMonitoring: {
    probe: {
      realTimeMessaging: {
        externalIdField: 'message_id',
        extraFields: ['action']
      },
      maxAsyncEventQueueItems: 128,
      maxAsyncEventQueueAgeMs: 0.5 * 60 * 1000,
      // milliseconds.
      asyncEventQueueCheckIntervalMs: 10 * 1000 // milliseconds.

    }
  },
  stats: {
    isEnabled: false,
    useContributionsForEntries: true,
    includeSupervisorsByDefault: false,
    initialPreloadLimit: 5,
    trendLineWidth: 70,
    trendLineHeight: 18
  },
  uiSelectConfig: {
    theme: 'bootstrap',
    resetSearchInput: true,
    spinnerEnabled: false,
    appendToBody: false
  }
});