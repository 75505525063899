'use strict';

import angular from 'angular'
import editAttachmentState from './edit-attachment-state.constant'
import editSegmentState from './edit-segment-state.constant'
import mdHelpState from './md-help-state.constant'
import ForumController from './forum.controller.js'
import ViewAttachmentController from './view-attachment.controller.js'
import forumHtml from './forum.html'
import forumThreadHtml from './forum-thread.html'
import realTimeMenuDrawerHtml from '../real-time-menu-drawer.html'
import realTimeMenuDrawerHeaderHtml from '../real-time-menu-drawer-header.html'

function _injectForumAndThreadId(state, name) {
    /**
     * The `state.locals.globals.params` is a
     * workaround for changed `$state` structure.
     * See `realTimeConnectedUsers` directive and the
     * faked `$state.$current.locals`.
     */
    if (name.includes('{forumId}') || name.includes('{threadId}')) {
        let activityId,
            threadId;

        if (angular.isDefined(state.locals)) {
            activityId = state.locals.globals.params.activityId;
            threadId = state.locals.globals.params.threadId;
        } else {
            activityId = 'undefined';
            threadId = 'undefined';
        }

        name = name
            .replace('{forumId}', activityId)
            .replace('{threadId}', threadId);
    }
    return name;
}

/* @ngInject */
function config($stateProvider, editSegmentState, viewAttachmentState,
                editAttachmentState, mdHelpState) {
    $stateProvider
        .state({
            name: 'home.forum',
            url: '^/{activityType:forum}/',
            views: {
                'main-contents@': {
                    controller: 'ModalAttachmentController',
                    templateUrl: forumHtml
                },
                'right-drawer-header@': {
                    controller: 'RealTimeMenuDrawerController',
                    templateUrl: realTimeMenuDrawerHeaderHtml
                },
                'right-drawer-contents@': {
                    controller: 'RealTimeMenuDrawerController',
                    templateUrl: realTimeMenuDrawerHtml
                }
            },
            secure: true
        })
        .state({
            name: 'home.forum.topics',
            url: '{activityId:int}/',
            views: {
                'forum': {
                    controller: 'ForumController',
                    templateUrl: forumThreadHtml
                },
                // 'real-time-menu': {
                //     controller: 'RealTimeMenuDrawerController',
                //     templateUrl: realTimeMenuDrawerHtml
                // }
            },
            data: {
                segmentsName: 'forum.{forumId}.segments',
                selectionsName: 'forum.{forumId}.users',
                selectionsNameModifier: _injectForumAndThreadId,
                selectionsIdName: 'user_id'
            },
            secure: true
        })
        .state(editSegmentState('home.forum.topics.segment'))
        .state({
            name: 'home.forum.thread',
            url: '{activityId:int}/thread/{threadId:int}/',
            views: {
                'forum': {
                    controller: 'ForumController',
                    templateUrl: forumThreadHtml
                },
                // 'real-time-menu': {
                //     controller: 'RealTimeMenuDrawerController',
                //     templateUrl: realTimeMenuDrawerHtml
                // },
                'right-drawer-header@': {
                    controller: 'RealTimeMenuDrawerController',
                    templateUrl: realTimeMenuDrawerHeaderHtml
                },
                'right-drawer-contents@': {
                    controller: 'RealTimeMenuDrawerController',
                    templateUrl: realTimeMenuDrawerHtml
                }
            },
            data: {
                segmentsName: 'forum.{forumId}.thread.{threadId}.segments',
                selectionsName: 'forum.{forumId}.thread.{threadId}.users',
                selectionsNameModifier: _injectForumAndThreadId,
                selectionsIdName: 'user_id'
            },
            secure: true
        })
        .state(editSegmentState('home.forum.thread.segment'))
        .state(viewAttachmentState('home.forum.thread.view-attachment'))
        .state(editAttachmentState('home.forum.thread.edit-attachment'))
        .state(mdHelpState('home.forum.thread.md-help'));
}

export default angular
    .module('activities.forum', [
        editSegmentState.name,
        ForumController.name,
        ViewAttachmentController.name,
        editAttachmentState.name,
        mdHelpState.name,
    ])
    .config(config);
