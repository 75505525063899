'use strict';

import angular from 'angular';
import activityStatHtml from './activity-stat.html'

/* @ngInject */
function activityStat(config) {
    function _link(scope) {
        scope.config = config;
    }

    return {
        scope: {
            stat: '=activityStat',
            fx: '=',
            fy: '=',
            usersName: '@',
            totalWhat: '@'
        },
        link: _link,
        templateUrl: activityStatHtml
    };
}

export default angular
    .module('activities.activityStats.activityStat', [])
    .directive('activityStat', activityStat);
