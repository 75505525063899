'use strict';

import angular from 'angular';
import _partial from 'lodash/partial'


/* @ngInject */
function trackingService($log, $q, $analytics, panelService) {
    function _track(category, action, label, value, activity, panel_id, currentPanel) {
        const eventData = {
            category: category, // What kind of value are we getting?
            label: label, // Which stage in value creation are we at?
            value: value,  // An integer value.

            // Custom dimensions that need to be set up in the Google Analytics config
            // (Admin/Property/Custom Definitions/Custom Dimensions):
            dimension1: panel_id, // Actual Panel id (scope: hit)
            dimension2: currentPanel && currentPanel.panel_id, // Current Panel id (scope: user)
            dimension3: activity && activity.activity_id // (scope: hit)
            //dimension4: gitRevision,
        };
        $log.debug(`Tracking: action="${action}"`, eventData);
        $analytics.eventTrack(action, eventData);
    }

    function _getCurrentPanel() {
        const deferred = $q.defer();
        panelService.get().then(function _success(panels) {
            deferred.resolve(panels.current);
        }, function _failure() {
            /* Didn't get panel. */
        });
        return deferred.promise;
    }

    function _withCurrentPanel(trackFn) {
        _getCurrentPanel().finally(trackFn);
    }

    function _trackActivity(action, activity, value) {
        const track = _partial(_track, 'Activity', action, activity.type, value,
            activity, activity.panel_id);
        _withCurrentPanel(track);
    }

    function _trackOnboarding(action, label) {
        const track = _partial(_track, 'Onboarding', action, label, null, null, null);
        _withCurrentPanel(track);
    }

    function _trackPlatform(action, label) {
        const track = _partial(_track, 'Platform', action, label, null, null, null);
        _withCurrentPanel(track);
    }

    function _trackContribution(action, activity) {
        // todo: label: activity type
        const track = _partial(_track, 'Contribution', action, activity && activity.type,
            null, activity, null);
        _withCurrentPanel(track);
    }

    function _trackProfile(action, label) {
        const track = _partial(_track, 'Profile', action, label, null, null, null);
        _withCurrentPanel(track);
    }

    const _actions = {
        about: 'Read More',
        answerQuestion: 'Answer question',
        begin: 'Begin',
        beginOnboarding: 'Begin Onboarding',
        complete: 'Complete',
        confirmEmail: 'Confirm Email',
        contribute: 'Contribute',
        dismiss: 'Dismiss',
        display: 'Display',
        edit: 'Edit',
        enterUserData: 'Enter User Data', // Registration info
        join: 'Join',
        leave: 'Leave',
        login: 'Login',
        loginFail: 'Login Failure',
        logout: 'Logout',
        nextPage: 'Click Next Page',
        open: 'Open',
        password: {
            change: 'Change Password',
            requestForgotten: 'Request Forgotten Password'
        },
        previousPage: 'Click Previous Page',
        receiveBadge: 'Receive Badge',
        receivePoint: 'Receive Point',
        register: 'Register', // (label: Facebook/Plain)
        report: 'View Report',
        spendPoints: 'Spend Point',
        uploadImage: 'Upload Image',
        userSessions: {
            visit: 'Visit User Sessions',
            delete: 'Delete User Session'
        },
        userHistory: {
            visit: 'Visit User History',
        },
        viewPoints: 'View Points'
    };

    return {
        trackOnboarding: _trackOnboarding,
        trackActivity: _trackActivity,
        trackPlatform: _trackPlatform,
        trackProfile: _trackProfile,
        trackContribution: _trackContribution,
        actions: _actions
    };
}

export default angular
    .module('components.trackingService', [])
    .factory('trackingService', trackingService);
