'use strict';
import platform from 'platform'

function _isMobile() {
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i
        .test(platform.ua) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

export default function _isMobileDevice() {
    return _isMobile();
}
