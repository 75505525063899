'use strict';

import angular from 'angular';
import _assign from 'lodash/assign'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _head from 'lodash/head'
import _keys from 'lodash/keys'
import _remove from 'lodash/remove'
import _isNumber from 'lodash/isNumber'
import _isUndefined from 'lodash/isUndefined'
import _toInteger from 'lodash/toInteger'
import _groupBy from 'lodash/groupBy'
import _keyBy from 'lodash/keyBy'


/* @ngInject */
function panelService($rootScope, $resource, $q, $sce, $window, $log, api,
                      config, metaService, profileService) {
    const _panels = {
        isInitialized: false,
        all: undefined,
        current: undefined,
        currentRecruitment: undefined
    };
    let _panelDeferred;

    function _internalSetCurrentPanel(panelId) {
        const panel = _panels.index.open[panelId];

        if (angular.isDefined(panel)) {
            _panels.current = panel;

            _panels.current.welcomeImageStyle = {
                'background-image': `url(${api.url}${panel.welcome_logo_url})`
            };

            $window.Raven.setExtraContext({
                panel_id: panel.panel_id,
                panel_name: panel.name
            });

            $log.debug(`$emit(${_panelService.currentPanelChangedSignal})`);
            $rootScope.$emit(_panelService.currentPanelChangedSignal,
                _panels.current);
        }
        return panel;
    }

    function _persistCurrentPanel(panelId) {
        return metaService.get()
            .then(function (meta) {
                return api.postForm(meta.set_current_panel_url,
                    {panel_id: panelId});
            });
    }

    function _setCurrentPanel(panelId, doPersist) {
        if (!_isNumber(panelId)) {
            $log.error(`panelId must be an integer. Got ${typeof (panelId)}:`, panelId);
        }

        if (_isUndefined(_panels.index.open[panelId])) {
            const wrongPanelId = panelId;
            panelId = _toInteger(_head(_keys(_panels.index.open)));
            $log.warn(`Got panelId=${wrongPanelId}, for a panel not among the open ones; will use ${panelId} instead.`);
            doPersist = true;
        }

        return $q.when(doPersist ?
            _persistCurrentPanel(panelId) : angular.noop())
            .then(() => _internalSetCurrentPanel(panelId));
    }

    function _refreshPanelService(profile) {
        // status ∈ {'open', 'closed'}
        const groups = _groupBy(profile.panels, 'status');

        _panels.open = groups.open;
        _panels.closed = groups.closed;

        _panels.index = {
            open: _keyBy(_panels.open, 'panel_id'),
            closed: _keyBy(_panels.closed, 'panel_id')
        };

        $log.debug('$emit(' + _panelService.isRefreshedSignal + ')');
        $rootScope.$emit(_panelService.isRefreshedSignal, _panels);

        return _setCurrentPanel(profile.user.current_panel_id, false)
            .then(() => _panels);
    }

    function _prepareUnauthenticatedProfile(reason) {
        $log.debug('Attempting unauthenticated panel refresh, because:',
            reason);

        return metaService.get()
            .then((meta) => api.get(meta.auth.front_page_url))
            .then(function _fakeProfileUpdate(res) {
                const panel = res.data.panel,
                    currentRecruitment = res.data.current_recruitment,
                    profile = {
                        panels: [panel],
                        user: {
                            current_panel_id: panel.panel_id
                        }
                    };

                if (currentRecruitment) {
                    _panels.currentRecruitment = currentRecruitment;

                    _assign(_panels.currentRecruitment, {
                        frontPageBody1: $sce.trustAsHtml(
                            currentRecruitment.front_page_body_1),
                        frontPageHeader1: currentRecruitment.front_page_header_1
                    });
                } else {
                    _panels.currentRecruitment = undefined;
                }
                return profile;
            });
    }

    function _refresh() {
        // $log.debug('panelService.refresh()');

        // Promise states: 0 -> pending, 1 -> resolved, 2 -> rejected.
        if (angular.isDefined(_panelDeferred) &&
            (_panelDeferred.promise.$$state.status === 0)) {
            // $log.debug('panelService.refresh(): returning pending promise');
            return _panelDeferred.promise;
        }

        _panelDeferred = $q.defer();
        _panels.isInitialized = false;

        profileService.get()
            .then((profile) => {
                _refreshPanelService(profile).then((panels) => {
                    _panelDeferred.resolve(panels);
                    _panels.isInitialized = true;
                });
            }, (reason) => {
                // Refresh unauthenticated.
                _prepareUnauthenticatedProfile(reason)
                    .then((profile) => {
                        _refreshPanelService(profile).then((panels) => {
                            _panelDeferred.resolve(panels);
                            _panels.isInitialized = true;
                        });
                    });
            });

        return _panelDeferred.promise;
    }

    function _setCurrentPanelWrapper(panelId, doPersist) {
        return _panelService.get()
            .then(() => _setCurrentPanel(panelId, doPersist));
    }

    function _getPanels() {
        if (_panels.isInitialized) {
            return $q.when(_panels);
        } else {
            return _refresh();
        }
    }

    function _getById(panelId) {
        return _getPanels()
            .then((panels) => {
                if (_has(panels.index.open, panelId)) {
                    return panels.index.open[panelId];
                } else if (_has(panels.index.closed, panelId)) {
                    return panels.index.closed[panelId];
                }

                throw `Panel with with panel_id == ${panelId} not found in panels index.`;
            });
    }

    function _removeIrrelevantActivitiesFromCustomDomain(activities) {
        const deferred = $q.defer();

        if (config.isCustomDomain) {
            _remove(activities, function (activity) {
                return activity.panel_id !== _panels.current.panel_id;
            });
        }

        deferred.resolve(activities);

        return deferred.promise;
    }

    function _removeActivitiesFromClosedPanels(activities) {
        const deferred = $q.defer();

        _panelService.get().then(function (panels) {
            _remove(activities, function (activity) {
                return activity.panel_id in panels.index.closed;
            });

            deferred.resolve(activities);
        });
        return deferred.promise;
    }

    function _addBrandingToActivities(activities) {
        const deferred = $q.defer();

        _panelService.get().then(function (panels) {
            let panel;

            _forEach(activities, function _addBrandingToActivity(activity) {
                panel = panels.index.open[activity.panel_id];

                if (angular.isDefined(panel)) {
                    activity.branding = {
                        brand_color: panel.brand_color,
                        brand_icon_url: panel.brand_icon_url
                    };
                } else {
                    $log.warn('Trying to brand an activity not in ' +
                        'the open-panel index: activity_id, panel_id',
                        activity.activity_id, activity.panel_id);
                }
            });

            deferred.resolve(activities);
        });

        return deferred.promise;
    }

    const resource = $resource(api.url + '/api/panels/:panelId/',
        {panelId: '@panel_id'}, {
            query: {
                method: 'GET',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    const _panelService = {
        panels: _panels,
        isRefreshedSignal: 'panel:isRefreshed',
        currentPanelChangedSignal: 'panel:currentPanelChanged',
        refresh: _refresh,
        setCurrentPanel: _setCurrentPanelWrapper,
        get: _getPanels,
        getById: _getById,
        query: resource.query,
        removeActivitiesFromClosedPanels: _removeActivitiesFromClosedPanels,
        removeIrrelevantActivitiesFromCustomDomain: _removeIrrelevantActivitiesFromCustomDomain,
        addBrandingToActivities: _addBrandingToActivities
    };

    $rootScope.$on(profileService.isRefreshedSignal, _panelService.refresh);

    $rootScope.$on(profileService.refreshFailedSignal, _panelService.refresh);

    return _panelService;
}

export default angular
    .module('panel.panelService', [])
    .factory('panelService', panelService);
