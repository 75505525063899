'use strict';

import angular from 'angular';
import attachmentSwitch from '../../components/real-time-engine/real-time-message/attachment-switch.directive.js';
import viewAttachmentHeaderHtml from './view-attachment.header.html'
import viewAttachmentHtml from './view-attachment.html'
import viewAttachmentFooterHtml from './view-attachment.footer.html'

/* @ngInject */
function viewAttachmentState(name) {
    return {
        name: name,
        params: {attachment: undefined},
        views: {
            'modal-header@': {
                controller: 'ActivityPreviewController',
                controllerAs: 'vm',
                templateUrl: viewAttachmentHeaderHtml
            },
            'modal-contents@': {
                controller: 'ViewAttachmentController',
                templateUrl: viewAttachmentHtml
            },
            'modal-footer@': {
                controller: 'ActivityPreviewController',
                controllerAs: 'vm',
                templateUrl: viewAttachmentFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                size: 'large'
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.viewAttachmentState', [
        attachmentSwitch.name
    ])
    .constant('viewAttachmentState', viewAttachmentState);
