'use strict';
import { QueryQuestionServiceFactory } from './query-question-service.factory';
import * as angular from 'angular';
import { QueryConstraintAnswer } from "./query-node/query-constraint-answer";
import { QuestionPreviewItemDirective } from './question-preview-item.directive';
var entitySearchHtml = require('./entity-search.html');
var tmpl = require('./select.tpl.html');
import _forEach from 'lodash/forEach';
/* @ngInject */
function entitySearch($q, $log, $templateCache, config, activeFiltersService, answerService, queryQuestionService, segmentService) {
    function _patchUISelect() {
        $templateCache.put('bootstrap/select.tpl.html', $templateCache.get(tmpl));
    }
    _patchUISelect();
    function _link(scope) {
        scope.model.isSearching = 0;
        function _addSearchGroup(elements, searchGroup) {
            _forEach(elements, function _tagQuestion(element) {
                element.searchGroup = searchGroup;
            });
        }
        function _handleResponse(response) {
            var panel_questions = response.questions.panel_questions, profile_questions = response.questions.profile_questions, recently_edited_segments = response.segments.recently_edited, shared_segments = response.segments.shared;
            _addSearchGroup(profile_questions, 'Profile Questions');
            _addSearchGroup(panel_questions, 'Questions');
            var questions = profile_questions.concat(panel_questions);
            _forEach(questions, answerService.classifyAnswerType);
            _addSearchGroup(recently_edited_segments, 'Recently Edited Segments');
            _addSearchGroup(shared_segments, 'Shared Segments');
            var segments = shared_segments.concat(recently_edited_segments);
            var searchItems = segments.concat(questions);
            scope.searchItems = searchItems;
            $log.debug('searchItems', searchItems);
            //scope.model.isSearching--;
            return searchItems;
        }
        function _refreshEntities(term) {
            var filter = activeFiltersService.getQueryConstraints();
            $log.debug('term', term);
            scope.model.isSearching++;
            return $q.all({
                questions: queryQuestionService.get({
                    search_constraint: term,
                    panel_ids: filter.panels,
                    activity_ids: filter.activities
                }).$promise,
                segments: segmentService.query({
                    search_term: term,
                    panel_ids: filter.panels,
                    activity_ids: filter.activities
                }).$promise
            }).then(_handleResponse)
                .finally(function _decreaseSearchCount() {
                scope.model.isSearching--;
            });
        }
        scope.refreshEntities = _refreshEntities;
        function _itemIsSelected($item, $model) {
            // NOTE: $item === $model
            var node = scope.model;
            if (angular.isDefined($item.question_id)) {
                if ($item.cast) {
                    node.cast = $item.cast;
                    node.humaneCast =
                        queryQuestionService.humaneCasts[$item.cast];
                }
                else {
                    node.cast = null;
                    node.humaneCast = null;
                }
                node.question = null;
                node.question_id = $item.question_id;
                if (scope.model.discriminator === 'query_constraint'
                    && $item.answerTypeClass === 'value') {
                    node.children = [
                        new QueryConstraintAnswer(),
                        new QueryConstraintAnswer()
                    ];
                }
                else {
                    node.children = []; // <-- Important.
                }
                node.subquery_id = null;
                node.subquery = null;
            }
            else if (angular.isDefined($item.query_id)) {
                node.cast = null;
                node.humaneCast = null;
                node.subquery_id = $item.query_id;
                node.subquery = null;
                node.question = null;
                node.question_id = null;
            }
            segmentService.requestUpdate(node);
        }
        scope.itemIsSelected = _itemIsSelected;
        scope.maxItemsCount = config.admin.entitySearchMaxValueItemsCount;
        function _init() {
            var node = scope.model;
            var preselected;
            if (node.question_id) {
                preselected = node.question;
            }
            else if (node.subquery_id) {
                preselected = node.subquery;
            }
            if (angular.isDefined(preselected)) {
                scope.searchItems = [preselected];
                node.selectedSearchItem = preselected;
            }
        }
        _init();
    }
    return {
        scope: {
            model: '=entitySearch'
        },
        link: {
            pre: _link,
        },
        templateUrl: entitySearchHtml
    };
}
export var EntitySearchDirective = angular
    .module('components.query.entitySearch', [
    QueryQuestionServiceFactory.name,
    QuestionPreviewItemDirective.name,
])
    .directive('entitySearch', entitySearch);
