'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'


/* @ngInject */
function validatePassword($q, $log, passwordService) {
    const validationLabels = [
        'passwordIsMissing',
        'passwordIsTooCommon',
        'passwordIsReused'
    ];

    function _link(scope, elem, attrs, ctrl) {
        function _validatePassword(modelValue) {
            if (ctrl.$isEmpty(modelValue)) {
                // Consider empty model valid.
                return $q.when();
            }

            return passwordService.check({candidate: modelValue}).$promise
                .then(function _updateField(response) {
                    if (response.status === 'success') {
                        _forEach(validationLabels, function _setValidationOK(label) {
                            ctrl.$setValidity(label, true);
                        });
                        return $q.resolve();
                    } else {
                        _forEach(validationLabels, function _setValidationStatus(label) {
                            ctrl.$setValidity(label, response.label !== label);
                        });
                        return $q.reject();
                    }
                });
        }

        ctrl.$asyncValidators.password = _validatePassword;
    }


    return {
        require: "ngModel",
        link: _link
    };
}

export default angular
    .module('components.validatePassword', [])
    .directive('validatePassword', validatePassword);
