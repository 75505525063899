'use strict';

import angular from 'angular';
import {UserDataImportServiceFactory} from './user-data-import-service.factory';
import userImportHelp from './user-import-help.directive';

import _reduce from 'lodash/reduce'
import _keys from 'lodash/keys'
import _forEach from 'lodash/forEach'
import _partial from 'lodash/partial'
import _map from 'lodash/map'
import _merge from 'lodash/merge'


/* @ngInject */
function ImportUsersController($state, $log, config,
                               panelAdmin, userDataImport, formService,
                               segmentService, activeFiltersService,
                               gettext) {
    const vm = this,
        _fieldDefinitions = {
            spreadsheet: {
                spreadsheet_id: {mode: 'r'},
                original_name: {mode: 'r'},
                created: {mode: 'r'},
                performed: {mode: 'r'},
                name: {mode: 'wc'},
                comment: {mode: 'wc'},
                file: {mode: 'rwc'},
                panel_id: {mode: 'rwc'},
                panel_name: {mode: 'r'},
                all_users_query_id: {mode: 'r'},
                new_users_query_id: {mode: 'r'},
                updated_users_query_id: {mode: 'r'},
                ambiguous_rows: {mode: 'r'},
                recruitment_id: {mode: 'rwc'},
                row_count: {mode: 'r'},
                uploader_name: {mode: 'r'}
            },
            external_columns: {mode: 'r', isList: true},
            mapped_columns: {mode: 'rw', isList: true}
        },
        _fieldKeys = _reduce(_fieldDefinitions,
            formService.generateFieldKeys, {}),
        _master = _reduce(_fieldDefinitions,
            formService.generateEmtpyMasterData, {}),
        _subFormNames = _keys(_fieldDefinitions);

    vm.defaultDebounceOptions = config.getDebounceOptions();

    function _initMasterData(response) {
        // eslint-disable-next-line lodash/prefer-lodash-method
        if ($state.includes('admin.*.user_imports.create') &&
            response.user_import_id) {
            $state.go('^.edit', {userDataImportId: response.user_import_id});
        }
        formService.populateFormMasterData(_master, response, _subFormNames,
            _fieldKeys);
    }

    function _resetForm(form, name) {
        formService.coreResetForm(form, name, vm, _master);
    }

    function _resetSubForms() {
        _forEach(_subFormNames, function _resetSubForm(name) {
            _resetForm(undefined, name);
        });
    }

    function _postSubmitHandling(promise, form) {
        formService.clearFormError(vm.saveError, form);

        return promise
            .then(_initMasterData,
                _partial(formService.handleSaveFormError, vm.saveError, form))
            .then(_resetSubForms);
    }

    function _uploadUserData(form) {
        const names = ['spreadsheet'],
            subData = _map(names, function _getData(name) {
                return formService.makeSafeCopy(vm[name],
                    _fieldDefinitions[name]);
            }),
            data = _merge(...[{}].concat(subData));

        if (angular.isUndefined(data.panel_id)) {
            data.panel_id = $state.params.panelId;
        }

        const promise = userDataImport.save(data).$promise;

        return _postSubmitHandling(promise, form);
    }

    function _saveForm(form) {
        const names = vm.spreadsheet.spreadsheet_id ?
                [form.$name] : [form.$name, 'mapped_columns'],
            subData = _map(names, function _getData(name) {
                return formService.makeSafeCopy(vm[name],
                    _fieldDefinitions[name]);
            }),
            data = _merge(...[{}].concat(subData));

        const promise = data.spreadsheet_id ?
            panelAdmin.update(data).$promise :
            panelAdmin.save(data).$promise;

        return _postSubmitHandling(promise, form);
    }

    function _setHistoricalImports(response) {
        vm.historicalImports = response.user_imports;
    }

    function _setRecruitments(response) {
        vm.recruitments = response.recruitments;
    }

    function _viewUsers(queryId) {
        segmentService.get({queryId: queryId, mode: 'compact'}).$promise
            .then(function _foobar(response) {
                activeFiltersService.addQuery(response);

                $state.go('admin.users.list');
            });
    }

    function _activate() {
        formService.patchUISelect();

        if (angular.isUndefined($state.params.userDataImportId)) {
            _forEach(_subFormNames, function _createEmptyMaster(name) {
                _master[name] = formService.createEmptyObject(_fieldKeys[name]);
            });

            userDataImport.getPanelRecruitments({
                panelId: $state.params.panelId
            }).$promise
                .then(_setRecruitments);
        } else {
            userDataImport.get({
                user_import_id: $state.params.userDataImportId
            }).$promise
                .then(_initMasterData)
                .then(_resetSubForms);
        }

        userDataImport.query({
            panel_id: $state.params.panelId
        }).$promise
            .then(_setHistoricalImports);

        vm.topLevelViews = [
            {
                name: 'meta',
                description: gettext('Choose Import'),
                icon: 'fa-upload'
            },
            {
                name: 'help',
                description: gettext('Help'),
                icon: 'fa-question-circle fa-lg'
            }
        ];

        vm.topLevelView = vm.topLevelViews[0].name;

        vm.saveError = {};

        vm.uploadUserData = _uploadUserData;
        vm.saveForm = _saveForm;
        vm.viewUsers = _viewUsers;

        vm.resetForm = _resetForm;
    }

    _activate();
}

export default angular
    .module('admin.users.ImportUsersController', [
        UserDataImportServiceFactory.name,
        userImportHelp.name
    ])
    .controller('ImportUsersController', ImportUsersController);
