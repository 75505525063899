'use strict';

import angular from 'angular';
import {PanelAdminServiceFactory} from './panel-service.factory';
import panelsListHtml from './panels-list.html'

/* @ngInject */
function panelsList($state, activeFiltersService, gridUtils, gettext,
                    gettextCatalog, utility, panelAdmin, $log) {
    const columnDefs = [
        // {
        //     field: 'panel_id',
        //     headerName: '<input type="checkbox" ' +
        //     'class="ag-selection-checkbox" ' +
        //     'indeterminate-checkbox="selections.isAllIndicator"' +
        //     'on-change="selections.allChanged">',
        //     headerClass: 'check-all',
        //     suppressResize: true,
        //     width: 40,
        //     checkboxSelection: true,
        //     valueGetter: gridUtils.nullValue,
        //     suppressMenu: true,
        //     suppressSorting: true,
        //     headerTooltip: 'Toggle all',
        //     compileWithAngular: true
        // },
        {
            headerName: gettext('Panel ID'),
            field: 'panel_id',
            filter: 'number',
            width: 95,
            hide: true
        },
        {
            headerName: gettext('Name'),
            field: 'name',
            filter: 'text',
            width: 164,
            sort: 'asc'
        },
        {
            headerName: gettext('Internal Name'),
            field: 'internal_name',
            filter: 'text',
            width: 194
        },
        {
            headerName: gettext('Description'),
            field: 'description',
            filter: 'text',
            hide: true,
            width: 130,
            suppressSorting: true
        },
        {
            headerName: gettext('Status'),
            field: 'status',
            filter: 'set',
            filterParams: {
                cellRenderer: _panelStatusRenderer,
                values: ['open', 'closed']
            },
            cellRenderer: _panelStatusRenderer,
            width: 98
        },
        {
            headerName: gettext('Client ID'), field: 'client_id',
            filter: 'number',
            // width: 95,
            hide: true
            // rowGroupIndex: 1
        },
        {
            headerName: gettext('Client'), field: 'client_name',
            filter: 'text',
            width: 146
            // rowGroupIndex: 1
        },
        {
            headerName: gettext('Market ID'), field: 'market_id',
            filter: 'number',
            hide: true
            // width: 95
        },
        {
            headerName: gettext('Market'), field: 'market_name',
            filter: 'text',
            width: 137,
            hide: true
        },
        {
            headerName: gettext('Created Date'), field: 'created',
            filter: 'text',
            width: 93,
            hide: true
        },
        {
            headerName: gettext('Actions'), field: 'panel_id',
            cellRenderer: _actionColumnRenderer,
            width: 175,
            suppressMenu: true,
            suppressSorting: true
        }
    ];

    function _actionColumnRenderer() {
        return '<a ui-sref="admin.panels.edit({panelId: data.panel_id})" ' +
            'ng-bind="::\'Edit\' |translate"></a> ' +
            '<a ui-sref="admin.panels.user_imports.create({panelId: data.panel_id})" ' +
            'ng-bind="::\'User-data Imports\' |translate"></a>';
    }

    var _humaneStatusText = {
        open: gettext('Open'),
        closed: gettext('Closed')
    };

    function _panelStatusRenderer(params) {
        return _humaneStatusText[angular.isDefined(params.data) ?
            params.data.status : params.value] || '';
    }

    function _link(scope) {
        const selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName),
            selectionIdName = utility.getParentData($state.$current,
                activeFiltersService.defaultSelectionsIdName),
            rowIdPath = 'panel_id',
            ops = gridUtils.getGridOperations({
                useVirtualPaging: true,
                columnDefs: columnDefs,
                gridOptions: {},
                muteQueryParams: [
                    'activity_ids',
                    'query_ids'
                ],
                selectionName: selectionName,
                selectionIdName: selectionIdName,
                rowIdPath: rowIdPath,
                resource: panelAdmin,
                resourceExtraColumns: [
                    'name',
                    'status',
                    'client_id',
                    'client_name',
                    'market_id',
                    'market_name',
                    'description',
                    'created'
                ],
                resourceExtraQueryParams: {
                    'status': ['open', 'closed']
                },
                resourceResponseCollection: 'panels',
                storageName: 'panelsList',
                scope: scope
            });

        scope.toggleToolPanelVisibility = ops.toggleToolPanelVisibility;
        scope.showSql = false;

        scope.gridOptions = ops.gridOptions;
    }

    return {
        scope: true,
        replace: true,
        link: _link,
        /**
         * The ng-if="gridOptions" in the template element is there to avoid
         * initializing `ag-grid` until the options have been defined.
         */
        templateUrl: panelsListHtml
    };
}

export default angular
    .module('admin.panels.panelsList', [
        'agGrid',
        PanelAdminServiceFactory.name
    ])
    .directive('panelsList', panelsList);
