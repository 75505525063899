'use strict';

import angular from 'angular';
import _includes from 'lodash/includes'
import _partial from 'lodash/partial'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _reject from 'lodash/reject'
import _assign from 'lodash/assign'
import _remove from 'lodash/remove'
import _assignIn from 'lodash/assignIn'
import _has from 'lodash/has'
import _transform from 'lodash/transform'
import _replace from 'lodash/replace'
import _reduce from 'lodash/reduce'
import _noop from 'lodash/noop'


/* @ngInject */
function activityService($rootScope, $q, $uiRouterGlobals, $timeout, $resource,
                         $log, api, panelService, metaService,
                         authorizationsService, activityStatsService, utility,
                         storage, gettextCatalog, config, gridUtils) {
    const _activities = [],
        _fullActivities = {},
        _filteredActivities = [],
        _sliderActivities = [],
        _homeScreenActivities = [],
        _activityCounts = {},
        _activityFilters = [
            'all', 'new', 'active', 'closed', 'reported', 'perks'
        ],
        _panelFilters = [
            'new', 'active', 'reported', 'perks'
        ],
        _actions = {
            activity: [
                'get_user_feedback',
                'get_media_upload_info'
            ],
            questionnaire: [
                'get_calendar',
                'get_results',
                'get_session',
                'save_session'
            ]
        },
        _actionParams = {
            activity: {
                '': {
                    activityID: 'activity_id',
                    activityType: 'discriminator'
                }
            },
            forum: {
                'meta': {
                    threadID: 'thread_id'
                }
            },
            questionnaire: {
                '': {
                    questionnaireSessionID: 'questionnaire_session_id',
                    userQuestionnaireSessionID: {
                        key: 'entry_id',
                        default: 'new'
                    }
                }
            }
        },
        _state = {
            isRefreshing: null
        };
    let _activitiesDeferred;

    _actions.diary = _actions.questionnaire;
    _actionParams.diary = _actionParams.questionnaire;

    function _filterPanelActivities(filter, activity) {
        return activity.panel_id === filter.panelId &&
            _includes(_panelFilters, activity.rendered_status);
    }

    function _filterActivities(activities, filter) {
        let doInclude, filteredActivities;

        //$log.debug('activityService._filterActivities: filter', filter);

        if (filter.type) {
            switch (filter.type) {
                case 'all':
                    filteredActivities = activities;
                    break;
                case 'perks':
                    filteredActivities = _filter(activities,
                        ['is_perk_activity', true]);
                    break;
                default:
                    filteredActivities = _filter(activities,
                        ['rendered_status', filter.type]);
            }
        } else if (filter.panelId) {
            doInclude = _partial(_filterPanelActivities, filter);
            filteredActivities = _filter(activities, doInclude);
        } else {
            filteredActivities = activities;
        }

        return filteredActivities;
    }

    function _updateSliderActivities(activitiesArray) {
        //$log.debug('activity-service.factory._updateSliderActivities: ');
        utility.updateSharedArray(_sliderActivities,
            _filter(activitiesArray, {
                include_in_slider: true,
                isCurrentlyVisibleForUser: true
            }));
    }

    function _updateFilteredActivities() {
        //$log.debug('activityService._updateFilteredActivities');

        utility.emptyArray(_filteredActivities);

        const tempArray = _filterActivities(_activities, {
            type: $uiRouterGlobals.params.filter,
            panelId: parseInt($uiRouterGlobals.params.panelId, 10)
        });

        activityStatsService.prepareActivities(_activities, tempArray);

        _updateSliderActivities(tempArray);

        utility.updateSharedArray(_homeScreenActivities,
            _filter(tempArray, ['show_on_home_screen', true]));

        utility.updateSharedArray(_filteredActivities, tempArray);

        $log.debug('$emit(' + _activityService.filtersUpdatedSignal + ')');
        $rootScope.$emit(_activityService.filtersUpdatedSignal);
    }

    function _updateActivityCounts() {
        let filteredActivities;

        _forEach(_activityFilters, function _countActivities(filter) {
            filteredActivities = _filter(_filterActivities(_activities, {
                type: filter
            }), ['isCurrentlyVisibleForUser', true]);

            _activityCounts[filter] = filteredActivities.length;
        });
    }

    function _generateI18nDomainKey(activity) {
        return activity.is_translatable ?
            activity.discriminator + '-' + activity.activity_id : 'none';
    }

    function _nullTranslator(string) {
        return string;
    }

    function _translatorFactory(i18nDomain) {
        return function _translator(string) {
            return gettextCatalog.getString(string, null, i18nDomain);
        };
    }

    function _updateTranslations(activities) {
        activities = activities || _activities;

        _forEach(activities, (activity) => {
            const translator = activity.is_translatable ?
                _translatorFactory(activity.i18nDomain) : _nullTranslator;

            activity.i18nName = translator(activity.name);
            activity.i18nDescription = translator(activity.description);
        });
    }

    function _updateGettextDomains(activities) {
        _forEach(_reject(activities, ['is_translatable', true]),
            (activity) => _assign(activity, {i18nDomain: 'none'}));

        _forEach(_filter(activities, ['is_translatable', true]),
            (activity) => {
                activity.i18nDomain = _generateI18nDomainKey(activity);

                if (activity.i18n) {
                    _forEach(activity.i18n,
                        function _setDomain(strings, language) {
                            gettextCatalog.setStrings(language, strings,
                                activity.i18nDomain);
                        });
                }
            });
    }

    function _refreshActivities(response) {
        const activities = response.data.activities;

        _remove(activities, function (activity) {
            /* TODO: Residue note from River:
             Check if this geofenced activity has a
             local geofence trigger to make it visible in listview.
             */
            return (activity.geofence !== null &&
                activity.geofence.locations.length > 0) ||
                /^default_/.test(activity.name);  // Remove default activities.
        });

        _updateGettextDomains(activities);
        _updateTranslations(activities);

        panelService.removeIrrelevantActivitiesFromCustomDomain(activities)
            .then(panelService.removeActivitiesFromClosedPanels)
            .then(panelService.addBrandingToActivities)
            .then(authorizationsService.markIsSupervisedByCurrentUser)
            .then(function _updateSharedActivities(activities) {
                _forEach(activities, function _callHandleOpening(activity) {
                    _handleOpening(activity,
                        config.activities.preOpeningMinutes);
                });

                utility.updateSharedArray(_activities, activities);
                _updateFilteredActivities();
                _updateActivityCounts();

                _state.isRefreshing = false;
                _activitiesDeferred.resolve(_activities);
            });

        $log.debug('$emit(' + _activityService.isRefreshedSignal + ')');
        $rootScope.$emit(_activityService.isRefreshedSignal, activities);
    }

    function _handleError(_state, _activitiesDeferred, reason) {
        $log.warn('activityService:', reason);
        _state.isRefreshing = false;
        _activitiesDeferred.reject(reason);
    }

    function _refresh() {
        _activitiesDeferred = $q.defer();
        _state.isRefreshing = true;
        //$log.debug('activityService refresh');

        metaService.get().then(function _refresh(meta) {
            if (meta.user.trial_is_expired) {
                _handleError(_state, _activitiesDeferred,
                    "Cannot update activities because current " +
                    "user's trial is expired.");
            } else if (meta.user.is_authenticated) {
                api.get('/my_activities.json?api_version=' + meta.version)
                    .then(_refreshActivities);
            } else {
                _handleError(_state, _activitiesDeferred,
                    'Cannot update activities because current user ' +
                    'is not authenticated.');
            }
        });

        return _activitiesDeferred.promise;
    }

    function _get() {
        return angular.isDefined(_activitiesDeferred) ?
            _activitiesDeferred.promise : _refresh();
    }

    function _flush() {
        //keys(_openingTimeoutCancellers, function _callById(idString) {
        //    _cancelExistingTimeouts(parseInt(idString, 10));
        //});

        _forEach(_activities, _cancelExistingTimeouts);

        utility.emptyArray(_activities);
        utility.emptyObject(_fullActivities);
        utility.emptyArray(_filteredActivities);
        utility.emptyArray(_sliderActivities);
        utility.emptyArray(_homeScreenActivities);
        utility.emptyObject(_activityCounts);

        if (angular.isDefined(_activitiesDeferred)) {
            _activitiesDeferred.reject();
            _activitiesDeferred = undefined;
        }
    }

    function _getStartedActivity(id) {
        const user = storage.user.getItem();

        /**
         * This might break during private browsing sessions in Safari in iOS.
         * For more info, see:
         *
         *  http://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-an
         **/
        return user.activities[id];
    }

    function _saveActivity(id, data) {
        const user = storage.user.getItem();

        user.activities[id] = data;

        storage.user.setItem(user);
    }

    function _removeActivity(id) {
        const user = storage.user.getItem();

        delete user.activities[id];

        storage.user.setItem(user);
    }

    function _generateActivityParams(activity, extraParams) {
        const params = _assignIn(
            utility.extractParams(_actionParams,
                ['activity', activity.discriminator], activity),
            extraParams);

        params.activityURL =
            utility.format(activity.activityURL, params);

        return params;
    }

    function _prepareActivity(result) {
        let activity;

        if (angular.isDefined(result.data.activities)) {
            activity = result.data.activities[0];
            delete result.data.activities;
        } else {
            activity = result.data.activity;
            delete result.data.activity;
        }

        if (_has(activity, 'meta')) {
            $log.error('Activity object already has "meta" field.');
        }
        activity.meta = result.data;
        activity.params = _generateActivityParams(activity, {});

        activity.i18nDomain = _generateI18nDomainKey(activity);

        _handleOpening(activity, config.activities.preOpeningMinutes);

        activity.compiledActions =
            utility.compileActionURLTemplates(activity.action_urls,
                activity.params);

        function _composePartials(result, action) {
            result[action] = _partial(
                utility.httpMethodHandlers[
                    activity.compiledActions[action].method],
                activity.compiledActions[action].url(activity.params));
        }

        activity.actions = {};

        const kinds = ['activity'];
        if (_has(_actions, activity.discriminator)) {
            kinds.push(activity.discriminator);
        }

        _forEach(kinds, function _createActionPartials(kind) {
            _transform(_actions[kind], _composePartials,
                activity.actions);
        });

        activity.getMediaUploadInfo = function _getMediaUploadInfo(mediaType) {
            return activity.actions
                .get_media_upload_info({media_type: mediaType});
        };

        return activity;
    }

    function _getAPIEndpoint(params) {
        const info = {
                type: params.activityType,
                id: params.activityId
            },
            defaultUrl = '/' + info.type + '/' + info.id + '/';

        switch (info.type) {
            case 'forum':
                if (angular.isDefined(params.threadId)) {
                    info.url = defaultUrl +
                        'thread/' + params.threadId + '/';
                } else {
                    info.url = defaultUrl;
                }
                break;
            case 'diary':
            /* Fall-through */
            // eslint-disable-next-line no-fallthrough
            case 'questionnaire':
                if (angular.isDefined(params.questionnaireSessionId) &&
                    angular.isDefined(params.what)) {
                    info.url = defaultUrl +
                        'session/' + params.questionnaireSessionId +
                        '/' + params.what + '/';
                } else {
                    info.url = defaultUrl;
                }
                break;
            default:
                info.url = defaultUrl;
        }

        if (params.includeI18n) {
            info.url += '?include_i18n=true';
        }

        return info;
    }

    function _getActivity(params, _config) {
        const deferred = $q.defer();

        _config = angular.isDefined(_config) ? _config : {};

        _config.fromCache = _has(_config, 'fromCache') ?
            _config.fromCache : true;

        if (_config.isOnboarding) {
            params.includeI18n = true;
        }

        if (angular.isDefined(params.activityId) &&
            angular.isDefined(params.activityType)) {
            const endPoint = _getAPIEndpoint(params),
                key = endPoint.url;

            if (_config.fromCache && _has(_fullActivities, key)) {
                deferred.resolve(_fullActivities[key]);
            } else {
                const cacheActivity = function _cacheActivity(activity) {
                    activity.cacheKey = key;
                    _fullActivities[endPoint.url] = activity;
                    return activity;
                };

                api.get(key)
                    .then(_prepareActivity)
                    .then(cacheActivity)
                    .then(deferred.resolve);
            }
        } else {
            deferred.reject('There is no current activity.');
        }

        return deferred.promise;
    }

    function _getCurrentActivity() {
        return _getActivity($uiRouterGlobals.params);
    }

    function _updateVisibilityForUser(activity, forcedOpenedValues) {
        if (angular.isDefined(forcedOpenedValues)) {
            _assign(activity, forcedOpenedValues);
            //$log.debug('Activity #' + activity.activity_id,
            //    'isPreOpened =', activity.isPreOpened,
            //    'isOpened =', activity.isOpened);
        } else if (activity.status === 'implied') {
            if (angular.isUndefined(activity.openingDatetime)) {
                throw {
                    message: 'activity has no openingDatetime',
                    value: activity
                };
            }

            activity.isPreOpened = new Date() >= activity.preOpeningDatetime;
            activity.isOpened = new Date() >= activity.openingDatetime;
        } else {
            activity.isOpened = activity.isPreOpened =
                (activity.status === 'active');
        }

        if (activity.isSupervisedByCurrentUser) {
            activity.isCurrentlyVisibleForUser = true;
        } else {
            activity.isCurrentlyVisibleForUser =
                activity.status !== 'implied' || activity.isOpened;
        }
    }

    function _computeDatetimes(activity, preOpeningMinutes) {
        preOpeningMinutes = angular.isDefined(preOpeningMinutes) ?
            preOpeningMinutes : 0;

        const msPerMinute = 60 * 1000,
            startDatetime =
                new Date(_replace(activity.start_datetime, ' ', 'T'));
        return {
            startDatetime: startDatetime,
            preOpeningDatetime: new Date(startDatetime.valueOf() -
                (preOpeningMinutes * msPerMinute)),
            openingDatetime: startDatetime
        };
    }

    function _computeAndSetOpeningTime(activity, preOpeningMinutes) {
        if (angular.isUndefined(activity.openingDatetime)) {
            _assign(activity, _computeDatetimes(activity, preOpeningMinutes));
        }
    }

    function _openOnTimeout(activity, timeoutCallback) {
        const nowMs = new Date().valueOf(),
            timeoutsMs = _reduce({
                isPreOpened: activity.preOpeningDatetime.valueOf() - nowMs,
                isOpened: activity.openingDatetime.valueOf() - nowMs
            }, function _keepPositive(result, ms, timeoutName) {
                if (ms > 0) {
                    result[timeoutName] = ms;
                }
                return result;
            }, {});

        function _updateOpenedStatus(state) {
            const oldVisibleForUser = activity.isCurrentlyVisibleForUser;

            //$log.debug('Activity #' + activity.activity_id,
            //    'is checked (by timeout).');
            _updateVisibilityForUser(activity, state);

            if (oldVisibleForUser !== activity.isCurrentlyVisibleForUser) {
                $log.debug('$emit(' +
                    _activityService.currentVisibilityUpdatedSignal + ')');
                $rootScope.$emit(_activityService.currentVisibilityUpdatedSignal);
            }

            if (angular.isDefined(timeoutCallback)) {
                //$log.debug('timeoutCallback', timeoutCallback);
                timeoutCallback();
            }
        }

        function _createTimeout(result, timeoutMs, timeoutName) {
            //$log.debug('Setting timeout for activity #' +
            //    activity.activity_id, timeoutMs);
            result[timeoutName] = $timeout(function _callUpdate() {
                const state = {};
                state[timeoutName] = true;
                _updateOpenedStatus(state);
            }, timeoutMs);
            return result;
        }

        const cancellers = _reduce(timeoutsMs, _createTimeout, {});

        function _cancelTimeouts() {
            _forEach(cancellers, function _cancelTimeout(timeout) {
                //$log.debug('Cancelling ' + name + ' timeout for activity #' +
                //    activity.activity_id, timeout);
                $timeout.cancel(timeout);
            });
        }

        return _cancelTimeouts;
    }

    function _handleTimeControlledOpening(activity, preOpeningMinutes,
                                          timeoutCallback) {
        _computeAndSetOpeningTime(activity, preOpeningMinutes);
        _updateVisibilityForUser(activity);

        return activity.isPreOpened && activity.isOpened ?
            _noop : _openOnTimeout(activity, timeoutCallback);
    }

    function _cancelExistingTimeouts(activity) {
        /* Cancel any existing timeouts/promises for activity_id. */
        if (_has(activity, '_openingTimeoutCancellers')) {
            _forEach(activity._openingTimeoutCancellers,
                (functor) => {
                    functor();
                }
            );
        }

        activity._openingTimeoutCancellers = [];
    }


    function _handleOpening(activity, preOpeningMinutes, timeoutCallback) {
        _cancelExistingTimeouts(activity);

        if (activity.status === 'implied') {
            activity._openingTimeoutCancellers.push(
                _handleTimeControlledOpening(activity,
                    preOpeningMinutes, timeoutCallback));
        } else {
            _updateVisibilityForUser(activity);
        }

        if (activity.discriminator === 'chat') {
            _updateVisibilityForUser(activity, {isOpened: true});
        }
    }

    const resource = $resource(api.url + '/api/activities/:activityId/',
        {activityId: '@activity_id'}, {
            query: {
                method: 'GET',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    function _queryParamsFromFilters(filters, ignoreExtraFilters) {
        const params = {
            panel_ids: filters.panels,
            activity_ids: filters.activities
            // query_ids: filters.queries
        };

        if (angular.isDefined(filters.items)) {
            params.exclude_activity_ids =
                filters.items.exclude ? filters.items.ids : null;
            params.activity_ids =
                !filters.items.exclude ? filters.items.ids : null;

            if (!ignoreExtraFilters) {
                params.filters =
                    _reduce(filters.items.extraFilters,
                        _partial(gridUtils.generateFilterKey,
                            filters.items.columnDefs),
                        []);
            }
        }
        return params;
    }

    const _activityService = {
        activities: _activities,
        filteredActivities: _filteredActivities,
        sliderActivities: _sliderActivities,
        homeScreenActivities: _homeScreenActivities,
        activityCounts: _activityCounts,
        state: _state,
        isRefreshedSignal: 'activityService:isRefreshed',
        updateFilterSignal: 'activityService:updateFilter',
        currentVisibilityUpdatedSignal: 'activityService:currentVisibilityUpdated',
        filtersUpdatedSignal: 'activityService:filtersUpdated',
        refresh: _refresh,
        get: _get,
        query: resource.query,
        flush: _flush,
        getStartedActivity: _getStartedActivity,
        saveActivity: _saveActivity,
        removeActivity: _removeActivity,
        getActivity: _getActivity,
        getCurrentActivity: _getCurrentActivity,
        updateGettextDomains: _updateGettextDomains,
        handleOpening: _handleOpening,
        updateVisibilityForUser: _updateVisibilityForUser,
        queryParamsFromFilters: _queryParamsFromFilters
    };

    function _flushAndRefreshIfExisting() {
        if (angular.isDefined(_activitiesDeferred) &&
            utility.promiseIsResolved(_activitiesDeferred)) {
            _flush();
            _refresh();
        }
    }

    function _visibilityChangeHandler() {
        _updateSliderActivities();
        _updateActivityCounts();
    }

    $rootScope.$on(panelService.isRefreshedSignal, _refresh);

    $rootScope.$on(_activityService.updateFilterSignal,
        _updateFilteredActivities);

    $rootScope.$on(metaService.currentLanguageIsUpdatedSignal,
        _flushAndRefreshIfExisting);

    $rootScope.$on(_activityService.currentVisibilityUpdatedSignal,
        _visibilityChangeHandler);

    return _activityService;
}

export default angular
    .module('components.activityService', [])
    .factory('activityService', activityService);
