'use strict';

import angular from 'angular';
import _fill from 'lodash/fill'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import _reduce from 'lodash/reduce'
import _remove from 'lodash/remove'
import _sortBy from 'lodash/sortBy'
import _zipObject from 'lodash/zipObject'


/* @ngInject */
function EditPanelController($state, $scope, $resource, $q, $log, genders,
                             Modernizr, config, panelAdmin, api,
                             postalCodeService, groupService, utility,
                             activeFiltersService, gettext, gettextCatalog) {
    function _generateFieldKeys(fieldKeys, fields, key) {
        fieldKeys[key] = _keys(fields);
        return fieldKeys;
    }

    function _generateEmtpyMasterData(master, fields, key) {
        master[key] = {};
        return master;
    }

    const vm = this,
        _fieldDefinitions = {
            panel: {
                panel_id: {mode: 'rw'},
                name: {mode: 'rwc'},
                internal_name: {mode: 'rwc'},
                description: {mode: 'rwc'},
                created: {mode: 'r'}
            },
            password: {
                panel_id: {mode: 'rw'},
                password: {mode: 'wc'}
            },
            personals: {
                panel_id: {mode: 'rw'},
                first_name: {mode: 'rwc'},
                last_name: {mode: 'rwc'},
                gender_id: {mode: 'rwc'},
                birthdate: {mode: 'rwc'},
                email_address: {mode: 'rwc'},
                phone_number: {mode: 'rwc'},
                address_line_1: {mode: 'rwc'},
                address_line_2: {mode: 'rwc'},
                country_id: {mode: 'rwc'},
                postal_code: {mode: 'rwc'},
                city: {mode: 'rwc'},
                region: {mode: 'rwc'},
                timezone: {mode: 'rwc'},
                preferred_language_id: {mode: 'rwc'}
            },
            service_configurations: {
                panel_id: {mode: 'rw'},
                service_configuration_ids: {mode: 'rwc'}
            },
            administrators: {
                panel_id: {mode: 'r'}
            },
            managers: {
                panel_id: {mode: 'r'}
            },
            representatives: {
                panel_id: {mode: 'r'}
            },
            moderators: {
                panel_id: {mode: 'rw'},
                moderator_ids: {mode: 'rwc'},
                all: {mode: 'cr'}
            }
        },
        _fieldKeys = _reduce(_fieldDefinitions, _generateFieldKeys, {}),
        _master = _reduce(_fieldDefinitions, _generateEmtpyMasterData, {}),
        _subFormNames = _keys(_fieldDefinitions);

    vm.doShow = {
        administrators: false,
        managers: false,
        representatives: false,
        moderators: true
    };

    vm.countryCodes = null;
    vm.countryDialingCodes = null;
    vm.genders = genders;
    vm.browser = {
        supportsInputTypeDate: Modernizr.inputtypes.date &&
        config.dateInputType === 'date'
    };
    vm.dateInputType = config.dateInputType;
    vm.dateValidRegex = config.dateValidRegex;
    vm.defaultDebounceOptions = config.getDebounceOptions();

    let _supervisorsDeferred,
        _availableSupervisorsDeferred;

    function _refreshSupervisors(panel_id) {
        _supervisorsDeferred = $q.defer();

        panelAdmin.getSupervisors({
            panelId: panel_id
        }).$promise
            .then(function _setSupervisors(response) {
                $log.debug('edit-panel.controller._setSupervisors: response', response);
                _supervisorsDeferred.resolve(response);
            });

        return _supervisorsDeferred.promise;
    }

    function _getSupervisors(panel_id, forceUpdate) {
        forceUpdate = !!forceUpdate;
        return !forceUpdate && angular.isDefined(_supervisorsDeferred) ?
            _supervisorsDeferred.promise : _refreshSupervisors(panel_id);
    }

    function _refreshAvailableSupervisors(panel_id) {
        _availableSupervisorsDeferred = $q.defer();

        panelAdmin.getAvailableSupervisors({
            panelId: panel_id
        }).$promise
            .then(function _setSupervisors(response) {
                _availableSupervisorsDeferred.resolve(response.users);
            });

        return _availableSupervisorsDeferred.promise;
    }

    function _getAvailableSupervisors(panel_id, forceUpdate) {
        forceUpdate = !!forceUpdate;
        return !forceUpdate &&
        angular.isDefined(_availableSupervisorsDeferred) ?
            _availableSupervisorsDeferred.promise :
            _refreshAvailableSupervisors(panel_id);
    }

    function _resetForm(form, name) {
        if (angular.isUndefined(name)) {
            name = form.$name;
        }

        if (angular.isDefined(form)) {
            form.$setPristine();
            form.$setUntouched();
        }

        vm[name] = angular.copy(_master[name]);
    }

    function _generateGroupSelections(masterGroups) {
        return groupService.get()
            .then(function _assignSelections(groups) {
                masterGroups.selections =
                    _filter(groups, function _getSelectedGroups(group) {
                        return _includes(masterGroups.group_ids, group.group_id);
                    });
            });
    }

    const _supervisorGroups = [
        'administrators',
        'managers',
        'representatives',
        'moderators'
    ];

    function _generateModeratorSelections(master) {
        return _getSupervisors(master.moderators.panel_id, true)
            .then(function _assignSelections(supervisors) {
                _forEach(_supervisorGroups, function _assignSelections(name) {
                    master[name].selections = supervisors[name].users;
                });
            });
    }

    function _updateAvailableModerators(masterModerators) {
        return _getAvailableSupervisors(masterModerators.panel_id, true)
            .then(function _setAvailableSupervisors(moderators) {
                masterModerators.all = moderators;
            });
    }

    function _initMasterData(response) {
        if ($state.is('admin.panels.create') && response.panel_id) {
            $state.go('^.edit', {panelId: response.panel_id});
        }

        _forEach(_subFormNames, function _initSubForm(name) {
            _master[name] = _pick(response, _fieldKeys[name]);
        });

        $log.debug('edit-panel.controller._initMasterData: _master', _master);
        return $q.all([
            _updateAvailableModerators(_master.moderators),
            _generateModeratorSelections(_master)
            // _generateGroupSelections(_master.groups)
        ]);
    }

    function _attributeIsSubmittable(definitions, blacklist, value, key) {
        return !_includes(blacklist, key) &&
            _has(definitions, key) &&
            _includes(definitions[key].mode, 'w');
    }

    function _makeSafeCopy(data, definitions, blacklist) {
        blacklist = angular.isDefined(blacklist) ? blacklist : [];

        return _pickBy(data,
            _partial(_attributeIsSubmittable, definitions, blacklist));
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.fields) {
                _forEach(response.data.fields, function _setErrors(field) {
                    form[field.form_field].$setValidity(field.error, false);
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        vm.saveError[form.$name] = status;

        return $q.reject();
    }

    function _clearFormError(form) {
        vm.saveError[form.$name] = undefined;
    }

    function _resetSubForms() {
        _forEach(_subFormNames, function _resetSubForm(name) {
            _resetForm(undefined, name);
        });
    }

    function _postSubmitHandling(promise, form) {
        _clearFormError(form);

        return promise
            .then(_initMasterData, _partial(_handleSaveFormError, form))
            .then(_resetSubForms);
    }

    function _saveForm(form) {
        const names = vm.panel.panel_id ?
                [form.$name] : [form.$name, 'groups', 'moderators'],
            subData = _map(names, function _getData(name) {
                return _makeSafeCopy(vm[name], _fieldDefinitions[name]);
            }),
            data = _merge(...[{}].concat(subData));

        // $log.debug('edit-panel.controller._saveForm: names', names);
        // $log.debug('data', data);

        const promise = data.panel_id ?
            panelAdmin.update(data).$promise :
            panelAdmin.save(data).$promise;

        return _postSubmitHandling(promise, form);
    }

/*
    function _saveSupervisorsForm(form) {
        var panelData = _makeSafeCopy(vm.moderators, _fieldDefinitions.moderators);

        if (!panelData.panel_id) {
            return;
        }

        _postSubmitHandling(panelAdmin.update(panelData).$promise, form,
            'savePasswordError');
    }
*/

    function _blockAccount(form, value) {
        const panelData = _makeSafeCopy(vm.panel, _fieldDefinitions.panel);

        if (!panelData.panel_id) {
            return;
        }

        panelData.is_blocked = value;

        _postSubmitHandling(panelAdmin.update(panelData).$promise, form,
            'saveAccountError');
    }

    function _verifyAccount(form) {
        const panelData = _makeSafeCopy(vm.panel, _fieldDefinitions.panel);

        if (!panelData.panel_id) {
            return;
        }

        panelData.has_verified_email_address = true;

        _postSubmitHandling(panelAdmin.update(panelData).$promise, form,
            'saveAccountError');
    }

    function _createEmptyObject(keys) {
        return _zipObject(keys, _fill(new Array(keys.length), null));
    }

    function _deleteAccount() {
        if (vm.panel.panel_id) {
            panelAdmin.delete({panelId: vm.panel.panel_id}).$promise
                .then(function _goToList() {
                    $state.go('^.list');
                }, function _handleError(response) {
                    vm.deleteError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                });
        }
    }

    function _propagatePostalInfo(response) {
        let place;

        if (response.postal_codes && response.postal_codes.length) {
            place = response.postal_codes[0];
        } else {
            place = {
                place_name: null,
                admin_name1: null
            };
        }

        vm.personals.city = place.place_name;
        vm.personals.region = place.admin_name1;
    }

    function _lookupPostalCode() {
        postalCodeService.query({
            country_code: vm.personals.country_id,
            postal_code: vm.personals.postal_code
        }).$promise
            .then(_propagatePostalInfo);
    }

    function _updateSelection(item, action, sourceCollection, targetCollection, key, sortKey) {
        switch (action) {
            case 'select':
                if (!_filter(targetCollection, [key, item[key]]).length) {
                    utility.insertSorted(targetCollection, item, sortKey);
                }
                _remove(sourceCollection, [key, item[key]]);
                break;
            case 'remove':
                if (!_filter(sourceCollection, [key, item[key]]).length) {
                    utility.insertSorted(sourceCollection, item, sortKey);
                }
                _remove(targetCollection, [key, item[key]]);
                break;
        }
    }

    function _updateGroupsSelection(item, action) {
        _updateSelection(item, action, vm.groups.group_ids, 'group_id');
    }

    function _updateModeratorsSelection(item, action, form) {
        _updateSelection(item, action, vm.moderators.all,
            vm.moderators.selections, 'user_id', 'formal_name');
        if (action === 'select') {
            vm.moderators.selected = undefined;
        } else {
            form.$setDirty();
        }
        $log.debug('vm.moderators.moderator_ids', vm.moderators.moderator_ids);
        $log.debug('map(vm.moderators.selections, "user_id")', _map(vm.moderators.selections, 'user_id'));
        if (angular.isUndefined(vm.moderators.moderator_ids)) {
            vm.moderators.moderator_ids = [];
        }
        utility.updateSharedArray(vm.moderators.moderator_ids,
            _sortBy(_map(vm.moderators.selections, 'user_id')));
    }

    const _panelGroups = {
        open: gettext('Open Moderators'),
        closed: gettext('Closed Moderators')
    };

    function _panelGroup(item) {
        return gettextCatalog.getString(_panelGroups[item.status]);
    }

    function _setDefaultGroups() {
        _master.groups.group_ids = [2];

        return _generateGroupSelections(_master.groups);
    }

    function _setDefaultSupervisors() {
        const panel_ids = activeFiltersService.getAllActivePanelFilters();

        /**
         * `panel_id === 40` is Labben, the default panel.
         **/
        _master.supervisors.panel_ids = panel_ids.length ? panel_ids : [40];

        return _generateModeratorSelections(_master.supervisors);
    }
    function _menuCollapseState(name, isOpen) {
        vm.doShow[name] = isOpen;
    }

    function _activate() {
        // $log.debug('$state.params.panelId', $state.params.panelId);
        if (angular.isUndefined($state.params.panelId)) {
            _forEach(_subFormNames, function _createEmptyMaster(name) {
                _master[name] = _createEmptyObject(_fieldKeys[name]);
            });

            _setDefaultGroups()
                .then(_setDefaultSupervisors)
                .then(_resetSubForms);

        } else {
            panelAdmin.get({panelId: $state.params.panelId}).$promise
                .then(_initMasterData)
                .then(_resetSubForms);
        }

        // groupService.get().then(_setGroups);

        // countries.get('locations', true).then(_setCountries);
        // countries.get('dialingCodes', true).then(_setDialingCodes);
        // languages.get().then(_setLanguages);

        vm.saveError = {};

        vm.blockAccount = _blockAccount;
        vm.verifyAccount = _verifyAccount;
        vm.saveForm = _saveForm;

        vm.updateGroupsSelection = _updateGroupsSelection;
        vm.updateModeratorsSelection = _updateModeratorsSelection;

        vm.panelGroup = _panelGroup;

        vm.lookupPostalCode = _lookupPostalCode;

        vm.resetForm = _resetForm;

        vm.deleteAccount = _deleteAccount;

        vm.menuCollapseState = _menuCollapseState;
    }

    _activate();
}

export default angular
    .module('admin.panels.EditPanelController', [])
    .controller('EditPanelController', EditPanelController);
