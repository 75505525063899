'use strict';

import angular from 'angular';
import foursquareVenueAnswerHtml from './foursquare-venue-answer.html'
import _debounce from 'lodash/debounce'
import _isObject from 'lodash/isObject'


/* @ngInject */
function foursquareVenueAnswer($q, $timeout, $log, foursquareService) {
    const debounceWait = 250,
        _debouncedSearch = _debounce(_getVenues, debounceWait);
    let _currentVenues = [];

    function _getVenues(searchValue, deferred, rejectTimer) {
        const params = {
            query: searchValue
        };
        $timeout.cancel(rejectTimer);
        $log.debug('searchValue', searchValue);

        if (_isObject(searchValue)) {
            deferred.resolve(_currentVenues);
        } else if (searchValue && searchValue.length >= 3) {
            if (!foursquareService.gotLatLong) {
                params.near = searchValue;
            }

            foursquareService.venues.suggestCompletion(params).$promise
                .then(function _success(data) {
                    _currentVenues = data.venues;

                    deferred.resolve(data.venues);
                }, function _failure(error) {
                    const message =
                        `Could not lookup value "${searchValue}" at Foursquare.  Reason:`;
                    $log.warn(message, error);
                    deferred.reject({message: message, error: error});
                });
        } else {
            deferred.resolve([]);
        }
    }

    function _searchVenue(searchValue) {
        const deferred = $q.defer(),
            deferredTimeOut = 2 * debounceWait;

        // Make sure all deferred objects get rejected if they
        // aren't resolved within deferredTimeOut ms.
        const timeout = $timeout(function _cleanupTimedOutDeferred() {
            const reason =
                `Search for "${searchValue}" timed out after ${deferredTimeOut} ms, probably because it got debounced.`;
            $log.debug('Timeout:', reason);
            deferred.reject(reason);
        }, deferredTimeOut, false);

        _debouncedSearch(searchValue, deferred, timeout);

        return deferred.promise;
    }

    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: foursquareVenueAnswerHtml,
        link: function _link(scope) {
            const question = scope.question,
                initValue = question.answerIndex.rows[0].value;

            function _changedValue(newValue, oldValue) {
                let setValue,
                    doUpdate = false;

                if (newValue !== oldValue) {
                    if (_isObject(newValue)) {
                        setValue = newValue.id;
                        doUpdate = true;
                    } else {
                        setValue = null;
                        doUpdate = true;
                    }

                    if (doUpdate) {
                        $log.debug(
                            'Setting ' +
                            'question.answerIndex.rows[0].value:',
                            setValue);
                        question.answerIndex.rows[0].value = setValue;
                        question.updateAnswer();
                    }
                }
            }

            function _startWatchingSearch() {
                scope.$watch('searchValue', _changedValue);
            }

            function _setValueFromVenue(venue) {
                scope.searchValue = venue.name;
                question.updateAnswer();
                _startWatchingSearch();
            }

            foursquareService.init().then(function _continueLink() {
                if (initValue) {
                    foursquareService.venues.get({action: initValue})
                        .$promise.then(_setValueFromVenue);
                } else {
                    _startWatchingSearch();
                }

                scope.searchVenue = _searchVenue;
            });
        }
    };
}

export default angular
    .module('activities.questionnaire.answerType.foursquareVenueAnswer', [])
    .directive('foursquareVenueAnswer', foursquareVenueAnswer);
