'use strict';

import angular from 'angular';
import ChangePasswordController from './change-password.controller.js'
import countryField from './country-field.directive.js'
import deleteAccountHtml from './delete-account.html'
import deleteAccountHeaderHtml from './delete-account.header.html'
import deleteAccountFooterHtml from './delete-account.footer.html'
import phoneNumberField from './phone-number-field.directive.js'
import phoneNumberField2 from './phone-number-field-2.directive.js'
import ProfileController from './profile.controller.js'
import ProfileBoxController from './profile-box.controller.js'
import profileBox from './profile-box.directive'
import ProfileEditController from './profile-edit.controller.js'
import profileService from './profile-service.factory.js'
import profilePoints from './profile-points.directive.js'
import repeatPassword from './repeat-password.directive.js'
import UserHistoryController from './user-history.controller'
import UserSessionsController from './user-sessions.controller.js'
import validIdentifier from './valid-identifier.directive.js'
import validPhoneNumber from './valid-phone-number.directive.js'
import profileImageCropperHeaderHtml from './profile-image-cropper.header.html'
import profileImageCropperHtml from './profile-image-cropper.html'
import profileImageCropperFooterHtml from './profile-image-cropper.footer.html'
import profileHtml from './profile.html'
import changePasswordHtml from './change-password.html'
import userHistoryHtml from './user-history.html'
import userSessionsHtml from './user-sessions.html'
import profileEditHtml from './profile-edit.html'
import countryPostalCodeField from './country-postal-code-field.directive.js'

function _getCropImageState(name) {
    return {
        name: name,
        url: 'crop-image/',
        params: {imageMeta: undefined},
        views: {
            'modal-header@': {
                controller: 'ProfileImageCropperController',
                controllerAs: 'vm',
                templateUrl: profileImageCropperHeaderHtml
            },
            'modal-contents@': {
                controller: 'ProfileImageCropperController',
                controllerAs: 'vm',
                templateUrl: profileImageCropperHtml
            },
            'modal-footer@': {
                controller: 'ProfileImageCropperController',
                controllerAs: 'vm',
                templateUrl: profileImageCropperFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                closeTargetState: '^',
                size: 'large'
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
        //resolve: {
        //    meta: metaResolver
        //}
    };
}

function _getDeleteAccountState(name) {
    return {
        name: name,
        url: 'delete_account/',
        params: {imageMeta: undefined},
        views: {
            'modal-header@': {
                controller: 'DeleteAccountController',
                controllerAs: 'vm',
                templateUrl: deleteAccountHeaderHtml
            },
            'modal-contents@': {
                controller: 'DeleteAccountController',
                controllerAs: 'vm',
                templateUrl: deleteAccountHtml
            },
            'modal-footer@': {
                controller: 'DeleteAccountController',
                controllerAs: 'vm',
                templateUrl: deleteAccountFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                closeTargetState: '^',
                size: 'large'
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
        //resolve: {
        //    meta: metaResolver
        //}
    };
}

/* @ngInject */
function config($stateProvider) {
    // Let Angular resolve and inject meta value.
    const metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state({
            name: 'home.profile',
            url: '^/profile/',
            views: {
                'main-contents@': {
                    controller: 'ProfileController',
                    controllerAs: 'vm',
                    templateUrl: profileHtml
                }
            },
            secure: true,
            resolve: {
                meta: metaResolver
            }
        })
        .state({
            name: 'home.profile.change_password',
            url: 'change_password/',
            views: {
                'main-contents@': {
                    controller: 'ChangePasswordController',
                    controllerAs: 'vm',
                    templateUrl: changePasswordHtml
                }
            },
            secure: true,
            resolve: {
                meta: metaResolver
            }
        })
        .state(_getDeleteAccountState('home.profile.delete_account'))
        .state({
            name: 'home.profile.user_sessions',
            url: 'user_sessions/',
            views: {
                'main-contents@': {
                    controller: 'UserSessionsController',
                    controllerAs: 'vm',
                    templateUrl: userSessionsHtml
                }
            },
            secure: true,
            resolve: {
                meta: metaResolver
            }
        })
        .state(_getCropImageState('home.profile.crop-image'))
        .state({
            name: 'home.profile.edit',
            url: 'edit/',
            views: {
                'main-contents@': {
                    controller: 'ProfileEditController',
                    controllerAs: 'vm',
                    templateUrl: profileEditHtml
                }
            },
            secure: true,
            resolve: {
                meta: metaResolver
            }
        })
        .state(_getCropImageState('home.profile.edit.crop-image'))
        .state({
            name: 'home.profile.history',
            url: 'history/',
            views: {
                'main-contents@': {
                    controller: 'UserHistoryController',
                    controllerAs: 'vm',
                    templateUrl: userHistoryHtml
                }
            },
            secure: true,
            resolve: {
                meta: metaResolver
            }
        });
}

export default angular
    .module('profile', [
        ChangePasswordController.name,
        countryField.name,
        countryPostalCodeField.name,
        phoneNumberField.name,
        phoneNumberField2.name,
        profileBox.name,
        ProfileController.name,
        ProfileBoxController.name,
        ProfileEditController.name,
        profileService.name,
        profilePoints.name,
        repeatPassword.name,
        UserHistoryController.name,
        UserSessionsController.name,
        validIdentifier.name,
        validPhoneNumber.name
    ])
    .config(config);
