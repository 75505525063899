'use strict';

import angular from 'angular';
import angularMessages from 'angular-messages'
import _defaults from 'lodash/defaults'


/* @ngInject */
function FacebookController($rootScope, $scope, $state, $log, facebook,
                            countries, profileService, Modernizr, config,
                            utility, passwordService, genders, panelService,
                            acceptEulaService, legalModalService) {
    const vm = this;

    function _fillOutDefaultsFromFacebook(result) {
        const defaults = result.values;

        if (angular.isObject(defaults)) {
            $log.debug('Received from Facebook: defaults', defaults);

            defaults.accept = true;

            defaults.phone_number =
                profileService.phoneNumberStringToObject(defaults.phone_number);

            if (config.dateInputType === 'date') {
                defaults.birthdate = defaults.birthdate ?
                    utility.dateTime.fromIso(defaults.birthdate) : '';
            }

            vm.registration = defaults;
        } else {
            $log.debug('Received nothing from Facebook.  Going home.');
            $state.go('home', null, {reload: true});
        }
    }

    function _updateFbUser() {
        const formData = _defaults({}, vm.registration),
            phone_number = formData.phone_number;

        if (config.dateInputType === 'date') {
            formData.birthdate = utility.dateTime.toIsoDate(formData.birthdate);
        }

        formData['phone_number.phone_number_country_code'] =
            phone_number.phone_number_country_code;
        formData['phone_number.phone_number'] = phone_number.phone_number;

        delete formData.phone_number;

        $log.debug('$rootScope.$emit("growl:removeAll")');
        $rootScope.$emit('growl:removeAll');

        return facebook.updateFbUser(formData)
            .then(() => {
                const modalLevel = legalModalService.getModalLevel();
                legalModalService.onExit(modalLevel);
                delete acceptEulaService.registration;

                return panelService.refresh()
                    .then(() => {
                        $state.go('home', null, {reload: true});
                    });
            });
    }

    function _setDialingCodes(countryDialingCodes) {
        vm.countryDialingCodes = countryDialingCodes;
    }

    function _setCountryCodes(countryCodes) {
        vm.countryCodes = countryCodes;
    }

    function _next() {
        acceptEulaService.registration = _updateFbUser;
        $state.go('welcome.facebook.eula');
    }

    function _propagateLocation(location) {
        vm.registration.country = vm.registration.postalCode.countryCode;
        vm.registration.postal_code = vm.registration.postalCode.postalCode;

        vm.location = (location.city !== null && location.region !== null) ? {
            city: location.city,
            region: location.region
        } : null;
    }

    function _activate() {
        vm.defaultDebounceOptions = config.getDebounceOptions();
        vm.genders = genders;
        vm.today = utility.dateTime.todayAsIsoDate();
        vm.dateInputType = config.dateInputType;
        vm.dateValidRegex = config.dateValidRegex;
        vm.browser = {
            supportsInputTypeDate: Modernizr.inputtypes.date &&
                config.dateInputType === 'date'
        };
        vm.passwordMinLength = passwordService.minLength;
        vm.propagateLocation = _propagateLocation;
        vm.updateFbUser = _updateFbUser;

        vm.next = _next;

        facebook.getFacebookProfileData().then(_fillOutDefaultsFromFacebook);

        countries.get('dialingCodes', true).then(_setDialingCodes);

        countries.get('locations', true).then(_setCountryCodes);
    }

    _activate();
}

export default angular
    .module('welcome.facebook.facebookController', [
        angularMessages
    ])
    .controller('FacebookController', FacebookController);
