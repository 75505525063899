'use strict';

import angular from 'angular';

/* @ngInject */
function validIdentifier($q, $log, profileService) {
    function _link(scope, element, attrs, ctrl) {
        var requireExisting = false;

        switch (scope.requirement) {
            case 'require unique':
                requireExisting = false;
                break;
            case 'require existing':
                requireExisting = true;
                break;
            default:
                $log.error('Received unknown validation requirement:',
                    scope.requirement);
        }

        function _validateIdentifier(modelValue /*, viewValue */) {
            if (ctrl.$isEmpty(modelValue)) {
                // Consider empty model valid.
                return $q.when();
            }

            var deferred = $q.defer();

            $log.debug('valid-identifier.directive._validateIdentifier: scope.userId', scope.userId);

            profileService.isValidEmailAddress(modelValue, requireExisting,
                scope.userId)
                .then(function _updateField(isValid) {
                    if (isValid) {
                        deferred.resolve();
                    } else {
                        deferred.reject(requireExisting ?
                            'does not exist' : 'exists');
                    }
                });

            return deferred.promise;
        }

        ctrl.$asyncValidators.validIdentifier = _validateIdentifier;
    }

    return {
        require: 'ngModel',
        scope: {
            requirement: '@validIdentifier',
            userId: '='
        },
        link: _link
    };
}

export default angular
    .module('profile.validIdentifier', [])
    .directive('validIdentifier', validIdentifier);

