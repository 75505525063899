'use strict';

import angular from 'angular';
import filterRepHtml from './filter-rep.html'

/* @ngInject */
function filterRep(activeFiltersService) {
    function _link(scope) {
        function _removeFilter() {
            activeFiltersService.removeFilter(scope.filter);
        }

        function _updateToggleClass() {
            scope.toggleIconClass = {
                'fa-square-o': !scope.filter.enabled,
                'fa-check-square-o': scope.filter.enabled
            };
            scope.toggleDisabledClass = {
                'disabled': !scope.filter.enabled
            };
        }

        function _toggleFilter() {
            activeFiltersService.toggleFilter(scope.filter);
            _updateToggleClass();
        }

        function _init() {
            scope.removeFilter = _removeFilter;
            scope.toggleFilter = _toggleFilter;

            _updateToggleClass();
        }

        _init();
    }

    return {
        scope: {
            filter: '=filterRep'
        },
        replace: true,
        templateUrl: filterRepHtml,
        link: _link
    };
}

export default angular
    .module('admin.activeFilters.filterRep', [])
    .directive('filterRep', filterRep);
