'use strict';

import angular from 'angular';

/* @ngInject */
function storageService($window) {
    function _storageInstance(storage, key) {
        function _setItem(value) {
            storage.setItem(key, angular.toJson(value));
        }
        function _getItem() {
            return angular.fromJson(storage.getItem(key));
        }
        function _removeItem() {
            return storage.removeItem(key);
        }

        return {
            setItem: _setItem,
            getItem: _getItem,
            removeItem: _removeItem
        };
    }

    function _clear() {
        $window.localStorage.clear();
        $window.sessionStorage.clear();
    }

    return {
        user: _storageInstance($window.localStorage, 'user'),
        admin: _storageInstance($window.sessionStorage, 'admin'),
        emoji: _storageInstance($window.localStorage, 'emoji'),
        editedMessage: _storageInstance($window.localStorage, 'editedMessage'),
        storageInstance: _storageInstance,
        clear: _clear
    };
}

export default angular
    .module('components.storage', [])
    .factory('storage', storageService);
