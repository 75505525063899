'use strict';

import angular from 'angular';

/* @ngInject */
function ProfileController(activityService, profileService, countries, upload,
                           profileImageCropperService, trackingService
                           /*, cfpLoadingBar*/) {
    let vm = this;

    function _pickAndUpload(response) {
        return upload.pickAndStore(response.data);
    }

    function _setMediaUploadInfo(mediaUploadInfo) {
        vm.mediaUploadInfo = mediaUploadInfo;
    }

    function _trackEditProfile() {
        trackingService.trackProfile(trackingService.actions.edit, 'Profile');
    }

    function _trackEditProfileImage() {
        trackingService.trackProfile(trackingService.actions.edit, 'Profile Image');
    }

    function _trackChangePassword() {
        trackingService.trackProfile(trackingService.actions.password.change, 'Password');
    }

    function _trackUserSessions() {
        trackingService.trackProfile(trackingService.actions.userSessions.visit, 'User Sessions');
    }

    function _trackUserHistory() {
        trackingService.trackProfile(trackingService.actions.userHistory.visit, 'User History');
    }

    function _pickAndStoreImage() {
        _trackEditProfileImage();
        _pickAndUpload(vm.mediaUploadInfo)
            .then(profileImageCropperService.uploadedProfilePicture)
            .then(profileImageCropperService.cropImage);
    }

    function _setCountries(countryCodes) {
        vm.countryCodes = countryCodes;
    }

    // For debugging the `angular-loading-bar`, for example z-index problems.
    // let isLoading = false;
    //
    // function _startLoadingBar() {
    //     if (!isLoading) {
    //         cfpLoadingBar.start();
    //     } else {
    //         cfpLoadingBar.complete();
    //     }
    //     isLoading = !isLoading;
    // }
    // vm.startLoadingBar = _startLoadingBar;

    vm.profile = profileService.profile;
    vm.profileForm = profileService.profileForm;
    vm.activityCounts = activityService.activityCounts;
    vm.pickAndStoreImage = _pickAndStoreImage;
    vm.trackEditProfile = _trackEditProfile;
    vm.trackChangePassword = _trackChangePassword;
    vm.trackUserHistory = _trackUserHistory;
    vm.trackUserSessions = _trackUserSessions;


    function _activate() {
        profileService.getMediaUploadInfo({media_type: 'image'})
            .then(_setMediaUploadInfo);
        countries.getIndex('locations', false).then(_setCountries);
    }

    _activate();
}

export default angular
    .module('profile.profileController', [])
    .controller('ProfileController', ProfileController);
