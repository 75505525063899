'use strict';

import angular from 'angular';
import PromoController from './promo.controller.js';
import promoHtml from './promo.html'

/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state({
            name: 'home.promo',
            url: '^/{activityType:promo}/{activityId:int}/',
            views: {
                'main-contents@': {
                    controller: 'PromoController',
                    templateUrl: promoHtml
                }
            },
            secure: true
        });
}

export default angular
    .module('activities.promo', [
        PromoController.name
    ])
    .config(config);
