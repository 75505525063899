'use strict';

import angular from 'angular';

/* @ngInject */
function OnboardingController($scope, $state, $rootScope, $urlMatcherFactory,
                              meta, activityService, questionnaireService,
                              gettext) {
    var urlMatcher = $urlMatcherFactory.compile(
        '/{activityType:questionnaire}/{activityId:int}.json');

    function _activate(questionnaire) {
        activityService.updateGettextDomains([questionnaire.activity]);
        $scope.activity = questionnaire.activity;
        $scope.navigator = questionnaire.activity.navigator;

        $state.current.title = gettext("Start-up Questions");
    }

    questionnaireService.getActivity(
        urlMatcher.exec(meta.onboarding.introduction_url), {
            oneQuestionPerPage: true,
            isOnboarding: true
        }).then(_activate);
}

export default angular
    .module('welcome.onboarding.onboardingController', [])
    .controller('OnboardingController', OnboardingController);
