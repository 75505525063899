'use strict';
import * as angular from 'angular';
var publishedToPanelHtml = require('./published-to-panel.html');
import _map from 'lodash/map';
import _filter from 'lodash/filter';
/* @ngInject */
function publishedToPanel($log) {
    function _link(scope, element, attrs, queryEditor) {
        function _removePanel() {
            var panelIds = _map(queryEditor.publishedInPanels, 'panel_id'), newPanelIds = _filter(panelIds, function (panel_id) { return panel_id !== scope.panel.panel_id; });
            queryEditor.savePublishingDomains(scope.query.query_id, newPanelIds);
        }
        function _init() {
            scope.removePanel = _removePanel;
        }
        _init();
    }
    return {
        link: _link,
        require: '^queryEditor',
        replace: true,
        scope: {
            panel: '=publishedToPanel',
            query: '=query'
        },
        templateUrl: publishedToPanelHtml
    };
}
export var PublishedToPanelDirective = angular
    .module('components.query.publishedToPanel', [])
    .directive('publishedToPanel', publishedToPanel);
