var QueryConstraint = /** @class */ (function () {
    function QueryConstraint(options) {
        var params = options || {};
        this.discriminator = 'query_constraint';
        this.operator = params.operator || '==';
        this.question_id = params.question_id;
        this.question = null;
        this.subquery_id = params.subquery_id;
        this.subquery = null;
        this.children = params.children || [];
        this.query_constraint_id = params.query_constraint_id;
        this.meta = params.meta || null;
    }
    return QueryConstraint;
}());
export { QueryConstraint };
