'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'
import _map from 'lodash/map'


/* @ngInject */
function ProspectiveAccountDeletionsController($state, $window, $log,
                                               activeFiltersService,
                                               panelAdmin) {
    const vm = this;

    function _successfullyChanged() {
        const filter = activeFiltersService
            .getSelectionsFilter('admin.users.edit-memberships.panels',
                'panel_id');

        activeFiltersService.removeFilter(filter);
        $state.go('admin.users.list', {}, {reload: true});
    }

    function _handleMessageError(response) {
        vm.messageError = {
            message: _isString(response.data) ?
                response.statusText :
                response.data.message
        };
    }

    function _performRemoval(params) {
        params.already_checked = true;

        vm.messageError = null;

        panelAdmin.changeMemberships(params).$promise
            .then(_successfullyChanged, _handleMessageError);
    }

    function _activate() {
        vm.cancel = () => {
            $window.history.back();
        };

        if ($state.params.users && $state.params.wouldBeDeletedIds) {
            vm.users = _filter($state.params.users,
                (user) => _includes($state.params.wouldBeDeletedIds, user.user_id));
        } else {
            vm.users = [];
        }

        vm.removeAll = () => {
            const params = $state.params.params;

            params.user_ids = _map($state.params.users, 'user_id');

            _performRemoval($state.params.params);
        };

        vm.removeButLeaveSomeAlone = () => {
            const params = $state.params.params;

            params.user_ids = _map(
                _filter($state.params.users,
                    (user) => !_includes($state.params.wouldBeDeletedIds, user.user_id)),
                'user_id');

            _performRemoval(params);
        };
    }

    _activate();
}

export default angular
    .module('admin.users.ProspectiveAccountDeletionsController', [])
    .controller('ProspectiveAccountDeletionsController', ProspectiveAccountDeletionsController);
