'use strict';

import angular from 'angular';
import realTimeMessage from './real-time-message.directive.js'
import ModalAttachmentController from './modal-attachment.controller.js'

export default angular
    .module('components.realTimeEngine.realTimeMessage', [
        realTimeMessage.name,
        ModalAttachmentController.name
    ]);
