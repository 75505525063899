'use strict';
import * as angular from 'angular';
/* @ngInject */
function convertToNumber() {
    function _link(scope, instanceElement, instanceAttributes, ngModel) {
        ngModel.$parsers.push(function (val) {
            return parseInt(val, 10);
        });
        ngModel.$formatters.push(function (val) {
            return '' + val;
        });
    }
    return {
        require: 'ngModel',
        link: _link
    };
}
export var ConvertToNumberDirective = angular
    .module('components.query.convertToNumber.convertToNumber', [])
    .directive('convertToNumber', convertToNumber);
