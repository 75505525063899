'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _pick from 'lodash/pick'
import _pickBy from 'lodash/pickBy'
import _reduce from 'lodash/reduce'
import _remove from 'lodash/remove'
import _zipObject from 'lodash/zipObject'
import _fill from 'lodash/fill'
import _sortBy from 'lodash/sortBy'


/* @ngInject */
function EditMarketController($state, $scope, $resource, $q, $log, genders,
                            Modernizr, countries, languages, config, market, api,
                            postalCodeService, groupService, user, utility,
                            activeFiltersService, gettext, gettextCatalog) {
    function _generateFieldKeys(fieldKeys, fields, key) {
        fieldKeys[key] = _keys(fields);
        return fieldKeys;
    }

    function _generateEmtpyMasterData(master, fields, key) {
        master[key] = {};
        return master;
    }

    const vm = this,
        _fieldDefinitions = {
            market: {
                market_id: {mode: 'rw'},
                name: {mode: 'rwc'},
                description: {mode: 'rwc'},
                created: {mode: 'r'}
                // trial_is_expired: {mode: 'r'},
                // profile_picture: {mode: 'r'},
                // is_blocked: {mode: 'rwc'}
            },
            password: {
                market_id: {mode: 'rw'},
                password: {mode: 'wc'}
            },
            personals: {
                market_id: {mode: 'rw'},
                first_name: {mode: 'rwc'},
                last_name: {mode: 'rwc'},
                gender_id: {mode: 'rwc'},
                birthdate: {mode: 'rwc'},
                email_address: {mode: 'rwc'},
                phone_number: {mode: 'rwc'},
                address_line_1: {mode: 'rwc'},
                address_line_2: {mode: 'rwc'},
                country_id: {mode: 'rwc'},
                postal_code: {mode: 'rwc'},
                city: {mode: 'rwc'},
                region: {mode: 'rwc'},
                timezone: {mode: 'rwc'},
                preferred_language_id: {mode: 'rwc'}
            },
            service_configurations: {
                market_id: {mode: 'rw'},
                service_configuration_ids: {mode: 'rwc'}
            },
            administrators: {
                market_id: {mode: 'rw'},
                administrator_ids: {mode: 'rwc'},
                all: {mode: 'cr'}
            }
        },
        _fieldKeys = _reduce(_fieldDefinitions, _generateFieldKeys, {}),
        _master = _reduce(_fieldDefinitions, _generateEmtpyMasterData, {}),
        _subFormNames = _keys(_fieldDefinitions);

    vm.countryCodes = null;
    vm.countryDialingCodes = null;
    vm.genders = genders;
    vm.browser = {
        supportsInputTypeDate: Modernizr.inputtypes.date &&
        config.dateInputType === 'date'
    };
    vm.dateInputType = config.dateInputType;
    vm.dateValidRegex = config.dateValidRegex;
    vm.defaultDebounceOptions = config.getDebounceOptions();

    let _administratorsDeferred,
        _availableAdministratorsDeferred;

    function _refreshAdministrators(market_id) {
        _administratorsDeferred = $q.defer();

        market.getAdministrators({
            marketId: market_id
        }).$promise
            .then(function _setAdministrators(response) {
                _administratorsDeferred.resolve(response.administrators);
            });

        return _administratorsDeferred.promise;
    }

    function _getAdministrators(market_id, forceUpdate) {
        forceUpdate = !!forceUpdate;
        return !forceUpdate && angular.isDefined(_administratorsDeferred) ?
            _administratorsDeferred.promise : _refreshAdministrators(market_id);
    }

    function _refreshAvailableAdministrators(market_id) {
        _availableAdministratorsDeferred = $q.defer();

        market.getAvailableAdministrators({
            marketId: market_id
        }).$promise
            .then(function _setAdministrators(response) {
                _availableAdministratorsDeferred.resolve(response.users);
            });

        return _availableAdministratorsDeferred.promise;
    }

    function _getAvailableAdministrators(market_id, forceUpdate) {
        forceUpdate = !!forceUpdate;
        return !forceUpdate && angular.isDefined(_availableAdministratorsDeferred) ?
            _availableAdministratorsDeferred.promise : _refreshAvailableAdministrators(market_id);
    }

    function _resetForm(form, name) {
        if (angular.isUndefined(name)) {
            name = form.$name;
        }

        if (angular.isDefined(form)) {
            form.$setPristine();
            form.$setUntouched();
        }

        vm[name] = angular.copy(_master[name]);
    }

    function _generateGroupSelections(masterGroups) {
        return groupService.get()
            .then(function _assignSelections(groups) {
                masterGroups.selections =
                    _filter(groups, function _getSelectedGroups(group) {
                        return _includes(masterGroups.group_ids, group.group_id);
                    });
            });
    }

    function _generateAdministratorSelections(masterAdministrators) {
        return _getAdministrators(masterAdministrators.market_id, true)
            .then(function _assignSelections(administrators) {
                masterAdministrators.selections = administrators;
            });
    }

    function _updateAvailableAdministrators(masterAdministrators) {
        return _getAvailableAdministrators(masterAdministrators.market_id, true)
            .then(function _setAvailableAdministrators(administrators) {
                masterAdministrators.all = administrators;
            });
    }

    function _initMasterData(response) {
        if ($state.is('admin.markets.create') && response.market_id) {
            $state.go('^.edit', {marketId: response.market_id});
        }

        _forEach(_subFormNames, function _initSubForm(name) {
            _master[name] = _pick(response, _fieldKeys[name]);
        });

        return $q.all([
            _updateAvailableAdministrators(_master.administrators),
            _generateAdministratorSelections(_master.administrators)
            // _generateGroupSelections(_master.groups)
        ]);
    }

    function _attributeIsSubmittable(definitions, blacklist, value, key) {
        return !_includes(blacklist, key) &&
            _has(definitions, key) &&
            _includes(definitions[key].mode, 'w');
    }

    function _makeSafeCopy(data, definitions, blacklist) {
        blacklist = angular.isDefined(blacklist) ? blacklist : [];

        return _pickBy(data,
            _partial(_attributeIsSubmittable, definitions, blacklist));
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.fields) {
                _forEach(response.data.fields, function _setErrors(field) {
                    form[field.form_field].$setValidity(field.error, false);
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        vm.saveError[form.$name] = status;

        return $q.reject();
    }

    function _clearFormError(form) {
        vm.saveError[form.$name] = undefined;
    }

    function _resetSubForms() {
        _forEach(_subFormNames, function _resetSubForm(name) {
            _resetForm(undefined, name);
        });
    }

    function _postSubmitHandling(promise, form) {
        _clearFormError(form);

        return promise
            .then(_initMasterData, _partial(_handleSaveFormError, form))
            .then(_resetSubForms);
    }

    function _saveForm(form) {

        const    names = vm.market.market_id ?
                [form.$name] : [form.$name, 'groups', 'administrators'],
            subData = _map(names, function _getData(name) {
                return _makeSafeCopy(vm[name], _fieldDefinitions[name]);
            }),
            data = _merge(...[{}].concat(subData));

        const promise = data.market_id ?
            market.update(data).$promise :
            market.save(data).$promise;

        return _postSubmitHandling(promise, form);
    }

    function _blockAccount(form, value) {
        const marketData = _makeSafeCopy(vm.market, _fieldDefinitions.market);

        if (!marketData.market_id) {
            return;
        }

        marketData.is_blocked = value;

        _postSubmitHandling(market.update(marketData).$promise, form,
            'saveAccountError');
    }

    function _verifyAccount(form) {
        const marketData = _makeSafeCopy(vm.market, _fieldDefinitions.market);

        if (!marketData.market_id) {
            return;
        }

        marketData.has_verified_email_address = true;

        _postSubmitHandling(market.update(marketData).$promise, form,
            'saveAccountError');
    }

    function _savePasswordForm(form) {
        const marketData = _makeSafeCopy(vm.password, _fieldDefinitions.password);

        if (!marketData.market_id) {
            return;
        }

        _postSubmitHandling(market.update(marketData).$promise, form,
            'savePasswordError');
    }

    function _createEmptyObject(keys) {
        return _zipObject(keys, _fill(new Array(keys.length), null));
    }

    function _deleteAccount() {
        if (vm.market.market_id) {
            market.delete({marketId: vm.market.market_id}).$promise
                .then(function _goToList() {
                    $state.go('^.list');
                }, function _handleError(response) {
                    vm.deleteError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                });
        }
    }

    function _propagatePostalInfo(response) {
        let place;

        if (response.postal_codes && response.postal_codes.length) {
            place = response.postal_codes[0];
        } else {
            place = {
                place_name: null,
                admin_name1: null
            };
        }

        vm.personals.city = place.place_name;
        vm.personals.region = place.admin_name1;
    }

    function _lookupPostalCode() {
        postalCodeService.query({
            country_code: vm.personals.country_id,
            postal_code: vm.personals.postal_code
        }).$promise
            .then(_propagatePostalInfo);
    }
    function _updateSelection(item, action, sourceCollection, targetCollection, key, sortKey) {
        switch (action) {
            case 'select':
                if (!_filter(targetCollection, [key, item[key]]).length) {
                    utility.insertSorted(targetCollection, item, sortKey);
                }
                _remove(sourceCollection, [key, item[key]]);
                break;
            case 'remove':
                if (!_filter(sourceCollection, [key, item[key]]).length) {
                    utility.insertSorted(sourceCollection, item, sortKey);
                }
                _remove(targetCollection, [key, item[key]]);
                break;
        }
    }

    function _updateGroupsSelection(item, action) {
        _updateSelection(item, action, vm.groups.group_ids, 'group_id');
    }

    function _updateAdministratorsSelection(item, action, form) {
        _updateSelection(item, action, vm.administrators.all,
             vm.administrators.selections, 'user_id', 'formal_name');
        if (action === 'select') {
            vm.administrators.selected = undefined;
        } else {
            form.$setDirty();
        }
        utility.updateSharedArray(vm.administrators.administrator_ids,
            _sortBy(_map(vm.administrators.selections, 'user_id')));
    }

    const _panelGroups = {
        open: gettext('Open Administrators'),
        closed: gettext('Closed Administrators')
    };

    function _panelGroup(item) {
        return gettextCatalog.getString(_panelGroups[item.status]);
    }

    function _setDefaultGroups() {
        _master.groups.group_ids = [2];

        return _generateGroupSelections(_master.groups);
    }

    function _setDefaultAdministrators() {
        const panelIds = activeFiltersService.getAllActivePanelFilters();

        /**
         * `panel_id === 40` is Labben, the default panel.
         **/
        _master.administrators.panel_ids = panelIds.length ? panelIds : [40];

        return _generateAdministratorSelections(_master.administrators);
    }

    const _passwordsResource = $resource(api.url + '/api/passwords/', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });

    function _generatePassword(form) {
        return _passwordsResource.query().$promise
            .then(function _setPassword(response) {
                $scope.$applyAsync(function _makeFieldDirty() {
                    vm.password.password = response.password;
                    form.password.$setDirty();
                });
            });
    }

    function _activate() {
        // $log.debug('$state.params.marketId', $state.params.marketId);
        if (angular.isUndefined($state.params.marketId)) {
            _forEach(_subFormNames, function _createEmptyMaster(name) {
                _master[name] = _createEmptyObject(_fieldKeys[name]);
            });

            _setDefaultGroups()
                .then(_setDefaultAdministrators)
                .then(_resetSubForms);

        } else {
            market.get({marketId: $state.params.marketId}).$promise
                .then(_initMasterData)
                .then(_resetSubForms);
        }

        // groupService.get().then(_setGroups);

        // countries.get('locations', true).then(_setCountries);
        // countries.get('dialingCodes', true).then(_setDialingCodes);
        // languages.get().then(_setLanguages);

        vm.saveError = {};

        vm.blockAccount = _blockAccount;
        vm.verifyAccount = _verifyAccount;
        vm.saveForm = _saveForm;
        vm.savePasswordForm = _savePasswordForm;
        vm.generatePassword = _generatePassword;

        vm.updateGroupsSelection = _updateGroupsSelection;
        vm.updateAdministratorsSelection = _updateAdministratorsSelection;

        vm.panelGroup = _panelGroup;

        vm.lookupPostalCode = _lookupPostalCode;

        vm.resetForm = _resetForm;

        vm.deleteAccount = _deleteAccount;
    }

    _activate();
}

export default angular
    .module('admin.markets.EditMarketController', [])
    .controller('EditMarketController', EditMarketController);
