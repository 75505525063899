/* eslint-disable lodash/import-scope */
'use strict';

import angular from 'angular';
import angularMessages from 'angular-messages'
import _forEach from 'lodash/forEach'
import _isString from 'lodash/isString'


/* @ngInject */
function ChangePasswordController($q, $state, $window, $log, activityService,
                                  profileService, countries, api, gettextCatalog,
                                  Modernizr, config, profileImageCropperService,
                                  trackingService, passwordService, upload) {
    const vm = this;

    vm.imageFileInput = null;
    vm.profile = profileService.profile;
    vm.changePasswordForm = {
        username: undefined,
        old_password: '',
        password1: '',
        password2: ''
    };
    vm.gettextCatalog = gettextCatalog;
    vm.activityCounts = activityService.activityCounts;

    vm.apiUrl = api.url;

    function _trackCompletedEditProfile() {
        trackingService.trackProfile(trackingService.actions.complete, 'Profile');
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.form_errors) {
                _forEach(response.data.form_errors.fields, (value, name) => {
                    if (angular.isDefined(form[name])) {
                        form[name].$setValidity('serverValidation', false);
                        form[name].$setDirty();
                        form[name].serverValidationMessage = value;
                    }
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        if (status !== null) {
            vm.saveError.password = status;
        }
        return $q.reject(status);
    }

    function changePassword(form) {
        profileService.changePassword(vm.changePasswordForm)
            .then(_trackCompletedEditProfile, function _wrapHandler(response) {
                return _handleSaveFormError(form, response);
            })
            .then(function _goToProfile() {
                $state.go('home.profile');
            });
    }

    function _pickAndUpload(response) {
        return upload.pickAndStore(response.data);
    }

    function _setMediaUploadInfo(mediaUploadInfo) {
        vm.mediaUploadInfo = mediaUploadInfo;
    }

    function _trackEditProfileImage() {
        trackingService.trackProfile(trackingService.actions.edit, 'Profile Image');
    }

    function _pickAndStoreImage() {
        _trackEditProfileImage();
        _pickAndUpload(vm.mediaUploadInfo)
            .then(profileImageCropperService.uploadedProfilePicture)
            .then(profileImageCropperService.cropImage);
    }

    function _cancel() {
        $window.history.back();
    }

    function _activate() {
        vm.hasOldPassword = undefined;

        profileService.refreshEditForm()
            .then((profileForm) => {
                vm.hasOldPassword = profileForm.old_password !== null;
                vm.changePasswordForm.username = profileForm.email;
            });

        profileService.getMediaUploadInfo({media_type: 'image'})
            .then(_setMediaUploadInfo);

        vm.passwordMinLength = passwordService.minLength;

        vm.saveError = {
            password: null
        };
    }

    vm.changePassword = changePassword;
    vm.pickAndStoreImage = _pickAndStoreImage;
    vm.cancel = _cancel;

    _activate();
}

export default angular
    .module('profile.changePasswordController', [
        angularMessages
    ])
    .controller('ChangePasswordController', ChangePasswordController);
