'use strict';
// require('@nathanfaucett/now');
import * as angular from 'angular';
import _filter from 'lodash/filter';
import _first from 'lodash/first';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import _pick from 'lodash/pick';
import _pullAt from 'lodash/pullAt';
import _reduce from 'lodash/reduce';
import _remove from 'lodash/remove';
import _reverse from 'lodash/reverse';
import _size from 'lodash/size';
/* @ngInject */
function selfMonitoring($rootScope, $window, $interval, $resource, $log, config, api) {
    var _nextProbeId = 0, _activeProbes = [], _asyncEventQueue = [], _maxAsyncEventQueueItems = config.selfMonitoring.probe.maxAsyncEventQueueItems || 256, _maxAsyncEventQueueAgeMs = config.selfMonitoring.probe.maxAsyncEventQueueAgeMs
        || 5 * 60 * 1000, _asyncEventQueueCheckInterval = config.selfMonitoring.probe.asyncEventQueueCheckIntervalMs
        || 10 * 1000, _resource = $resource(api.url + '/api/monitoring/', {}, {}, {
        stripTrailingSlashes: false
    });
    var MergeError = /** @class */ (function () {
        function MergeError(message, probe, event) {
            this.message = message;
            this.probe = probe;
            this.event = event;
        }
        return MergeError;
    }());
    var MonitoringEvent = /** @class */ (function () {
        function MonitoringEvent(externalId, state, extra, elapsedTimeMs) {
            if (elapsedTimeMs === void 0) { elapsedTimeMs = null; }
            this.externalId = externalId;
            this.state = state;
            this.extra = extra;
            this.elapsedTimeMs = elapsedTimeMs;
            this.created = $window.performance.now();
        }
        return MonitoringEvent;
    }());
    var MonitoringProbe = /** @class */ (function () {
        function MonitoringProbe(params) {
            var _this = this;
            if (params === void 0) { params = {}; }
            this.enqueue = function () {
                _activeProbes.push(_this);
            };
            this.remove = function () {
                _remove(_activeProbes, _this);
            };
            this.getElapsedTime = function (now) {
                if (now === void 0) { now = $window.performance.now(); }
                return now - _this.start;
            };
            this.addEvent = function (event) {
                _this.events.push(event);
            };
            this.extractExternalId = function (eventData) {
                return _get(eventData, _this.externalIdField);
            };
            this.report = function () {
                var data = angular.toJson(_this);
                $log.debug('report: data', data);
                _resource.save(data);
            };
            this.reportAndRemoveIfComplete = function () {
                var completeStates = _size(_filter(_this.events, ['state', _this.completeState]));
                if (completeStates) {
                    _this.report();
                    _this.remove();
                }
            };
            this.registerEvent = function (state, eventData) {
                //$log.debug('eventData', eventData);
                if (_this.externalId === null) {
                    _this.externalId = _this.extractExternalId(eventData);
                }
                _this.addEvent(new MonitoringEvent(_this.externalId, state, _pick(eventData, _this.extraFields), _this.getElapsedTime()));
                return eventData;
            };
            this.registerEventFromResponse = function (state, response) {
                return _this.registerEvent(state, response.data);
            };
            this.cancel = function (state, eventData) {
                $log.debug('Cancelling MonitoringProbe:', _this);
                if (_this.externalId === null) {
                    _this.externalId = _this.extractExternalId(eventData);
                }
                _this.remove();
                return eventData;
            };
            this.cancelFromResponse = function (state, response) {
                return _this.cancel(state, response.data);
            };
            this.mergeEvent = function (event) {
                if (_this.externalId !== event.externalId) {
                    throw new MergeError("externalId doesn't match.", _this, event);
                }
                if (angular.isUndefined(event.state)) {
                    throw new MergeError('`event` is missing `state` attribute.', _this, event);
                }
                event.elapsedTimeMs = _this.getElapsedTime(event.created);
                _this.addEvent(event);
            };
            this.id = _nextProbeId++;
            this.externalId = null;
            this.domain = params.domain || null;
            this.extra = params.extra || null;
            this.externalIdField = params.externalIdField;
            this.extraFields = params.extractFields || [];
            this.events = [];
            /**
             * `completeState` === null will ensure that this probe is
             * reported as soon as it gets a state.  Any other string
             * value will be matched against each event's `state` value.
             * Only complete probes will be reported and destroyed.
             */
            this.completeState = params.completeState || null;
            this.start = $window.performance.now();
            this.enqueue();
        }
        return MonitoringProbe;
    }());
    function _enqueueAsyncEvent(event) {
        if (_asyncEventQueue.length >= _maxAsyncEventQueueItems) {
            // Remove the oldest unmerged event.
            _asyncEventQueue.shift();
        }
        _asyncEventQueue.push(event);
    }
    function _registerAsyncEvent(event) {
        var probe = _first(_filter(_activeProbes, { externalId: event.externalId }));
        if (angular.isDefined(probe)) {
            probe.mergeEvent(event);
        }
        else {
            _enqueueAsyncEvent(event);
        }
    }
    function _eventIsExpired(event) {
        return ($window.performance.now() - event.created)
            > _maxAsyncEventQueueAgeMs;
    }
    function _maybeMerge(probe, mergedIndexes, event, i) {
        if (probe.externalId === event.externalId) {
            probe.mergeEvent(event);
            mergedIndexes.push(i);
        }
        return mergedIndexes;
    }
    function _mergeIncomingAsyncEvents(updatedProbes, probe) {
        var _mergedIndexes = _reduce(_asyncEventQueue, _partial(_maybeMerge, probe), []);
        if (_mergedIndexes.length) {
            updatedProbes.push(probe);
        }
        _pullAt(_asyncEventQueue, _mergedIndexes);
        return updatedProbes;
    }
    function _checkAsyncEventQueue() {
        //$log.debug('self-monitoring.factory._checkAsyncEventQueue: ');
        _remove(_asyncEventQueue, _eventIsExpired);
        var updatedProbes = _reduce(_activeProbes, _mergeIncomingAsyncEvents, []);
        _forEach(_reverse(updatedProbes), function (probe) {
            probe.reportAndRemoveIfComplete();
        });
    }
    function _init() {
        var stop = $interval(_checkAsyncEventQueue, _asyncEventQueueCheckInterval);
        $rootScope.$on('$destroy', function _stop() {
            $interval.cancel(stop);
        });
    }
    var _selfMonitoring = {
        MonitoringEvent: MonitoringEvent,
        MonitoringProbe: MonitoringProbe,
        registerAsyncEvent: _registerAsyncEvent
    };
    _init();
    return _selfMonitoring;
}
export var name = 'components.selfMonitoring';
export var SelfMonitoringFactory = angular
    .module(name, [])
    .factory('selfMonitoring', selfMonitoring);
