'use strict';

import angular from 'angular';
import SockJS from 'sockjs-client';
import {Stomp} from 'stompjs';
import _keysIn from 'lodash/keysIn'
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _noop from 'lodash/noop'


/* @ngInject */
function realTime($rootScope, $q, $log, api, metaService, config) {
    let _stomp,
        _sessionToken = null,
        _queuedConnects = $q.when(),
        _queuedSubscribes = $q.when(),
        _queuedSends = $q.when(),
        _queuedUnsubscribes = $q.when();
    const _sendWhenSubscribedQueues = {},
        _channels = {},
        _defaults = {};

    function _getConnectionInfo() {
        return {
            isConnected: _stomp && _stomp.connected,
            transport: (_stomp && _stomp.ws) ? _stomp.ws.transport : null
        };
    }

    function _debugSTOMP(str) {
        $log.debug('STOMP debug:', str);
    }

    function _connect() {
        const deferred = $q.defer();

        if (_getConnectionInfo().isConnected) {
            deferred.resolve('Already connected to real-time server.  ' +
                'Will not reconnect.');
        } else {
            //$log.debug('Connecting to real-time server.');

            metaService.get().then(function _initSTOMP(meta) {
                // const ws = new SockJS(api.url + '/stomp');
                const wsUrl = `wss://${window.location.hostname}/ws`;
                // console.log(wsUrl);
                const ws = new WebSocket(wsUrl);
                let user, pass;

                _stomp = Stomp.over(ws);
                _stomp.heartbeat = {
                    outgoing: 0,
                    incoming: 0
                };

                if (!meta.auth.stomp) {
                    user = _defaults.username;
                    pass = _defaults.password;
                } else {
                    user = meta.auth.stomp.username;
                    pass = meta.auth.stomp.password;
                }

                _stomp.debug = config.realTimeMessaging.debugSTOMP ?
                    _debugSTOMP : _noop;

                _stomp.connect({
                        login: user,
                        passcode: pass
                    }, function _onSuccessfulConnect(frame) {
                        // Example session header:
                        //   'session-eS6RJq3WPihKTFf0X_6oGA'.
                        _sessionToken =
                            /^session-(.+)$/i.exec(frame.headers.session)[1];

                        //$log.debug('Connected to real-time server.');
                        deferred.resolve('Connected to real-time server.');
                    }, function _onError(error) {
                        deferred.reject('Could not connect to real-time' +
                            ' server: ', error);
                    }
                );
            });
        }

        return deferred.promise;
    }

    function _handleSTOMPError(what, error) {
        $log.error('STOMP error: ' + what, error);
    }

    function _queuedConnect() {
        _queuedConnects = _queuedConnects
            .then(_connect, _handleSTOMPError);
    }

    function _unsubscribeChannel(channel) {
        //$log.debug('Unsubscribing channel', channel);
        if (_channels.hasOwnProperty(channel)) {
            _channels[channel].unsubscribe();
            delete _channels[channel];
        }
    }

    function _unsubscribe(destinations) {
        _forEach(destinations === 'all' ? _keysIn(_channels) : destinations,
            _unsubscribeChannel);

        return $q.when();
    }

    function _queuedUnsubscribe(destinations) {
        _queuedUnsubscribes = _queuedUnsubscribes
            .then(function _callUnsubscribe() {
                return _unsubscribe(destinations);
            });
    }

    function _isSubscribed(destination) {
        return _has(_channels, destination);
    }

    function _subscribeToChannel(destination, eventName) {
        const deferred = $q.defer();

        if (!_isSubscribed(destination)) {
            const callback = function onMsg(frame) {
                $rootScope.$emit(eventName, angular.fromJson(frame));
            };

            if (!_sessionToken) {
                throw 'realTime needs sessionToken to create ' +
                'unique queue names.';
            }

            _channels[destination] =
                _stomp.subscribe(destination, callback, {
                    'x-queue-name': destination + '.' + _sessionToken
                });

            //$log.debug('Subscribed to ' + destination);

            if (_has(_sendWhenSubscribedQueues, destination)) {
                //$log.debug('Will send enqueued messages for ' + destination);

                _forEach(_sendWhenSubscribedQueues[destination],
                    function _sendQueuedMessage(message) {
                        _queuedSend(message.destination, message.headers,
                            message.body);
                    });
            }

            deferred.resolve();
        } else {
            //$log.debug('Already subscribed to ' + destination);
            deferred.resolve();
        }

        return deferred.promise;
    }

    function _queuedSubscribe(destinations, eventName) {
        function _subscribeWhenConnected() {
            _forEach(destinations, function _subscribeToSingleChannel(destination) {
                _queuedSubscribes = _queuedSubscribes
                    .then(function _callSubscribe() {
                        return _subscribeToChannel(destination, eventName);
                    }, _handleSTOMPError);
            });
        }

        if (!_getConnectionInfo().isConnected) {
            _queuedConnect();
        }

        _queuedConnects.then(_subscribeWhenConnected, _handleSTOMPError);
    }

    function _send(channelName, headers, body) {
        _stomp.send(channelName, headers, body);

        return $q.when();
    }

    function _queuedSend(destination, headers, body) {
        function _sendWhenSubscribed() {
            _queuedSends = _queuedSends
                .then(function _callSend() {
                    return _send(destination, headers, body);
                });
        }

        if (!_isSubscribed(destination)) {
            //$log.debug('Not yet connected to ' + destination +
            //    ', so queueing the message for now.');

            if (!_has(_sendWhenSubscribedQueues, destination)) {
                _sendWhenSubscribedQueues[destination] = [];
            }

            _sendWhenSubscribedQueues[destination].push({
                destination: destination,
                headers: headers,
                body: body
            });
        } else {
            _queuedSubscribes.then(_sendWhenSubscribed, _handleSTOMPError);
        }
    }

    return {
        connect: _queuedConnect,
        subscribe: _queuedSubscribe,
        unsubscribe: _queuedUnsubscribe,
        send: _queuedSend,
        getConnectionInfo: _getConnectionInfo
    };
}

export default angular
    .module('components.realTimeEngine.realTime', [])
    .factory('realTime', realTime);
