'use strict';

import angular from 'angular';
import _debounce from 'lodash/debounce'
import _forEach from 'lodash/forEach'


/* @ngInject */
function adjustHeight() {
    /* @ngInject */
    function HeightSetterController($scope, $element) {
        const self = this;
        let _currentHeight = null;

        function _isVisible() {
            return $element.is(":visible");
        }

        self.isVisible = _isVisible;

        function _updateHeight(height) {
            _currentHeight = Math.max(_currentHeight, height);
            $element.height(_currentHeight);
        }

        self.updateHeight = _updateHeight;
    }

    return {
        controller: HeightSetterController
    };
}

/* @ngInject */
function monitorHeight($window, $timeout) {
    const debounceWait = 300;  // milliseconds.

    function _link(scope, element, attrs, heightSetterController) {
        function _updateHeight() {
            heightSetterController.updateHeight(element.height());
        }

        const _debouncedUpdateHeight =
                _debounce(_updateHeight, debounceWait, {
                    'leading': true,
                    'maxWait': 1000,
                    'trailing': true
                }),
            _timeoutPromises = [];

        angular.element($window).on('resize', _debouncedUpdateHeight);


        function _repeatIfNotVisible() {
            _timeoutPromises.pop();

            if (heightSetterController.isVisible()) {
                _updateHeight();
            } else {
                _timeoutPromises.push($timeout(_repeatIfNotVisible, 250));
            }
        }

        _timeoutPromises.push($timeout(_repeatIfNotVisible, 0));

        scope.$on('$destroy', function _cleanUp() {
            angular.element($window).off('resize', _debouncedUpdateHeight);

            _forEach(_timeoutPromises, function _cancelTimeout(promise) {
                $timeout.cancel(promise);
            });
        });
    }

    return {
        require: '^^adjustHeight',
        link: _link
    };
}

export default angular
    .module('activities.questionnaire.answerType.adjustHeight', [])
    .directive('adjustHeight', adjustHeight)
    .directive('monitorHeight', monitorHeight);
