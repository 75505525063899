'use strict';

import angular from 'angular';
import RewardController from './reward.controller.js'
import activityModalHeaderHtml from '../activities/activity.modal.header.html'
import rewardHtml from './reward.html'
import rewardFooterHtml from './reward.footer.html'

/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state({
            name: 'home.reward',
            url: '^/reward/',
            views: {
                'modal-header@': {
                    templateUrl: activityModalHeaderHtml
                },
                'modal-contents@': {
                    controller: 'RewardController',
                    templateUrl: rewardHtml
                },
                'modal-footer@': {
                    templateUrl: rewardFooterHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    closeTargetState: '^',
                    size: 'small'
                });
            },
            onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.closeModal();
            },
            secure: true,
            isPopup: true,
            isReward: true
        });
}

export default angular
    .module('reward', [
        RewardController.name
    ])
    .config(config);
