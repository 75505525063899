'use strict';
import * as angular from 'angular';
import _reduce from 'lodash/reduce';
import _partial from 'lodash/partial';
/* @ngInject */
function messageAdminService($resource, api, gridUtils, utility) {
    var _currentMessages = [], _currentUserIndex = {}, _status = {
        hasSearched: false,
        isSearching: false,
        processingMs: 0,
    };
    function _clearCurrent() {
        utility.emptyArray(_currentMessages);
        utility.emptyObject(_currentUserIndex);
    }
    var messagesResource = $resource(api.url + '/api/messages/:messageId/', { messageId: '@message_id' }, {
        query: {
            method: 'POST',
            url: api.url + '/api/messages/:messageId/query/',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var messageSupervisorsResource = $resource(api.url + '/api/messages/:messageId/supervisors/', { messageId: '@message_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var messageAvailableSupervisorsResource = $resource(api.url + '/api/messages/:messageId/supervisors_available/', { messageId: '@message_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        // update: {
        //     method: 'PUT',
        //     isArray: false
        // }
    }, {
        stripTrailingSlashes: false
    });
    var messageMembershipsResource = $resource(api.url + '/api/message_memberships/', {}, {
    // check: {
    //     method: 'POST',
    //     url: api.url + '/api/message_memberships/check/',
    //     isArray: false
    // }
    }, {
        stripTrailingSlashes: false
    });
    var messagesDeleteResource = $resource(api.url + '/api/messages_delete/', {}, {
        deleteMultiple: {
            method: 'DELETE',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var messagesStatsResource = $resource(api.url + '/api/stats/messages', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    function _queryParamsFromFilters(filters) {
        return {
            message_ids: filters.messages,
            panel_ids: filters.panels,
            activity_ids: filters.activities,
            query_ids: filters.queries
        };
    }
    function _itemsFilterToQueryParams(items, ignoreExtraFilters) {
        if (ignoreExtraFilters === void 0) { ignoreExtraFilters = false; }
        var params = {};
        if (angular.isDefined(items)) {
            params.exclude_message_ids = items.exclude ? items.ids : null;
            params.message_ids = !items.exclude ? items.ids : null;
            if (!ignoreExtraFilters) {
                params.messageFilters = _reduce(items.extraFilters, _partial(gridUtils.generateFilterKey, items.columnDefs), []);
            }
        }
        return params;
    }
    return {
        currentMessages: _currentMessages,
        currentUserIndex: _currentUserIndex,
        status: _status,
        clearCurrent: _clearCurrent,
        get: messagesResource.get,
        query: messagesResource.query,
        save: messagesResource.save,
        update: messagesResource.update,
        delete: messagesResource.delete,
        deleteMultiple: messagesDeleteResource.deleteMultiple,
        stats: messagesStatsResource.query,
        changeMemberships: messageMembershipsResource.save,
        getSupervisors: messageSupervisorsResource.query,
        getAvailableSupervisors: messageAvailableSupervisorsResource.query,
        updateSupervisors: messageSupervisorsResource.update,
        queryParamsFromFilters: _queryParamsFromFilters,
        itemsFilterToQueryParams: _itemsFilterToQueryParams
    };
}
export var name = 'components.messageAdminService';
export var MessageAdminServiceFactory = angular
    .module(name, [])
    .factory('messageAdmin', messageAdminService);
