'use strict';

import angular from 'angular';
import activitiesListHtml from './activities-list.html'

/* @ngInject */
function activitiesList($state, activeFiltersService, gridUtils, gettext,
                        gettextCatalog, utility, activityService) {
    var columnDefs = [
        {
            field: 'activity_id',
            headerName: '<input type="checkbox" ' +
            'class="ag-selection-checkbox" ' +
            'indeterminate-checkbox="selections.isAllIndicator"' +
            'on-change="selections.allChanged">',
            headerClass: 'check-all',
            suppressResize: true,
            width: 40,
            checkboxSelection: true,
            valueGetter: gridUtils.nullValue,
            suppressMenu: true,
            suppressSorting: true,
            headerTooltip: 'Toggle all',
            compileWithAngular: true
        },
        {
            headerName: gettext('Activity ID'), field: 'activity_id',
            filter: 'number',
            width: 95,
            hide: true
        },
        {
            headerName: gettext('Name'), field: 'name',
            filter: 'text',
            width: 130
        },
        {
            headerName: gettext('Panel'), field: 'panel_internal_name',
            filter: 'text',
            width: 130
        },
        {
            headerName: gettext('Type'), field: 'humane_type',
            filter: 'text',
            cellRenderer: _typeColumnRenderer
        },
        {
            headerName: gettext('Effective State'), field: 'state',
            filter: 'text',
            width: 100
            // hide: true
        },
        {
            headerName: gettext('Description'), field: 'description',
            filter: 'text',
            width: 130
        },
        {
            headerName: gettext('Created Date'), field: 'created',
            filter: 'text',
            width: 100,
            hide: true
        },
        {
            headerName: gettext('Actions'), field: 'activity_id',
            cellRenderer: _actionColumnRenderer,
            width: 100,
            suppressMenu: true,
            suppressSorting: true,
            hide: true  // FIXME: Hidden until implemented.
        }
    ];

    function _typeColumnRenderer(params) {
        return angular.isDefined(params.data) ?
            gettextCatalog.getString(params.data.humane_type) : null;
    }

    function _actionColumnRenderer() {
        return '<a ui-sref="admin.activities.edit({activityId: data.activity_id})" ' +
            'ng-bind="::\'Edit\' |translate"></a>';
    }

    function _link(scope) {
        var selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName),
            selectionIdName = utility.getParentData($state.$current,
                activeFiltersService.defaultSelectionsIdName),
            rowIdPath = 'activity_id',
            ops = gridUtils.getGridOperations({
                useVirtualPaging: true,
                columnDefs: columnDefs,
                gridOptions: {},
                selectionName: selectionName,
                selectionIdName: selectionIdName,
                rowIdPath: rowIdPath,
                resource: activityService,
                resourceExtraColumns: [
                    'name',
                    'panel_internal_name',
                    'humane_type',
                    'status',
                    'description',
                    'created'
                ],
                resourceResponseCollection: 'activities',
                storageName: 'activitiesList',
                scope: scope
            });

        scope.toggleToolPanelVisibility = ops.toggleToolPanelVisibility;
        scope.showSql = false;

        scope.gridOptions = ops.gridOptions;
    }

    return {
        scope: true,
        replace: true,
        link: _link,
        /**
         * The ng-if="gridOptions" in the template element is there to avoid
         * initializing `ag-grid` until the options have been defined.
         */
        templateUrl: activitiesListHtml
    };
}

export default angular
    .module('admin.activities.activitiesList', ['agGrid'])
    .directive('activitiesList', activitiesList);
