'use strict';

import angular from 'angular';

/* @ngInject */
function EditAttachmentController($state, $stateParams, $rootScope) {
    const vm = this;

    vm.message = $stateParams.message;
    vm.what = $stateParams.what;

    function _resetInputs() {
        vm.linkURL = null;
        vm.youtubeURL = null;
        vm.attachedFiles = null;
    }

    function _closeModal() {
        $state.go('^');
    }

    function _addAttachment(attachment) {
        vm.message.attachments.push(attachment);
        _resetInputs();
        $rootScope.$broadcast('attachmentsChanged');
        _closeModal();
    }

    function _addLink() {
        _addAttachment({
            url: vm.linkURL,
            type: 'link'
        });
    }

    vm.addLink = _addLink;

    function _addYouTube() {
        _addAttachment({
            url: vm.youtubeURL,
            type: 'youtube'
        });
    }

    vm.addYouTube = _addYouTube;

    function _cancel() {
        _resetInputs();
        _closeModal();
    }
    vm.cancel = _cancel;
}

export default angular
    .module('activities.forum.editAttachmentController', [])
    .controller('EditAttachmentController', EditAttachmentController);
