'use strict';

import angular from 'angular';
import _assign from 'lodash/assign'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'


/* @ngInject */
function authorizationsService($rootScope, $q, $log, metaService, api,
                               utility, config) {
    const _authorizations = {};
    let _authorizationDeferred;

    function _refreshProfile(res) {
        const authorizations = _assign({}, res.data);

        utility.updateSharedObject(_authorizations, authorizations);

        $log.debug("$emit(" + _authorizationService.isRefreshedSignal + ")");
        $rootScope.$emit(_authorizationService.isRefreshedSignal,
            _authorizations);

        _authorizationDeferred.resolve(_authorizations);
    }

    function _handlerError(deferred, resource, reason) {
        const explanation = `Could not refresh ${resource}: ${reason}`;

        $log.warn('authorizationsService:', explanation);
        deferred.reject(explanation);
    }

    function _markActivityWithAuthorizations(activity, authorizations) {
        activity.isOwned = authorizations.is_owner;

        activity.isAdministered =
            activity.isOwned ||
            _includes(authorizations.administers_market_ids,
                activity.market_id);

        activity.isManaged =
            activity.isAdministered ||
            _includes(authorizations.manages_client_ids, activity.client_id);

        activity.isModerated =
            activity.isManaged ||
            _includes(authorizations.moderates_panel_ids, activity.panel_id);

        activity.isObserved =
            _includes(authorizations.observes_client_ids, activity.client_id);
    }

    function _isCurrentUserSupervisor(activity) {
        const isSupervisor = $q.defer();

        _authorizationService.get()
            .then(function _check(authorizations) {
                    isSupervisor.resolve(
                        _markActivityWithAuthorizations(activity, authorizations));
                }
            );

        return isSupervisor.promise;
    }

    function _markIsSupervisedByCurrentUser(activities) {
        const deferred = $q.defer();

        _authorizationService.get()
            .then(function _checkAll(authorizations) {
                _forEach(activities, function _mark(activity) {
                    _markActivityWithAuthorizations(activity, authorizations);
                    activity.isSupervisedByCurrentUser = activity.isModerated || activity.isObserved;
                    activity.statsAreEnabled = config.stats.isEnabled;
                });

                deferred.resolve(activities);
            });

        return deferred.promise;
    }

    function _refresh() {
        _authorizationDeferred = $q.defer();
        $log.debug('authorizationsService.refresh()');

        metaService.get()
            .then(function _refreshFromMeta(meta) {
                if (meta.user.trial_is_expired) {
                    return $q.reject("User's trial period is expired.");
                }

                return api.get('/api/authorizations/');
            })
            .then(_refreshProfile, function _abort(reason) {
                _handlerError(_authorizationDeferred, 'authorizations',
                    reason);

                $rootScope.$emit(_authorizationService.refreshFailedSignal);
            });

        return _authorizationDeferred.promise;
    }

    function _get() {
        return angular.isDefined(_authorizationDeferred) ?
            _authorizationDeferred.promise : _refresh();
    }

    function _supervisesAnything(authorizations) {

        return Boolean(
            authorizations.administers_market_ids.length ||
            authorizations.manages_client_ids.length ||
            authorizations.moderates_panel_ids.length ||
            authorizations.observes_client_ids.length
        );

    }

    const _authorizationService = {
        authorizations: _authorizations,
        isCurrentUserSupervisor: _isCurrentUserSupervisor,
        markIsSupervisedByCurrentUser: _markIsSupervisedByCurrentUser,
        isRefreshedSignal: 'authorizations:isRefreshed',
        refreshFailedSignal: 'authorizations:refreshFailed',
        refresh: _refresh,
        get: _get,
        supervisesAnything: _supervisesAnything
    };

    $rootScope.$on(metaService.isRefreshedSignal, function _refreshIfExisting() {
        if (angular.isDefined(_authorizationDeferred) &&
            utility.promiseIsResolved(_authorizationDeferred)) {
            _refresh();
        }
    });

    return _authorizationService;
}

export default angular
    .module('components.authorizationsService', [])
    .factory('authorizationsService', authorizationsService);
