'use strict';

import angular from 'angular';
import attachment from './attachment.directive.js'
import attachmentsHtml from './attachments.html'

/* @ngInject */
function attachments($rootScope, $sce, utility, media) {
    function _getYoutubeVideoURL(url) {
        // See https://developers.google.com/youtube/player_parameters
        return $sce.trustAsResourceUrl(
            'https://www.youtube.com/embed/' +
            media.parseVideoURL(url).id +
            '?modestbranding=1' +
            '&controls=2' +
            '&rel=0' +
            '&showinfo=0' +
            '&origin=' + document.location.origin);
    }

    var i,
        _attachmentTypes = ['youtube', 'image', 'link'],
        _attachmentPositions = {};

    for (i = 0; i < _attachmentTypes.length; i++) {
        _attachmentPositions[_attachmentTypes[i]] = i;
    }

    function _sortByAttachmentType(attachments) {
        var attachment,
            i;

        for (i = 0; i < attachments.length; i++) {
            attachment = attachments[i];

            attachment.typeIndex = _attachmentPositions[
                attachment.type];
            attachment.origPosition = i;
        }
        utility.multicolumnNumericSort(attachments,
            'typeIndex', 'origPosition');
    }

    function _deleteAttachmentHandler(event, attachment) {
        var scope = event.currentScope;

        var i = scope.attachments.indexOf(attachment);

        if (~i) {
            scope._toBeDeleted.push(attachment);

            scope.attachments.splice(i, 1);
        }
    }

    function _updateAttachments(scope) {
        if (scope.message && scope.message.attachments) {
            _sortByAttachmentType(scope.message.attachments);
            scope.attachments = scope.message.attachments;
        } else {
            scope.attachments = [];
        }

        angular.forEach(scope.attachments,
            function (attachment) {
                if (attachment.type === 'youtube') {
                    attachment.embed_url =
                        _getYoutubeVideoURL(attachment.url);
                }
            });
    }

    function _link(scope, element, attrs) {
        scope._toBeDeleted = [];
        scope.editAttachments = scope.editAttachments || false;

        if (!attrs.viewOnly) {
            scope.$on('attachmentsViewMode', function () {
                scope.editAttachments = false;
            });

            scope.$on('attachmentsEditMode', function () {
                scope.editAttachments = true;
            });

            scope.$on('deleteAttachment', _deleteAttachmentHandler);
        }

        _updateAttachments(scope);

        scope.$on('updateAttachments', function _scopeLocalUpdateAttachments() {
            _updateAttachments(scope);
        });

        var destroyListener = $rootScope.$on('updateAttachments',
            function _globalUpdateAttachments(event, data) {
                if (data.messageId === scope.message.id) {
                    _updateAttachments(scope);
                }
            });

        scope.$on('$destroy', destroyListener);
    }

    return {
        restrict: 'AE',
        scope: {
            message: '=',
            viewOnly: '@',
            editAttachments: '@'
        },
        templateUrl: attachmentsHtml,
        link: _link
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.attachments', [
        attachment.name
    ])
    .directive('attachments', attachments);
