'use strict';

import angular from 'angular';

import ageDistribution from './age-distribution.directive.js';
import genderDistribution from './gender-distribution.directive.js';
import userActivityDistribution
    from './user-activity-distribution.directive.js';
import isVerifiedDistribution from './is-verified-distribution.directive.js';
import _forEach from 'lodash/forEach'
import _merge from 'lodash/merge'


/* @ngInject */
function UsersStatsController($state, $rootScope, $scope, $log,
                              activeFiltersService, user, utility, config) {
    const vm = this;

    const selectionName = utility.getParentData($state.$current,
        activeFiltersService.defaultSelectionsName),
        rowIdPath = 'user_id';

    function _handleFailure(results) {
        $log.error('User stats request failed.', results);
        vm.usersStats = {};
    }

    function _updateStats(results) {
        vm.usersStats = results;
    }

    function _getStats() {
        const filters = activeFiltersService.getQueryConstraints(selectionName,
            rowIdPath),
            queryParams = _merge(user.queryParamsFromFilters(filters), {
                extra_columns: null
            });
        $log.debug('users-stats.controller._getStats: filters', filters);
        $log.debug('users-stats.controller._getStats: queryParams', queryParams);

        user.stats(queryParams).$promise.then(_updateStats, _handleFailure);

    }

    function _init() {
        const listenerDestroyers = [
            $rootScope.$on(activeFiltersService.updatedSignal, _getStats)
        ];

        $scope.$on('$destroy', function _destroyListeners() {
            _forEach(listenerDestroyers, utility.call);
        });

        _getStats();

        vm.colors = config.colors;
    }

    _init();
}

export default angular
    .module('admin.users.UsersStatsController', [
        ageDistribution.name,
        genderDistribution.name,
        isVerifiedDistribution.name,
        userActivityDistribution.name,
    ])
    .controller('UsersStatsController', UsersStatsController);
