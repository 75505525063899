'use strict';
import * as angular from 'angular';
import _reduce from 'lodash/reduce';
import _partial from 'lodash/partial';
/* @ngInject */
function marketService($resource, api, gridUtils) {
    var marketsResource = $resource(api.url + '/api/markets/:marketId/', { marketId: '@market_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var marketAdministratorsResource = $resource(api.url + '/api/markets/:marketId/administrators/', { marketId: '@market_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var marketAvailableAdministratorsResource = $resource(api.url + '/api/markets/:marketId/administrators_available/', { marketId: '@market_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        // update: {
        //     method: 'PUT',
        //     isArray: false
        // }
    }, {
        stripTrailingSlashes: false
    });
    var marketsDeleteResource = $resource(api.url + '/api/markets_delete/', {}, {
        deleteMultiple: {
            method: 'DELETE',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var marketsStatsResource = $resource(api.url + '/api/stats/markets', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    function _queryParamsFromFilters(filters, ignoreExtraFilters) {
        var params = {
            panel_ids: filters.panels,
            activity_ids: filters.activities,
            query_ids: filters.queries
        };
        if (angular.isDefined(filters.items)) {
            params.exclude_market_ids =
                filters.items.exclude ? filters.items.ids : null;
            params.market_ids =
                !filters.items.exclude ? filters.items.ids : null;
            if (!ignoreExtraFilters) {
                params.filters =
                    _reduce(filters.items.extraFilters, _partial(gridUtils.generateFilterKey, filters.items.columnDefs), []);
            }
        }
        return params;
    }
    return {
        get: marketsResource.get,
        query: marketsResource.query,
        save: marketsResource.save,
        update: marketsResource.update,
        delete: marketsResource.delete,
        deleteMultiple: marketsDeleteResource.deleteMultiple,
        stats: marketsStatsResource.query,
        getAdministrators: marketAdministratorsResource.query,
        getAvailableAdministrators: marketAvailableAdministratorsResource.query,
        updateAdministrators: marketAdministratorsResource.update,
        queryParamsFromFilters: _queryParamsFromFilters
    };
}
export var name = 'components.marketService';
export var marketServiceFactory = angular
    .module(name, [])
    .factory('market', marketService);
