'use strict';

import angular from 'angular';

/* @ngInject */
function CookiesController($log, $state, $stateParams, api, panelService, config, legalModalService) {
    const vm = this;

    function _activate() {
        vm.organization = config.organization;
        vm.lastUpdated = config.legal.cookies.lastUpdated;

        vm.modalLevel = legalModalService.getModalLevel();
    }

    _activate();
}

export default angular
    .module('components.cookiesController', [])
    .controller('CookiesController', CookiesController);
