'use strict';

import angular from 'angular'
import _isString from 'lodash/isString'


/* @ngInject */
function EulaController($log, $state, $stateParams, api, panelService, config,
                        legalModalService) {
    const vm = this;

    function _activate() {
        vm.organization = config.organization;
        vm.lastUpdated = config.legal.eula.lastUpdated;

        vm.modalLevel = legalModalService.getModalLevel();

        panelService.get()
            .then((panels) => {
                vm.currentPanel = panels.current;

                vm.eulaEmail = _isString(vm.currentPanel.support_email) ?
                    vm.currentPanel.support_email : config.organization.eulaEmail;
            });
    }

    _activate();
}

export default angular
    .module('components.eulaController', [])
    .controller('EulaController', EulaController);
