'use strict';

import angular from 'angular';

/* @ngInject */
function RewardController($scope, $rootScope, rewardService, gettext, config,
                          random) {
    $rootScope.hideCloseButton = true;
    $rootScope.title = config.isPointsEnabled ?
        gettext("Reward") : gettext("Thanks"); // TODO: Move to state.title.

    var headers = [
        gettext("Thanks for your contribution!"),
        gettext("Great, thanks for your answers!"),
        gettext("Thanks for your help!"),
        gettext("That's it!"),
        gettext("Thanks a lot for answering!")
    ];

    var encouragements = [
        gettext("We hear you! There's more to participate in, so keep going."),
        gettext("Hope you will take part in more activities."),
        gettext("You have been heard. Please join even more activities."),
        gettext("We hope to see you soon in other activities."),
        gettext("Well done. We hope you to see you in more activities.")
    ];

    function randomElement(arr) {
        return arr[Math.floor(random() * arr.length)];
    }

    if (!config.isPointsEnabled) {
        $scope.header = randomElement(headers);
        $scope.encouragement = randomElement(encouragements);
    }

    $scope.redeemable_points = rewardService.redeemable_points;
    $scope.config = config;
}

export default angular
    .module('reward.RewardController', [])
    .controller('RewardController', RewardController);
