'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _remove from 'lodash/remove'


class MenuController {
    constructor($rootScope, $scope, $state, $timeout, $location, $log,
                user, config, gettext, panelService, activityService,
                coreLayoutService, authorizationsService,
                activityStatsService) {
        'ngInject';

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$log = $log;

        this.config = config;
        this.gettext = gettext;
        this.user = user;

        this.isCustomDomain = config.isCustomDomain;

        this.panelService = panelService;
        this.activityService = activityService;
        this.coreLayoutService = coreLayoutService;
        this.authorizationsService = authorizationsService;
        this.activityStatsService = activityStatsService;

        this.origAdminShowMenuSetting = this.config.admin.showMenu;
        this.config.admin.showMenu = false;  // Initially hidden.
    }

    _getAdminMenuItems(authorizations) {
        let items = [];

        if (authorizations.administers_market_ids.length) {
            items.push({
                text: this.gettext('Markets'),
                icon: 'fal fa-globe',
                state: 'admin.markets.list',
                stateGroup: 'admin.markets'
            });
        }

        if (authorizations.administers_market_ids.length ||
            authorizations.observes_client_ids.length) {
            items = items.concat([
                // Not implemented yet:
                // {
                //     text: this.gettext('Clients'),
                //     icon: 'fas fa-flag',
                //     state: 'admin.clients',
                //     stateGroup: 'admin.clients'
                // }
            ]);
        }

        if (authorizations.administers_market_ids.length ||
            authorizations.moderates_panel_ids.length) {
            items = items.concat([
                {
                    text: this.gettext('Panels'),
                    icon: 'fal fa-users-class',
                    state: 'admin.panels.list',
                    stateGroup: 'admin.panels'
                }
            ]);
        }

        if (authorizations.administers_market_ids.length ||
            authorizations.observes_client_ids.length ||
            authorizations.moderates_panel_ids.length) {
            items = items.concat([
                {
                    text: this.gettext('Activities'),
                    icon: 'fal fa-play-circle',
                    state: 'admin.activities.list',
                    stateGroup: 'admin.activities'
                },
                {
                    text: this.gettext('Users'),
                    icon: 'fal fa-users',
                    state: 'admin.users.list',
                    stateGroup: 'admin.users'
                },
                // Not implemented yet:
                // {
                //     text: this.gettext('Locations'),
                //     icon: 'fa-map-marker',
                //     state: 'admin.locations',
                //     stateGroup: 'admin.locations'
                // }
                {
                    text: this.gettext('Messages'),
                    icon: 'fal fa-envelope',
                    state: 'admin.messages.list',
                    stateGroup: 'admin.messages'
                },

            ]);
        }

        return items;
    }

    hideMenu() {
        this.coreLayoutService.closeDrawer('leftDrawer');
    }

    logout() {
        // this.$log.debug('menu.controller.logout: ');
        this.hideMenu();
        this.user.logout();
    }

    toggleMonitor() {
        this.config.stats.isEnabled = !this.config.stats.isEnabled;
        if (this.config.stats.isEnabled) {
            this.$timeout(this.activityStatsService.initializeVisibleActivities);
        }
    }

    adminMenuCollapseState(isOpen) {
        this.adminMenuIsExpanded = isOpen;
    }

    setupAdminMenu(authorizations) {
        this.menuItems.admin = this._getAdminMenuItems(authorizations);
    }

    showIfSupervisor(authorizations) {
        // this.$log.debug('menu.controller.showIfSupervisor: authorizations', authorizations);
        if (this.origAdminShowMenuSetting) {
            this.config.admin.showMenu =
                this.authorizationsService.supervisesAnything(authorizations);
        }

        return authorizations;
    }

    $onDestroy() {
        _forEach(this._deregister, (destroyer) => destroyer());
    }

    $onInit() {
        if (this.config.admin.isFullMenuEnabled) {
            this.authorizationsService.get()
                .then((authorizations) => this.showIfSupervisor(authorizations))
                .then((authorizations) => this.setupAdminMenu(authorizations));
        }

        this._deregister = [
            this.$rootScope.$on(this.authorizationsService.isRefreshedSignal,
                (event, authorizations) => this.showIfSupervisor(authorizations)),
        ];

        this.userIsSupervisor = false;  // May change during activation.
        this.adminMenuIsExpanded = true;

        this.panels = this.panelService.panels;
        this.activityCounts = this.activityService.activityCounts;
        this.trialIsExpired = this.$state.includes('home.trial-expired');
        this.conf = this.config;

        this.menuItems = {
            activities: [
                {
                    text: this.gettext('All'),
                    icon: 'fa-list',
                    filter: 'all'
                },
                {
                    text: this.gettext('New'),
                    icon: 'fa-exclamation',
                    filter: 'new'
                },
                {
                    text: this.gettext('Active'),
                    icon: 'fa-check',
                    filter: 'active'
                },
                {
                    text: this.gettext('Completed'),
                    icon: 'fa-times',
                    filter: 'closed'
                },
                {
                    text: this.gettext('Reports'),
                    icon: 'fa-bar-chart',
                    filter: 'reported'
                },
                // {
                //     text: this.gettext('Perks'),
                //     icon: 'fa-gift',
                //     filter: 'perks'
                // }
            ],
            me: [
                {
                    text: this.gettext('Me'),
                    icon: 'fa-user',
                    state: 'profile'
                },
                {
                    text: this.gettext('About'),
                    icon: 'fa-info',
                    state: 'about'
                }
            ]
        };

        if (!this.config.menu.showReports) {
            _remove(this.menuItems.activities, {filter: 'reported'});
        }

        this.$scope.$on('$destroy', () => this.$onDestroy());
    }
}

export default angular
    .module(
        'home.menu.menuController',
        []
    )
    .controller('MenuController', MenuController);
