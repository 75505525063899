'use strict';

import angular from 'angular';
import _defaults from 'lodash/defaults'
import _now from 'lodash/now'
import _pick from 'lodash/pick'


/* @ngInject */
function profileImageCropperService($state, $q, upload, profileService,
                                    trackingService) {
    const _imageDataFieldsForSubmit = [
        'max_box_width',
        'crop_x_size',
        'crop_y_size',
        'img_width',
        'img_height'
    ];

    function _uploadedProfilePicture(responses) {
        const response = responses[0],
            deferred = $q.defer();

        const imageData = response.data,
            fullImageUrl = imageData.file_url,
            result = {
                user: {
                    response: imageData,
                    profile_picture: fullImageUrl + '?_=' + _now(),
                    profile_picture_raw: fullImageUrl
                },
                imageUploadParams: _defaults(
                    _pick(imageData, _imageDataFieldsForSubmit),
                    {
                        max_box_width: 0,
                        s3_file_id: imageData.s3_file_id
                    })
            };

        deferred.resolve(result);

        return deferred.promise;
    }

    function _cropImage(imageMeta) {
        $state.go('.crop-image', {imageMeta: imageMeta});
    }

    function _trackCompleteProfileImage(response) {
        trackingService.trackProfile(trackingService.actions.complete, 'Profile Image');
        return response;
    }

    function _saveCroppedImage(imageUploadParams) {
        return upload.saveCroppedProfilePicture(imageUploadParams)
            .then(_trackCompleteProfileImage)
            .then(function _cleanUpView(response) {
                if (profileService.profile.user.user_id ===
                    response.data.user_id) {
                    profileService.refreshProfileImage(
                        response.data.profile_picture_url);
                }
            });
    }

    return {
        uploadedProfilePicture: _uploadedProfilePicture,
        cropImage: _cropImage,
        saveCroppedImage: _saveCroppedImage
    };
}

export default angular
    .module('profile.profileEditController.profileImageCropperService', [])
    .service('profileImageCropperService', profileImageCropperService);
