'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'


/* @ngInject */
function run($rootScope, $state, $uiRouterGlobals, $timeout, $trace, $window,
             $transitions, $location, $q, $log, ezfb, metaService,
             activityService, panelService, config, userSignals,
             iScrollService, editableOptions) {
    /**
     * It's handy to add references to $state and $stateParams to the
     * $rootScope so that you can access them from any scope within your
     * applications.  For example,
     *   <li ng-class="{ active: $root.$state.includes('contacts.list') }">
     * will set the <li> to active whenever 'contacts.list' or one of its
     * decendents is active.
     */
    $rootScope.$state = $state;
    $rootScope.$stateParams = $uiRouterGlobals.params;

    // $trace.enable();

    // Horrible place to do this kind of configuration.  I'm looking at you,
    // `angular-xeditable`!
    editableOptions.theme = 'bs3';
    editableOptions.icon_set = 'font-awesome';

    const actualSubDomain = $location.host().split('.', 1)[0];
    const commonSubDomain = new URL(config.baseUrl).host.split('.', 1)[0];

    config.isCustomDomain = actualSubDomain !== commonSubDomain;

    function _initFacebook(meta) {
        ezfb.init({
            appId: meta.auth.facebook.fb_app_id,
            cookie: config.facebook.cookie,
            version: config.facebook.apiVersion
        });
    }

    // Ugly attempt to fix the viewport height bug in the Facebook app's
    // embedded browser on iOS 9.x.
    console.log($window);
    _forEach([0, 250, 500, 1000, 3000, 5000, 10000],
        function _forceResize(delay) {
            $timeout(() => {

                angular.element($window).trigger('resize');
            }, delay);
        });

    metaService.get().then(_initFacebook);

    function _onStateChangeStart(trans) {
        // return $q.all([metaService.get(), panelService.get()])
        //     .then(([meta, panels]) => {
        // return metaService.get().then((meta) => {

        return metaService.get()
            .then((meta) => {
                const deferred = $q.defer();

                panelService.get().then((panels) => {
                    deferred.resolve([meta, panels]);
                });
                return deferred.promise;
            })
            .then(([meta, panels]) => {
                // TODO: Meta user should be handled by the user/auth service.
                const trialIsExpired =
                        (meta.user === undefined) ? false :
                            meta.user.trial_is_expired,
                    toState = trans.to();

                if (trialIsExpired && toState.name !== 'home.trial-expired') {
                    return trans.router.stateService.target('home.trial-expired',
                        null, {reload: true});
                }

                if (!meta.user.is_authenticated && toState.secure) {
                    // $log.debug('Not logged in, redirecting to welcome page.');
                    return trans.router.stateService.target('welcome', null,
                        {reload: true});
                }

                if (meta.user.is_authenticated) {
                    // $log.debug('app.run.: panels.current.has_accepted_eula', panels.current.has_accepted_eula);
                    // $log.debug('app.run.: panels.current.panel_id', panels.current.panel_id);
                    if (angular.isDefined(panels.current.has_accepted_eula) && !panels.current.has_accepted_eula) {
                        if (!_includes([
                            'home.accept-eula',
                            'home.cookies',
                            'home.privacy'], toState.name)) {
                            // $log.debug('app.run.will-go-to: home.accept-eula');
                            return trans.router.stateService
                                .target('home.accept-eula', null, {reload: true});
                        }
                    } else {
                        if (_includes([
                            'home.accept-eula',
                            'home.cookies',
                            'home.privacy'], toState.name)) {
                            return trans.router.stateService
                                .target('home', null, {reload: true});
                        } else {
                            if (meta.user.set_pw && toState.name !== 'home.set-password') {
                                // $log.debug('app.run.will-go-to: home.set-password');
                                return trans.router.stateService
                                    .target('home.set-password', null, {reload: true});
                            }

                        }
                    }

                }

                // Refresh meta if necessary.
                if (toState.name === 'default') {
                    let target;
                    if (trialIsExpired) {
                        target = 'home.trial-expired';
                    } else {
                        if (meta.user.is_authenticated) {
                            target = 'home';
                        } else {
                            target = 'welcome';
                        }
                    }

                    return trans.router.stateService.target(target, null,
                        {reload: true});
                }
            });
    }

    $transitions.onStart({}, _onStateChangeStart);

    function _onStateChangeSuccess(trans) {
        const toState = trans.to();
        // $log.debug('app.run._onStateChangeSuccess: toState', toState);

        $rootScope.currentState = toState;
        $rootScope.currentStateParams = trans.params();
        $rootScope.showMainMenu = false;
        $rootScope.toggleMainOverlay = false;
        $rootScope.hideCloseButton = false;

        // $root.currentStateName is now only used in templates.
        $rootScope.currentStateName = toState.name;

        $rootScope.isPopup = toState.isPopup || false;
        $rootScope.isSurvey = toState.isSurvey || false;
        $rootScope.isReward = toState.isReward || false;
        $rootScope.isLogin = toState.isLogin || false;
        $rootScope.isRegister = toState.isRegister || false;
        $rootScope.isOnboarding = toState.isOnboarding || false;

        if ($state.is('home') ||
            $state.is('home.activities') ||
            $state.is('home.panel')) {
            $log.debug('$emit(' + activityService.updateFilterSignal + ')');
            $rootScope.$emit(activityService.updateFilterSignal);
        }

        if ($state.is('home.panel')) {
            if (angular.isDefined($state.params.panelId)) {
                panelService.setCurrentPanel($state.params.panelId, true)
                    .then((panel) => {
                        if (!panel.has_accepted_eula) {
                            $state.go('home.accept-eula');
                        }
                    });
            }
        }

        if ($state.includes('admin')) {
            if (iScrollService.state.useIScroll) {
                iScrollService.disable(false);
            }
        } else {
            if (!iScrollService.state.useIScroll &&
                !iScrollService.state.autoDetectedUseNativeScroll) {
                iScrollService.enable(false);
            }
        }
    }

    $transitions.onSuccess({}, _onStateChangeSuccess);

    function _goToWelcome(/*event*/) {
        $state.go('welcome', null, {reload: true});
    }

    $rootScope.$on(userSignals.userLogoutSuccessSignal, _goToWelcome);
}

export default run;
