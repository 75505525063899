'use strict';

import angular from 'angular';
import EditMembershipsController from './edit-memberships.controller';
import MembershipChangesController from './membership-changes.controller'
import EditUserController from './edit-user.controller.js';
import ExportUserDataController from './export-user-data.controller.js';
import DeleteUserController from './delete-users.controller.js';
import MessageUsersController from './message-users.controller.js';
//import questionList from './question-list/question-list.directive.js';
import UsersController from './users.controller.js';
import usersList from './users-list.directive.js';
import UsersStatsController from './users-stats.controller.js';
import usersSurveyService from './users-survey-service.factory';
import usersWrapperHtml from './users-wrapper.html'
import usersHtml from './users.html'
import editUserHtml from './edit-user.html'
import exportUserDataHtml from './export-user-data.html'
import panelModalHeaderHtml
    from '../../components/modal/panel.modal.header.html'
import deleteUsersHtml from './delete-users.html'
import messageUsersHtml from './message-users.html'
import editMembershipsHtml from './edit-memberships.html'
import membershipsChangesHtml from './memberships-changes.html'
import usersFilterHtml from './users-filter.html'
import usersStatsHtml from './users-stats.html'
import ProspectiveAccountDeletionsController from './prospective-account-deletions.controller'
import prospectiveAccountDeletionsHtml from './prospective-account-deletions.html'


/* @ngInject */
function config($stateProvider, gettext) {
    /*
     Let Angular resolve and inject meta value.
     */
    var metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    $stateProvider
        .state({
            name: 'admin.users',
            abstract: true,
            url: 'users/',
            views: {
                'main-contents@': {
                    templateUrl: usersWrapperHtml,
                    controller: 'UsersController',
                    controllerAs: 'vm'
                }
            },
            secure: true,
            data: {
                selectionsName: 'admin.users',
                selectionsIdName: 'user_id'
            },
            resolve: {
                meta: metaResolver
            }
        })
        .state({
            name: 'admin.users.list',
            url: 'list/',
            views: {
                'users': {
                    templateUrl: usersHtml
                }
            },
            secure: true
        })
        .state({
            name: 'admin.users.create',
            url: 'create/',
            views: {
                'users': {
                    templateUrl: editUserHtml,
                    controller: 'EditUserController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state({
            name: 'admin.users.edit',
            url: ':userId/edit/',
            views: {
                'users': {
                    templateUrl: editUserHtml,
                    controller: 'EditUserController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        })
        .state({
            name: 'admin.users.export-user-data',
            url: 'export-user-data/',
            views: {
                'users': {
                    templateUrl: exportUserDataHtml,
                    controller: 'ExportUserDataController',
                    controllerAs: 'vm'
                }
            },
            params: {
                userSampleQueryParams: null
            },
            secure: true
        })
        .state({
            name: 'admin.users.list.delete',
            url: 'delete/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: deleteUsersHtml,
                    controller: 'DeleteUsersController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.users.list'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },
            title: gettext('Danger Zone'),
            panelMode: 'danger',
            hideCloseButton: false,
            secure: true
        })
        .state({
            name: 'admin.users.message-users',
            url: 'message/',
            views: {
                'users': {
                    templateUrl: messageUsersHtml,
                    controller: 'MessageUsersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                userSampleQueryParams: null
            },
            secure: true
        })
        .state({
            name: 'admin.users.edit-memberships',
            url: 'edit-memberships/',
            views: {
                'users': {
                    templateUrl: editMembershipsHtml,
                    controller: 'EditMembershipsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                userSampleQueryParams: null
            },
            data: {
                selectionsName: 'admin.users.edit-memberships.panels',
                selectionsIdName: 'panel_id'
            },
            secure: true
        })
        .state({
            name: 'admin.users.edit-memberships.changes',
            url: 'changes/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: membershipsChangesHtml,
                    controller: 'MembershipChangesController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.users.edit-memberships'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },

            title: gettext('Prospective Changes'),
            panelMode: 'info',
            hideCloseButton: false,
            params: {
                action: undefined,
                userIds: undefined,
                panel: undefined,
            },
            secure: true
        })
        .state({
            name: 'admin.users.edit-memberships.deleting-accounts',
            url: 'delete-warning/',
            views: {
                'modal-header@': {
                    templateUrl: panelModalHeaderHtml,
                    controller: 'ModalHeaderController'
                },
                'modal-contents@': {
                    templateUrl: prospectiveAccountDeletionsHtml,
                    controller: 'ProspectiveAccountDeletionsController',
                    controllerAs: 'vm'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    header: {
                        visible: coreLayoutService.defaultExcept({all: true})
                    },
                    footer: {
                        hidden: coreLayoutService.defaultExcept({all: true})
                    },
                    closeTargetState: 'admin.users.edit-memberships'
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.closeModal({
                    closeTargetState: null
                });
            },

            title: gettext('User Accounts Could Become Deleted'),
            panelMode: 'danger',
            hideCloseButton: false,
            params: {
                params: undefined,
                users: undefined,
                wouldBeDeletedIds: undefined
            },
            secure: true
        })
        .state({
            name: 'admin.users.segment',
            url: 'segment/:queryId',
            views: {
                'users': {
                    templateUrl: usersFilterHtml
                }
            },
            secure: true
        })
        .state({
            name: 'admin.users.stats',
            url: 'stats/',
            views: {
                'users': {
                    templateUrl: usersStatsHtml,
                    controller: 'UsersStatsController',
                    controllerAs: 'vm'
                }
            },
            secure: true
        });

}

export default angular
    .module('admin.users', [
        DeleteUserController.name,
        EditMembershipsController.name,
        EditUserController.name,
        ExportUserDataController.name,
        MembershipChangesController.name,
        MessageUsersController.name,
        ProspectiveAccountDeletionsController.name,
        //questionList.name,
        UsersController.name,
        usersList.name,
        UsersStatsController.name,
        usersSurveyService.name
    ])
    .config(config);
