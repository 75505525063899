'use strict';

import angular from 'angular';

/* @ngInject */
function postalCodeService($resource, api) {
    const _postalCodeResource = $resource(api.url + '/api/postal_codes/', {}, {
        query: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });

    function _init() {
    }

    _init();

    return {
        query: _postalCodeResource.query
    };
}

export default angular
    .module('components.postalCodeService', [])
    .factory('postalCodeService', postalCodeService);
