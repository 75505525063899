'use strict';
import * as angular from 'angular';
import { SegmentServiceFactory } from './segment-service.factory';
import { EntitySearchDirective } from './entity-search.directive';
import { QueryEditorDirective } from "./query-editor.directive";
export var name = 'components.query';
export var QueryModule = angular
    .module(name, [
    SegmentServiceFactory.name,
    EntitySearchDirective.name,
    QueryEditorDirective.name,
]);
