'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _trim from 'lodash/trim'


/* @ngInject */
function clearServerSideValidationOnChange() {
    function _link(scope, elem, attrs, ctrl) {
        let labels;

        if (attrs.clearServerSideValidationOnChange === '') {
            labels = ['serverValidation'];
        } else {
            labels =
                _map(attrs.clearServerSideValidationOnChange.split(','),
                    _trim);
        }

        function _clearServerSideValidationOnChange(value) {
            _forEach(labels, function _clearLabel(label) {
                ctrl.$setValidity(label, true);
            });

            return value;
        }

        ctrl.$parsers.push(_clearServerSideValidationOnChange);
    }

    return {
        require: "ngModel",
        link: _link
    };
}

export default angular
    .module('components.clearServerSideValidationOnChange', [])
    .directive('clearServerSideValidationOnChange', clearServerSideValidationOnChange);
