'use strict';

import angular from 'angular';
import yesNoAnswerHtml from './yes-no-answer.html'
import _forEach from 'lodash/forEach'


/* @ngInject */
function yesNoAnswer($log) {
    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        templateUrl: yesNoAnswerHtml,
        link: function _link(scope) {
            const question = scope.question;

            scope.next = function _next() {
                _forEach(question.session_answers, function _debugYesNo(answer) {
                    $log.debug('_debugYesNo: answer.valuerow_id',
                        answer.valuerow_id);
                });
            };
        }
    };
}

export default angular
    .module('activities.questionnaire.answerType.yesNoAnswer', [])
    .directive('yesNoAnswer', yesNoAnswer);
