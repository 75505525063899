'use strict';

import angular from 'angular';
import growl from './growl.js'
import notificationsHeader from './notifications-header.directive.js'
import notifications from './notifications.directive.js'

export default angular
    .module('notifications', [
        growl.name,
        notificationsHeader.name,
        notifications.name
    ]);


