'use strict';

import angular from 'angular';
import _assign from 'lodash/assign'
import _assignIn from 'lodash/assignIn'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _isArray from 'lodash/isArray'
import _isUndefined from 'lodash/isUndefined'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'


/* @ngInject */
function ImportUsersAddMappingController($state, $stateParams, $log,
                                         userDataImport, gettext, formService) {
    const vm = this,
        _answerTypes = {
            'Free Text (Short)': {
                label: gettext('Free Text'),
                helpText: gettext('Can hold any value that does not ' +
                    'fit the other data types.'),
                format: null
            },
            'Single Select': {
                label: gettext('Single Select'),
                helpText: gettext('When there are few (less than 10) ' +
                    'unique values in a column.'),
                format: null
            },
            'Yes/No': {
                label: gettext('Yes / No'),
                helpText: gettext('When there are only two unique values.  ' +
                    'All values in the format field will be interpreted ' +
                    'as TRUE, all other values will be interpreted as FALSE.'),
                format: '1, ja, j, yes, y, sann, s, true, t'
            },
            'Date Selection': {
                label: gettext('Date'),
                helpText: gettext('A date value.  The values will be ' +
                    'interpreted according to the specified format.'),
                format: '%Y-%m-%d'
            }
        };

    vm.closePopup = function _closePopup() {
        $state.go('^');
    };

    function _clearExistingMappingIfDefined(attribute) {
        if (angular.isDefined(attribute.external_column)) {
            attribute.external_column.isMapped = false;
            attribute.external_column = undefined;
            attribute.external_column_id = null;
        }
    }

    function _setUniqueColumnValues(response) {
        vm.uniqueColumnValues = response.column_values;
        vm.uniqueColumnValueCount = response.column_value_count;
        vm.uniqueColumnValueTotal = response.column_value_total;
    }

    function _clearUniqueColumnValues() {
        vm.uniqueColumnValues = [];
        vm.uniqueColumnValueCount = null;
        vm.uniqueColumnValueTotal = null;
    }

    function _assignColumnToAttribute(attribute, column, action) {
        if (action === 'select') {
            _clearExistingMappingIfDefined(attribute);

            if (angular.isDefined(column) && column !== null) {
                attribute.external_column_id = column.external_column_id;
                column.isMapped = true;
                attribute.external_column = column;

                _clearUniqueColumnValues();

                userDataImport.getUniqueColumnValues({
                    userDataImportId: $state.params.userDataImportId,
                    columnId: column.external_column_id
                }).$promise
                    .then(_setUniqueColumnValues);
            } else {
                attribute.external_column = undefined;
                _clearUniqueColumnValues();
            }
        } else {
            attribute.external_column_id = null;
            column.isMapped = false;
        }
        // _postMappingUpdates();
    }

    function _createEmptyAttribute() {
        return {
            default_format: null,
            description: null,
            external_column: undefined,
            external_column_id: null,
            format: null,
            internal_attribute_id: undefined,
            label: null,
            name: null,
            order: null,
            question_id: null,
            selection: null,
            user_import_id: undefined,
            value: null,
            answertype_id: null,
            answertype: null  // Only for view updates.
        };
    }

    function _updateIsMappedFlagForExistingAttributes(attributes) {
        // TODO: Create vm.unmappedAttributes from $sta…Para….mappedColumns (!.external_column_id):

        const questionIds = _map(
            _filter($stateParams.mappedColumns, 'question_id'), 'question_id');

        _forEach(attributes, (attribute) => {
            attribute.isMapped = _includes(questionIds, attribute.question_id) || attribute.name === 'thirdparty_id';  // <-- Nope, this is not the correct way!
        });

        return attributes;
    }

    function _replaceAttribute(attribute, targetAttribute) {
        const isStandardAttribute = _includes(vm.mappedColumns, attribute);

        const values = _isNil(attribute) ? {
            label: null,
            description: null,
            question_id: null,
            answertype: null,
            format: null,
            user_import_id: null,
        } : {
            label: attribute.name,
            description: attribute.description,
            question_id: attribute.question_id,
            answertype: _find(vm.attributeTypes, {answertype_id: attribute.answertype_id}),
            format: attribute.format,
            user_import_id: $state.params.userDataImportId,
        };

        if (!isStandardAttribute) {
            _assignIn(targetAttribute, values);
        }
        _updateIsMappedFlagForExistingAttributes(vm.existingAttributes);
    }

    function _expandTypeDefinition(answerType) {
        return _assign(answerType, _answerTypes[answerType.name]);
    }

    function _setAttributeTypes(response) {
        vm.attributeTypes = _map(response.answer_types,
            _expandTypeDefinition);
    }

    function _addAttribute(mode) {
        switch (mode) {
            case 'standard':
                vm.standardAttribute.external_column_id =
                    vm.externalColumn.external_column_id;
                vm.externalColumn.isMapped = true;
                vm.standardAttribute.selection= vm.externalColumn;
                vm.postMappingUpdates();
                break;
            case 'reuse':
                vm.existingAttribute.internal_attribute_id =
                    `tmp-${formService.getUniqueTempId()}`;
                vm.existingAttribute.external_column_id =
                    vm.externalColumn.external_column_id;
                vm.externalColumn.isMapped = true;
                vm.existingAttribute.selection= vm.externalColumn;
                vm.postMappingUpdates();
                $stateParams.mappedColumns.push(vm.existingAttribute);
                break;
            case 'create':
                vm.newAttribute.answertype_id =
                    vm.newAttribute.answertype.answertype_id;
                vm.newAttribute.format = vm.newAttribute.answertype.format;
                vm.newAttribute.internal_attribute_id =
                    `tmp-${formService.getUniqueTempId()}`;

                vm.newAttribute.external_column_id =
                    vm.externalColumn.external_column_id;
                vm.externalColumn.isMapped = true;
                vm.newAttribute.selection= vm.externalColumn;
                vm.postMappingUpdates();

                $stateParams.mappedColumns.push(vm.newAttribute);
                break;
            default:
                $log.error('Unhandled attribute kind:', mode);
        }

        $state.go('^');
    }

    function _groupExistingAttribute(attribute) {
        return attribute.user_import_name;
    }

    function _setExistingAttributes(response) {
        vm.existingAttributes = _updateIsMappedFlagForExistingAttributes(response.attributes);
        // $log.debug('vm.existingAttributes', vm.existingAttributes);
    }

    function _activate() {
        vm.standardAttribute = _createEmptyAttribute();
        vm.newAttribute = _createEmptyAttribute();
        vm.existingAttribute = null;

        vm.truncatingLinesConfig = {
            initTruncated: true,
            maxLines: 3,
        };
        vm.showTab = 'standard';

        if (_isUndefined($stateParams.externalColumns) || _isUndefined($stateParams.mappedColumns)) {
            /* This might happen if user reloads page while _this_ modal
             * dialog is open.
             */
            $state.go('^');
        }

        vm.externalColumns = $stateParams.externalColumns;
        vm.mappedColumns = $stateParams.mappedColumns;

        vm.postMappingUpdates = $stateParams.callback;

        const externalColumnId = Number($stateParams.externalColumnId);
        vm.externalColumn = _find($stateParams.externalColumns, ['external_column_id', externalColumnId]);

        userDataImport.getExistingAttributes({
            panel_id: Number($stateParams.panelId),
            userDataImportId: Number($stateParams.userDataImportId),
        }).$promise
            .then(_setExistingAttributes);

        userDataImport.getAttributeTypes().$promise
            .then(_setAttributeTypes);

        vm.uniqueColumnValues = [];

        vm.groupExistingAttribute = _groupExistingAttribute;
        vm.assignColumnToAttribute = _assignColumnToAttribute;
        vm.replaceAttribute = _replaceAttribute;
        vm.addAttribute = _addAttribute;
    }

    _activate();
}

function hideAlreadyMappedColumnsFilter() {
    return (items) => (_isArray(items) ? _filter(items, {
        question_id: null,
        external_column_id: null
    }) : items);
}

export default angular
    .module('admin.users.ImportUsersAddMappingController', [])
    .controller('ImportUsersAddMappingController',
        ImportUsersAddMappingController)
    .filter('hideAlreadyMappedColumnsFilter', hideAlreadyMappedColumnsFilter);
