'use strict';
import * as angular from 'angular';
import _forEach from 'lodash/forEach';
import _partial from 'lodash/partial';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _pickBy from 'lodash/pickBy';
import _findIndex from 'lodash/findIndex';
/* @ngInject */
function segmentService($rootScope, $resource, $log, api, utility) {
    var _attributeBlackList = [
        '$promise',
        '$resolved'
    ];
    var _attributeWhiteFilter = {
        query_node: [
            'query_node_id',
            'query_id',
            'order',
            'parent_query_id',
            'parent_id',
            'is_negated',
            'comment',
            'extra'
            //'created'  <-- Removed to save some bandwidth.
        ],
        query_constraint_answer: [
            'discriminator',
            'query_constraint_answer_id',
            'value',
            'valuerow_id',
            'valuecolumn_id'
        ],
        query_constraint: [
            'discriminator',
            'query_constraint_id',
            //'children',
            'subquery_id',
            'question_id',
            'operator',
            'value_a',
            'value_b',
            'value_list',
            'cast'
        ],
        query_bracket: [
            'discriminator',
            'query_bracket_id',
            //'children',
            'quantifier',
            'temporal_operator',
            'start_datetime',
            'end_datetime'
        ],
        query: [
            'query_id',
            //'children',
            'creator_id',
            'name',
            'description',
            'comment',
            'extra',
            'api_version',
            'is_published',
            'is_global',
            'discarded'
            //'created'  <-- Removed to save some bandwidth.
        ]
    }, mayHaveChildren = ['query_bracket', 'query_constraint'];
    function _safeCopyAttributes(nodeType, value, key) {
        var filter = _attributeWhiteFilter[nodeType];
        return !_includes(_attributeBlackList, key) &&
            (key[0] === '$' || _includes(filter, key));
    }
    function _trimQueryNode(node) {
        //$log.debug('_trimQuery(): arguments', arguments);
        var copy = _pickBy(node, _partial(_safeCopyAttributes, node.discriminator));
        if (_includes(mayHaveChildren, node.discriminator) &&
            angular.isDefined(node.children)) {
            copy.children = _map(node.children, _trimQueryNode);
        }
        return copy;
    }
    function _trimQuery(query) {
        var copy = _pickBy(query, _partial(_safeCopyAttributes, 'query'));
        if (angular.isDefined(query.children)) {
            copy.children = _map(query.children, _trimQueryNode);
        }
        //$log.debug('copy', copy);
        return copy;
    }
    function _anonymizeQueryNode(node) {
        node[node.discriminator + '_id'] = null;
        if (angular.isDefined(node.children)) {
            _forEach(node.children, _anonymizeQueryNode);
        }
        return node;
    }
    var _segmentsResource = $resource(api.url + '/api/segments/:queryId/:subResource/', { queryId: '@query_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        },
        edits: {
            params: { subResource: 'edits' },
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    }), _segmentPanelsResource = $resource(api.url + '/api/segments/:queryId/panels/:panelId', { queryId: '@query_id', panelId: '@panel_id' }, {
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    function _updateQuery(root) {
        var data = _trimQuery(root), promise;
        if (!angular.isDefined(data.api_version)) {
            data.api_version = 2;
        }
        if (!data.query_id) {
            promise = _segmentsResource.save(data);
        }
        else {
            promise = _segmentsResource.update(data);
        }
        return promise;
    }
    var _updateRequestSignal = 'segmentService:updateRequest', _queryWasUpdatedSignal = 'segmentService:queryWasUpdated';
    function _requestUpdate(node) {
        $rootScope.$emit(_updateRequestSignal);
    }
    function _deleteNode(node, target) {
        var i = _findIndex(node.children, target);
        if (i !== -1) {
            node.children.splice(i, 1);
        }
        else {
            _forEach(node.children, function _traverse(child) {
                _deleteNode(child, target);
            });
        }
    }
    return {
        get: _segmentsResource.get,
        query: _segmentsResource.query,
        requestUpdate: _requestUpdate,
        update: _segmentsResource.update,
        edits: _segmentsResource.edits,
        panels: _segmentPanelsResource,
        updateQuery: _updateQuery,
        updateRequestSignal: _updateRequestSignal,
        queryWasUpdatedSignal: _queryWasUpdatedSignal,
        deleteNode: _deleteNode,
        trimQuery: _trimQuery,
        trimQueryNode: _trimQueryNode,
        anonymizeQueryNode: _anonymizeQueryNode
    };
}
export var name = 'components.query.segmentService';
export var SegmentServiceFactory = angular
    .module(name, [])
    .factory('segmentService', segmentService);
