'use strict';

import angular from 'angular';
import activityStatsHtml from './activity-stats.html'

/* @ngInject */
function activityStats($timeout, $log, config) {
    function _fx(item) {
        return item.x;
    }

    function _fy(item) {
        return item.y;
    }

    function _link(scope) {
        const activity = scope.activity;

        function _reRender() {
            $timeout(() => {
                // This async call is to allow the toggled
                // `stats.conf.includeSupervisors` value to update first
                // (after clicking the label/check-box).
                activity.stats.refresh();
            })

        }

        function _activate() {
            scope.config = config;
            scope.fx = _fx;
            scope.fy = _fy;
            scope.reRender = _reRender;

            scope.stats = activity.stats;
        }

        _activate();
    }

    return {
        restrict: 'AE',
        scope: {
            activity: '=activityStats',
            includeSupervisors: '='
        },
        link: _link,
        templateUrl: activityStatsHtml
    };
}

export default angular
    .module('activities.activityStats.directive', [])
    .directive('activityStats', activityStats);
