'use strict';

import angular from 'angular';
import answerClass from './answer-class.constant.js';
import answerService from './answer-service.factory.js';
import growBox from './grow-box.directive.js';
import QuestionnaireNavigatorController from './questionnaire-navigator.controller.js';
import questionnaireNavigator from './questionnaire-navigator.factory.js';
import questionnaireService from './questionnaire-service.factory.js';
import questionService from './question-service.factory.js';
import QuestionnaireController from './questionnaire.controller.js';
import questionnaire from './questionnaire.directive.js';
import subQuestionAnimation from './sub-question-animation.directive.js';
import activityModalHeaderHtml from '../activity.modal.header.html'
import questionnaireModalHtml from './questionnaire.modal.html'
import questionnaireFooterHtml from './questionnaire.footer.html'

function modalQuestionnaireState(name) {
    return {
        name: name,
        url: '/{activityType:questionnaire}/{activityId:int}/?doLoop',
        views: {
            'modal-header@': {
                templateUrl: activityModalHeaderHtml
            },
            'modal-contents@': {
                templateUrl: questionnaireModalHtml,
                controller: 'QuestionnaireController'
            },
            'modal-footer@': {
                templateUrl: questionnaireFooterHtml,
                controller: 'QuestionnaireNavigatorController'
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                closeTargetState: '^',
                size: 'large'
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true,
        isPopup: true,
        isSurvey: true
    };
}


/* @ngInject */
function config($stateProvider) {
    $stateProvider
        .state(modalQuestionnaireState('home.activities.questionnaire'))
        .state(modalQuestionnaireState('home.panel.questionnaire'))
        .state(modalQuestionnaireState('home.questionnaire'));
}

export default angular
    .module('activities.questionnaire', [
        answerClass.name,
        answerService.name,
        growBox.name,
        QuestionnaireNavigatorController.name,
        questionnaireNavigator.name,
        questionnaireService.name,
        questionService.name,
        QuestionnaireController.name,
        questionnaire.name,
        subQuestionAnimation.name
    ])
    .config(config);
