'use strict';

import angular from 'angular';
import _isString from 'lodash/isString'
import _every from 'lodash/every'
import _forEach from 'lodash/forEach'
import _partial from 'lodash/partial'
import _map from 'lodash/map'
import _at from 'lodash/at'
import _assignIn from 'lodash/assignIn'
import _remove from 'lodash/remove'
import _assign from 'lodash/assign'


/* @ngInject */
function questionnaireService($q, $stateParams, $log, activityService,
                              questionService, utility) {
    const _frontEndVersion = '2.0.0',
        _entryUrlPattern =
            '/{activityType:string}/{activityId:int}' +
            '/session/{sessionId:int}/{what:string}/';

    function _getSubmissionMetaData(activity) {
        const startDatetime = _isString(activity.start_datetime) ?
            utility.dateTime.fromIso(activity.start_datetime) :
            activity.start_datetime;

        return {
            user_id: activity.meta.user.user_id,
            activity_id: activity.activity_id,
            questionnaire_session_id: activity.questionnaire_session_id,
            entry_id: activity.entry_id,
            upload_session_token: activity.upload_session_token,
            came_from: activity.meta.came_from,
            hide_answered_questions: activity.hide_answered_questions,
            start_datetime: utility.dateTime.toIso(startDatetime),
            do_redirect: false
        };
    }

    function _updatePageStatus(page) {
        page.allQuestionsAreAnswered =
            _every(page.questions, 'is_already_answered');

        page.$$$_questionnaire.navigator.updateButtons('questionnaireService._updatePageStatus');

        page.$$$_questionnaire.updateStatus();
    }

    function _preparePage(page) {
        //page.questions = [];

        _forEach(page.questions, function _referenceParent(question) {
            question.$$$_page = page;
            question.i18nDomain = page.i18nDomain;
        });

        page.updateStatus = _partial(_updatePageStatus, page);

        _forEach(page.questions, questionService.prepareQuestion);

        return page;
    }

    function _updateQuestionnaireStatus(/*questionnaire*/) {
        // NOP
    }

    //function _determineStartPage(questionnaire) {
    //    return [head(questionnaire.pages).page_id];
    //}

    function _getPageData(page) {
        return {
            page_id: page.page_id,
            questions: _map(page.questions,
                questionService.getAnswerData)
        };
    }

    function _getAnswerData(questionnaire) {
        const visitedPages = _at(
            questionnaire.pages, questionnaire.navigator.pageIndexHistory);

        return {
            pages: _map(visitedPages, _getPageData)
        };
    }

    function _prepareSubmissionData(questionnaire) {
        const submissionData = _getSubmissionMetaData(questionnaire),
            answerData = _getAnswerData(questionnaire);

        $log.debug('submit: questionnaire', questionnaire);

        _assignIn(submissionData, answerData);

        $log.debug('submissionData', submissionData);
        return submissionData;
    }

    function _submit(questionnaire) {
        const deferred = $q.defer(),
            submissionData = _prepareSubmissionData(questionnaire);

        questionnaire.actions.save_session(submissionData)
            .then(deferred.resolve, deferred.reject);

        return deferred.promise;
    }

    function _checkBranchPoints(questionnaire) {
        const deferred = $q.defer(),
            action = 'check_branch_points',
            compiledAction = questionnaire.compiledActions[action],
            method = compiledAction.method,
            pageID = questionnaire.navigator.getCurrentPage().page_id,
            submissionData = _prepareSubmissionData(questionnaire),
            handler = _partial(
                utility.httpMethodHandlers[method],
                compiledAction.url(
                    _assignIn({pageID: pageID}, questionnaire.params)));

        handler(submissionData)
            .then(function _handleResult(result) {
                if (result.data.status === 'success') {
                    deferred.resolve(result.data);
                } else {
                    const reason = 'checkBranchPoints received error ' +
                        'notification from server:';
                    $log.error(reason, result.data);
                    deferred.reject(reason, result.data);
                }
            });

        return deferred.promise;
    }

    function _removeDisabledQuestions(questionnaire) {
        _forEach(questionnaire.pages, function _removeQuestionsFromPage(page) {
            page.disabledQuestions = _remove(page.questions, 'is_hidden');
        });
    }

    function _prepareQuestionnaire(config, questionnaire) {
        //$log.debug('config', config);
        //$log.debug('questionnaire', questionnaire);
        _removeDisabledQuestions(questionnaire);

        questionnaire.frontEndVersion = _frontEndVersion;
        questionnaire.config = config;
        questionnaire.questionIndex = {};

        _forEach(questionnaire.pages, function _referenceParent(page) {
            /*
             The '$$$' prefix is used since AngularJS has reserved '$'
             and '$$', but attribute names starting with '$$'
             will be left out during angular.toJson, thereby
             avoiding self-reference loops.
             */
            page.$$$_questionnaire = questionnaire;
            page.i18nDomain = questionnaire.i18nDomain;
        });

        questionnaire.updateStatus =
            _partial(_updateQuestionnaireStatus, questionnaire);

        _forEach(questionnaire.pages, _preparePage);

        questionnaire.submit = _partial(_submit, questionnaire);
        questionnaire.checkBranchPoints = _partial(_checkBranchPoints,
            questionnaire);

        return questionnaire;
    }


    function _getActivity(params, config) {
        const deferred = $q.defer(),
            prepareQuestionnaire = _partial(_prepareQuestionnaire, config);

        activityService.getActivity(params, config)
            .then(prepareQuestionnaire, deferred.reject)
            .then(function _resolve(questionnaire) {
                deferred.resolve({
                    activity: questionnaire
                });
            });

        return deferred.promise;
    }

    function _parseNewEntryURL(url) {
        return utility.matchUrl(_entryUrlPattern, url);
    }

    function _getNewEntry(config) {
        const deferred = $q.defer();

        activityService.getActivity($stateParams)
            .then(function _foo(activity) {
                const params = {
                    activityType: activity.discriminator,
                    activityId: activity.activity_id,
                    questionnaireSessionId: activity.questionnaire_session_id,
                    what: 'new'
                };

                _getActivity(params, config).then(deferred.resolve);
            });

        return deferred.promise;
    }

    function _getCurrentActivity(config) {
        const deferred = $q.defer();

        if (!$stateParams.doLoop) {
            _getActivity($stateParams, config)
                .then(deferred.resolve);
        } else {
            _getNewEntry(_assign(config, {fromCache: false}))
                .then(function _tagDemo(result) {
                    result.activity.doLoop = true;
                    deferred.resolve(result);
                });
        }

        return deferred.promise;
    }

    return {
        getActivity: _getActivity,
        getCurrentActivity: _getCurrentActivity,
        parseNewEntryURL: _parseNewEntryURL,
        getNewEntry: _getNewEntry
    };
}

export default angular
    .module('activities.questionnaire.questionnaireService', [])
    .factory('questionnaireService', questionnaireService);
