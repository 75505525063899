'use strict';

import angular from 'angular'
import editAttachmentState from '../forum/edit-attachment-state.constant.js'
import editSegmentState from '../forum/edit-segment-state.constant.js'
import mdHelpState from '../forum/md-help-state.constant'
import viewAttachmentState from '../forum/view-attachment-state.constant.js'
import realTimeMenuDrawerHeaderHtml from '../real-time-menu-drawer-header.html'
import realTimeMenuDrawerHtml from '../real-time-menu-drawer.html'
import ChatController from './chat.controller.js'
import chatHtml from './chat.html'
import stickyCarousel from './sticky-carousel.directive.js'

/* @ngInject */
function config($stateProvider, editSegmentState, viewAttachmentState,
                editAttachmentState, mdHelpState) {
    $stateProvider
        .state({
            name: 'home.chat',
            url: '^/{activityType:chat}/{activityId:int}/',
            views: {
                'main-contents@': {
                    controller: 'ChatController',
                    templateUrl: chatHtml
                },
                'right-drawer-header@': {
                    controller: 'RealTimeMenuDrawerController',
                    templateUrl: realTimeMenuDrawerHeaderHtml
                },
                'right-drawer-contents@': {
                    controller: 'RealTimeMenuDrawerController',
                    templateUrl: realTimeMenuDrawerHtml
                }
            },
            data: {
                segmentsName: 'chat.{chatId}.segments',
                selectionsName: 'chat.{chatId}.users',
                selectionsNameModifier: function _injectChatId(state, name) {
                    /**
                     * The `state.locals.globals.params` is a
                     * workaround for changed `$state` structure.
                     * See `realTimeConnectedUsers` directive and the
                     * faked `$state.$current.locals`.
                     */
                    if (name.includes('{chatId}')) {
                        const activityId = angular.isDefined(state.locals) ?
                            state.locals.globals.params.activityId : 'unknown';

                        name = name.replace('{chatId}', activityId);

                    }
                    return name;
                },
                selectionsIdName: 'user_id'
            },
            secure: true
        })
        .state(editSegmentState('home.chat.segment'))
        .state(viewAttachmentState('home.chat.view-attachment'))
        .state(editAttachmentState('home.chat.edit-attachment'))
        .state(mdHelpState('home.chat.md-help'));
}

export default angular
    .module('activities.chat', [
        ChatController.name,
        stickyCarousel.name,
        editSegmentState.name,
        viewAttachmentState.name,
        editAttachmentState.name,
        mdHelpState.name,
    ])
    .config(config);
