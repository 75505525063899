'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _reduce from 'lodash/reduce'


/* @ngInject */
function ReUploadSpreadsheetController($rootScope, $state, $stateParams, $log,
                                       userDataImport, gettext, formService) {
    const vm = this,
        _fieldDefinitions = {
            spreadsheet: {
                spreadsheet_id: {mode: 'r'},
                created: {mode: 'r'},
                performed: {mode: 'r'},
                name: {mode: 'wc'},
                comment: {mode: 'wc'},
                file: {mode: 'rwc'},
                panel_id: {mode: 'rwc'},
                panel_name: {mode: 'r'},
                all_users_query_id: {mode: 'r'},
                recruitment_id: {mode: 'rwc'},
                row_count: {mode: 'r'},
                uploader_name: {mode: 'r'}
            },
            external_columns: {mode: 'r', isList: true},
            mapped_columns: {mode: 'rw', isList: true}
        },
        _fieldKeys = _reduce(_fieldDefinitions,
            formService.generateFieldKeys, {}),
        _master = _reduce(_fieldDefinitions,
            formService.generateEmtpyMasterData, {}),
        _subFormNames = _keys(_fieldDefinitions);

    function _initMasterData(response) {
        formService.populateFormMasterData(_master, response, _subFormNames,
            _fieldKeys);
    }

    function _showUploadResults(response) {
        vm.stats = response.stats;
    }

    function _resetForm(form, name) {
        formService.coreResetForm(form, name, vm, _master);
    }

    function _resetSubForms() {
        _forEach(_subFormNames, function _resetSubForm(name) {
            _resetForm(undefined, name);
        });
    }

    function _postSubmitHandling(promise, form) {
        formService.clearFormError(vm.saveError, form);

        return promise
            .then(_showUploadResults,
                _partial(formService.handleSaveFormError, vm.saveError, form))
            .then(_resetSubForms)
            .then(() => {
                $rootScope.$emit('spreadsheet-upload-done');
            });
    }

    function _reUploadUserData(form) {
        const names = ['spreadsheet'],
            subData = _map(names, function _getData(name) {
                return formService.makeSafeCopy(vm[name],
                    _fieldDefinitions[name]);
            }),
            data = _merge(...[{}].concat(subData));

        if (angular.isUndefined(data.user_import_id)) {
            data.user_import_id = $state.params.userDataImportId;
        }

        const promise = userDataImport.updateSpreadsheet(data).$promise;

        return _postSubmitHandling(promise, form);
    }

    function _activate() {
        userDataImport.get({
            user_data_import_id: $state.params.userDataImportId
        }).$promise
            .then(_initMasterData)
            .then(_resetSubForms);

        vm.stats = null;
        vm.saveError = {};

        vm.reUploadUserData = _reUploadUserData;
    }

    _activate();
}

export default angular
    .module('admin.users.ReUploadSpreadsheetController', [])
    .controller('ReUploadSpreadsheetController',
        ReUploadSpreadsheetController);
