'use strict';

import angular from 'angular';

import MarkdownIt from 'markdown-it'
import mila from 'markdown-it-link-attributes'

const md = new MarkdownIt({
    html: true,
    breaks: true,
    typographer: true
}).use(mila, {
    attrs: {
        target: '_blank',
        rel: 'noopener noreferrer'
    }
});

export default angular.module('lib.markdownIt', []).constant('markdownIt', md);
