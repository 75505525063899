'use strict';

import angular from 'angular';
import clientSideLicenses from '../third-party-packages.json';

/* @ngInject */
function LicensesController($log, api) {
    const vm = this;

    function _activate() {
        api.get('/_licenses.json').then((res) => {
            res.data.clientSide = clientSideLicenses.dependencies;
            vm.licensesData = res.data;
        });
    }

    _activate();
}

export default angular
    .module('about.licensesController', [])
    .controller('LicensesController', LicensesController);
