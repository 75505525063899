'use strict';

import angular from 'angular';
import {saveAs} from 'file-saver';
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _partial from 'lodash/partial'
import _transform from 'lodash/transform'


/* @ngInject */
function ExportUserDataController($window, $rootScope, $scope, $state, $q, $log,
                                  activeFiltersService, usersSurveyService,
                                  user, utility, gettext) {
    const vm = this,
        selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName);
    let _userSampleQueryParams = null;

    function _getQueryParams(includeSelectedSurveys) {
        includeSelectedSurveys = !!includeSelectedSurveys;

        const filters =
                activeFiltersService.getQueryConstraints(selectionName,
                    'user_id', true),
            params = _merge(user.queryParamsFromFilters(filters), {
                included_entries: vm.includedEntries,
                column_model: vm.columnModel,
                user_sample: _userSampleQueryParams
            });
        if (includeSelectedSurveys) {
            params.survey_ids = _getSelectedSurveyIdsArray(vm.selectedSurveys);
        }
        return params;
    }

    function _successfullyDeleted() {
        const filter = activeFiltersService
            .getInheritedSelectionsFilter($state.$current);
        activeFiltersService.removeFilter(filter);
        $state.go('admin.users.list', {}, {reload: true});
    }

    function _handleDeleteError(response) {
        vm.deleteError = _isString(response.data) ? {
            message: response.statusText
        } : {
            message: response.data.message
        };
    }

    function _deleteSelectedAccounts() {
        user.deleteMultiple(_getQueryParams()).$promise
            .then(_successfullyDeleted, _handleDeleteError);
    }

    function _updateSurveySelections(surveys) {
        surveys = angular.isDefined(surveys) ? surveys : [];

        if (angular.isUndefined(vm.selectedSurveys)) {
            vm.selectedSurveys = {};
        }

        const surveyIds = _map(surveys, 'questionnaire_id');

        _forEach(vm.selectedSurveys, function _deselectNonExistent(value, key) {
            const surveyId = parseInt(key, 10);

            if (!_includes(surveyIds, surveyId)) {
                delete vm.selectedSurveys[surveyId];
            }
        });
    }

    function _updateSurveys(response) {
        vm.surveys = response.surveys;
        vm.userSampleSize = response.user_sample_size;
        vm.saveError.surveys = null;

        _updateSurveySelections(response.surveys);
    }

    function _refreshSurveyList() {
        usersSurveyService.query(_getQueryParams()).$promise
            .then(_updateSurveys);
    }

    const _storage = $window.sessionStorage,
        _prefix = 'exportUserData';

    function _persistCollection(collection, data) {
        _storage.setItem(_prefix + ':' + collection, angular.toJson(data));
    }

    function _loadCollection(collection) {
        const item = _storage.getItem(_prefix + ':' + collection);

        return item !== null ? angular.fromJson(item) : item;
    }

    function _broadcastUpdate(collection) {
        $rootScope.$emit(_getUpdatedSignal(collection));
    }

    function _updateOnStorageChange(event) {
        const item = event.originalEvent.newValue;
        const keyParts = event.originalEvent.key.split(':');

        if (keyParts.length < 2 || keyParts[0] !== _prefix) {
            return;
        }
        const collection = keyParts[1];
        _userSampleQueryParams = angular.fromJson(item);
        _broadcastUpdate(collection);
    }

    function _getUpdatedSignal(collection) {
        return _prefix + ':updated:' + collection;
    }

    function _getUserSampleQueryParams() {
        let params;

        if ($state.params.userSampleQueryParams === null) {
            params = _loadCollection('userSampleQueryParams');
        } else {
            params = $state.params.userSampleQueryParams;
            _persistCollection('userSampleQueryParams', params);
        }
        return params;
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.fields) {
                _forEach(response.data.fields, function _setErrors(field) {
                    form[field.form_field].$setValidity(field.error, false);
                });
            } else {
                let message = response.data.message;

                if (response.data.errors) {
                    message += _filter(response.data.errors).join('  ');
                }
                status = {
                    message: message
                };
            }
        }
        vm.saveError[form.$name] = status;

        return $q.reject();
    }

    function _saveDownload(response) {
        saveAs(response.blob, response.fileName || 'document.csv');

        vm.saveError.surveys = null;
    }

    function _postSubmitHandling(promise, form) {
        return promise
            .then(_saveDownload, _partial(_handleSaveFormError, form));
    }

    function _getSelectedSurveyIdsArray(selections) {
        return _transform(selections, function _getTrueKeys(result, value, key) {
            if (value) {
                result.push(parseInt(key, 10));
            }
        }, []);
    }

    function _saveForm(form) {
        const data = _getQueryParams(true);
        const promise = usersSurveyService.export(data).$promise;

        return _postSubmitHandling(promise, form);
    }

    function _isSubmittable(form) {
        return (!form.$pristine) &&
            form.$valid && _getSelectedSurveyIdsArray(vm.selectedSurveys).length;
    }

    function _activate() {
        const _window = angular.element($window);

        _userSampleQueryParams = _getUserSampleQueryParams();

        vm.userSampleQueryParamsError = _userSampleQueryParams === null;

        vm.info = {
            columnModel: gettext(
                'The expansive model will, for example, output one column ' +
                'for each alternative for a single-select question, ' +
                'while the compact model will instead try to compress ' +
                'the output by inlining the selected answer values in ' +
                'a single column.'
            ),
            filtering: gettext(
                'You can use the filtering mechanism above to control which ' +
                'surveys are shown here.  ' +
                'Altering the filters will not affect the user sample, ' +
                'which is locked.  ' +
                'To change the user sample, go back the users list and make ' +
                'a new selection.  ' +
                'The surveys below are restricted to those that the users ' +
                'in the user sample have contributed to.'),
            includedAnswers: gettext(
                'Some questionnaires, like those used in diaries, may ' +
                'be answered multiple times; to see all entries per user in ' +
                'such cases, choose “All Answers” here.  ' +
                'If you combine the “General Profile Questions” with ' +
                'other surveys and choose “All Answers”, then only ' +
                'the latest entry per user for “General Profile Questions” ' +
                'will be included.'
            )
        };

        vm.saveError = {
            surveys: null
        };
        vm.includedEntries = 'latest';
        vm.columnModel = 'expansive';
        vm.selectedSurveys = {};
        vm.isSubmittable = _isSubmittable;

        vm.deleteSelectedAccounts = _deleteSelectedAccounts;
        vm.saveForm = _saveForm;

        const listenerDestroyers = [
            $rootScope.$on(activeFiltersService.updatedSignal,
                _refreshSurveyList),
            $scope.$watch('vm.includedEntries', _refreshSurveyList)
        ];

        _window.off('storage', _updateOnStorageChange);

        $scope.$on('$destroy', function _destroyListeners() {
            _window.off('storage', _updateOnStorageChange);
            _forEach(listenerDestroyers, utility.call);
        });

        _refreshSurveyList();
    }

    _activate();
}

export default angular
    .module('admin.users.ExportUserDataController', [])
    .controller('ExportUserDataController', ExportUserDataController);
