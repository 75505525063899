'use strict';

import angular from 'angular';

/* @ngInject */
function ArticleController($scope, $location, $sce, api) {
    api.get($location.path()).then(function (res) {
        $scope.article = res.data.activity;

        $scope.trustedHtml = $sce.trustAsHtml($scope.article.rendered_content);

        $scope.$emit('article:done');
    });
}

export default angular
    .module('activities.article.articleController', [])
    .controller('ArticleController', ArticleController);
