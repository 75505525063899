'use strict';

import angular from 'angular';
import acceptEulaHeaderHtml from './accept-eula.header.html'
import acceptEulaHtml from '../../components/eula/eula.html'
import acceptEulaFooterHtml from './accept-eula.footer.html'

function acceptEulaState(name) {
    return {
        name: name,
        url: '/accept-eula/',
        params: {profile: undefined},
        views: {
            'modal-header@': {
                controller: 'AcceptEulaNavigatorController',
                controllerAs: 'vm',
                templateUrl: acceptEulaHeaderHtml
            },
            'modal-contents@': {
                controller: 'AcceptEulaController',
                controllerAs: 'vm',
                templateUrl: acceptEulaHtml
            },
            'modal-footer@': {
                controller: 'AcceptEulaNavigatorController',
                controllerAs: 'vm',
                templateUrl: acceptEulaFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService, $state$, legalModalService) {
            coreLayoutService.openModal({
footer: {
                    visible: coreLayoutService.defaultExcept({all: true}),
                    hidden: coreLayoutService.defaultExcept()
                },
                size: 'large',
            });
            legalModalService.onEnter($state$);
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
    };
}

export default angular
    .module('home.acceptEula.state', [])
    .constant('acceptEulaState', acceptEulaState);
