'use strict';

import angular from 'angular';
import {marketServiceFactory} from './market-service.factory';
import marketsListHtml from './markets-list.html'

/* @ngInject */
function marketsList($state, activeFiltersService, gridUtils, gettext,
                     gettextCatalog, utility, market, $log) {
    var columnDefs = [
        // {
        //     field: 'market_id',
        //     headerName: '<input type="checkbox" ' +
        //     'class="ag-selection-checkbox" ' +
        //     'indeterminate-checkbox="selections.isAllIndicator"' +
        //     'on-change="selections.allChanged">',
        //     headerClass: 'check-all',
        //     suppressResize: true,
        //     width: 40,
        //     checkboxSelection: true,
        //     valueGetter: gridUtils.nullValue,
        //     suppressMenu: true,
        //     suppressSorting: true,
        //     headerTooltip: 'Toggle all',
        //     compileWithAngular: true
        // },
        {
            headerName: gettext('Market ID'), field: 'market_id',
            filter: 'number',
            width: 95,
            // rowGroupIndex: 0,
            // keyCreator: _groupingKeyGenerator,
            hide: true
        },
        {
            headerName: gettext('Name'), field: 'name',
            filter: 'text',
            rowGroupIndex: 0,
            hide: true,
            // keyCreator: _groupingKeyGenerator,
            width: 164
        },
        // {
        //     headerName: gettext('Type'), field: 'humane_type',
        //     filter: 'text',
        //     cellRenderer: _typeColumnRenderer
        // },
        // {
        //     headerName: gettext('Effective State'), field: 'state',
        //     filter: 'text',
        //     width: 100
        //     // hide: true
        // },
        {
            headerName: gettext('Description'), field: 'description',
            filter: 'text',
            hide: true,
            width: 130
        },
        {
            headerName: gettext('Client ID'), field: 'client_id',
            filter: 'number',
            // width: 95,
            hide: true
            // rowGroupIndex: 1
        },
        {
            headerName: gettext('Client'), field: 'client_name',
            filter: 'text',
            width: 164,
            rowGroupIndex: 1
        },
        {
            headerName: gettext('Panel ID'), field: 'panel_id',
            filter: 'number',
            hide: true
            // width: 95
        },
        {
            headerName: gettext('Panel'), field: 'panel_name',
            filter: 'text',
            width: 188
        },
        {
            headerName: gettext('Panel (Internal Name)'),
            field: 'panel_internal_name',
            filter: 'text',
            width: 194
        },
        {
            headerName: gettext('Created Date'), field: 'created',
            filter: 'text',
            width: 100,
            hide: true
        },
        {
            headerName: gettext('Actions'), field: 'market_id',
            cellRenderer: _actionColumnRenderer,
            width: 125,
            suppressMenu: true,
            suppressSorting: true
        }
    ];

    /*
        function _groupingKeyGenerator(params) {
            $log.debug('params', params);
            $log.debug('params.value', params.value);
            return params.value.market_id;
        }
    */

    function _actionColumnRenderer(params) {
        var result;

        switch (params.node.level) {
            case 0:
                var marketId = params.node.allLeafChildren[0].data.market_id;
                result = `<a ui-sref="admin.markets.edit({marketId: ${marketId}})" ng-bind="::'Edit Market' |translate"></a>`;
                break;
            case 1:
                if (angular.isDefined(params.node.allLeafChildren)) {
                    var clientId = params.node.allLeafChildren[0].data.client_id;
                    result = `<a ui-sref="admin.clients.edit({clientId: ${clientId}})" ng-bind="::'Edit Client' |translate"></a>`;
                } else {
                    result = '';
                }
                break;
            case 2:
                var panelId = params.node.data.panel_id;
                result = `<a ui-sref="admin.panels.edit({marketId: ${panelId}})" ng-bind="::'Edit Panel' |translate"></a>`;
                break;
            default:
                result = null;
        }

        return result;
    }

    function _innerRenderer(params) {
        return params.node.key; // + ', ' + params.node.allLeafChildren[0].data.description;
    }

    function _link(scope) {
        var selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName),
            selectionIdName = utility.getParentData($state.$current,
                activeFiltersService.defaultSelectionsIdName),
            rowIdPath = 'market_id',
            ops = gridUtils.getGridOperations({
                useVirtualPaging: false,
                columnDefs: columnDefs,
                gridOptions: {
                    groupSuppressAutoColumn: false,
                    groupColumnDef: null,
                    groupRowInnerRenderer: _innerRenderer
                },
                muteQueryParams: [
                    'panel_ids',
                    'activity_ids',
                    'query_ids'
                ],
                selectionName: selectionName,
                selectionIdName: selectionIdName,
                rowIdPath: rowIdPath,
                resource: market,
                resourceExtraColumns: [
                    'name',
                    'clients',
                    'panels',
                    'description',
                    'created'
                ],
                resourceResponseCollection: 'markets',
                storageName: 'marketsList',
                scope: scope
            });

        scope.toggleToolPanelVisibility = ops.toggleToolPanelVisibility;
        scope.showSql = false;

        scope.gridOptions = ops.gridOptions;
    }

    return {
        scope: true,
        replace: true,
        link: _link,
        /**
         * The ng-if="gridOptions" in the template element is there to avoid
         * initializing `ag-grid` until the options have been defined.
         */
        templateUrl: marketsListHtml
    };
}

export default angular
    .module('admin.markets.marketsList', [
        'agGrid',
        marketServiceFactory.name
    ])
    .directive('marketsList', marketsList);
