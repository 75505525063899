'use strict';

import angular from 'angular';
import mainLayoutHtml from './main-layout.html'

import profileBoxHtml from '../profile/profile-box.html';

/* @ngInject */
function mainLayout() {
    return {
        templateUrl: mainLayoutHtml,
        replace: true,
        transclude: true
    };
}

export default angular
    .module('components.mainLayout', [])
    .directive('mainLayout', mainLayout);
