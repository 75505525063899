'use strict';

import '../scss/web/style.scss';

// Start with some polyfills.  `requestAnimationFrame` is needed by `smoothscroll-polyfill`.
import raf from 'raf';
raf.polyfill();

// For testing purposes only:
//window.__forceSmoothScrollPolyfill__ = true;

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// import '@fortawesome/fontawesome-pro/js/all'
// import '@fortawesome/fontawesome-pro/js/v4-shims'
// import '@fortawesome/fontawesome-pro/js/conflict-detection'


import platformPreInit from './platform-pre-init.js';
import angular from 'angular';
import * as agGrid from 'ag-grid';
import enterprise from 'ag-grid-enterprise';
import 'angular-simple-logger';

import 'angular-google-maps';

import Raven from 'raven-js';
import 'angular-raven';
import angularCookies from 'angular-cookies';
import angularInView from 'angular-inview';
import 'angular-xeditable';
import angularMoment from 'angular-moment';
import {angularSpinner} from 'angular-spinner';
import angulartics from 'angulartics';
import angularticsGoogleAnalytics from 'angulartics-google-analytics';
import 'angular-hotkeys';
import coreLayout from 'core-layout-probe/dist/lib/core-layout';
import 'angular-ellipsis';
//import angularScroll from 'angular-scroll';
import 'angular-easyfb';
import 'gettext';
import angularLoadingBar from 'angular-loading-bar';
import angularAnimate from 'angular-animate';
import angularSanitize from 'angular-sanitize';
import angularTouch from 'angular-touch';
import 'angular-carousel';
import angularResource from 'angular-resource';
import 'ui.keypress';
import uiRouter from '@uirouter/angularjs';
import uiSelect from "ui-select";
import AppController from './app.controller.js';
import about from './about/about.js';
import activities from './activities/activities.js';
import admin from './admin/admin.js';
import completeProfile from './complete-profile/complete-profile.js';
import lib from './lib/lib.js';
import components from './components/components.js';
import home from './home/home.js';
import layout from './layout/layout.js';
import notifications from './notifications/notifications.js';
import panel from './panel/panel.js';
import profile from './profile/profile.js';
import reward from './reward/reward.js';
import trialExpired from './trial-expired/trial-expired.js';
import welcome from './welcome/welcome.js';
import 'i18n'; // Need to come after the above 'gettext' require.
import moment from 'moment';
import 'moment/min/locales.min';

import appConfig from './app.config.js';
import appRun from './app.run.js';

import _split from 'lodash/split'
import _upperFirst from 'lodash/upperFirst'


enterprise.LicenseManager.setLicenseKey(
    _split('35f74bb6a54f77db813812fe2732c59a==AMwADMwADM0YDN3UTM__9102_rebmevoN_52sveD1_mroftalp_)SaaS_CORM(_tnuocnE_ehT_SA_tnuocnE', '')
        .reverse()
        .join('')
);
agGrid.initialiseAgGridWithAngular1(angular);

platformPreInit.all();

window.Raven = Raven;

const requires = [
    'ngRaven',  // angularRaven
    angularCookies,
    //angularIScroll,
    angularInView,
    'xeditable',  // angularXEditable
    angularMoment,
    angularSpinner.name,
    angulartics,
    angularticsGoogleAnalytics,
    'cfp.hotkeys',  // angularHotkeys.name,
    coreLayout,
    'dibari.angular-ellipsis',  // angularEllipsis
    //angularScroll,
    'ezfb',  // angular-easyfb
    'gettext',  // angular-gettext
    angularLoadingBar,
    angularAnimate,
    angularSanitize,
    angularTouch,
    'angular-carousel',  // 'angular-carousel'
    angularResource,
    'ui.keypress',  // 'ui.keypress'
    uiRouter,
    uiSelect,
    //legenddirectives.name,
    // templates.name,
    'ui.router.util',
    'uiGmapgoogle-maps',
    // Local app modules.
    AppController.name,
    about.name,
    activities.name,
    admin.name,
    completeProfile.name,
    lib.name,
    components.name,
    home.name,
    layout.name,
    notifications.name,
    panel.name,
    profile.name,
    reward.name,
    trialExpired.name,
    welcome.name
];

function upperFirstFilter() {
    return _upperFirst;
}

angular.module('encount', requires)
    .constant('moment', moment)
    .filter('upperFirst', upperFirstFilter)
    .config(appConfig)
    .run(appRun);

export default angular.module('encount');
