'use strict';
import * as angular from 'angular';
/* @ngInject */
function userDataImportService($resource, api) {
    function _formDataObject(data) {
        var fd = new FormData();
        angular.forEach(data, function (value, key) {
            fd.append(key, value);
        });
        return fd;
    }
    var _userDataResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/', {
        userDataImportId: '@user_import_id',
    }, {
        save: {
            method: 'POST',
            transformRequest: _formDataObject,
            headers: {
                'Content-Type': undefined,
                enctype: 'multipart/form-data'
            }
        },
        query: {
            method: 'GET',
            isArray: false
        },
        update: {
            method: 'PUT',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var _userDataSpreadsheetResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/spreadsheet/', {
        userDataImportId: '@user_import_id',
    }, {
        save: {
            method: 'POST',
            transformRequest: _formDataObject,
            headers: {
                'Content-Type': undefined,
                enctype: 'multipart/form-data'
            }
        },
    }, {
        stripTrailingSlashes: false
    });
    var _userDataSpreadsheetMatchingRowsResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/spreadsheet/matching_rows/', {
        userDataImportId: '@user_import_id',
    }, {}, {
        stripTrailingSlashes: false
    });
    var _userDataColumnResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/column/:columnId/', {
        userDataImportId: '@user_import_id',
        columnId: '@column_id'
    }, {}, {
        stripTrailingSlashes: false
    });
    var _userDataAttributeTypeResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/attribute_types/', {
        userDataImportId: '@user_import_id'
    }, {}, {
        stripTrailingSlashes: false
    });
    var _userDataPerformResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/perform/', {
        userDataImportId: '@user_import_id',
    }, {
        save: {
            method: 'POST',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var _userDataExistingAttributesResource = $resource(api.url +
        '/api/user_data_imports/:userDataImportId/existing/', {
        panelId: '@panel_id',
        userDataImportId: '@user_import_id',
    }, {
        save: {
            method: 'POST',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });
    var _panelResource = $resource(api.url +
        '/api/panels/:panelId/recruitments/', {
        panelId: '@panelId'
    }, {}, {
        stripTrailingSlashes: false
    });
    return {
        get: _userDataResource.get,
        getAttributeTypes: _userDataAttributeTypeResource.get,
        getExistingAttributes: _userDataExistingAttributesResource.get,
        getPanelRecruitments: _panelResource.get,
        getUniqueColumnValues: _userDataColumnResource.get,
        perform: _userDataPerformResource.save,
        query: _userDataResource.query,
        save: _userDataResource.save,
        updateSpreadsheet: _userDataSpreadsheetResource.save,
        update: _userDataResource.update,
        delete: _userDataResource.delete,
        deleteMatchingRows: _userDataSpreadsheetMatchingRowsResource.delete,
    };
}
export var name = 'admin.panels.userDataImportService';
export var UserDataImportServiceFactory = angular
    .module(name, [])
    .factory('userDataImport', userDataImportService);
