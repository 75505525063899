'use strict';

import angular from 'angular';
import SetPasswordService from './set-password-service.factory.js';

/* @ngInject */
function SetPasswordNavigatorController(setPasswordService) {
    const vm = this;

    function _activate() {
        vm.navigator = setPasswordService;
    }

    _activate();
}

export default angular
    .module('home.setPassword.navigatorController', [
        SetPasswordService.name
    ])
    .controller('SetPasswordNavigatorController', SetPasswordNavigatorController);
