'use strict';

import angular from 'angular';
import 'ng-file-model';
import fileInputButtonDirectiveHtml from './file-input-button.directive.html'

/* @ngInject */
function fileInputButton() {
    function _link(scope, element) {
        var _inputElement =
            angular.element(element.find('.fib-hidden-file-input'));

        function _activateFileUpload() {
            _inputElement.click();
        }

        function _onChange(currentFiles, previousFiles) {
            scope.fibOnChange(currentFiles, previousFiles);
            scope.files = [];  // To avoid overwriting the edited document.
        }

        function _init() {
            var deregisterer = scope.$watchCollection('files', _onChange);
            scope.$on('$destroy', deregisterer);

            scope.activateFileUpload = _activateFileUpload;
        }

        _init();
    }

    return {
        link: _link,
        scope: {
            files: '=fileInputButton',
            fibOnChange: '=',
            fibLabel: '='
        },
        templateUrl: fileInputButtonDirectiveHtml
    };
}

export default angular
    .module('admin.fileInputButton', [
        'ng-file-model'
    ])
    .directive('fileInputButton', fileInputButton);
