'use strict';

import angular from 'angular';

/* @ngInject */
function repeatPassword() {
    function _link(scope, elem, attrs, ctrl) {
        var otherInput =
            elem.inheritedData("$formController")[attrs.repeatPassword];

        ctrl.$parsers.push(function _checkRepeatPassword(value) {
            if (value === otherInput.$viewValue) {
                ctrl.$setValidity("repeat", true);
                return value;
            }
            ctrl.$setValidity("repeat", false);
        });

        otherInput.$parsers.push(function (value) {
            ctrl.$setValidity("repeat", value === ctrl.$viewValue);
            return value;
        });
    }

    return {
        require: "ngModel",
        link: _link
    };
}

export default angular
    .module('profile.repeatPassword', [])
    .directive('repeatPassword', repeatPassword);
