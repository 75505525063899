'use strict';

import angular from 'angular';
import jdenticon from 'jdenticon';
import md5 from 'js-md5';
import profileImageHtml from './profile-image.html'
import _forEach from 'lodash/forEach'
import _startsWith from 'lodash/startsWith'


const _defaultPadding = 0.15;

/* @ngInject */
function profileImage($timeout, config, Modernizr) {
    function _link(scope, element, attrs) {
        let stopWatching;

        function _setImage(imageUrl, oldValue) {
            if (imageUrl === oldValue) {
                return;
            }

            let isDefaultImage = false;

            _forEach(config.profileImage.defaultUrls, function _match(url) {
                if (_startsWith(imageUrl, url)) {
                    isDefaultImage = true;
                }
            });

            if (Modernizr.canvas && isDefaultImage) {
                const canvas = element.find('> canvas'),
                    userId = angular.isDefined(scope.profile.user) ?
                        scope.profile.user.user_id : scope.profile.user_id;

                scope.imageUrl = null;

                canvas.attr({'width': attrs.width, 'height': attrs.height});

                jdenticon.update(canvas[0], md5('' + userId),
                    attrs.padding || _defaultPadding);

                canvas.removeClass('hidden');
            } else {
                scope.imageUrl = imageUrl;
            }

            if (angular.isDefined(stopWatching)) {
                stopWatching();
            }
        }

        if (angular.isDefined(scope.profile) && angular.isDefined(scope.profile.profile_picture)) {
            $timeout(function _dontWait() {
                _setImage(scope.profile.profile_picture);
            }, 0);
        } else {
            stopWatching = scope.$watch('profile.profile_picture', _setImage);
        }
    }

    return {
        scope: {
            profile: '=profileImage',
            classes: '@'
        },
        link: _link,
        templateUrl: profileImageHtml
    };
}

export default angular
    .module('components.profileImage', [])
    .directive('profileImage', profileImage);
