'use strict';

import angular from 'angular';
import _merge from 'lodash/merge'


/* @ngInject */
function ActivitiesAdminController($state, $log, gettext,
                                   activeFiltersService, activityService,
                                   utility) {
    const vm = this,
        selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName);

    function _getActivitySampleQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'activity_id', true);
        return _merge(activityService.queryParamsFromFilters(filters), {
            included_entries: vm.includedEntries
        });
    }

    function _goToExport() {
        if (!(vm.selectionsFilter.collection &&
                vm.selectionsFilter.collection.count)) {
            return;
        }

        const activitySampleQueryParams = _getActivitySampleQueryParams();
        $state.go('admin.activities.export-activity-data', {
            activitySampleQueryParams: activitySampleQueryParams
        });
    }

    function _activate() {
        vm.tabs = [
            {
                title: gettext('List'),
                icon: 'fa-list',
                state: 'admin.activities.list'
            },
            {
                title: gettext('Stats'),
                icon: 'fa-bar-chart',
                state: 'admin.activities.stats',
                hideWhenInactive: true  // FIXME: Hidden until implemented.
            },
            {
                title: gettext('Sample'),
                icon: 'fa-filter',
                state: 'admin.activities.segment',
                hideWhenInactive: true
            },
            {
                title: gettext('Create Activity'),
                icon: 'fa-edit',
                state: 'admin.activities.create',
                hideWhenInactive: true
            },
            {
                title: gettext('Edit Activity'),
                icon: 'fa-edit',
                state: 'admin.activities.edit',
                hideWhenInactive: true
            },
            {
                title: gettext('Export Activity Data'),
                icon: 'fa-table',
                state: 'admin.activities.export-activity-data',
                hideWhenInactive: true
            }
        ];

        vm.tabs.activeTab = 0;

        vm.selectionsFilter =
            activeFiltersService.getInheritedSelectionsFilter($state.$current);

        vm.goToExport = _goToExport;
    }

    _activate();
}

export default angular
    .module('admin.activities.ActivitiesAdminController', [])
    .controller('ActivitiesAdminController', ActivitiesAdminController);
