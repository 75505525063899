'use strict';

import angular from 'angular';
import countryFieldHtml from './country-field.html'

/* @ngInject */
function countryField() {
    return {
        scope: {
            country: '=countryField',
            countryCodes: '='
        },
        templateUrl: countryFieldHtml
    };
}

export default angular
    .module('profile.countryField', [])
    .directive('countryField', countryField);
