'use strict';

import angular from 'angular';

/* @ngInject */
function validDate($log, utility) {
    function _link(scope, element, attrs, ngModel) {
        function _validDate(modelValue, viewValue) {
            if (ngModel.$isEmpty(modelValue)) {
                return true;  // Consider empty models to be valid.
            }

            return utility.dateTime.toIsoDate(
                    utility.dateTime.fromIso(modelValue)) === modelValue;
        }
        ngModel.$validators.validDate = _validDate;
    }

    return {
        require: 'ngModel',
        link: _link
    };
}

export default angular
    .module('components.validDate', [])
    .directive('validDate', validDate);
