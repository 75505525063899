'use strict';

import angular from 'angular';
import indeterminateCheckboxHtml from './indeterminate-checkbox.html'

/* @ngInject */
function indeterminateCheckbox($log) {
    function _link(scope, element) {
        function _toggle() {
            scope.state = element.prop('checked');
        }

        scope.toggle = _toggle;

        scope.$watch('state', function _onChange(newValue, oldValue) {
            //$log.debug('indeterminate-checkbox.directive._onChange: newValue', newValue);
            var state;

            switch (newValue) {
                case true:
                case false:
                    state = {
                        isChecked: newValue,
                        isIndeterminate: false
                    };
                    break;
                default:
                    state = {
                        isChecked: false,
                        isIndeterminate: true
                    };
            }
            scope.isChecked = state.isChecked;
            element.prop('indeterminate', state.isIndeterminate);
            if (angular.isDefined(scope.onChange)) {
                scope.onChange(scope.state, oldValue);
            }
        });
    }

    return {
        scope: {
            state: '=indeterminateCheckbox',
            onChange: '='
        },
        link: _link,
        replace: true,
        templateUrl: indeterminateCheckboxHtml
    };
}

export default angular
    .module('components.indeterminateCheckbox', [])
    .directive('indeterminateCheckbox', indeterminateCheckbox);
