'use strict';

import angular from 'angular';
import AcceptEulaService from './accept-eula-service.factory.js';

/* @ngInject */
function AcceptEulaNavigatorController(acceptEulaService) {
    const vm = this;

    function _activate() {
        vm.navigator = acceptEulaService;
    }

    _activate();
}

export default angular
    .module('home.acceptEula.navigatorController', [
        AcceptEulaService.name
    ])
    .controller('AcceptEulaNavigatorController', AcceptEulaNavigatorController);
