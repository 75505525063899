'use strict';

import angular from 'angular';

/* @ngInject */
function notificationsService($rootScope, $location, user, utility) {
    var _notifications = [
        //{subject: 'First Notification'},
        //{subject: 'Second Notification'},
        //{subject: 'Third Notification'},
        //{subject: 'Fourth Notification'},
        //{subject: 'Fifth Notification'},
        //{subject: 'Sixth Notification'},
        //{subject: 'Seventh Notification'},
        //{subject: 'Eighth Notification'}
    ];

    function _visitNotificationUrl(notification) {
        var visitUrl = notification.visit_url;

        if (visitUrl.indexOf('forum') > -1) {
            // is forum notification
            var arr = visitUrl.split('/');
            arr.pop();
            var url = arr.join('/');

            $location.path(url);
        } else {
            $location.path(visitUrl);
        }
    }

    function _removeAllNotifications() {
        user.removeAlerts();
        utility.emptyArray(_notifications);
    }

    function _refresh() {
        //$log.debug('notifications-service.factory.js:35:notificationsService._refresh._refresh: ');
        _notifications = user.getAlerts();
    }

    function _activate() {
        _refresh();
    }

    _activate();

    $rootScope.$on('alert', _refresh);

    return {
        notifications: _notifications,
        removeAllNotifications: _removeAllNotifications,
        visitNotificationUrl: _visitNotificationUrl
    };
}

export default angular
    .module('notifications.notificationsService', [])
    .factory('notificationsService', notificationsService);
