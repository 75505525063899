'use strict';

import angular from 'angular';


/* @ngInject */
function legalModalService() {
    const _stateStack = [];

    function _pop(index) {
        const state = _stateStack[index];
        if (_stateStack.length > index) {
            _stateStack.splice(index, _stateStack.length - index);
        }
        return state;
    }

    function _push(state) {
        _stateStack.push(state)
    }

    function _onEnter($state$) {
        const name = $state$.name;

        let modalLevel;

        if (_stateStack.length > 0 &&
            _stateStack[_stateStack.length - 1].name === name) {
            modalLevel = _pop(_stateStack.length - 1).modalLevel;
        } else if (_stateStack.length > 1 &&
            _stateStack[_stateStack.length - 2].name === name) {
            modalLevel = _pop(_stateStack.length - 2).modalLevel;
        } else {
            modalLevel = _stateStack.length ? _getModalLevel() + 1 : 1;
        }

        _push({
            modalLevel: modalLevel,
            name: name
        });

        return modalLevel;
    }

    function _onExit(levels) {
        if (!levels) {
            levels = 1;
        }

        _pop(_stateStack.length - levels);
    }

    function _get() {
        return _stateStack[_stateStack.length - 1];
    }

    function _getModalLevel() {
        return _get().modalLevel;
    }

    return {
        getModalLevel: _getModalLevel,
        onEnter: _onEnter,
        onExit: _onExit
    };
}

export default angular
    .module('legalModalService', [])
    .factory('legalModalService', legalModalService);
