'use strict';

import angular from 'angular';
import chartCanvasHtml from './trend-line-canvas.html';
import moment from 'moment';
import _map from 'lodash/map'
import _merge from 'lodash/merge'


/* @ngInject */
function trendLine($timeout, $log, ChartJs, config, utility) {
    const sparklineColor = config.colors['gender-color-female-0.5'];

    function _createLabels(values) {
        return _map(values, () => '');
    }

    function _createDataSet(values) {
        return {
            data: values,
            borderColor: sparklineColor,
            pointRadius: 0,
            fill: true,
            borderWidth: 1
        };
    }

    function _link(scope, element) {
        let ctx, chart;

        function _updateStats(newValue, oldValue) {
            if (newValue !== oldValue) {
                const dataSet = _createDataSet(newValue);
                chart.data.labels = _createLabels(newValue);

                if (chart.data.datasets.length) {
                    utility.updateSharedArray(chart.data.datasets[0].data, dataSet.data);
                } else {
                    chart.data.datasets = [dataSet];
                }

                chart.resize();
                chart.update();
            }
        }

        function _init() {
            ctx = element.find('#chart-' + scope.$id);

            const stats = angular.isDefined(scope.stats) ?
                scope.stats : [{x: 0}, {y: 0}];

            const dataSet = _createDataSet(stats);

            const axesConfig = {
                type: 'linear',
                ticks: {
                    display: false,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false
                }
            };

            chart = new ChartJs.Chart(ctx, {
                type: 'line',
                strokeColor: dataSet.strokeColor,
                data: {
                    labels: _createLabels(stats),
                    datasets: [dataSet]
                },
                options: {
                    maintainAspectRatio: false,
                    layout: {
                        padding: 1
                    },
                    legend: {
                        display: false
                    },
                    elements: {
                        line: {
                            cubicInterpolationMode: 'monotone',
                            tension: 0  // Disables bezier curves.
                        }
                    },
                    scales: {
                        xAxes: [_merge({}, axesConfig, {
                            ticks: {
                                beginAtZero: false
                            },
                        })],
                        yAxes: [_merge({}, axesConfig, {
                            ticks: {
                                beginAtZero: true,
                            },
                        })]
                    },
                    tooltips: {
                        callbacks: {
                            title: function _generateTitle([tooltipItem]) {
                                return moment(tooltipItem.xLabel, 'X').format();
                            }
                        }
                    }
                }
            });
            chart.canvas.parentNode.style.height = scope.height + 'px';
            chart.canvas.parentNode.style.width = scope.width + 'px';
            chart.canvas.parentNode.style.position = 'relative';

            chart.resize();
            chart.update();

            scope.$watch('stats', _updateStats);
        }

        $timeout(_init, 0);
    }

    return {
        scope: {
            stats: '=trendLine',
            width: '=',
            height: '=',
            fx: '=',
            fy: '='
        },
        link: _link,
        templateUrl: chartCanvasHtml
    };
}

export default angular
    .module('activities.activityStats.trendLine', [])
    .directive('trendLine', trendLine);
