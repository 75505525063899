'use strict';
import * as angular from 'angular';
var queryConstraintAnswerHtml = require('./query-constraint-answer.html');
/* @ngInject */
function queryConstraintAnswer($log, segmentService) {
    function _link(scope, instanceElement, instanceAttributes, controller, transclude) {
        //scope.onChange = function _onChange(node:IQueryNode) {
        //    $log.debug('node', node);
        //    segmentService.updateQuery(node.meta.root).$promise
        //        .then(function _emitUpdateAlert(result:any) {
        //            $log.debug('result', result);
        //        });
        //}
        scope.onChange = segmentService.requestUpdate;
    }
    return {
        scope: {
            node: '=queryConstraintAnswer',
            extra: '='
        },
        link: _link,
        templateUrl: queryConstraintAnswerHtml,
        replace: true
    };
}
export var QueryConstraintAnswerDirective = angular
    .module('components.query.queryNode.queryConstraintAnswer', [])
    .directive('queryConstraintAnswer', queryConstraintAnswer);
