'use strict';

import angular from 'angular';
import notificationsService from './notifications-service.factory.js'
import notificationsHeaderHtml from './notifications-header.html'

/* @ngInject */
function notificationsHeader(notificationsService, coreLayoutService) {
    function _link(scope) {
        scope.notifications = notificationsService.notifications;
        scope.removeAllNotifications =
            notificationsService.removeAllNotifications;

        function _hideNotifications() {
            coreLayoutService.toggleDrawer('rightDrawer');
        }

        scope.hideNotifications = _hideNotifications;
    }

    return {
        link: _link,
        templateUrl: notificationsHeaderHtml
    };
}

export default angular
    .module('notifications.notificationsHeader', [
        notificationsService.name
    ])
    .directive('notificationsHeader', notificationsHeader);
