'use strict';
import platform from 'platform'

function _iosVersion() {
    if (platform.os.family === "iOS") {
        const v = (platform.os.version).match(/(\d+).(\d+).?(\d+)?/);
        return [
            parseInt(v[1], 10),
            parseInt(v[2], 10),
            parseInt(v[3] || 0, 10)];
    }
}

export default function _cssPositionFixed() {
    const iosVersion = _iosVersion();

    return typeof(iosVersion) === 'undefined' ||
        !(5 <= iosVersion[0] && iosVersion[0] <= 7);
}
