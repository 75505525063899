'use strict';

import angular from 'angular';
import welcomeButtons from './welcome-buttons.directive.js'
import welcomeLogoHtml from './welcome-logo.html'

/* @ngInject */
function welcomeLogo() {
    return {
        scope: false,
        // replace: true,
        templateUrl: welcomeLogoHtml
    };
}

export default angular
    .module('welcome.welcomeLogo', [
        welcomeButtons.name,
    ])
    .directive('welcomeLogo', welcomeLogo);
