'use strict';

import angular from 'angular';
import _debounce from 'lodash/debounce'
import _forEach from 'lodash/forEach'


/* @ngInject */
function HomeController($cookies, $scope, $state, $log, $q, hotkeys,
                        activityService, panelService, trackingService, api,
                        gettext, utility) {
    const hotKeyDefinitions = [
        {
            combo: 'left',
            description: gettext('Show previous slide.'),
            callback: _previousSlide
        },
        {
            combo: 'right',
            description: gettext('Show next slide.'),
            callback: _nextSlide
        }
    ];

    function _previousSlide() {
        $scope.sliderMeta.index--;
    }

    function _nextSlide() {
        $scope.sliderMeta.index++;
    }

    function _trackConfirmedEmailCookie() {
        const cookieName = 'confirmed_email';

        if ($cookies.get(cookieName)) {
            $log.debug("track by cookie");
            trackingService
                .trackOnboarding(trackingService.actions.confirmEmail);
            $cookies.remove(cookieName);
        }
    }

    function _activate() {
        $scope.apiUrl = api.url;
        $scope.activities = activityService.homeScreenActivities;
        $scope.broadcastScrollEvent = _debounce(utility.broadcastScrollEvent, 250);

        $scope.sliderMeta = {
            index: null
        };

        _forEach(hotKeyDefinitions, (definition) => {
            hotkeys.add(definition);
        });

        _trackConfirmedEmailCookie();
    }

    _activate();
}

export default angular
    .module('home.homeController', [])
    .controller('HomeController', HomeController);
