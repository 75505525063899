'use strict';

import angular from 'angular';

var answerClass = {
    image: 'image',
    value: 'value',
    iconRack: 'icon-rack',
    singleRow: 'single-row',
    multiRow: 'multi-row',
    multiRowSingleColumn: 'multi-row, single-column',
    multiRowMultiColumn: 'multi-row, multi-column'
};

export default angular
    .module('activities.questionnaire.answerClass', [])
    .constant('answerClass', answerClass);
