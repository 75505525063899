'use strict';

import angular from 'angular';
import welcomeModalHeaderHtml from './welcome.modal.header.html'

function welcomeModalState(config) {
    config = config || {};

    return {
        name: config.name,
        abstract: true,
        views: {
            'modal-header@': {
                templateUrl: welcomeModalHeaderHtml,
                controller: 'ModalHeaderController'
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                header: {visible:
                    coreLayoutService.defaultExcept({all: true})},
                footer: {hidden:
                    coreLayoutService.defaultExcept({all: true})},
                closeTargetState: 'welcome'
            });
        },
        onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
            coreLayoutService.closeModal({
                closeTargetState: null
            });
        },
        hideCloseButton: config.hideCloseButton || false,
        secure: false,
        isLogin: true
    };
}

export default angular
    .module('welcome.modalState', [])
    .constant('welcomeModalState', welcomeModalState);
