'use strict';

import angular from 'angular';
import 'jquery.viewport';
import supervisorBoxHtml from './supervisor-box.html'

/* @ngInject */
function supervisorBox(config, activityStatsService) {
    function _link(scope) {
        scope.conf = config;

        function _toggleActivityStats() {
            config.stats.isEnabled = !config.stats.isEnabled;

            if (config.stats.isEnabled) {
                activityStatsService.initializeVisibleActivities();
            }
        }

        scope.toggleActivityStats = _toggleActivityStats;
    }

    return {
        link: _link,
        replace: true,
        templateUrl: supervisorBoxHtml
    };
}

export default angular
    .module('admin.supervisorBox', [])
    .directive('supervisorBox', supervisorBox);
