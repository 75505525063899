'use strict';

import angular from 'angular';
import {UserDataImportServiceFactory} from './user-data-import-service.factory';
import userImportHelp from './user-import-help.directive';
import _reduce from 'lodash/reduce'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _merge from 'lodash/merge'
import _forEach from 'lodash/forEach'
import _keys from 'lodash/keys'
import _every from 'lodash/every'
import _intersection from 'lodash/intersection'
import _partial from 'lodash/partial'
import _difference from 'lodash/difference'
import _isString from 'lodash/isString'
import _pull from 'lodash/pull'


/* @ngInject */
function EditImportUsersController($rootScope, $scope, $state, $log, config, userDataImport,
                                   formService, gettext) {
    const vm = this,
        _fieldDefinitions = {
            spreadsheet: {
                spreadsheet_id: {mode: 'r'},
                original_name: {mode: 'r'},
                created: {mode: 'r'},
                performed: {mode: 'r'},
                name: {mode: 'wc'},
                comment: {mode: 'wc'},
                file: {mode: 'rwc'},
                panel_id: {mode: 'rwc'},
                panel_name: {mode: 'r'},
                all_users_query_id: {mode: 'r'},
                new_users_query_id: {mode: 'r'},
                updated_users_query_id: {mode: 'r'},
                ambiguous_rows: {mode: 'r'},
                duplicate_rows: {mode: 'r'},
                matching_rows: {mode: 'r'},
                recruitment_id: {mode: 'rw'},
                row_count: {mode: 'r'},
                user_import_id: {mode: 'rw'},
                uploader_name: {mode: 'r'},
                update_existing_core_values: {mode: 'rw'},
            },
            external_columns: {
                mode: 'r',
                isList: true
            },
            mapped_columns: {
                mode: 'rw',
                isList: true,
                itemDef: {
                    default_format: {mode: 'r'},
                    description: {mode: 'rwc'},
                    external_column_id: {mode: 'rwc'},
                    format: {mode: 'rwc'},
                    internal_attribute_id: {mode: 'rw'},
                    isDisabled: {mode: 'rwc'},
                    label: {mode: 'rwc'},
                    name: {mode: 'rwc'},
                    order: {mode: 'rwc'},
                    question_id: {mode: 'rwc'},
                    user_import_id: {mode: 'rwc'},
                    value: {mode: 'rwc'},
                    answertype_id: {mode: 'rwc'}
                }
            }
        },
        _fieldKeys = _reduce(_fieldDefinitions,
            formService.generateFieldKeys, {}),
        _master = _reduce(_fieldDefinitions,
            formService.generateEmtpyMasterData, {}),
        _subFormNames = _keys(_fieldDefinitions);

    vm.countryCodes = null;
    vm.countryDialingCodes = null;
    vm.defaultDebounceOptions = config.getDebounceOptions();
    vm.truncatingLinesConfig = {
        initTruncated: true,
        maxLines: 3,
    };
    vm.unmappedColumnsName = gettext('Available Columns');
    vm.assignColumnName = gettext('Assign …');
    vm.showAssignColumnsHelp = false;

    function _initMasterData(response) {
        // eslint-disable-next-line lodash/prefer-lodash-method
        if ($state.includes('admin.*.user_imports.create') &&
            response.user_import_id) {
            $state.go('^.edit', {userDataImportId: response.user_import_id});
        }

        formService.populateFormMasterData(_master, response, _subFormNames,
            _fieldKeys);
    }

    function _resetForm(form, name) {
        formService.coreResetForm(form, name, vm, _master);
    }

    function _linkExternalColumnIdsToObjects(mappedColumns, externalColumns) {
        _forEach(mappedColumns, function _linkIfMissing(mappedColumn) {
            if (mappedColumn.external_column_id &&
                !mappedColumn.external_column) {
                const externalColumn = _find(externalColumns, [
                        'external_column_id', mappedColumn.external_column_id]);
                if (angular.isDefined(externalColumn)) {
                    mappedColumn.selection = externalColumn;
                    mappedColumn.external_column = externalColumn;
                    externalColumn.isMapped = true;
                }
            }
        });
    }

    function checkRequirement(req, mapped) {
        return _intersection(req, mapped).length === req.length;
    }

    function _determineReadyForExecution(mappedColumns) {
        const requiredColumnNames = [
            'thirdparty_id',
            'email_address',
            'phone_number',
            'phone_number_country_code',
            'gender',
            'birthdate',
            'country_id'
        ], mutuallyExclusiveRequirements = [
            [['first_name', 'last_name'], ['display_name']],
            [['compound_address'], ['postal_code', 'city']]
        ], requiredUpdateColumnNames = [
            'thirdparty_id',
            'email_address',
            'phone_number',
            // 'phone_number_country_code',
        ];
        const mappedNames =
            _map(_filter(mappedColumns, 'external_column_id'), 'name');

        function _oneOfTwoIsCovered(pair) {
            return checkRequirement(pair[0], mappedNames) ||
                checkRequirement(pair[1], mappedNames);
        }

        vm.readyForImport =
            checkRequirement(requiredColumnNames, mappedNames) &&
            _every(_map(mutuallyExclusiveRequirements, _oneOfTwoIsCovered));

        vm.allRowsMatchExistingUsers =
            _intersection(requiredUpdateColumnNames,
                _map(_filter(mappedColumns, {analysis: {matches_all: true}}),
                    'name')).length > 0;

        vm.readyForUpdates = vm.allRowsMatchExistingUsers ||
            _intersection(requiredUpdateColumnNames,
                _map(_filter(mappedColumns,
                    (mapping) => mapping.analysis && mapping.analysis.percentage > 0),
                    'name')).length > 0;

        vm.identifiersNotMatching =
            (_intersection(requiredUpdateColumnNames,
                _map(_filter(mappedColumns, {analysis: {matches_all: false}}),
                    'name')).length > 0);


    }

    function _resetSubForms() {
        _forEach(_subFormNames, function _resetSubForm(name) {
            _resetForm(undefined, name);
        });

        _linkExternalColumnIdsToObjects(vm.mapped_columns, vm.external_columns);

        _determineReadyForExecution(vm.mapped_columns);

        if (angular.isUndefined(vm.spreadsheet.update_existing_core_values)) {
            vm.spreadsheet.update_existing_core_values = true;
        }
    }

    function _postSubmitHandling(promise, form) {
        formService.clearFormError(vm.saveError, form);

        return promise
            .then(_initMasterData,
                _partial(formService.handleSaveFormError, vm.saveError, form))
            .then(_resetSubForms)
            .then(_postMappingUpdates);
    }

    function _saveForm(form) {
        const names = [form.$name, 'mapped_columns'],
            subData = _map(names, function _getData(name) {
                const safeCopy = formService.makeSafeCopy(vm[name],
                    _fieldDefinitions[name]);
                let result = {};

                if (_fieldDefinitions[name].isList) {
                    result[name] = safeCopy;
                } else {
                    result = safeCopy;
                }

                return result;
            }),
            data = _merge(...[{}].concat(subData));
        let promise;

        if (data.user_import_id) {
            promise = userDataImport.update(data).$promise;
        }

        return _postSubmitHandling(promise, form);
    }

    function _isAnyMapped(targetColumns, mappedColumns) {
        return _difference(targetColumns,
            _map(_filter(mappedColumns, 'external_column_id'),
                'name')).length < targetColumns.length;
    }

    function _clearExistingMappingIfDefined(attribute) {
        if (angular.isDefined(attribute.external_column)) {
            attribute.external_column.isMapped = false;
        }
        attribute.external_column = undefined;
        attribute.external_column_id = null;

    }

    function _disableMapColumn(targetColumn, mappedColumns) {
        const attribute = _find(mappedColumns, ['name', targetColumn]);

        if (angular.isDefined(attribute)) {
            _clearExistingMappingIfDefined(attribute);
            attribute.isDisabled = true;
        }
    }

    function _enableMapColumn(targetColumn, mappedColumns) {
        const attribute = _find(mappedColumns, ['name', targetColumn]);

        if (angular.isDefined(attribute)) {
            attribute.isDisabled = false;
        }
    }


    function _disableIfMapped(targetColumns, blockerColumns) {
        if (_isAnyMapped(blockerColumns, vm.mapped_columns)) {
            _forEach(targetColumns, (targetColumn) => {
                _disableMapColumn(targetColumn, vm.mapped_columns);
            });
        } else {
            _forEach(targetColumns, (targetColumn) => {
                _enableMapColumn(targetColumn, vm.mapped_columns);
            });
        }
    }

    function _updateMappedOfImportedColumns() {
        vm.mappedColumnsCount = _filter(vm.external_columns, ['isMapped', true]).length;
        vm.mappedOfImportedColumns =
            `${vm.mappedColumnsCount} / ${vm.external_columns.length}`;
    }

    function _disableMutuallyExclusive(targetColumnsA, targetColumnsB) {
        _disableIfMapped(targetColumnsA, targetColumnsB);
        _disableIfMapped(targetColumnsB, targetColumnsA);
    }

    function _postMappingUpdates() {
        _disableMutuallyExclusive(['display_name'], [
            'first_name',
            'last_name'
        ]);
        _disableMutuallyExclusive(['country'], ['country_id']);
        _disableMutuallyExclusive(['compound_address'], [
            'address_line_1',
            'address_line_2',
            'region',
            'postal_code',
            'city'
        ]);

        _updateMappedOfImportedColumns();
    }

    function _updateMapping(attribute, column, action) {
        if (action === 'select') {
            _clearExistingMappingIfDefined(attribute);

            if (angular.isDefined(column) && column !== null) {
                attribute.external_column_id = column.external_column_id;
                column.isMapped = true;
                attribute.external_column = column;
            } else {
                attribute.external_column = undefined;
            }
        } else {
            //attribute.external_column_id = null;
            _clearExistingMappingIfDefined(attribute);
            column.isMapped = false;
        }
        _postMappingUpdates();
    }

    function _removeMapping(attribute) {
        const column = attribute.selection;
        column.isMapped = false;
        // attribute.external_column_id = null;
        _clearExistingMappingIfDefined(attribute);
        attribute.selection = null;

        // eslint-disable-next-line lodash/prefer-immutable-method
        _pull(vm.mapped_columns, attribute);

        _postMappingUpdates();
    }

    function _menuCollapseState(name, isOpen) {
        vm.doShow[name] = isOpen;
    }

    function _clearExecuteError() {
        vm.executeError = null;
    }

    function _performImport() {
        // $log.debug('vm.spreadsheet.update_existing_core_values', vm.spreadsheet.update_existing_core_values);
        const ignoreUnrecognized = !vm.readyForImport;

        if ($state.params.userDataImportId) {
            _clearExecuteError();

            userDataImport.perform({
                user_import_id: $state.params.userDataImportId,
                ignore_unrecognized: ignoreUnrecognized,
                update_existing_core_values:
                vm.spreadsheet.update_existing_core_values,
            }).$promise
                .then(function _goBack() {
                    $state.go('^.create');
                }, function _handleError(response) {
                    vm.executeError = _isString(response.data) ? {
                        message: response.statusText
                    } : {
                        message: response.data.message
                    };
                });
        }
    }

    function _setRecruitments(response) {
        vm.recruitments = response.recruitments;
    }

    function _removeMatchingRows(attribute) {
        userDataImport.deleteMatchingRows({
            userDataImportId: Number($state.params.userDataImportId),
        }).$promise
            .then(() => {
                loadSpreadsheet();
            });


    }

    function loadSpreadsheet() {
        userDataImport.get({
            userDataImportId: Number($state.params.userDataImportId)
        }).$promise
            .then(_initMasterData)
            .then(_resetSubForms)
            .then(_postMappingUpdates);
    }

    function _activate() {
        formService.patchUISelect();

        vm.mappedColumnsCount = 0;
        vm.mappedOfImportedColumns = '?';


        if (angular.isUndefined($state.params.userDataImportId)) {
            _forEach(_subFormNames, function _createEmptyMaster(name) {
                _master[name] = formService.createEmptyObject(_fieldKeys[name]);
            });
        } else {
            loadSpreadsheet();
        }

        const deregister = $rootScope.$on('spreadsheet-upload-done',
            loadSpreadsheet);
        $scope.$on('$destroy', deregister);

        userDataImport.getPanelRecruitments({
            panelId: $state.params.panelId
        }).$promise
            .then(_setRecruitments);

        vm.topLevelViews = [
            {
                name: 'mappings',
                description: gettext('Edit Mappings'),
                icon: 'fa-exchange'
            },
            {
                name: 'help',
                description: gettext('Help'),
                icon: 'fa-question-circle fa-lg'
            }
        ];

        vm.topLevelView = vm.topLevelViews[0].name;

        vm.readyForImport = false;

        vm.saveError = {};

        _clearExecuteError();

        vm.postMappingUpdates = _postMappingUpdates;
        vm.updateMapping = _updateMapping;
        vm.removeMapping = _removeMapping;
        vm.saveForm = _saveForm;
        vm.resetForm = _resetForm;
        vm.performImport = _performImport;

        vm.removeMatchingRows = _removeMatchingRows;

        vm.downloadSpreadsheetURI = `/api/user_data_imports/${Number($state.params.userDataImportId)}/current_csv`;
        vm.downloadDeletedRowsURI = `/api/user_data_imports/${Number($state.params.userDataImportId)}/deleted_rows_csv`;

        vm.menuCollapseState = _menuCollapseState;
    }

    _activate();
}

export default angular
    .module('admin.users.EditImportUsersController', [
        UserDataImportServiceFactory.name,
        userImportHelp.name
    ])
    .controller('EditImportUsersController', EditImportUsersController);
