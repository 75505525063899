'use strict';
import * as angular from 'angular';
var filterSearchItemPreviewHtml = require('./filter-search-item-preview.html');
/* @ngInject */
function filterSearchItemPreview() {
    return {
        templateUrl: filterSearchItemPreviewHtml
    };
}
export var filterSearchItemPreviewDirective = angular
    .module('admin.filterSearchItemPreview', [])
    .directive('filterSearchItemPreview', filterSearchItemPreview);
