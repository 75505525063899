'use strict';

import angular from 'angular';
import panelService from './panel-service.factory.js'
import activitiesHtml from '../activities/activities.html'

/* @ngInject */
function config($stateProvider, activityPreviewState) {
    $stateProvider
        .state({
            name: 'home.panel',
            url: '^/panel/{panelId:int}',
            views: {
                'main-contents@': {
                    controller: 'ActivitiesController',
                    templateUrl: activitiesHtml
                }
            },
            secure: true
        })
        .state(activityPreviewState('home.panel.activity-preview'));
}

export default angular
    .module('panel', [
        panelService.name
    ])
    .config(config);
