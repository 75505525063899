'use strict';

import angular from 'angular';

/* @ngInject */
function passwordService($log, $resource, config, api) {
    let _currentCheck = null;

    const resource = $resource(api.url + '/api/passwords/',
        {}, {
            check: {
                method: 'POST',
                isArray: false,
                cancellable: true
            }
        }, {
            stripTrailingSlashes: false
        });

    function _check(params) {
        if (_currentCheck !== null) {
            _currentCheck.$cancelRequest();
        }
        _currentCheck = resource.check(params);

        return _currentCheck;
    }

    return {
        minLength: config.passwords.minLength,
        check: _check
    };
}

export default angular
    .module('components.passwordService', [])
    .factory('passwordService', passwordService);
