'use strict';

import angular from 'angular';
import activityProgressBarHtml from './activity-progress-bar.html'

/* @ngInject */
function activityProgressBar(activityService) {
    function _link(scope) {
        var barStyle = null,
            savedActivity =
                activityService.getStartedActivity(
                    scope.activity_id);

        if (angular.isDefined(savedActivity)) {
            if (angular.isDefined(savedActivity.navigator)) {
                barStyle = {
                    width: savedActivity.navigator.progress.percentage + '%'
                };
            } else if (savedActivity.currentSliderIndex) {
                // Cleaned up legacy method, for River's
                // replaced implementation.
                var total = savedActivity.questions.length,
                    current = savedActivity.currentSliderIndex,
                    percent = (current * 100) / total;

                barStyle = {
                    width: percent + '%'
                };
            }
        }

        scope.doShow = !!barStyle;
        scope.barStyle = barStyle;
    }

    return {
        restrict: 'A',
        scope: {
            activity_id: '=activityProgressBar'
        },
        templateUrl: activityProgressBarHtml,
        link: _link
    };
}

export default angular
    .module('activities.activityProgressBar', [])
    .directive('activityProgressBar', activityProgressBar);
