'use strict';

import angular from 'angular';
import angularMessages from 'angular-messages'
import _remove from 'lodash/remove'
import _forEach from 'lodash/forEach'
import _assignIn from 'lodash/assignIn'


/* @ngInject */
function UserSessionsController($q, $state, $window, $log, activityService,
                                profileService, countries, api, gettextCatalog,
                                Modernizr, config, profileImageCropperService,
                                trackingService, passwordService, upload,
                                uiGmapGoogleMapApi, meta) {
    const vm = this;

    vm.imageFileInput = null;
    vm.profile = profileService.profile;
    vm.activityCounts = activityService.activityCounts;
    vm.isLookingUp = true;

    vm.apiUrl = api.url;

    function _pickAndUpload(response) {
        return upload.pickAndStore(response.data);
    }

    function _setMediaUploadInfo(mediaUploadInfo) {
        vm.mediaUploadInfo = mediaUploadInfo;
    }

    function _trackEditProfileImage() {
        trackingService.trackProfile(trackingService.actions.edit, 'Profile Image');
    }

    function _pickAndStoreImage() {
        _trackEditProfileImage();
        _pickAndUpload(vm.mediaUploadInfo)
            .then(profileImageCropperService.uploadedProfilePicture)
            .then(profileImageCropperService.cropImage);
    }

    function _deleteUserSession(response) {
        $log.debug('user-sessions.controller._deleteUserSession: response', response);

        _remove(vm.userSessions, {user_session_id: response.user_session_id});
    }

    function _invalidateSession(userSessionId) {
        $log.debug('user-sessions.controller._invalidateSession: userSessoinId', userSessionId);
        profileService.userSessions.delete({userId: meta.user.user_id, user_session_id: userSessionId}).$promise
            .then(_deleteUserSession);

    }

    function _goBack() {
        $window.history.back();
    }

    function _setUserSessions(response) {
        vm.isLookingUp = false;
        _forEach(response.sessions, function _prepareMapData(userSession) {

            if (angular.isDefined(userSession.geo_location)) {
                _assignIn(userSession, {
                    mapCenter: angular.copy(userSession.geo_location),
                    mapZoom: 6,
                    locationFill: {
                        color: 'red',
                        opacity: 0.3
                    },
                    locationStroke: {
                        color: 'white',
                        //opacity: 0.5,
                        weight: 0.5
                    }
                });
                userSession.geo_location.accuracy_radius *= 1000;  // Convert km to m.
            } else {
                _assignIn(userSession, {
                    geo_location: {
                        latitude: 0,
                        longitude: 0
                    },
                    mapCenter: {
                        latitude: 0,
                        longitude: 0
                    },
                    mapZoom: 0
                });
            }
        });

        vm.userSessions = response.sessions;
    }

    function _activate() {
        vm.isLookingUp = true;

        profileService.getMediaUploadInfo({media_type: 'image'})
            .then(_setMediaUploadInfo);

        profileService.userSessions.get({userId: meta.user.user_id}).$promise
            .then(_setUserSessions);

        uiGmapGoogleMapApi.then(function _maps(maps) {
            // $log.debug('Got maps!', maps);
            vm.map = {
                options: {
                    disableDefaultUI: true,
                    gestureHandling: 'cooperative',
                    scrollwheel: false
                },
                center: {
                    latitude: 45,
                    longitude: -73
                },
                zoom: 8
            };
        });
    }

    vm.invalidateSession = _invalidateSession;
    vm.pickAndStoreImage = _pickAndStoreImage;
    vm.cancel = _goBack;

    _activate();
}

export default angular
    .module('profile.userSessionsController', [
        angularMessages
    ])
    .controller('UserSessionsController', UserSessionsController);
