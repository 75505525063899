'use strict';

import angular from 'angular';
import _startsWith from 'lodash/startsWith'
import _isString from 'lodash/isString'


/* @ngInject */
function themeColor($rootScope, branding) {
    function _getTag(element, discriminator) {
        return element.find(discriminator);
    }

    function _setTag(element, attr, value, discriminator, template) {
        let meta = _getTag(element, discriminator);
        const isMissing = !meta.length;

        if (isMissing) {
            meta = angular.element(template);
        }

        meta.attr(attr, value);

        if (isMissing) {
            element.append(meta);
        }
    }

    function _removeTag(element, discriminator) {
        const meta = _getTag(element, discriminator);

        if (meta.length) {
            meta.remove();
        }
    }

    function _link(scope, element) {
        const themeColorDiscriminator = 'meta[name="theme-color"]',
            iconDiscriminator = 'link[rel="icon"]';

        function _updateThemeColor() {
            const themeColor = branding.color[branding.defaultShade];

            if (_isString(themeColor) && _startsWith(themeColor, '#')) {
                _setTag(element, 'content', themeColor,
                    themeColorDiscriminator, '<meta name="theme-color">');
            } else {
                _removeTag(element, themeColorDiscriminator);
            }

            if (branding.logoUrlText) {
                _setTag(element, 'href', branding.logoUrlText,
                    iconDiscriminator, '<link rel="icon" sizes="60x60">');
            } else {
                _removeTag(element, iconDiscriminator);
            }
        }

        const deregister = $rootScope.$on(branding.updateSignal, _updateThemeColor);

        scope.$on('$destroy', deregister);

        _updateThemeColor();
    }

    return {
        restrict: 'A',
        link: _link
    };
}

export default angular
    .module('components.themeColor', [])
    .directive('themeColor', themeColor);
