'use strict';

import angular from 'angular';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _last from 'lodash/last';
import _split from 'lodash/split';

/* @ngInject */
function ShowMatchingRowsController($rootScope, $state, $stateParams, $log,
                                    userDataImport) {
    const vm = this;

    function setMatchingUsers(matchingUsers) {
        vm.matchingUsers = _map(_sortBy(matchingUsers, [3, 4]), (row) => {
            row[2] = _last(_split(row[2], ':', 2));
            return row;
        });
    }

    function _activate() {
        if (angular.isDefined($stateParams.attribute) &&
            angular.isDefined($stateParams.attribute.analysis)) {
            setMatchingUsers($stateParams.attribute.analysis.matching_users);
        } else {
            userDataImport.get({
                userDataImportId: Number($state.params.userDataImportId)
            }).$promise
                .then((response) => {
                    const attribute = _find(response.mapped_columns,
                        {
                            external_column_id:
                                Number($stateParams.externalColumnId)
                        });
                    setMatchingUsers(attribute.analysis.matching_users);
                });
        }
    }

    _activate();
}

export default angular
    .module('admin.users.ShowMatchingRowsController', [])
    .controller('ShowMatchingRowsController',
        ShowMatchingRowsController);
