'use strict';

import angular from 'angular';
import 'blob.js';

/* @ngInject */
function usersSurveyService($resource, api, utility) {
    function _transformResponse(data, headers) {
        var csv = null;
        if (data) {
            csv = new Blob([data], {
                type: contentType
            });
        }

        // Server should've sent content-disposition header.
        return {
            blob: csv,
            fileName: utility.getFileNameFromHeader(headers('content-disposition'))
        };
    }

    var contentType = 'text/csv',
        _usersSurveyResource = $resource(api.url + '/api/users_surveys/:action/', {}, {
            export: {
                params: {action: 'export'},
                method: 'POST',
                headers: {
                    accept: contentType
                },
                responseType: 'arraybuffer',
                transformResponse: _transformResponse
            },
            query: {
                params: {action: 'query'},
                method: 'POST',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    return {
        export: _usersSurveyResource.export,
        query: _usersSurveyResource.query
    };
}

export default angular
    .module('components.usersSurveyService', [])
    .factory('usersSurveyService', usersSurveyService);

