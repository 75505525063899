'use strict';

import angular from 'angular';
import answerType from './answer-type/answer-type.js';
import questionnaireQuestionHtml from './questionnaire-question.html'

/* @ngInject */
function questionnaireQuestion($timeout, config, gettext) {
    function _link(scope) {
        scope.defaultDebounceOptions = config.getDebounceOptions();

        scope.textPlaceHolder = gettext('Type text here...');

        /**
         * Tried to use the injected element here, but sometimes that did
         * not work.  The affected browser was FireFox (at least version
         * 43.0.4).  Ended up with using the 'body' DOM element here.
         **/
        var _resizeElement = angular.element('body');

        function _forcedResizeToFixLayoutProblems() {
            _resizeElement.trigger('resize');
        }

        $timeout(_forcedResizeToFixLayoutProblems, 0);
    }

    return {
        restrict: 'A',
        scope: {
            question: '=',
            navigator: '='
        },
        templateUrl: questionnaireQuestionHtml,
        link: _link
    };
}

export default angular
    .module('activities.questionnaire.questionnaireQuestion', [
        answerType.name
    ])
    .directive('questionnaireQuestion', questionnaireQuestion);
