'use strict';

import angular from 'angular';
import notificationsService from './notifications-service.factory.js'
import notificationsHtml from './notifications.html'

/* @ngInject */
function notifications(notificationsService) {
    function _link(scope) {
        scope.notifications = notificationsService.notifications;
        scope.visitNotificationUrl = notificationsService.visitNotificationUrl;
    }

    return {
        link: _link,
        templateUrl: notificationsHtml
    };
}

export default angular
    .module('notifications.notifications', [
        notificationsService.name
    ])
    .directive('notifications', notifications);
