'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _has from 'lodash/has'
import _merge from 'lodash/merge'


/* @ngInject */
function PanelsAdminController($state, $scope, $transitions, gettext,
                               activeFiltersService, panelService, utility, $log) {
    const vm = this,
        selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName),
        _hideActiveFiltersStates = [
            'admin.panels.edit',
            'admin.panels.user_imports'
        ];

    vm.$state = $state;

    // $log.debug('panels.controller.PanelsAdminController: $state', $state);

    function _getPanelSampleQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'panel_id', true);
        return _merge(panelService.queryParamsFromFilters(filters), {
            included_entries: vm.includedEntries
        });
    }

    function _goToExport() {
        if (!(vm.selectionsFilter.collection &&
                vm.selectionsFilter.collection.count)) {
            return;
        }

        const panelSampleQueryParams = _getPanelSampleQueryParams();
        $state.go('admin.panels.export-panel-data', {
            panelSampleQueryParams: panelSampleQueryParams
        });
    }

    function _activate() {
        vm.tabs = [
            {
                title: gettext('List'),
                icon: 'fa-list',
                state: 'admin.panels.list'
            },
            // {
            //     title: gettext('Stats'),
            //     icon: 'fa-bar-chart',
            //     state: 'admin.panels.stats'
            // },
            // {
            //     title: gettext('Sample'),
            //     icon: 'fa-filter',
            //     state: 'admin.panels.segment',
            //     hideWhenInactive: true
            // },
            {
                title: gettext('Create Panel'),
                icon: 'fa-edit',
                state: 'admin.panels.create',
                hideWhenInactive: true
            },
            {
                title: gettext('Edit Panel'),
                icon: 'fa-edit',
                state: 'admin.panels.edit',
                hideWhenInactive: true
            },
            {
                title: gettext('Export Panel Data'),
                icon: 'fa-table',
                state: 'admin.panels.export-panel-data',
                hideWhenInactive: true
            },
            {
                title: gettext('Import User Data'),
                icon: 'fa-upload',
                state: 'admin.panels.user_imports.create',
                hideWhenInactive: true
            }
        ];

        const deregister = $transitions.onEnter({}, function _onEnter(transition, state) {
            const _state = state.$$state();
            let showActiveFilters = true;

            _forEach(_hideActiveFiltersStates, function _checkState(stateName) {
                if (_has(_state.includes, stateName)) {
                    showActiveFilters = false;
                }
            });

            vm.showActiveFilters = showActiveFilters;

            // $log.debug('panels.controller._onEnter: ', "Entered " + state.name + " module while transitioning to " + transition.to().name);
            // $log.debug('panels.controller._onEnter: transition', transition);
            // $log.debug('panels.controller._onEnter: state', state);
        });

        $scope.$on('$destroy', deregister);

        $log.debug('panels.controller._activate: $state', $state);

        vm.showActiveFilters = true;
        _forEach(_hideActiveFiltersStates, function _checkState(stateName) {
            if ($state.includes(stateName)) {
                vm.showActiveFilters = false;
            }
        });

        vm.tabs.activeTab = 0;

        vm.selectionsFilter =
            activeFiltersService.getInheritedSelectionsFilter($state.$current);

        vm.goToExport = _goToExport;
    }

    _activate();
}

export default angular
    .module('admin.panels.PanelsAdminController', [])
    .controller('PanelsAdminController', PanelsAdminController);
