'use strict';

function gettext(str) {
    /**
     * Does nothing, simply returns the input string.
     *
     * This function serves as a marker for `gulp-angular-gettext` to know that
     * this string should be extracted for translations.
     */
    return str;
}

import angular from 'angular';

var genders = [
    {
        name: gettext('Male'),
        value: 'male'
    },
    {
        name: gettext('Female'),
        value: 'female'
    }
];

export default angular
    .module('components.genders', [])
    .constant('genders', genders);
