'use strict';

import angular from 'angular';
import profileBoxHtml from './profile-box.html';
import './profile-box.scss';

/* @ngInject */
function profileBox(profileService, activityService) {
    function _link(scope) {
        scope.profile = profileService.profile;
        scope.activityCounts = activityService.activityCounts;
    }

    return {
        restrict: 'AE',
        scope: {},
        templateUrl: profileBoxHtml,
        link: _link
    };
}

export default angular
    .module('profile.profileBox', [])
    .directive('profileBox', profileBox);
