'use strict';

import angular from 'angular';

import {attachmentLoadedEvent} from '../../components/real-time-engine/real-time-message/attachment-switch.directive';

/* @ngInject */
function ChatController($stateParams, $scope, $q, $log, api, metaService,
                        realTimeEngine, activityService) {
    /*
        // Catch errors since some browsers throw when using the new `type` option.
        // https://bugs.webkit.org/show_bug.cgi?id=209216
        try {
            const po = new PerformanceObserver((list) => {
                for (const entry of list.getEntries()) {
                    // Log the entry and all associated details.
                    $log.debug('PerformanceObserver:', entry.toJSON());
                }
            });

            // po.observe({type: 'element'});
            // Start listening for `longtask` entries to be dispatched.
            po.observe({type: 'longtask', buffered: true});
        } catch (e) {
            // Do nothing if the browser doesn't support this API.
        }
    */

    const engine = realTimeEngine.create('chat', $scope);

    $scope.engine = engine;

    function _showAttachmentHandler(event, attachment) {
        event.stopPropagation();

        $scope.$broadcast('showModalAttachment', attachment);
    }

    $scope.apiUrl = api.url;

    $scope.$on('showAttachment', _showAttachmentHandler);

    $scope.$on(attachmentLoadedEvent, (event, attachment, isAtBottom) => {
        engine.scrollToLastMessage('smooth', isAtBottom);
    });

    $q.all([metaService.get(), activityService.getCurrentActivity()])
        .then(function _initChat(resources) {
            const _meta = resources[0],
                _activity = resources[1];

            engine.register(realTimeEngine.stateParamsToKey($stateParams));

            engine.init({
                activity: _activity,
                meta: _activity.meta,
                channelTypes: [
                    realTimeEngine.generateChannelSpec('message'),
                    realTimeEngine.generateChannelSpec('server'),
                    realTimeEngine.generateChannelSpec('status'),
                    realTimeEngine.generateChannelSpec('user', `user.${_meta.user.user_id}`)
                ],
                currentUser: _meta.user
            });
            $scope.trackTyping = engine.trackTyping;

            return engine.config.meta.last_messages_url;
        })
        .then(engine.getLastMessages)
        .then(engine.prepareMessages)
        .then(() => {
            engine.status.messagesAreLoaded = true;
        })
        // .then(engine.createNewMessagePlaceholders)
        // .then(engine.broadcastReadyStatus)
        .then(engine.subscribeToRealTime)
        .then(engine.startPublishingConnectedStatus);
}

export default angular
    .module('activities.chat.chatController', [])
    .controller('ChatController', ChatController);
