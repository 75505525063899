'use strict';

import angular from 'angular';
import _isString from 'lodash/isString'
import _map from 'lodash/map'
import _merge from 'lodash/merge'


/* @ngInject */
function DeleteUsersController($state, $log, activeFiltersService,
                               user, utility) {
    const vm = this,
        selectionName = utility.getParentData($state.$current,
            activeFiltersService.defaultSelectionsName);

    function _getQueryParams() {
        const filters =
            activeFiltersService.getQueryConstraints(selectionName,
                'user_id', true);
        return _merge(user.queryParamsFromFilters(filters), {
            extra_columns: [
                'email_address'
            ],
            sql: false
        });
    }

    function _successfullyDeleted() {
        const filter = activeFiltersService
            .getInheritedSelectionsFilter($state.$current);
        activeFiltersService.removeFilter(filter);
        $state.go('admin.users.list', {}, {reload: true});
    }

    function _handleDeleteError(response) {
        vm.deleteError = _isString(response.data) ? {
            message: response.statusText
        } : {
            message: response.data.message
        };
    }

    function _deleteSelectedAccounts() {
        if (vm.users && vm.users.length) {
            const params = {
                user_ids: _map(vm.users, 'user_id')
            };

            user.deleteMultiple(params).$promise
                .then(_successfullyDeleted, _handleDeleteError);
        }
    }

    function _activate() {
        user.query(_getQueryParams()).$promise
            .then(function _updateRows(response) {
                vm.total = response.total;
                vm.users = response.users;
            });

        vm.deleteSelectedAccounts = _deleteSelectedAccounts;
    }

    _activate();
}

export default angular
    .module('admin.users.DeleteUsersController', [])
    .controller('DeleteUsersController', DeleteUsersController);
