'use strict';

import angular from 'angular';

/* @ngInject */
function multiFileread() {
    return {
        scope: {
            multiFileread: "="
        },
        link: function _link(scope, element) {
            function _fileInputChanged(event) {
                scope.$apply(function _updateFileInputValue() {
                    scope.multiFileread = event.target.files;
                });
            }

            element.on('change', _fileInputChanged);
            element.on('$destroy', function _removeListener() {
                element.off('change', _fileInputChanged);
            });
        }
    };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.multiFileread', [])
    .directive('multiFileread', multiFileread);
