'use strict';

import angular from 'angular';

/* @ngInject */
function QuestionnaireController($scope, $rootScope, questionnaireService,
                                 gettext) {
    $rootScope.title = gettext('Survey'); // TODO: Move to state.title.

    function _initQuestionnaireCtrl(questionnaire) {
        $scope.activity = questionnaire.activity;
        $scope.navigator = questionnaire.activity.navigator;
    }

    questionnaireService.getCurrentActivity({
        oneQuestionPerPage: true
    }).then(_initQuestionnaireCtrl);
}

export default angular
    .module('activities.questionnaire.questionnaireController', [])
    .controller('QuestionnaireController', QuestionnaireController);
