'use strict';

import angular from 'angular';
import facebook from './facebook/facebook.js'
import forgottenPassword from './forgotten-password/forgotten-password.js'
import login from './login/login.js'
import onboarding from './onboarding/onboarding.js'
import registration from './registration/registration.js'
import reward from './reward/reward.js'
import WelcomeController from './welcome.controller.js'
import WelcomeFooterController from './welcome.footer.controller.js'
import welcomeLogo from './welcome-logo.directive.js'
import welcomeModalStateConstant from './welcome-modal-state.constant.js'
import welcomePresentation from './welcome-presentation.directive.js'
import headerHtml from '../components/header/header.html'
import welcomeHtml from './welcome.html'
import welcomeFooterHtml from './welcome.footer.html'

/* @ngInject */
function config($stateProvider, welcomeModalState) {
    /*
     Let Angular resolve and inject meta value.
     */
    const metaResolver = /* @ngInject */ function _metaResolver(metaService) {
        return metaService.get();
    };

    /* @ngInject */
    function rejectIfAuthenticated($q, $state, user) {
        const deferred = $q.defer();

        user.isLoggedIn().then(function _isLoggedIn(isLoggedIn) {
            if (isLoggedIn) {
                deferred.reject();
                $state.go('home', null, {reload: true});
            }
            else {
                deferred.resolve();
            }
        });

        return deferred.promise;
    }

    $stateProvider
        .state({
            name: 'welcome',
            url: '/welcome',
            views: {
                'main-header@': {
                    templateUrl: headerHtml
                },
                'main-contents@': {
                    templateUrl: welcomeHtml,
                    controller: 'WelcomeController'
                },
                'main-footer@': {
                    templateUrl: welcomeFooterHtml,
                    controller: 'WelcomeFooterController'
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.updateDrawer('leftDrawer', {enabled: false});
                coreLayoutService.updateDrawer('rightDrawer', {enabled: false});
                coreLayoutService.update({
                    footer: {
                        visible: coreLayoutService.defaultExcept({xs: true}),
                        hidden: coreLayoutService.defaultExcept()
                    }
                });
            },
            onExit: /* @ngInject */ function _closeModal(coreLayoutService) {
                coreLayoutService.update({
                    footer: {visible: coreLayoutService.defaultExcept()}
                });
            },
            secure: false,
            resolve: {
                meta: metaResolver,
                rejectIfAuthenticated: rejectIfAuthenticated
                // Children states also inherit these dependencies.
            }
        })
        .state(welcomeModalState({name: 'welcome.modal'}));
}

export default angular
    .module('welcome', [
        facebook.name,
        forgottenPassword.name,
        login.name,
        onboarding.name,
        registration.name,
        reward.name,
        WelcomeController.name,
        WelcomeFooterController.name,
        welcomeLogo.name,
        welcomeModalStateConstant.name,
        welcomePresentation.name
    ])
    .config(config);
