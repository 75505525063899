'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _findIndex from 'lodash/findIndex'
import _flatMap from 'lodash/flatMap'
import _forEach from 'lodash/forEach'
import _head from 'lodash/head'
import _includes from 'lodash/includes'
import _indexOf from 'lodash/indexOf'
import _last from 'lodash/last'
import _size from 'lodash/size'
import _take from 'lodash/take'
import _min from 'lodash/min'
import _pull from 'lodash/pull'


/* @ngInject */
function questionnaireNavigator($rootScope, $q, $timeout, $log, activityService,
                                gettext, answerService, trackingService) {
    let _questionnaire;

    function _setCurrent(object) {
        object.isCurrent = true;
    }

    function _unsetCurrent(object) {
        object.isCurrent = false;
    }

    function _getCurrentPageIndex() {
        // Get top stack element.
        return _last(_navigator.pageIndexHistory);
    }

    function _getPageByIndex(index) {
        return _questionnaire.pages[index];
    }

    function _getCurrentPage() {
        return _getPageByIndex(_getCurrentPageIndex());
    }

    function _getCurrentQuestionIndexHistory(page) {
        page = page || _getCurrentPage();
        if (angular.isUndefined(_navigator.pageQuestionIndexHistory[page.page_id])) {
            _navigator.pageQuestionIndexHistory[page.page_id] = [];
        }
        return _navigator.pageQuestionIndexHistory[page.page_id];
    }

    function _getCurrentQuestion(page) {
        page = page || _getCurrentPage();
        return page.questions[_last(_getCurrentQuestionIndexHistory(page))];
    }

    function _getOneQuestionPerPageTotalSteps(pageIndex) {
        let pages = _questionnaire.pages;

        if (angular.isDefined(pageIndex)) {
            pages = _take(pages, pageIndex);
        }

        const totalPagesWithDescription = _size(_filter(pages, 'description')),
            totalQuestions = _size(_flatMap(pages, 'questions'));

        return totalPagesWithDescription + totalQuestions;
    }

    function _getOneQuestionPerPageStep() {
        const questionPath = _getCurrentQuestionIndexHistory(),
            wholePageSteps = (_navigator.pageIndexHistory.length > 1) ?
                _getOneQuestionPerPageTotalSteps(_getCurrentPageIndex()) : 0;

        //$log.debug('questionnaire-navigator.factory.js:53:questionnaireNavigator._getOneQuestionPerPageStep._getOneQuestionPerPageStep: ');
        //$log.debug('questionPath', questionPath);

        return wholePageSteps + questionPath.length;
    }

    function _getProgressStep() {
        return _navigator.oneQuestionPerPage ?
            _getOneQuestionPerPageStep() : _getCurrentPageIndex() + 1;
    }

    function _updateProgress() {
        const progress = _navigator.progress;

        progress.step = _getProgressStep();
        progress.percentage = ((100 * progress.step) / progress.total);

        //$log.debug('_navigator.pageIndexHistory', _navigator.pageIndexHistory);
        //$log.debug('_getCurrentPage().page_id',
        //    _getCurrentPage().page_id);
        //forEach(_navigator.pageQuestionIndexHistory, function _db(path, pageID) {
        //    $log.debug('_navigator.pageQuestionIndexHistory', pageID, path);
        //});

        if (!_navigator.isOnboarding) {
            activityService.saveActivity(_questionnaire.activity_id,
                _questionnaire);
        }
    }

    function _initProgress() {
        const perQuestion = _questionnaire.config.oneQuestionPerPage;

        // $log.debug('questionnaire-navigator.factory._initProgress: ');
        return {
            unit: perQuestion ? gettext('Question') : gettext('Page'),
            total: perQuestion ?
                _getOneQuestionPerPageTotalSteps() :
                _questionnaire.pages.length,
            step: 0,
            percentage: 0
        };
    }

    function _setupPageWithOneQuestionPerPage(page) {
        const questionPath = _getCurrentQuestionIndexHistory(page);
        let question;

        if (questionPath.length === 0) {
            if (page.description) {
                questionPath.push(-1);
            } else {
                question = page.questions[0];

                if (angular.isDefined(question)) {
                    questionPath.push(0);
                    _setCurrent(question);
                } else {
                    $log.warn('There are no questions on this page.');
                }
            }
        } else {
            if (_last(questionPath) !== -1) {
                question = _getCurrentQuestion(page);
                _setCurrent(question);
            }
        }

        _navigator.showPageDescription = (_last(questionPath) === -1);
    }

    function _moveToQuestion() {
        _navigator.move.question = true;
        _navigator.move.page = false;
    }

    function _moveToPage() {
        _navigator.move.question = false;
        _navigator.move.page = true;
    }

    function _enterPage(page) {
        //$log.debug('Entering page: page_id', page.page_id);

        _moveToPage();
        _forEach(page.questions, _unsetCurrent);

        if (_navigator.oneQuestionPerPage) {
            _setupPageWithOneQuestionPerPage(page);
        } else {
            _forEach(page.questions, _setCurrent);
        }

        page.isCurrent = true;

        _navigator.updateProgress();
        _navigator.updateButtons('_enterPage');
        _updateGrowBoxes();
    }

    function _leavePage(page) {
        _forEach(page.questions, _unsetCurrent);
        page.isCurrent = false;
    }

    function _pageTransition(previous, next) {
        _navigator.leavePage(previous);
        _navigator.enterPage(next);
    }

    function _previousPage() {
        trackingService.trackContribution(trackingService.actions.previousPage,
            _questionnaire);

        const i = _navigator.pageIndexHistory.pop(),
            currentPage = _questionnaire.pages[i],
            previousIndex = _last(_navigator.pageIndexHistory),
            previousPage = _questionnaire.pages[previousIndex];

        //$log.debug('_previousPage');

        if (angular.isDefined(previousIndex)) {
            _navigator._pageTransition(currentPage, previousPage);
        } else {
            _navigator.pageIndexHistory.push(i);
            $log.error('Tried to navigate to before initial page.');
        }
    }

    function _previousQuestion() {
        const page = _getCurrentPage(),
            questionIndexHistory = _getCurrentQuestionIndexHistory(page);
        let currentQuestion,
            newIndex;

        //$log.debug('_previousQuestion');

        _moveToQuestion();
        _navigator.move.forward = false;

        if (questionIndexHistory.length > 1) {
            currentQuestion = _getCurrentQuestion(page);
            _unsetCurrent(currentQuestion);
            questionIndexHistory.pop();

            newIndex = _last(questionIndexHistory);
            if (newIndex === -1) {
                _navigator.showPageDescription = true;
            } else {
                currentQuestion = _getCurrentQuestion(page);
                _setCurrent(currentQuestion);
            }
            _navigator.updateProgress();
            _navigator.updateButtons('_previousQuestion');
            _updateGrowBoxes();
            $timeout(_scrollToTop, 0);
        } else {
            _previousPage();
        }
    }

    function _determineNextPage(currentPage, nextIndex) {
        const deferred = $q.defer(),
            defaultStep = {
                page: _questionnaire.pages[nextIndex],
                index: nextIndex
            };

        if (currentPage.branch_points.length) {
            _navigator.isCheckingBranchPoint = true;
            currentPage.$$$_questionnaire.checkBranchPoints()
                .then(function _extractNextPageID(data) {
                    const action = _head(
                        _filter(data.actions,
                            ['name', 'redirect_to_page_id']));

                    if (action) {
                        const page = _head(
                            _filter(_questionnaire.pages,
                                ['page_id', action.value]));

                        deferred.resolve({
                            page: page,
                            index: _indexOf(_questionnaire.pages, page)
                        });
                    } else {
                        deferred.resolve(defaultStep);
                    }
                })
                .finally(function _turnOffCheckIndicator() {
                    _navigator.isCheckingBranchPoint = false;
                });
        } else {
            deferred.resolve(defaultStep);
        }

        return deferred.promise;
    }

    function _nextPage() {
        const i = _getCurrentPageIndex(),
            currentPage = _questionnaire.pages[i],
            nextIndex = _navigator._getNextPageIndex(i);

        _determineNextPage(currentPage, nextIndex)
            .then(function _moveForward(nextStep) {
                //$log.debug('i, currentPage, nextStep.index, ' +
                //    'nextStep.page', i, currentPage, nextStep.index,
                //    nextStep.page);
                if (_includes(_navigator.pageIndexHistory, nextStep.index)) {
                    $log.error('Loop discovered in _questionnaire ' +
                        'page-index path: ', _navigator.pageIndexHistory,
                        'next index:', nextStep.index);
                }
                //$log.debug('Moving to pageIndex', nextStep.index);
                _navigator.pageIndexHistory.push(nextStep.index);
                //$log.debug('_navigator.pageIndexHistory', _navigator.pageIndexHistory);
                _navigator._pageTransition(currentPage, nextStep.page);

                trackingService.trackContribution(trackingService.actions.nextPage,
                    _questionnaire);
            });
    }

    function _updateGrowBoxes() {
        $rootScope.$emit('refreshGrowBox');
    }

    function _scrollToTop() {
        const scrollableArea = angular.element('.slide-container')
            .parents('.scrollable-area');

        scrollableArea.animate({scrollTop: 0}, 400);
    }

    function _nextQuestion() {
        const page = _getCurrentPage(),
            questionIndexHistory = _getCurrentQuestionIndexHistory(page),
            currentIndex = _last(questionIndexHistory),
            currentQuestion = _getCurrentQuestion();
        let nextQuestion;

        //$log.debug('_nextQuestion');
        if (angular.isDefined(currentQuestion)) {
            _unsetCurrent(currentQuestion);
        }

        const newIndex = _min([page.questions.length - 1, currentIndex + 1]);

        _moveToQuestion();
        _navigator.move.forward = true;

        //$log.debug('currentIndex', currentIndex);
        //$log.debug('newIndex', newIndex);

        if (newIndex !== currentIndex && newIndex >= 0) {
            questionIndexHistory.push(newIndex);
            nextQuestion = page.questions[newIndex];

            _navigator.showPageDescription = false;
            _setCurrent(nextQuestion);
            _navigator.updateProgress();
            _navigator.updateButtons('_nextQuestion');
            _updateGrowBoxes();
            $timeout(_scrollToTop, 0);
        } else {
            _nextPage();
        }
    }

    function _enterSubQuestion(question, direction) {
        const transition = question.$$$_transition,
            element = angular.isDefined(transition) ?
                transition.element : null;

        function _updateQuestionnaireScope() {
            transition.parent.animate({scrollTop: 0}, 400);
            if (angular.isDefined(_navigator.$$$_iScroll)) {
                _navigator.$$$_iScroll.scrollTo(0, 0);
            }
            _navigator.isAnimating = false;
        }

        function _changeToSubQuestion() {
            switch (direction) {
                case 'next':
                    question.rowIndex++;
                    break;
                case 'previous':
                    question.rowIndex--;
                    break;
            }
            _navigator.updateProgress();
            _navigator.updateButtons('_changeToSubQuestion');
        }

        if (!_navigator.isInitialized || element === null) {
            _changeToSubQuestion();
        } else {
            _navigator.isAnimating = true;
            _changeToSubQuestion();
            _updateQuestionnaireScope();
            //$animate.addClass(element, 'transition',
            //    function _transitionBack() {
            //        _changeToSubQuestion();
            //        //_updateQuestionnaireScope();
            //        //transition.scope.$apply();
            //        $animate.removeClass(element, 'transition',
            //            _updateQuestionnaireScope);
            //    });
        }
    }

    function _previousSubQuestion() {
        const page = _getCurrentPage(),
            question = _getCurrentQuestion(page);

        if (angular.isDefined(question) && question.isGridQuestion &&
            (question.rowIndex > 0)) {
            _enterSubQuestion(question, 'previous');
        } else {
            _previousQuestion();
        }
    }

    function _nextSubQuestion() {
        const page = _getCurrentPage(),
            question = _getCurrentQuestion(page);
        let maxRows;

        if (angular.isDefined(question) && question.isGridQuestion) {
            switch (question.gridQuestionType) {
                case 'per category':
                    maxRows = question.icon_rack_categories.length - 1;
                    break;
                case 'per row':
                    maxRows = question.valuerows.length - 1;
                    break;
                default:
                    $log.error('Received unknown gridQuestionType ' +
                        'sub-category:', question.gridQuestionType);
            }
            if (angular.isDefined(maxRows)) {
                if (question.rowIndex < maxRows) {
                    _enterSubQuestion(question, 'next');
                } else {
                    _nextQuestion();
                }
            }
        } else {
            _nextQuestion();
        }
    }

    function _onFirstStep(question) {
        let onFirst = (_navigator.progress.step === 1);

        if (onFirst && angular.isDefined(question) && question.isGridQuestion) {
            onFirst = (question.rowIndex === 0);
        }

        return onFirst;
    }

    function _onLastSubStep(question) {
        let onLast;

        //$log.debug('question.gridQuestionType', question.gridQuestionType);
        switch (question.gridQuestionType) {
            case 'per category':
                onLast = (question.rowIndex >=
                    question.icon_rack_categories.length - 1);
                break;
            case 'per row':
                //$log.debug('question.rowIndex', question.rowIndex);
                //$log.debug('question.valuerows.length - 1',
                //    question.valuerows.length - 1);
                onLast = (question.rowIndex >= question.valuerows.length - 1);
                //$log.debug('onLast', onLast);
                break;
            default:
                onLast = true;
        }

        return onLast;
    }

    function _isCurrentStepComplete(currentQuestion, subStepIsAnswered) {
        return currentQuestion.is_already_answered ||
            (currentQuestion.isGridQuestion && subStepIsAnswered);
    }

    function _getStatusOverview() {
        const currentPage = _getCurrentPage(),
            currentQuestion = _getCurrentQuestion(currentPage),
            pageID = angular.isDefined(currentPage) ?
                currentPage.page_id : null,
            questionID = angular.isDefined(currentQuestion) ?
                currentQuestion.question_id : null,
            currentIsAQuestion = questionID !== null,
            onLastSubStep = !currentIsAQuestion ||
                _onLastSubStep(currentQuestion);
        let subStep,
            subStepIsAnswered;

        if (currentIsAQuestion) {
            subStep = currentQuestion.rowIndex;
            subStepIsAnswered =
                answerService.subStepIsAnswered(currentQuestion, subStep);
        } else {
            subStep = null;
            subStepIsAnswered = null;
        }

        //$log.debug('subStepIsAnswered', subStepIsAnswered);
        return {
            onFirstStep: _onFirstStep(currentQuestion),
            onLastStep: (_navigator.progress.step >= _navigator.progress.total) &&
            onLastSubStep,
            currentStepIsAPage: angular.isUndefined(currentQuestion),
            currentQuestionIsAlreadyAnswered: (!currentIsAQuestion ||
                currentQuestion.is_already_answered),
            isNextAllowed: (!currentIsAQuestion || currentQuestion.optional ||
                _isCurrentStepComplete(currentQuestion, subStepIsAnswered)),
            hash: [pageID, questionID, subStep].join(':')
        };
    }

    function _doShowPreviousButton(status) {
        return !status.onFirstStep;
    }

    function _doShowNextButton(status) {
        return !status.onLastStep && status.isNextAllowed;
    }

    function _doShowSubmitButton(status) {
        //$log.debug('status', status);
        //$log.debug('status.onLastStep', status.onLastStep);
        //$log.debug('status.everythingSoFarIsAlreadyAnswered',
        //    status.everythingSoFarIsAlreadyAnswered);
        return status.onLastStep && status.isNextAllowed;
    }

    function _initButtons() {
        // $log.debug('questionnaire-navigator.factory._initButtons: ');
        if (_navigator.oneQuestionPerPage) {
            _navigator.next = _nextSubQuestion;
            _navigator.previous = _previousSubQuestion;
        } else {
            _navigator.next = _nextPage;
            _navigator.previous = _previousPage;
        }
    }

    function _goToQuestion(question) {
        let questionIndex = null;
        const pageIndex = _findIndex(_questionnaire.pages,
            function _findQuestion(page) {
                questionIndex = _findIndex(page.questions, question);
                return questionIndex >= 0;
            }),
            currentPage = _getCurrentPage();

        //$log.debug('pageIndex', pageIndex);
        //$log.debug('questionIndex', questionIndex);

        if (angular.isDefined(pageIndex) && (questionIndex !== null)) {
            const page = _getPageByIndex(pageIndex),
                questionIndexHistory = _getCurrentQuestionIndexHistory(page);

            if (_includes(_navigator.pageIndexHistory, pageIndex)) {
                _pull(_navigator.pageIndexHistory, pageIndex);
            }
            _navigator.pageIndexHistory.push(pageIndex);

            if (_includes(questionIndexHistory, questionIndex)) {
                _pull(questionIndexHistory, questionIndex);
            }
            questionIndexHistory.push(questionIndex);

            _pageTransition(currentPage, page);
        }
    }

    function _goToFirstUnanswered() {
        let i = 0,
            status,
            previousStepWasAPage = false,
            previousHash = '';

        _navigator.pageIndexHistory.push(i);
        _initButtons();
        _navigator.enterPage(_questionnaire.pages[i]);

        status = _getStatusOverview();

        while (previousHash !== status.hash &&
        status.currentQuestionIsAlreadyAnswered &&
        _navigator.showButtons.next) {
            previousStepWasAPage = status.currentStepIsAPage;
            previousHash = status.hash;
            //$log.debug('previousHash', previousHash);

            _navigator.next();
            status = _getStatusOverview();
            i++;
        }
        if (previousStepWasAPage) {
            _navigator.previous();
        }
    }

    function _unsetAllPagesAndQuestions() {
        // $log.debug('questionnaire-navigator.factory._unsetAllPagesAndQuestions: ');
        _forEach(_questionnaire.pages, (page) => {
            page.isCurrent = false;

            _forEach(page.questions, (question) => {
                question.isCurrent = false;
            });
        });
    }

    const _navigator = {
        move: {
            forward: true,
            question: true,
            page: false
        },
        oneQuestionPerPage: undefined,
        /**
         * The pageIndexHistory will comprise the list of visited pages.
         */
        pageIndexHistory: [],   // A _stack_ ;)
        /**
         * The pageQuestionIndexHistory will hold a list of visited questions, by
         * index, per page_id.  For example:
         *
         *   pageQuestionIndexHistory = {
         *       43: [0, 1, 2],
         *       45: [0]
         *   }
         */
        pageQuestionIndexHistory: {},
        progress: null,
        showButtons: {
            next: false,
            previous: false,
            submit: false
        },
        isCheckingBranchPoint: false,
        updateProgress: _updateProgress,
        anim: {
            enter: 'slide-in-from-right',
            leave: 'slide-out-to-left'
        },
        _subTransitionElements: [],
        showTransition: false,
        enterPage: _enterPage,
        leavePage: _leavePage,
        _pageTransition: _pageTransition,
        _getNextPageIndex: function _getNextPageIndex(i) {
            const max = _questionnaire.pages.length - 1;
            return _min([i + 1, max]);
        },
        next: undefined,
        previous: undefined,
        updateButtons: function _updateButtons(/*from*/) {
            // $log.debug('questionnaire-navigator.factory._updateButtons: from', from);
            const status = _getStatusOverview();

            _navigator.showButtons.previous = _doShowPreviousButton(status);
            _navigator.showButtons.next = _doShowNextButton(status);
            _navigator.showButtons.submit = _doShowSubmitButton(status);

            //$log.debug('_navigator.showButtons', _navigator.showButtons);
        },
        getCurrentPage: _getCurrentPage,
        goToQuestion: _goToQuestion,
        init: function _init(questionnaire, config) {
            if (angular.isDefined(config)) {
                _navigator.isOnboarding = config.isOnboarding || false;
                _navigator.isDiary = config.isDiary || false;
                _navigator._questionnaireScope = config.questionnaireScope;
            }

            // Reset stacks and book-keeping objects.
            _navigator.isInitialized = false;
            _navigator.pageQuestionIndexHistory = {};
            _navigator.subQuestionIndex = {};
            _navigator.pageIndexHistory = [];
            _navigator.isAnimating = false;
            _navigator.isCheckingBranchPoint = false;

            _questionnaire = questionnaire;
            _questionnaire.navigator = _navigator;
            _unsetAllPagesAndQuestions();

            _navigator.oneQuestionPerPage =
                _questionnaire.config.oneQuestionPerPage;

            _navigator.progress = _initProgress();

            _goToFirstUnanswered();

            _navigator.isInitialized = true;
        }
    };

    return _navigator;
}

export default angular
    .module('activities.questionnaire.questionnaireNavigator', [])
    .factory('questionnaireNavigator', questionnaireNavigator);
