'use strict';
import * as angular from 'angular';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _reverse from 'lodash/reverse';
/* @ngInject */
function queryQuestionService($resource, $log, $http, api, utility, gettext) {
    var apiUrl = api.url + '/api/:resource/:action';
    function _inlineUserCount(questionWrapper) {
        questionWrapper.Question.user_count = questionWrapper.user_count;
        delete questionWrapper.user_count;
        return questionWrapper.Question;
    }
    var _humaneCasts = {
        'age': gettext('Age in years')
    };
    function _addCastedVersions(questions) {
        var toBeCasted = _reduce(questions, function _extractCastable(result, question, i) {
            if (question.answer_type_name === 'Date Selection') {
                result.push({
                    index: i,
                    cast: 'age',
                    humaneCast: _humaneCasts['age']
                });
            }
            return result;
        }, []);
        _forEach(_reverse(toBeCasted), function _addCastedVersion(casting) {
            var copy = _cloneDeep(questions[casting.index]);
            copy.cast = casting.cast;
            copy.humaneCast = casting.humaneCast;
            questions.splice(casting.index, 0, copy);
        });
    }
    var queryAction = {
        method: 'GET',
        //cache: false,
        isArray: false,
        transformResponse: utility.appendTransform($http.defaults.transformResponse, function _getQuestions(data) {
            var result = {
                profile_questions: _map(data.result.profile_questions, _inlineUserCount),
                panel_questions: _map(data.result.panel_questions, _inlineUserCount)
            };
            _addCastedVersions(result.profile_questions);
            _addCastedVersions(result.panel_questions);
            return result;
        })
    };
    var _resource = $resource(apiUrl, { resource: 'questions' }, {
        get: queryAction
    });
    return {
        get: _resource.get,
        humaneCasts: _humaneCasts
    };
}
export var QueryQuestionServiceFactory = angular
    .module('components.query.queryQuestionService', [])
    .factory('queryQuestionService', queryQuestionService);
