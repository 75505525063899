var QueryConstraintAnswer = /** @class */ (function () {
    function QueryConstraintAnswer(options) {
        var params = options || {};
        this.discriminator = 'query_constraint_answer';
        this.valuerow_id = params.valuerow_id;
        this.valuerow = null;
        this.valuecolumn_id = params.valuecolumn_id;
        this.valuecolumn = null;
        this.query_constraint_answer_id = params.query_constraint_answer_id;
    }
    return QueryConstraintAnswer;
}());
export { QueryConstraintAnswer };
