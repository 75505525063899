'use strict';

import angular from 'angular';

import MdHelpController from './md-help.controller';
import mdHelpHeaderHtml from './md-help.header.html'
import mdHelpHtml from './md-help.html'
// import mdHelpFooterHtml from './md-help.footer.html'

/* @ngInject */
function mdHelpState(name) {
    return {
            name: name,
            url: 'md-help/',
            params: {
                message: undefined,
                what: undefined,
            },
            views: {
                'modal-header@': {
                    controller: 'MdHelpController',
                    controllerAs: 'vm',
                    templateUrl: mdHelpHeaderHtml
                },
                'modal-contents@': {
                    controller: 'MdHelpController',
                    controllerAs: 'vm',
                    templateUrl: mdHelpHtml
                },
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.openModal({
                    size: 'large',
                    footer: {
                        hidden: coreLayoutService.defaultExcept({}, true),
                        visible: coreLayoutService.defaultExcept()
                    }
                });
            },
            onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.closeModal();
            },
            secure: true
        };
}

export default angular
    .module('components.realTimeEngine.realTimeMessage.mdHelpState', [
        MdHelpController.name,
    ])
    .constant('mdHelpState', mdHelpState);
