'use strict';

import angular from 'angular';
import singleSelectAnswerHtml from './single-select-answer.html'

/* @ngInject */
function singleSelectAnswer(iScrollService) {
    function _setValue(question, row) {
        question.answerIndex.rows[0].valuerow_id = row.valuerow_id;

        question.updateAnswer();
    }

    function _link(scope) {
        scope.iScrollState = iScrollService.state;

        scope.setValue = _setValue;

        scope.question.updateAnswer();
    }

    return {
        restrict: 'A',
        scope: {
            question: '='
        },
        link: _link,
        templateUrl: singleSelectAnswerHtml
    };
}

export default angular
    .module('activities.questionnaire.answerType.singleSelectAnswer', [])
    .directive('singleSelectAnswer', singleSelectAnswer);
