'use strict';

import angular from 'angular';
import _defaults from 'lodash/defaults'
import _partial from 'lodash/partial'
import _reduce from 'lodash/reduce'


/* @ngInject */
function userService($rootScope, $q, $resource, $analytics, $log,
                     api, config, storage, metaService, realTime,
                     trackingService, activityService, utility, userSignals,
                     gridUtils, panelService) {

    function _checkAuthStatus(meta) {
        if (!meta.user.is_authenticated) {
            $log.debug('Login failed.');
        } else {
            // adformService.track('Innlogget i panelet');
            trackingService.trackPlatform(trackingService.actions.login,
                'Plain');
        }
    }

    function _logout() {
        trackingService.trackPlatform(trackingService.actions.logout);

        _userService.getAlertChannelNames().then(function (channelNames) {
            realTime.unsubscribe(channelNames);

            api.get(_userService.logoutUrl)
                .then(metaService.refresh)
                .then((meta) => {
                    if (!meta.user.is_authenticated) {
                        $log.debug(`$emit('${userSignals.userLogoutSignal}')`);
                        $rootScope.$emit(userSignals.userLogoutSignal);
                    } else {
                        $log.debug('logout failed.');
                    }
                })
                .then(panelService.refresh);
        });
    }

    function _register(registrationData) {
        const deferred = $q.defer(),
            data = _defaults({}, registrationData);

        if (config.dateInputType === 'date') {
            data.birthdate = utility.dateTime.toIsoDate(data.birthdate);
        }

        metaService.get()
            .then(function _register(meta) {
                return api.postJson(meta.registration.create_user.url,
                    data);
            })
            .then(function _postRegistration(res) {
                $log.debug('_postRegistration: res', res);
                if (res.data.status === 'success') {
                    metaService.refresh()
                        .then(function _checkAuthStatus(meta) {
                            if (!meta.user.is_authenticated) {
                                deferred.reject(
                                    'Registration failed.');
                            }
                        })
                        .then(deferred.resolve);
                }
            }, function _registrationFailed(res) {
                $log.debug('_registrationFailed: res', res);
                deferred.reject(res.data);
            });

        return deferred.promise;
    }

    function InvalidLoginException(value) {
        this.value = value;
        this.name = 'InvalidLoginException';
        this.message = 'Password cannot be empty; received ';
        this.toString = function _toString() {
            const value = angular.isDefined(this.value) ?
                `"${value}"` : 'undefined';
            return this.name + ': ' + this.message + value + '.';
        };
    }

    function _requestPasswordReset(login) {
        if (angular.isUndefined(login) || /^[\s\uFEFF\xA0]*$/g.test(login)) {
            const exception = new InvalidLoginException(login);
            $log.error('' + exception);
            throw exception;
        }

        return api.postForm('/login/recover_lost_password.json', {
            email: login
        });
    }

    function _initiatePasswordReset(userId, key) {
        return api.postForm('/login/initiate_password_reset.json', {
            user_id: userId,
            key: key
        });
    }

    function _resetPassword(userId, key, password, invalidateSessions) {
        return api.postForm('/login/complete_password_reset.json', {
            user_id: userId,
            key: key,
            password: password,
            invalidate_sessions: invalidateSessions
        });
    }

    function _login(login, password, extraLoginInfo) {
        return api.postForm('/login/app_login_handler', {
            login: login,
            password: password,
            remember: 2600000,
            do_join_panel: extraLoginInfo.doJoinPanel,
            panel_id: extraLoginInfo.currentPanelId,
        }).then((res) => {
            if (res.data.status === 'success') {
                metaService.refresh()
                    .then(_checkAuthStatus)
                    .then(panelService.refresh)
                    .then(() => panelService.setCurrentPanel(res.data.user.current_panel_id, false));
            }
        });
    }

    function _joinPanelOnLogin(panelId) {
        $log.debug('user-service.factory._joinPanelOnLogin: panelId', panelId);
        return api.postJson('/login/join_panel', {
            panel_id: panelId,
        });
    }

    function _isLoggedIn() {
        const deferred = $q.defer();
        metaService.get().then(function (meta) {
            deferred.resolve(meta.user.is_authenticated);
        });
        return deferred.promise;
    }

    function _flush() {
        storage.user.removeItem();
    }

    function _removeAlerts() {
        const user = storage.user.getItem();
        user.alerts = [];
        storage.user.setItem(user);
    }

    function _getAlerts() {
        const user = storage.user.getItem();
        return user === null ? [] : user.alerts || [];
    }

    function _addAlert(subject) {
        const user = storage.user.getItem();
        if (angular.isUndefined(user.alerts)) {
            user.alerts = [];
        }
        user.alerts.push(subject);
        storage.user.setItem(user);
    }

    function _getAlertChannelNames() {
        return metaService.get().then(function (meta) {
            return ['/topic/alerts.' + meta.user.user_id];
        });
    }

    function _init() {
        if (!storage.user.getItem()) {
            storage.user.setItem({
                alerts: [],
                activities: {}
            });
        }
    }

    function _subscribeToAlertDestinations(destinations) {
        realTime.subscribe(destinations, 'alert');
    }

    function _subscribeToRealTimeAlerts() {
        _userService.getAlertChannelNames().then(_subscribeToAlertDestinations);
    }

    function _initUserServiceFromMeta(meta) {
        _userService.logoutUrl = meta.auth.logout_url;
        $rootScope.$emit('growl:removeAll');
        return meta;
    }

    function _loginAnalytics(meta) {
        $analytics.setUsername(meta.user.user_id);
        return meta;
    }

    function _logoutAnalytics() {
        // $log.debug('Set analytics username to null');
        $analytics.setUsername(null);
    }

    const usersResource = $resource(api.url + '/api/users/:userId/',
        {
            userId: '@user_id'
        }, {
            query: {
                method: 'POST',
                url: api.url + '/api/users/:userId/query/',
                isArray: false
            },
            update: {
                method: 'PUT',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    const userPanelsResource = $resource(api.url + '/api/users/:userId/panels/:panelId/',
        {
            userId: '@user_id',
            panelId: '@panel_id'
        }, {}, {
            stripTrailingSlashes: false
        });

    const userEulaResource = $resource(api.url + '/api/users/:userId/eulas/:panelId/',
        {
            userId: '@user_id',
            panelId: '@panel_id'
        }, {
            update: {
                method: 'PUT',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    const usersDeleteResource = $resource(api.url + '/api/users_delete/', {}, {
        deleteMultiple: {
            method: 'DELETE',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });

    const usersMessageResource = $resource(api.url + '/api/user_messages/', {}, {
        messageMultiple: {
            method: 'POST',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });

    const usersMessageFromAddressesResource = $resource(api.url +
        '/api/user_messages/valid_from_addresses', {}, {
        get: {
            method: 'GET',
            isArray: false
        }
    }, {
        stripTrailingSlashes: false
    });


    const usersStatsResource = $resource(api.url + '/api/stats/users', {},
        {
            query: {
                method: 'GET',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    function _queryParamsFromFilters(filters, ignoreExtraFilters) {
        const params = {
            panel_ids: filters.panels,
            activity_ids: filters.activities,
            query_ids: filters.queries
        };

        if (angular.isDefined(filters.items)) {
            params.exclude_user_ids =
                filters.items.exclude ? filters.items.ids : null;
            params.user_ids =
                !filters.items.exclude ? filters.items.ids : null;

            if (!ignoreExtraFilters) {
                params.filters =
                    _reduce(filters.items.extraFilters,
                        _partial(gridUtils.generateFilterKey,
                            filters.items.columnDefs),
                        []);
            }
        }
        return params;
    }

    const _userService = {
        init: _init,
        getAlertChannelNames: _getAlertChannelNames,
        addAlert: _addAlert,
        getAlerts: _getAlerts,
        removeAlerts: _removeAlerts,
        flush: _flush,
        isLoggedIn: _isLoggedIn,
        // TODO: Move to authService
        login: _login,
        register: _register,
        requestPasswordReset: _requestPasswordReset,
        initiatePasswordReset: _initiatePasswordReset,
        resetPassword: _resetPassword,
        logout: _logout,
        get: usersResource.get,
        query: usersResource.query,
        save: usersResource.save,
        update: usersResource.update,
        delete: usersResource.delete,
        leavePanel: userPanelsResource.delete,
        acceptEula: userEulaResource.update,
        deleteMultiple: usersDeleteResource.deleteMultiple,
        messageMultiple: usersMessageResource.messageMultiple,
        getValidFromAddresses: usersMessageFromAddressesResource.get,
        stats: usersStatsResource.query,
        queryParamsFromFilters: _queryParamsFromFilters,
        joinPanelOnLogin: _joinPanelOnLogin,
    };

    $rootScope.$on(userSignals.userLoginSignal, function _onLogin() {
        _userService.init();
        metaService.get()
            .then(_initUserServiceFromMeta)
            .then(_loginAnalytics)
            .then(_subscribeToRealTimeAlerts);
    });

    $rootScope.$on(userSignals.userLogoutSignal, function _onLogout() {
        _userService.getAlertChannelNames().then(realTime.unsubscribe);
        _userService.flush();
        activityService.flush();
        storage.clear();  // Both localStorage and sessionStorage.

        _logoutAnalytics();
        $log.debug("$emit('" + userSignals.userLogoutSuccessSignal + "')");
        $rootScope.$emit(userSignals.userLogoutSuccessSignal);
    });

    return _userService;
}

export default angular
    .module('components.userService', [])
    .factory('user', userService);
