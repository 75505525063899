'use strict';

import angular from 'angular';
import Color from 'color-js';

/* @ngInject */
function branding($rootScope, api, panelService) {
    var defaults = {
            color: {
                medium: '/* @echo DEFAULT_BRAND_COLOR */' //'#E673AC'
            },
            title: 'FooBar'
        },
        black = new Color('#000000'),
        white = new Color('#ffffff'),
        shared = {
            color: _generateColorBlends(new Color(defaults.color.medium)),
            title: defaults.title,
            logoUrlText: null,
            logoUrl: undefined,
            defaultShade: 'medium',
            updateSignal: 'branding:updated',
            updateBranding: _updateBranding
        },
        blendFactor = 0.2;

    function _generateColorBlends(color) {
        return {
            light: color.blend(white, blendFactor).toCSS(),
            medium: color.toCSS(),
            dark: color.blend(black, blendFactor).toCSS()
        };
    }

    function _updateBranding(panel) {
        var brandColor = new Color(
            panel.brand_color || defaults.color.medium);

        shared.color = _generateColorBlends(brandColor);

        shared.title = panel.name;
        if (panel.logo_url) {
            shared.logoUrlText = api.url + panel.logo_url;
            shared.logoUrl = 'url(' + shared.logoUrlText + ')';
        } else {
            shared.logoUrlText = null;
            shared.logoUrl = 'none';
        }

        document.title = shared.title;

        $rootScope.$broadcast(shared.updateSignal);
    }

    function _currentPanelChangedHandler(event, panel) {
        if (angular.isDefined(panel)) {
            _updateBranding(panel);
        }
    }

    $rootScope.$on(panelService.currentPanelChangedSignal,
        _currentPanelChangedHandler);

    _currentPanelChangedHandler(null, $rootScope.currentPanel);

    return shared;
}

export default angular
    .module('components.branding', [])
    .factory('branding', branding);
