'use strict';

import angular from 'angular';

/* @ngInject */
function DiaryModalHeaderController($scope, $rootScope, $state,
                                    coreLayoutService) {
    function _hideQuestionnaire() {
        $rootScope.questionnaireVisible = false;
    }

    $scope.closeModal = function _closeModal() {
        if ($rootScope.questionnaireVisible) {
            _hideQuestionnaire();
        } else {
            $state.go(coreLayoutService.state.modal.closeTargetState);
        }
    };
}

export default angular
    .module('activities.diary.DiaryModalHeaderController', [])
    .controller('DiaryModalHeaderController', DiaryModalHeaderController);
