'use strict';
import platform from 'platform'

function _osVersion() {
    const v = (platform.os.version).match(/(\d+).(\d+).?(\d+)?/);
    return [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || 0, 10)];
}

function _isChromeMobile() {
    return platform.name === 'Chrome Mobile';
}

export default function _useNativeScroll() {
    if (platform.name === 'Opera Mini') {
        return false;
    }

    switch (platform.os.family) {
        case 'Android':
            // In Chrome we trust.
            return _isChromeMobile();
        case 'iOS':
            // Buggy handling in older iOS versions.
            var iosVersion = _osVersion();
            return iosVersion[0] > 7;
        default:
            // Assuming desktop or other browser.
            return true;
    }
}
