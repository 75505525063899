'use strict';

import angular from 'angular';

/* @ngInject */
function developerModeService(iScrollService) {
    var _state = {
        isDeveloper: false,
        unlockClicks: 0,
        openDeveloperMenu: false,
        iScroll: iScrollService.state
    };

    function _unlockDeveloperMode() {
        _state.unlockClicks += 1;
        _state.isDeveloper = _state.unlockClicks >= 7;
    }

    return {
        unlockDeveloperMode: _unlockDeveloperMode,
        state: _state
    };
}

export default angular
    .module('about.developerModeService', [])
    .service('developerModeService', developerModeService);
