'use strict';

import angular from 'angular';
import _forEach from 'lodash/forEach'
import _isString from 'lodash/isString'


/* @ngInject */
function ResetPasswordController($q, $state, $window, $log, user, config,
                                 passwordService) {
    const vm = this;

    vm.resetPasswordForm = {
        password1: '',
        password2: '',
        invalidateSessions: true
    };

    vm.state = {
        stage: 'pre-init'
    };

    function _getNewPassword(response) {
        vm.state.stage = 'post-init';
        vm.info = response.data;
    }

    function _cancel() {
        $window.history.back();
    }

    function _handleSaveFormError(form, response) {
        let status = null;

        if (_isString(response.data)) {
            status = {
                message: response.statusText
            };
        } else {
            // Update field-specific validation errors.
            if (response.data.form_errors) {
                _forEach(response.data.form_errors.fields, function _setErrors(value, name) {
                    if (name === 'password') {
                        name = 'password1';
                    }
                    if (angular.isDefined(form[name])) {
                        form[name].$setValidity('serverValidation', false);
                        form[name].$setDirty();
                        form[name].serverValidationMessage = value;
                    }
                });
            } else {
                status = {
                    message: response.data.message
                };
            }
        }
        return $q.reject(status);
    }

    function _showSuccessView(response) {
        vm.state.stage = 'post-reset';
        vm.info = response.data;
    }

    function _resetPassword(form) {
        user.resetPassword($state.params.userId, vm.info.key,
            vm.resetPasswordForm.password1,
            vm.resetPasswordForm.invalidateSessions)
            .then(_showSuccessView, function _wrapHandler(response) {
                if (response.status === 401) {
                    _getNewPassword(response);
                }
                return _handleSaveFormError(form, response);
            });
    }

    vm.cancel = _cancel;
    vm.resetPassword = _resetPassword;

    function _init(params) {
        vm.passwordMinLength = passwordService.minLength;

        user.initiatePasswordReset(params.userId, params.key)
            .then(_getNewPassword, _getNewPassword);
    }

    _init($state.params);
}

export default angular
    .module('welcome.forgotten-password.resetPasswordController', [])
    .controller('ResetPasswordController', ResetPasswordController);
