'use strict';

import angular from 'angular';

/* @ngInject */
function questionService(answerService) {
    return {
        getAnswerData: answerService.getAnswerData,
        prepareQuestion: function _prepareQuestion(question) {
            answerService.classifyQuestionType(question);
            answerService.classifyAnswerType(question);
            answerService.prepareSessionAnswers(question);
        }
    };
}

export default angular
    .module('activities.questionnaire.questionService', [])
    .factory('questionService', questionService);

