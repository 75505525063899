'use strict';

import angular from 'angular';

/* @ngInject */
function enterPress($parse, $log) {
    function _link(scope, element, attrs) {
        var func = $parse(attrs.enterPress);

        element.bind('keyup', function (event) {
            if (event.keyCode === 13) {
                $log.debug("Enter was pressed.");
                scope.$apply(function () {
                    func(scope);
                });
            }
        });
    }

    return {
        restrict: 'A',
        link: _link
    };
}

export default angular
    .module('components.enterPress', [])
    .directive('enterPress', enterPress);
