'use strict';

import angular from 'angular';

/* @ngInject */
function validPhoneNumber($q, $log, countries, profileService) {
    var _countryDialingCodes;

    function _setDialingCodes(countryDialingCodesIndex) {
        _countryDialingCodes = countryDialingCodesIndex;
    }

    function _link(scope, element, attrs, ctrl) {
        var requireExisting = false;

        switch (scope.requirement) {
            case 'require unique':
                requireExisting = false;
                break;
            case 'require existing':
                requireExisting = true;
                break;
            default:
                $log.error('Received unknown validation requirement:',
                    scope.requirement);
        }

        function _validatePhoneNumber(modelValue) {
            var modelIsEmpty = ctrl.$isEmpty(scope.countryCode) ||
                    ctrl.$isEmpty(modelValue),
                notYetInitialized = angular.isUndefined(_countryDialingCodes);

            if (modelIsEmpty || notYetInitialized) {
                return $q.when();
            }

            var deferred = $q.defer(),
                compound = scope.countryCode + ':+' +
                    _countryDialingCodes[scope.countryCode].dialing_country_code +
                    ' ' + modelValue;

            profileService.isValidPhoneNumber(compound, requireExisting, scope.userId)
                .then(function _updateField(isValid) {
                    if (isValid) {
                        deferred.resolve();
                    } else {
                        deferred.reject(requireExisting ?
                            'does not exist' : 'exists');
                    }
                });

            return deferred.promise;
        }

        function _countryCodeChanged(newValue, oldValue) {
            if (newValue !== oldValue) {
                ctrl.$validate();
            }
        }

        scope.$watch('countryCode', _countryCodeChanged);

        ctrl.$asyncValidators.validPhoneNumber = _validatePhoneNumber;
    }

    function _activate() {
        countries.getIndex('dialingCodes', false).then(_setDialingCodes);
    }

    _activate();

    return {
        require: 'ngModel',
        scope: {
            requirement: '@validPhoneNumber',
            countryCode: '=',
            userId: '='
        },
        link: _link
    };
}

export default angular
    .module('profile.validPhoneNumber', [])
    .directive('validPhoneNumber', validPhoneNumber);
