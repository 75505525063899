'use strict';

import angular from 'angular';

/* @ngInject */
function DeleteImportUsersController($state, $log, activeFiltersService,
                                     utility, userDataImport) {
    const vm = this;

    function _successfullyDeleted() {
        const filter = activeFiltersService
            .getInheritedSelectionsFilter($state.$current);
        activeFiltersService.removeFilter(filter);
        $state.go('admin.panels.user_imports.create', {}, {reload: true});
    }

    function _handleDeleteError(response) {
        vm.deleteError = typeof response.data === 'string' ? {
            message: response.statusText
        } : {
            message: response.data.message
        };
    }

    function _deleteUserDataImport(userDataImportId) {
        if (angular.isDefined(userDataImportId)) {
            userDataImport.delete({
                userDataImportId: userDataImportId
            }).$promise
                .then(_successfullyDeleted, _handleDeleteError);
        }
    }

    function _initMasterData(response) {
        vm.userImport = response;
    }

    function _activate() {
        userDataImport.get({userDataImportId: $state.params.userDataImportId})
            .$promise
            .then(_initMasterData);

        vm.deleteUserDataImport = _deleteUserDataImport;
    }

    _activate();
}

export default angular
    .module('admin.users.DeleteImportUsersController', [])
    .controller('DeleteImportUsersController', DeleteImportUsersController);
