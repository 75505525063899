'use strict';

import angular from 'angular';
import viewAttachmentState from './view-attachment-state.constant.js';

/* @ngInject */
function ViewAttachmentController($scope, $stateParams) {
    $scope.attachment = $stateParams.attachment;
}

export default angular
    .module('activities.forum.viewAttachmentController', [
        viewAttachmentState.name
    ])
    .controller('ViewAttachmentController', ViewAttachmentController);
