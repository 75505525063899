'use strict';

import angular from 'angular';
import _reduce from 'lodash/reduce'
import _startsWith from 'lodash/startsWith'
import _assign from 'lodash/assign'
import _now from 'lodash/now'
import _defaults from 'lodash/defaults'

/* @ngInject */
function profileService($rootScope, $q, $timeout, $log, api, metaService,
                        $resource, utility, config) {
    const _profile = {},
        _profileForm = {},
        _profileFormFields = [
            'user_id',
            'came_from',
            'first_name', 'last_name',
            'birthdate',
            'gender',
            'address_line_1', 'address_line_2',
            'city',
            'region',
            'postal_code',
            'country',
            'email',
            'phone_number',
            'about_me'
            // 'old_password',
            // 'password1',
            // 'password2'
        ],
        _profileFormFieldDefaults =
            _reduce(_profileFormFields, function (result, val) {
                result[val] = '';
                return result;
            }, {});
    let _profileDeferred,
        _profileFormDeferred;

    function _refreshProfileImage(profilePictureUrl, isRepeatedAttempt) {
        const _profile_picture =
            _startsWith(profilePictureUrl, 'http') ?
                profilePictureUrl : api.url + profilePictureUrl;

        _profile.profile_picture = _profile_picture + '?_=' + _now();

        if (!isRepeatedAttempt) {
            $timeout(function _secondAttempt() {
                _refreshProfileImage(profilePictureUrl, true);
            }, 1000);
        }
    }

    function _getMediaUploadInfo(config) {
        const deferred = $q.defer();
        metaService.get().then(function _getMediaUpload(meta) {
            api.postForm(meta.user.profile_url + 'media_upload.json', config)
                .then(function _handleResult(result) {
                    deferred.resolve(result);
                });
        });

        return deferred.promise;
    }

    function _refreshProfile(res) {
        const profile = _assign({}, res.data);

        utility.updateSharedObject(_profile, profile);
        _refreshProfileImage(profile.user.profile_picture);

        $log.debug("$emit(" + _profileService.isRefreshedSignal + ")");
        $rootScope.$emit(_profileService.isRefreshedSignal, profile);

        _profileDeferred.resolve(_profile);
    }

    function _refreshProfileEditForm(res) {
        const profileEditForm = res.data.form_values;

        // _update_profile_url = res.data.action;

        profileEditForm.display_name = res.data.display_name;

        profileEditForm.phone_number =
            _phoneNumberStringToObject(profileEditForm.phone_number);

        profileEditForm.postalCode = {
            postalCode: profileEditForm.postal_code,
            countryCode: profileEditForm.country,
        };

        if (config.dateInputType === 'date') {
            profileEditForm.birthdate = profileEditForm.birthdate &&
                utility.dateTime.fromIso(profileEditForm.birthdate);
        }

        $log.debug("$emit(" + _profileService.editFormIsRefreshedSignal + ")");
        $rootScope.$emit(_profileService.editFormIsRefreshedSignal,
            profileEditForm);

        utility.updateSharedObject(_profileForm, profileEditForm);
        _profileFormDeferred.resolve(_profileForm);
    }

    const profileResource = $resource(api.url + '/registration/update_user',
        {}, {
            query: {
                method: 'GET',
                isArray: false
            },
            update: {
                method: 'PUT',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    function _updateProfile(formData) {
        const data = _defaults({}, formData, _profileFormFieldDefaults),
            phone_number = data.phone_number;

        if (config.dateInputType === 'date') {
            data.birthdate = utility.dateTime.toIsoDate(data.birthdate);
        }

        data['phone_number.phone_number_country_code'] =
            phone_number.phone_number_country_code;
        data['phone_number.phone_number'] = phone_number.phone_number;

        delete data.phone_number;
        delete data.display_name;

        return profileResource.update(data).$promise
            .then(function _refreshProfileValues() {
                return $q.all([
                    _profileService.refresh(),
                    _profileService.refreshEditForm()
                ]);
            }, function _handleErrors(response) {
                return $q.reject(response);
            });
    }

    const passwordResource = $resource(api.url + '/api/users/:userId/password',
        {userId: '@user_id'}, {
            update: {
                method: 'PUT',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    function _changePassword(formData) {
        return metaService.get().then((meta) => {
            const data = _defaults({
                user_id: meta.user.user_id
            }, formData);

            return passwordResource.update(data).$promise
                .then(function _handleResponse() {
                    return $q.resolve();
                }, function _handleErrors(response) {
                    return $q.reject(response);
                });
        });
    }

    const actionsResource = $resource(api.url + '/api/users/:userId/actions/',
        {userId: '@user_id'}, {
        }, {
            stripTrailingSlashes: false
        });

    const userSessionsResource = $resource(api.url + '/api/users/:userId/sessions/',
        {userId: '@user_id'}, {
            invalidate: {
                method: 'DELETE',
                isArray: false
            }
        }, {
            stripTrailingSlashes: false
        });

    function _handlerError(deferred, resource, reason) {
        const explanation = `Could not refresh ${resource}: ${reason}`;

        $log.warn('profileService:', explanation);
        deferred.reject(explanation);
    }

    function _getEditForm() {
        return angular.isDefined(_profileFormDeferred) ?
            _profileFormDeferred.promise :
            _profileService.refreshEditForm();
    }

    function _refreshEditForm() {
        _profileFormDeferred = $q.defer();

        metaService.get()
            .then(function _refreshFromMeta(meta) {
                if (meta.user.trial_is_expired) {
                    return $q.reject("User's trial period is expired.");
                }

                if (meta.user.is_authenticated) {
                    return api.get('/registration/edit_user.json');
                } else {
                    return $q.reject('User is not authenticated.');
                }
            })
            .then(_refreshProfileEditForm, function _abort(reason) {
                _handlerError(_profileFormDeferred, 'profileForm', reason);
            });

        return _profileFormDeferred.promise;
    }

    function _get() {
        return angular.isDefined(_profileDeferred) ?
            _profileDeferred.promise : _profileService.refresh();
    }

    function _refresh() {
        _profileDeferred = $q.defer();
        $log.debug('_profileService.refresh()');

        metaService.get()
            .then(function _refreshFromMeta(meta) {
                if (meta.user.trial_is_expired) {
                    return $q.reject("User's trial period is expired.");
                }

                if (angular.isDefined(meta.user.profile_url)) {
                    return api.get(meta.user.profile_json_url);
                } else {
                    return $q.reject('Current user has no profile_url');
                }
            })
            .then(_refreshProfile, function _abort(reason) {
                _handlerError(_profileDeferred, 'profile', reason);

                $rootScope.$emit(_profileService.refreshFailedSignal);
            });

        return _profileDeferred.promise;
    }

    function _isValidEmailAddress(address, requireExisting, userId) {
        const deferred = $q.defer(),
            data = {
                identifier: address
            };

        if (angular.isDefined(userId)) {
            data.user_id = userId;
        }

        metaService.get().then(function _validateEmailAddress(meta) {
            api.get(meta.auth.login.is_valid_identifier_url, data)
                .then(function _resolve(result) {
                    deferred.resolve(requireExisting ?
                        result.data.is_valid : !result.data.is_valid);
                }, deferred.reject);
        });

        return deferred.promise;
    }

    function _isValidPhoneNumber(phoneNumber, requireExisting, userId) {
        const deferred = $q.defer(),
            data = {
                phone_number: phoneNumber
            };

        if (angular.isDefined(userId)) {
            data.user_id = userId;
        }

        metaService.get().then(function _validatePhoneNumber(meta) {
            api.get(meta.auth.login.is_valid_phone_number_url, data)
                .then(function _resolve(result) {
                    deferred.resolve(!requireExisting ?
                        result.data.is_unique : !result.data.is_unique);
                }, deferred.reject);
        });

        return deferred.promise;
    }

    function _phoneNumberStringToObject(phone_number) {
        const phoneNumberParts = phone_number ? phone_number.split(':', 2) :
            [null, ''];

        return {
            phone_number_country_code: phoneNumberParts[0],
            phone_number: phoneNumberParts[1].split(' ', 2)[1],
            rendered: phoneNumberParts[1]
        };
    }

    const _profileService = {
        actions: actionsResource,
        profile: _profile,
        profileForm: _profileForm,
        isRefreshedSignal: 'profile:isRefreshed',
        refreshFailedSignal: 'profile:refreshFailed',
        editFormIsRefreshedSignal: 'profile:editFormIsRefreshed',
        refresh: _refresh,
        get: _get,
        refreshEditForm: _refreshEditForm,
        getEditForm: _getEditForm,
        userSessions: userSessionsResource,
        changePassword: _changePassword,
        updateProfile: _updateProfile,
        refreshProfileImage: _refreshProfileImage,
        getMediaUploadInfo: _getMediaUploadInfo,
        isValidEmailAddress: _isValidEmailAddress,
        isValidPhoneNumber: _isValidPhoneNumber,
        phoneNumberStringToObject: _phoneNumberStringToObject
    };

    $rootScope.$on(metaService.isRefreshedSignal,
        function _refreshSharedResources() {
            $q.all([
                _profileService.refresh(),
                _profileService.refreshEditForm()
            ]);
        });

    return _profileService;
}

export default angular
    .module('profile.profileService', [])
    .factory('profileService', profileService);
