'use strict';

import angular from 'angular';

/* @ngInject */
function TrialExpiredController($scope, $state, api, user) {
    // public
    function _logout() {
        user.logout();
        $state.go('welcome', null, {reload: true});
    }
    $scope.backToLogin = _logout;

    function _resendEmail() {
        api.get('/trial_is_expired/resend_verification_email.json')
            .then(function (res) {
                if (res.data.status === 'success') {
                    $scope.emailSent = true;
                }

            });
    }
    $scope.resendEmail = _resendEmail;
}

export default angular
    .module('trialExpired.TrialExpiredController', [])
    .controller('TrialExpiredController', TrialExpiredController);
