'use strict';

import angular from 'angular';
import activityPreviewHeaderHtml from './activity-preview.header.html'
import activityPreviewHtml from './activity-preview.html'
import activityPreviewFooterHtml from './activity-preview.footer.html'

function activityPreviewState(name) {
    return {
        name: name,
        params: {activity: undefined},
        views: {
            'modal-header@': {
                controller: 'ActivityPreviewController',
                controllerAs: 'vm',
                templateUrl: activityPreviewHeaderHtml
            },
            'modal-contents@': {
                controller: 'ActivityPreviewController',
                controllerAs: 'vm',
                templateUrl: activityPreviewHtml
            },
            'modal-footer@': {
                controller: 'ActivityPreviewController',
                controllerAs: 'vm',
                templateUrl: activityPreviewFooterHtml
            }
        },
        onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.openModal({
                size: 'large'
            });
        },
        onExit: /* @ngInject */ function _updateLayout(coreLayoutService) {
            coreLayoutService.closeModal();
        },
        secure: true
    };
}

export default angular
    .module('activities.activityList.activityPreview.state', [])
    .constant('activityPreviewState', activityPreviewState);
