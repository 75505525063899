'use strict';

import $ from 'jquery';
import angular from 'angular';

/* @ngInject */
function api($http, config) {
    var _url = config.apiUrl;

    return {
        'url': _url,
        'get': function _get(path, data) {
            path = angular.isDefined(data) ? path + '?' + $.param(data) : path;
            return $http.get(_url + path);
        },
        'post': function _post(path, data, params) {
            if (params) {
                throw 'Params provided, but this ' +
                'post params handler is broken.';
            }
            return $http({
                method: 'POST',
                url: _url + path,
                data: data,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        },
        'postForm': function _postForm(path, data) {
            return $http({
                method: 'POST',
                url: _url + path,
                data: $.param(data),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        },
        'postJson': function _postForm(path, data) {
            return $http({
                method: 'POST',
                url: _url + path,
                data: data
            });
        },
        'deleteRequest': function _deleteRequest(path) {
            return $http({
                method: 'DELETE',
                url: _url + path
            });
        }
    };
}

export default angular
    .module('components.api', [])
    .factory('api', api);
