'use strict';

import angular from 'angular';
import sliderHtml from './slider.html'

/* @ngInject */
function slider($rootScope, activityService) {
    function _link(scope) {
        function _refreshSlider() {
            scope.sliderActivities = activityService.sliderActivities;
        }

        scope.carouselMeta = {
            index: null
        };

        if (activityService.sliderActivities.length) {
            _refreshSlider();
        }

        scope.$on('$destroy',
            $rootScope.$on(activityService.filtersUpdatedSignal, _refreshSlider));
    }

    return {
        scope: {
          carouselMeta: '=sliderMeta'
        },
        link: _link,
        templateUrl: sliderHtml
    };
}

export default angular
    .module('home.slider', [])
    .directive('slider', slider);
