'use strict';

import angular from 'angular';

/* @ngInject */
function EntryController($scope, $rootScope, $stateParams, $timeout, config,
                         questionnaireService, utility, Modernizr, gettext) {
    $rootScope.questionnaireVisible = false;

    $rootScope.title = gettext("Entry");  // TODO: Move to state.title.

    function _showQuestionnaire() {
        $rootScope.questionnaireVisible = true;
    }

    function _goToQuestion(question) {
        $scope.entry.navigator.goToQuestion(question);

        _showQuestionnaire();

        // Carousel bug (as River named it).
        $timeout(function _fakeResize() {
            angular.element(window).trigger('resize');
        }, 0);
    }

    $scope.goToQuestion = _goToQuestion;

    function _initEntryCtrl(questionnaire) {
        var startDateTime =
            utility.dateTime.stripSeconds(
                utility.dateTime.fromIso(questionnaire.activity.start_datetime));

        $scope.entry = questionnaire.activity;

        $scope.browser = {
            supportsInputTypeDate: Modernizr.inputtypes.date &&
            config.dateInputType === 'date'
        };
        $scope.dateInputType = config.dateInputType;
        $scope.dateValidRegex = config.dateValidRegex;

        if ($scope.browser.supportsInputTypeDate) {
            $scope.meta = {
                /* If either one of these attributes gets changed through
                 * input fields, the new value may be referring to a
                 * new Date object.  Thus, on submission, startDate and
                 * startTime _may_ refer to separate objects.
                 */
                startDate: startDateTime,
                startTime: startDateTime
            };
            $scope.today = utility.dateTime.todayAsIsoDate();
        } else {
            $scope.meta = {
                startDate: utility.dateTime.toIsoDate(startDateTime),
                startTime: utility.dateTime.toShortTime(startDateTime)
            };
        }
    }

    function _activate() {
        questionnaireService.getActivity($stateParams, {
            oneQuestionPerPage: true,
            isDiary: true,
            fromCache: false
        }).then(_initEntryCtrl);
    }

    _activate();
}

export default angular
    .module('activities.diary.EntryController', [])
    .controller('EntryController', EntryController);
