'use strict';

import angular from 'angular';
import ForgottenPasswordController from './forgotten-password.controller.js'
import ResetPasswordController from './reset-password.controller.js'
import forgottenPasswordHtml from './forgotten-password.html'
import resetPasswordHtml from './reset-password.html'

/* @ngInject */
function config($stateProvider, gettext) {
    $stateProvider
        .state({
            name: 'welcome.modal.forgotten-password',
            url: '/forgotten-password',
            title: gettext('Forgotten password'),
            views: {
                'modal-contents@': {
                    controller: 'ForgottenPasswordController',
                    controllerAs: 'vm',
                    templateUrl: forgottenPasswordHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.updateModal({
                    size: 'small',
                    footer: {
                        hidden: coreLayoutService.defaultExcept({}, true),
                        visible: coreLayoutService.defaultExcept()
                    }
                });
            },
            secure: false,
            isLogin: true
        })
        .state({
            name: 'welcome.modal.reset-password',
            url: '/reset-password/{userId:int}/{key:string}',
            title: gettext('Reset password'),
            views: {
                'modal-contents@': {
                    controller: 'ResetPasswordController',
                    controllerAs: 'vm',
                    templateUrl: resetPasswordHtml
                }
            },
            onEnter: /* @ngInject */ function _updateLayout(coreLayoutService) {
                coreLayoutService.updateModal({
                    size: 'medium',
                    footer: {
                        hidden: coreLayoutService.defaultExcept({}, true),
                        visible: coreLayoutService.defaultExcept()
                    }
                });
            },
            secure: false,
            isLogin: true
        });
}

export default angular
    .module('welcome.forgotten-password', [
        ForgottenPasswordController.name,
        ResetPasswordController.name
    ])
    .config(config);
