'use strict';

import angular from 'angular';
import _filter from 'lodash/filter'
import _keyBy from 'lodash/keyBy'
import _merge from 'lodash/merge'
import _forEach from "lodash/forEach";
import {MessageOverviewStatsServiceFactory} from "./message-overview-stats-service.factory";


/* @ngInject */
function MessagesController($rootScope, $scope, $state, $log, gettext, activeFiltersService, messageAdmin,
                            messageOverviewStatsService, utility) {
    const vm = this;

    vm.$state = $state;

    function _querySource() {
        messageAdmin.clearCurrent();

        const filters =
            activeFiltersService.getQueryConstraints(), queryParams =
            _merge(messageAdmin.queryParamsFromFilters(filters), {
                extra_columns: ['email_address'],
                search_term: vm.searchTerm,
                start_date: utility.dateTime.toIsoDate(vm.dateRange.start),
                end_date: utility.dateTime.toIsoDate(vm.dateRange.end),
                sql: false,
            } /*, config.resourceExtraQueryParams*/);

        messageAdmin.status.isSearching = true;
        messageAdmin.status.hasSearched = true;
        
        messageAdmin.query(queryParams).$promise
            .then((response) => {
                utility.updateSharedObject(messageAdmin.currentUserIndex,
                    _keyBy(response.users, 'user_id'));

                _forEach(response.messages, (message) => {
                    message.recipientCount = message.deliveries.length;
                    message.openedCount = _filter(message.deliveries, 'is_opened').length;

                    const sentCount = _filter(message.deliveries, 'is_sent').length;

                    message.stats = messageOverviewStatsService.createDataSet(
                        [
                            message.recipientCount - sentCount, /* Not sent. */
                            sentCount - message.openedCount,    /* Just sent. */
                            message.openedCount,                /* Opened. */
                        ]);
                });
                utility.updateSharedArray(messageAdmin.currentMessages,
                    response.messages);

                messageAdmin.status.processingMs = response.processing_ms.toFixed(3);
                vm.sqlExpression = response.sql;
                vm.total = response.totalMessages;

                messageAdmin.status.isSearching = false;
            }, function _handleFailure(reason) {
                $log.error(reason);
            });
    }

    function _activate() {
        vm.tabs = [
            {
                title: gettext('List'),
                icon: 'fa-list',
                state: 'admin.messages.list'
            },
            // {
            //     title: gettext('Stats'),
            //     icon: 'fa-bar-chart',
            //     state: 'admin.messages.stats'
            // },
            {
                title: gettext('Edit Follow-up'),
                icon: 'fa-reply-all',
                state: 'admin.messages.follow-up',
                hideWhenInactive: true
            }
        ];

        vm.tabs.activeTab = 0;

        vm.searchTerm = ''; /*'Ny aktivitet om rotgrønnsaker'*/
        vm.dateRange = {
            //start: utility.dateTime.fromIso('2018-06-13'), // addMonths(new Date(), -1),
            start: addMonths(new Date(), -1),
            end: new Date(),
        };
                
        const listenerDestroyers = [
            $rootScope.$on(activeFiltersService.updatedSignal, _querySource)
        ];

        $scope.$on('$destroy', function _destroyListeners() {
            _forEach(listenerDestroyers, utility.call);
        });

        vm.updateMessageList = () => {
            _querySource();
        };

        // _querySource();
    }

    function addMonths(date, count) {
        if (date && count) {
            const d = (date = new Date(+date)).getDate();
            date.setMonth(date.getMonth() + count, 1);
            const m = date.getMonth();
            date.setDate(d);
            if (date.getMonth() !== m) {
                date.setDate(0);
            }
        }
        return date;
    }

    _activate();
}

export default angular
    .module('admin.messages.MessagesController', [
        MessageOverviewStatsServiceFactory.name
    ])
    .controller('MessagesController', MessagesController);
